import React, { useState } from "react"
import Col from "../components/Col"
import Container from "../components/Container"
import LabeledField from "../components/Form/LabeledField"
import SimpleForm from "../components/Form/SimpleForm"
import Row from "../components/Row"
import { post } from "../lib/api"
import { useActions } from "../lib/overmind"

const ForcePasswordChange = () => {
  const actions = useActions().user

  const apiSubmitHandler = async (data) => {
    await post("/reset_password", data)
    actions.updateForcePasswordChange(false)
  }

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col variant="md-4">
          <h4>Please change your password</h4>
          <p className="mb-4">
            Your password needs to be changed. It must be over 8 characters
            long.
          </p>
          <SimpleForm apiSubmitHandler={apiSubmitHandler} initialValues={{}}>
            <LabeledField
              label="Password"
              name="new_password"
              type="password"
              className="form-control"
              required
              minlength={10}
            />
          </SimpleForm>
        </Col>
      </Row>
    </Container>
  )
}

export default ForcePasswordChange
