import React from "react"
import { Field } from "formik"
import SimpleForm from "../../components/Form/SimpleForm"
import { useActions } from "../../lib/overmind"

const NpsEditNote = ({ id, note, score, onCancel, onChange }) => {
  const actions = useActions().nps

  const apiSubmitHandler = async (data) => {
    await actions.updateNote({ id, score, note: data.note })
    onChange(data.note)
  }
  return (
    <div className="mb-3">
      <SimpleForm initialValues={{ note }} apiSubmitHandler={apiSubmitHandler}>
        <div className="d-flex mb-1">
          <label className="text-secondary fs-14" htmlFor="noteControl">
            Private Note
          </label>
          <button
            onClick={() => onCancel()}
            className="text-secondary fs-14 btn btn-link ms-auto p-0"
            type="button">
            Cancel
          </button>
        </div>

        <Field
          id="noteControl"
          className="form-control"
          as="textarea"
          rows="3"
          name="note"
        />
      </SimpleForm>
    </div>
  )
}

export default NpsEditNote
