import React from "react";
import moment from "moment";
import ReviewsBaseOverview from "./ReviewsBaseOverview";

const sampleResponse = {
  connection_status: {
    is_google_connected: true,
    needs_setup: false,
  },
  reviews: [
    {
      id: "accounts/105038958952864552192/locations/17558363329444277129/reviews/AbFvOqlh3SysZUF8spDo7L0H0DWKgNcijsXNf305haX_sw6eKHaTyU_lSndCkT3FR0TKX0htriOiLw",
      rating: 5,
      create_time: moment().subtract(1, "minutes").toISOString(),
      update_time: moment().subtract(1, "minutes").toISOString(),
      reviewer: {
        profile_photo:
          "https://lh3.googleusercontent.com/a/ACg8ocIRQzo8_y4zPZ-Nnthu57eQEGhT3P8NiUA9O4lPo7R0=s120-c-rp-mo-br100",
        display_name: "Jessica Williams",
      },
      comment:
        "(Example) Fantastic bike shop with a wide selection, knowledgeable staff, and great prices – my go-to place for all my cycling needs!",
      review_reply: {
        comment: "(Example) Thank you for your kind words, happy riding!",
        update_time: moment().subtract(5, "minutes").toISOString(),
      },
    },
    {
      id: "accounts/105038958952864552192/locations/17558363329444277129/reviews/AbFvOqmoEO0--G14UL_FQBXi1h9zkZmYHUayQiHxCQkv-ph-BbKVlzbt0PgTkBBj1jiYDEb1tIBl4g",
      rating: 3,
      create_time: moment().subtract(1, "days").toISOString(),
      update_time: moment().subtract(1, "days").toISOString(),
      reviewer: {
        profile_photo:
          "https://lh3.googleusercontent.com/a/ACg8ocIo3-5DLGfBSJlpzRC0fEtWTNz52WzpIvko5fwpJuSM=s120-c-rp-mo-br100",
        display_name: "Brian O'Conor",
      },
      comment:
        "(Example) Visited the bike shop and had a disappointing experience as the staff completely ignored me during my entire visit. I expected better customer service and assistance.",
      review_reply: {
        comment:
          "(Example) Thanks for lettings us know. We apologize for your unsatisfactory experience during your visit to our shop. Customer service is a priority for us, and we'll address this issue to ensure it doesn't happen again in the future.",
        update_time: moment()
          .subtract(1, "days")
          .add(12, "minutes")
          .toISOString(),
      },
    },
    {
      id: "accounts/105038958952864552192/locations/17558363329444277129/reviews/AbFvOql86xaUc2_2xmZYbO3okjBbollQaJqVtQTd1_eCPsAPcSvtbLKMh03ojxk9lh8CFO44xQ05pQ",
      rating: 5,
      create_time: moment().subtract(1, "days").add(3, "hours").toISOString(),
      update_time: moment().subtract(1, "days").add(3, "hours").toISOString(),
      reviewer: {
        profile_photo:
          "https://lh3.googleusercontent.com/a/ACg8ocJ2ido0-uScUQrt7w52tn133kLBoir4S8vkMOhfeGYIVA=s120-c-rp-mo-br100",
        display_name: "Desmond Turner",
      },
      comment:
        "(Example) I had an absolutely fantastic experience at this bike shop! Not only did I find the perfect bike, but the staff went above and beyond to ensure it was expertly fitted to my specifications. The level of service and attention to detail was exceptional, making my purchase a true pleasure.",
    },
  ],
  average_rating: 4.8,
  total_review_count: 418,
};
const dataPoints = {
  averageRating: sampleResponse.average_rating,
  totalSent: sampleResponse.total_review_count,
};

const ReviewsDemoOverview = (props) => {
  return (
    <div>
      <ReviewsBaseOverview
        reviews={sampleResponse.reviews}
        dataPoints={dataPoints}
        {...props}
      />
      <div className="my-2 alert alert-info py-1 d-inline-block mt-3">
        An example of the Reviews dashboard, go ahead and upgrade to{" "}
        <b>see your reviews</b> 😀
      </div>
    </div>
  );
};

export default ReviewsDemoOverview;
