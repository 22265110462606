import * as React from "react";
import { useState } from "../lib/overmind";

const SystemMessage = () => {
  const state = useState().account;
  const message = state.accountMessage;
  if (!message) return <div />; // Need to return an empty div for now based on the way the grid layout in App.js is laid out. Not optimal
  return (
    <div
      className="alert alert-warning p-1 mb-0 position-sticky"
      style={{ top: 0 }}
    >
      {message}
    </div>
  );
};

export default SystemMessage;
