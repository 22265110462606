import React, { useEffect } from "react";
import Modal, { toggleModal } from "../components/Modal";
import Browser, { useCurrentBrowser } from "../lib/browser";

const cookieDisabledModalId = "cookie-disabled-warning-modal";

const chromeUrl =
  "https://support.google.com/accounts/answer/61416?hl=en&co=GENIE.Platform%3DDesktop";
const firefoxUrl =
  "https://support.mozilla.org/en-US/kb/websites-say-cookies-are-blocked-unblock-them";

const GeneralCookieWarning = () => {
  const browser = useCurrentBrowser();
  let url = chromeUrl;
  if (browser === Browser.firefox) {
    url = firefoxUrl;
  }

  useEffect(() => {
    toggleModal(cookieDisabledModalId);
  }, []);

  return (
    <Modal id={cookieDisabledModalId}>
      <div className="d-flex flex-column gap-2 mb-3">
        <h5 className="fw-bold">
          <i className="bi-exclamation-circle-fill me-1 text-warning" /> Cookies
          are currently disabled and preventing you from using Ikeono.
        </h5>
        <p>
          To correct the issue please follow this guide. It takes only a couple
          of minutes to resolve the issue.
        </p>
        <a
          className="btn btn-primary"
          href={url}
          target="_blank"
          rel="noreferrer"
        >
          Open the Guide
        </a>
      </div>
    </Modal>
  );
};

export default GeneralCookieWarning;
