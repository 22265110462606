import React from "react";
import { SupportAnchor } from "../../lib/ikeono";

const AppStatusOperational = () => {
  return (
    <div className="alert alert-success" role="alert">
      <h4 className="alert-heading">All Systems Operational</h4>
      <p>
        All systems are operating normally with no known issues. If you need
        help with something you can chat with us or email us at{" "}
        <SupportAnchor />{" "}
      </p>
    </div>
  );
};

export default AppStatusOperational;
