import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import OffCanvas, { hideOffcanvas } from "../components/OffCanvas";
import { usePosConnectedEvent } from "../lib/pos";
import TrialExpiringSoon from "./TrialExpiringSoon";
import PointOfSaleDisconnected from "./PointOfSaleDisconnected";
import EinWarning from "./EinWarning";

export const accountUpdatesOffCanvasId = "accountUpdatesOffCanvas";

/*
This is intended to house all account level notifications, warnings, and info, but
at the moment it only has logic for the POS Disconnected state which should be
refactored into a separate component.
*/

const AccountUpdatesOffCanvas = () => {
  const location = useLocation();

  usePosConnectedEvent(() => {
    hideOffcanvas(accountUpdatesOffCanvasId);
  });

  useEffect(() => {
    hideOffcanvas(accountUpdatesOffCanvasId);
  }, [location]);

  return (
    <OffCanvas title="Important Notices" datatarget={accountUpdatesOffCanvasId}>
      <div className="d-flex gap-3 flex-column">
        <EinWarning />
        <TrialExpiringSoon />
        <PointOfSaleDisconnected />
      </div>
    </OffCanvas>
  );
};

export default AccountUpdatesOffCanvas;
