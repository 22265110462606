import React from "react"
import { useMixpanel } from "../../services/mixpanel"

const SetupNavigation = ({
  currentStepIndex,
  steps,
  setCurrentStepIndex,
  hideContinueButton,
}) => {
  const mixpanel = useMixpanel()

  return (
    <div
      className="d-none d-lg-flex gap-3 w-100 justify-content-end me-2 me-md-5 position-absolute "
      style={{ right: "25px", bottom: "25px" }}>
      <button
        type="button"
        className="btn btn-outline-secondary btn-sm"
        hidden={currentStepIndex === 0}
        onClick={() => {
          setCurrentStepIndex(currentStepIndex - 1)
        }}>
        Previous
      </button>
      <button
        type="button"
        className="btn btn-primary btn-sm"
        hidden={hideContinueButton}
        onClick={() => {
          const currentStep = steps[currentStepIndex]
          mixpanel.track(`complete ${currentStep.trackingName}`, {
            action: "continue without setup",
          })
          setCurrentStepIndex(currentStepIndex + 1)
        }}>
        Continue
      </button>
    </div>
  )
}
export default SetupNavigation
