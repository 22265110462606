import React from "react";
import SplitPanelFeatureUpgrade from "../FeatureUpgrade/SplitPanelFeatureUpgrade";
import { PricingPlans } from "../components/UpgradeAccountButtonV2";

const MessengerUpgrade = () => {
  const body = (
    <>
      <p style={{ fontSize: "18px" }}>
        Do not miss out on the opportunity to connect with your customers where
        they are; upgrade now and harness the power of Facebook Messenger to
        build stronger relationships and drive meaningful engagement.
      </p>
      <p style={{ fontSize: "18px" }}>
        Data shows that consumers are 53% more likely to buy from a business
        they can message.
      </p>
    </>
  );

  return (
    <SplitPanelFeatureUpgrade
      title="Messenger"
      learnMoreUrl="https://www.ikeono.com/blog/facebook-business-tools"
      plan={PricingPlans.standard}
      imageUrl="https://images.squarespace-cdn.com/content/v1/63a3448b4bb6213ae43afb6d/788bcced-d9ef-4ce6-8311-ccdfef61a580/Inbox+Backoffice+Details+%281%29.png?format=2500w"
    >
      {body}
    </SplitPanelFeatureUpgrade>
  );
};

export default MessengerUpgrade;
