import * as api from "../../api"

export const getInvoices = async (params) => {
  const response = await api.get("/shop/{exid}/invoice", params)
  return response.data
}

export const voidPayment = async (id) => {
  const response = await api.put(`/shop/{exid}/invoice/${id}`, {
    status: "void",
  })
  return response.data
}
