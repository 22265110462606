import React, { useEffect } from "react"
import InboxMessageList from "../Thread/InboxMessageList"
import InboxMessageInput from "../Thread/InboxMessageInput"
import { useActions, useState } from "../lib/overmind"
import PhoneErrorWarning from "./PhoneErrorWarning"
import BrowserExtensionSiderailHeader from "./BrowserExtensionSiderailHeader"
import InboxCreateModal from "../InboxCreate/InboxCreateModal"
import { postMessageToParent } from "../lib/overmind/browserExtensionEventBus/parentWindow"
import APIResponseError from "../components/APIResponseError"
import { getAccountCountryCode } from "../lib/overmind/account/util"
import InboxInvoice from "../Invoice/InboxInvoice"

const BrowserExtensionMessageSideRail = () => {
  const state = useState()
  const actions = useActions()
  const { activeThread } = state

  useEffect(() => {
    postMessageToParent({
      name: "browser_extension_siderail_ready",
      parameters: {
        country: getAccountCountryCode(),
      },
    })
  }, [])

  if (state.browserExtensionEventBus.phoneError) {
    return (
      <PhoneErrorWarning
        phoneNumber={state.browserExtensionEventBus.phoneNumber}
        error={state.browserExtensionEventBus.phoneError}
      />
    )
  }

  if (state.browserExtensionEventBus.error) {
    console.log("tmp: ERROR: ", state.browserExtensionEventBus.error)
    return (
      <APIResponseError
        className="m-3"
        response={state.browserExtensionEventBus.error}
      />
    )
  }

  const initialLineItems = []
  let posDeposit = null
  const { params } = state.browserExtensionEventBus

  if (params?.customerId || params?.registerId) {
    posDeposit = {
      customerId: params?.customerId,
      employeeId: params?.employeeId,
      primaryId: params?.primaryId,
      primaryType: params?.primaryType,
      registerId: params?.registerId,
    }
  }
  if (params?.saleAmount) {
    initialLineItems.push({
      amount: parseFloat(params.saleAmount),
      description: params?.saleDescription,
    })
  }

  return (
    <div
      className="w-100 d-flex flex-column"
      style={{ height: "calc(100vh - 10px)" }}>
      <BrowserExtensionSiderailHeader
        hidden={activeThread.isLoading}
        threadId={activeThread.conversationId}
      />
      <InboxMessageList
        threadId={activeThread.conversationId}
        isLoading={activeThread.isLoading}
        messages={activeThread.messages}
        apiError={activeThread.apiError}
        actions={actions}
      />
      <InboxMessageInput hidden={activeThread.isLoading} />
      <InboxInvoice
        initialLineItems={initialLineItems}
        posDeposit={posDeposit}
        hidden={activeThread.isLoading}
      />
      <InboxCreateModal />
    </div>
  )
}

export default BrowserExtensionMessageSideRail
