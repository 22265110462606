/* eslint-disable import/no-duplicates */
import {
  createStateHook,
  createActionsHook,
  createEffectsHook,
  createReactionHook,
  Provider,
} from "overmind-react"
import { namespaced } from "overmind/config"
import { createOvermind } from "overmind"
import React from "react"

import * as account from "./account"
import * as payments from "./payments"
import * as lightspeed from "./lightspeed"
import * as activeInbox from "./activeInbox"
import * as activeThread from "./activeThread"
import * as templates from "./templates"
import * as user from "./user"
import * as events from "./events"
import * as location from "./location"
import * as inboxes from "./inboxes"
import * as contacts from "./contacts"
import * as threadCreateContacts from "./contacts"
import * as users from "./users"
import * as browserExtensionEventBus from "./browserExtensionEventBus"
import * as toast from "./toast"
import * as tracking from "./tracking"
import * as integrations from "./integrations"
import * as pushNotification from "./pushNotification"
import * as nps from "./nps"
import * as google from "./google"
import * as reports from './reports'

const config = namespaced({
  account,
  payments,
  lightspeed,
  activeInbox,
  activeThread,
  templates,
  user,
  events,
  location,
  inboxes,
  contacts,
  threadCreateContacts,
  users,
  browserExtensionEventBus,
  toast,
  tracking,
  integrations,
  pushNotification,
  nps,
  google,
  reports
})

export const useState = createStateHook()
export const useActions = createActionsHook()
export const useEffects = createEffectsHook()
export const useReaction = createReactionHook()

export const overmindInstance = createOvermind(config, {
  devtools: "localhost:3032",
  name: "Web App",
})

export const OvermindProvider = ({ children }) => (
  <Provider value={overmindInstance}>{children}</Provider>
)
