import React from "react";
import VendConnectButton from "./VendConnectButton";

const VendDisconnectedWarningToast = () => (
  <div className="my-2">
    <h6 className="text-nowrap fw-bold">
      Lightspeed X-Series needs reconnecting.
    </h6>
    <p className="fs-16">
      Ikeono has become disconnected from Lightspeed and needs to be
      reconnected.
    </p>
    <VendConnectButton />
  </div>
);

export default VendDisconnectedWarningToast;
