import React from "react"
import { enableTooltips } from "./Tooltip"

const InformationTooltip = ({ title, children, hideTooltip }) => {
  React.useEffect(() => {
    enableTooltips()
  }, [])

  if (hideTooltip) {
    return children
  }

  return (
    <div data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title={title}>
      {children}
    </div>
  )
}

export default InformationTooltip
