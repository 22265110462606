import React from "react";
import { useActions, useState } from "../../lib/overmind";
import InboxListZeroState from "../../Inbox/InboxListZeroState";
import Dropdown from "../../components/Dropdown";
import Loader from "../../components/Loader";

const InboxChangeDropDown = ({ inboxId, threadId }) => {
  const state = useState();
  const actions = useActions();
  const [refresh, setRefresh] = React.useState(false);

  const inboxes = actions.activeThread.getAssignedInboxes();
  const inboxIds = inboxes?.map((i) => i.id) || [];
  const hasInboxes = !!state.inboxes.inboxes?.length;
  let isAssignedToInbox = false;

  return (
    <Dropdown
      onOpen={() => actions.inboxes.get()}
      className="btn btn-link text-black fs-14 dropdown-toggle px-0"
      title="Assign Inbox"
      dropdownMenuClassName="dropdown-menu-end"
    >
      <InboxListZeroState
        className="fs-14 p-2"
        hidden={hasInboxes || state.inboxes.isLoading}
      />
      <Loader className="my-3" hidden={!state.inboxes.isLoading} />
      <div
        className="overflow-y-scroll"
        style={{ maxHeight: "calc(100vh - 200px)" }}
      >
        {state.inboxes.inboxes?.map((inbox) => {
          const isSelected = inboxIds.includes(inbox.id);
          const visibility = isSelected ? null : "hidden";
          isAssignedToInbox = isAssignedToInbox || isSelected;
          return (
            <li key={inbox.id}>
              <a
                role="button"
                className="dropdown-item  text-capitalize"
                onClick={() => {
                  if (isSelected) {
                    actions.activeThread.assignThreadToInbox({
                      threadId,
                      previousInboxId: inboxId,
                    });
                  } else {
                    actions.activeThread.assignThreadToInbox({
                      threadId,
                      newInbox: inbox,
                    });
                  }
                  setRefresh(!refresh);
                }}
              >
                <i style={{ visibility }} className="bi-check me-1" />
                {inbox.title}
              </a>
            </li>
          );
        })}
      </div>

      <li hidden={!hasInboxes || !isAssignedToInbox}>
        <a
          role="button"
          className="dropdown-item"
          onClick={() => {
            actions.activeThread.assignThreadToInbox({
              threadId,
              previousInboxId: inboxId,
            });
          }}
        >
          <i style={{ visibility: "hidden" }} className="bi-check me-1" />
          Unassign
        </a>
      </li>
    </Dropdown>
  );
};

export default InboxChangeDropDown;
