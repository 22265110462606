import React from "react";

const Status = ({ status }) => {
  let color = "secondary";

  if (status === "complete") {
    color = "success";
  } else if (status === "pending") {
    color = "warning";
  } else if (status === "error") {
    color = "danger";
  }

  return (
    <span className={`badge text-bg-${color} text-capitalize`}>{status}</span>
  );
};

const MessageInvoiceCardDepositInfo = ({ status, depositError }) => {
  let description = "";

  if (status === "void") {
    description = "A deposit will not be added to the customer.";
  } else if (status === "pending") {
    description = "A deposit will be added to the customer on payment.";
  } else if (status === "complete") {
    description = "A deposit was successfully added to the customer.";
  } else if (status === "error") {
    description = "There was an error adding this deposit.";
  }

  return (
    <>
      <hr />
      <div className="d-flex flex-column">
        <div className="d-flex">
          <div className="flex-grow-1" style={{ fontSize: "15px" }}>
            <b>Customer Deposit</b>
            <span className="mt-1 d-block">
              <small>{description}</small>
            </span>
            <span className="mt-1 text-danger">{depositError}</span>
          </div>
          <div>
            <Status status={status} />
          </div>
        </div>
      </div>
    </>
  );
};

export default MessageInvoiceCardDepositInfo;
