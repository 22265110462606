/* eslint-disable no-restricted-globals */
/* eslint-disable no-nested-ternary */
export const openPopup = (url, title, width, height) => {
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;

  const windowWidth = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : screen.width;
  const windowHeight = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : screen.height;

  const systemZoom = windowWidth / window.screen.availWidth;
  const left = (windowWidth - width) / 2 / systemZoom + dualScreenLeft;
  const top = (windowHeight - height) / 2 / systemZoom + dualScreenTop;

  const newWindow = window.open(
    url,
    title,
    `
    width=${width / systemZoom},
    height=${height / systemZoom},
    top=${top},
    left=${left},
    resizable=yes,
    scrollbars=yes,
    toolbar=yes,
    menubar=no,
    location=no,
    directories=no,
    status=yes`,
  );

  if (window.focus) newWindow.focus();
};

export const closePopup = () => {
  const daddy = window.self;
  daddy.opener = window.self;
  daddy.close();
};
