import React from "react"
import NpsEditScore from "./NpsEditScore"
import { getVariant } from "../rating"
import { useState } from "../../lib/overmind"

const ScoreBadge = ({ score }) => {
  const variant = getVariant(score)
  return (
    <div
      style={{ height: "45px", width: "50px", fontSize: "18px" }}
      className={`d-inline-flex fw-semibold mb-3 px-2 text-${variant}-emphasis bg-${variant}-subtle border border-${variant}-subtle rounded-2 align-self-start justify-content-center align-items-center`}>
      {score}
    </div>
  )
}

const NpsScoreHeader = ({ id, score, onChange }) => {
  const [editing, setEditing] = React.useState()
  const state = useState().account

  if (editing) {
    return (
      <NpsEditScore
        id={id}
        score={score}
        onCancel={() => {
          setEditing(false)
        }}
        onSave={(newScore) => {
          onChange(newScore)
          setEditing(false)
        }}
      />
    )
  }

  let title = "Detractor"
  let description = "Unhappy and unlikely to buy from you again"
  if (score >= 9) {
    title = "Promoter"
    description = "Loyal and enthusiastic"
  } else if (score >= 8) {
    title = "Neutral"
    description = "Satisfied but not happy enough to be a promoter"
  }

  return (
    <div className="d-flex flex-row gap-2">
      <ScoreBadge score={score} />
      <div className="d-flex flex-column w-100">
        <div className="d-flex">
          <span className="h5 mb-0">{title}</span>
          <button
            type="button"
            className="btn btn-link ms-auto fs-14 p-0"
            onClick={() => {
              if (!state.data.user.permissions.can_edit_nps_score) {
                alert("You do not have permission to edit the score.")
                return
              }
              setEditing(true)
            }}>
            Edit Score
          </button>
        </div>
        <span className="fs-13">{description}</span>
      </div>
    </div>
  )
}

export default NpsScoreHeader
