/* eslint-disable no-unreachable */
import React from "react";
import { Link, useNavigate } from "react-router-dom";

const NavigateBackLink = (props) => {
  const className = `btn btn-text ps-0 ${props?.className || ""}`;
  const children = (
    <span>
      <i className="bi-arrow-left me-1" />
      {props?.children || "Back"}
    </span>
  );

  const navigate = useNavigate();
  if (props.to) {
    return (
      <Link className={className} to={props.to}>
        {children}
      </Link>
    );
  }

  return (
    <a
      role="button"
      className={className}
      onClick={() => {
        navigate(-1);
      }}
      {...props}
    >
      {children}
    </a>
  );
};

export default NavigateBackLink;
