import React from "react"

const DefaultNoPermissionWarning = () => {
  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col text-center">
          <h5>Permission Denied</h5>
          <p>Talk to your account owner about accessing this feature.</p>
        </div>
      </div>
    </div>
  )
}

export default DefaultNoPermissionWarning
