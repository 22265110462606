/* eslint-disable no-restricted-syntax */
export const get = async ({ state, effects }, queryParams) => {
  if (state.location.locations?.length && !state.location.isDirty) return

  state.location.apiError = null
  state.location.isLoading = true
  state.location.locations = []

  console.log(`tmp: Query Params`, queryParams?.includeUnreadCounts)

  try {
    const data = await effects.location.getLocations({
      query: state.location.filter?.query,
      showOnlyUnread: state.location.filter?.showOnlyUnread,
      includeUnreadCounts: queryParams?.includeUnreadCounts,
    })
    state.location.locations = data.data

    state.location.indexedLocations = {}
    if (!data.data) return
    for (const location of data.data) {
      state.location.indexedLocations[location.id] = location
    }
    state.location.isDirty = false
  } catch (error) {
    state.location.apiError = error
  }
  state.location.isLoading = false
}

export const updateFilter = ({ state, actions }, filter) => {
  if (JSON.stringify(state.location.filter) === JSON.stringify(filter)) return
  state.location.filter = filter
  state.location.isDirty = true
  actions.location.get()
}

export const onInitializeOvermind = ({ state }, instance) => {
  instance.reaction(
    ({ account }) => account.data,
    () => {
      state.location.filter = null
    }
  )
}
