import React from "react";
import { useState } from "../lib/overmind";
import { PosSystem } from "../lib/pos";

const PosVisibility = ({
  children,
  showLightspeed,
  showStandalone,
  hidden,
  showAllButStandalone,
}) => {
  if (hidden) return null;
  const posSystem = useState().account?.posSystem;
  if (!posSystem) return children;

  if (showAllButStandalone && posSystem !== PosSystem.Standalone) {
    return children;
  }

  if (showLightspeed && posSystem === PosSystem.LightspeedRetail) {
    return children;
  }

  if (showStandalone && posSystem === PosSystem.Standalone) {
    return children;
  }

  return null;
};

export default PosVisibility;
