import React from "react";
import { Tooltip } from "../Tooltip";

/*
children: [
  {
    name: 'First name",
    value: "{first name}"
  },
]
*/

export class MergeField {
  constructor(name, value, description) {
    this.name = name;
    this.value = value;
    this.description = description;
  }
}

export const CommonMergeFields = {
  firstName: new MergeField(
    "First Name",
    "{first name}",
    "The first name of the contact.",
  ),
  lastName: new MergeField(
    "Last Name",
    "{last name}",
    "The last name of the contact.",
  ),
  shopName: new MergeField(
    "Shop Name",
    "{shop}",
    "The name of the current location.",
  ),
};

const MergeFields = ({ children, onClick }) => {
  return (
    <div className="d-flex flex-row gap-2 flex-wrap">
      {children?.map(({ name, value, description }) => (
        <button
          title={description}
          type="button"
          className="btn btn-sm btn-outline-primary p-0 px-1"
          key={name}
          onClick={() => onClick(value)}
        >
          {name}
        </button>
      ))}
    </div>
  );
};

export default MergeFields;
