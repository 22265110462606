import React from "react";
import VerticalNav, { NavLink } from "../components/VerticalNav";

const ReportsNav = ({ reports }) => {
  return (
    <div
      className="d-none d-sm-block border-end overflow-scroll"
      style={{ minWidth: "235px", height: "calc(100vh - 125px)" }}
    >
      <VerticalNav style={{ top: "0px" }}>
        <h5 className="fw-bold mx-2 mt-3">Reports</h5>
        {reports.map(({ title, report_id }) => (
          <NavLink to={`./${report_id}`}>{title}</NavLink>
        ))}
      </VerticalNav>
    </div>
  );
};

export default ReportsNav;
