import React from "react";

const MessageCardStatus = ({ status }) => {
  let icon = "";
  switch (status) {
    case "delivered":
      icon = "bi-check-circle";
      break;
    case "failed":
      icon = "bi-x-circle text-danger";
      break;
    case "queued":
      icon = "bi-hourglass";
      break;
  }
  return <i style={{ fontSize: "12px" }} className={icon} />;
};

export default MessageCardStatus;
