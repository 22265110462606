import React from "react";
import { formatDate } from "../../lib/date";

const IncidentUpdate = ({ status, body, updatedAt }) => {
  return (
    <div className="border-bottom pb-3">
      <div className="my-2">
        <span className="fw-bold text-capitalize">{status}</span> -{" "}
        <span>{body}</span>
      </div>
      <span className="fs-14 text-secondary">{formatDate(updatedAt)}</span>
    </div>
  );
};

export default IncidentUpdate;
