import React from "react"

const _ = require("lodash")

const EmptyStar = () => <i className="bi-star  text-warning" />
const HalfStar = ({ hidden }) => {
  if (hidden) return null
  return <i className="bi-star-half text-warning" />
}
const FullStar = () => <i className="bi-star-fill  text-warning" />

const Stars = ({ className = "", rating = 5, maxStars = 5, style }) => {
  const fullStars = _.range(Math.floor(rating))
  const showHalfStar = rating - Math.floor(rating) >= 0.5
  const emptyStars = _.range(Math.ceil(maxStars - rating) - +showHalfStar)

  return (
    <div className={`d-flex ${className}`} style={{ gap: "3px", ...style }}>
      {fullStars.map(() => (
        <FullStar />
      ))}
      <HalfStar hidden={!showHalfStar} />
      {emptyStars.map(() => (
        <EmptyStar />
      ))}
    </div>
  )
}

export default Stars
