/* eslint-disable camelcase */
import React from "react"
import Badge from "../../components/Badge"
import { getVariant } from "../rating"

const BoolAnswer = ({ answer }) => {
  const variant = answer ? "success" : "danger"
  return <Badge variant={variant}>{answer ? "Yes" : "No"}</Badge>
}

const RatingAnswer = ({ answer }) => {
  return <Badge variant={getVariant(answer)}>{answer}</Badge>
}

const TextAnswer = ({ answer }) => {
  return <Badge variant="secondary">{answer}</Badge>
}

const AnswerFactory = ({ answer, questionType }) => {
  if (questionType === "rating") {
    return <RatingAnswer answer={answer} />
  }
  if (questionType === "boolean") {
    return <BoolAnswer answer={answer} />
  }
  return <TextAnswer answer={answer} />
}

const QuestionRow = ({ question, answer, questionType }) => {
  return (
    <>
      <div>
        <div className="mb-1">{question}</div>
        <AnswerFactory answer={answer} questionType={questionType} />
      </div>
      <hr />
    </>
  )
}

const SurveyQuestionList = ({ questions }) => {
  if (!questions) return null
  return (
    <div>
      <div className="fw-semibold mb-2 ">Survey Questions</div>
      {questions.map(({ q, a, question_type }) => (
        <QuestionRow
          key={q}
          question={q}
          answer={a}
          questionType={question_type}
        />
      ))}
    </div>
  )
}

export default SurveyQuestionList
