import React from "react"
import { useState } from "../lib/overmind"
import "./style.css"

export const attachmentDetailModalId = "mms-modal"

const AttachmentDetailModal = () => {
  const state = useState().activeThread

  return (
    <div
      className="modal fade"
      id={attachmentDetailModalId}
      tabIndex="-1"
      aria-hidden="true">
      <div className="modal-dialog my-0 attachment-modal-img h-100 d-flex align-items-center justify-content-center">
        <div
          className="modal-content bg-transparent d-inline-flex"
          style={{ width: "auto" }}>
          <img
            alt="Example"
            className="object-fit-contain"
            src={state.focusedAttachmentUrl}
            style={{ maxWidth: "100%", maxHeight: "calc(100vh - 3px)" }}
          />
        </div>
      </div>
      <div
        className="position-absolute top-0 end-0 me-3 mt-3"
        data-bs-dismiss="modal">
        <a
          href={state.focusedAttachmentUrl}
          target="_blank"
          className="btn btn-sm btn-light text-nowrap me-3"
          rel="noreferrer">
          <i className="bi-box-arrow-up-right me-1" />
          Open
        </a>
        <button
          id={`#${attachmentDetailModalId}-modal-close-button`}
          type="button"
          className="btn btn-light btn-sm"
          aria-label="Close">
          <i className="bi-x-circle" />
        </button>
      </div>
    </div>
  )
}

export default AttachmentDetailModal
