import React from "react"
import LegacyPortalFrame from "./LegacyPortalFrame"
import { useState } from "./lib/overmind"
import Redirect from "./components/Redirect"

const Campaigns = () => {
  const state = useState().account
  if (state.data?.config.portal.campaigns.needs_account_upgrade) {
    return <Redirect to="/inbox/all" />
  }
  return <LegacyPortalFrame path="/campaigns" className="h-100" />
}

export default Campaigns
