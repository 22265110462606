const general = {
  Details: {
    path: "details",
    keywords: [
      "location details",
      "store details",
      "shop details",
      "address",
      "review link",
      "website",
      "email",
    ],
  },
  "Store Hours": {
    path: "store-hours",
    keywords: ["store hours", "location hours", "general"],
  },
  "Message Templates": {
    path: "message-templates",
    keywords: ["message templates", "general"],
  },
  "After Hours Response": {
    path: "after-hours-response",
    keywords: [
      "automated response",
      "after hours response",
      "closed",
      "auto reply",
      "general",
    ],
  },
};

export const features = {
  "Browser Extension": {
    path: "browser-extension",
    keywords: [
      "browser extension",
      "chrome extension",
      "firefox extension",
      "browser addon",
      "website addon",
      "chrome addon",
      "firefox addon",
    ],
  },
  "Website Widget": {
    path: "website-widget",
    keywords: [
      "website widget",
      "website plugin",
      "shopify",
      "woo commerce",
      "wordpress",
      "web chat",
      "webchat",
    ],
  },
  Reviews: {
    path: "reviews",
    keywords: ["reviews", "customer feedback"],
  },
  NPS: {
    path: "nps",
    keywords: ["nps", "net promoter score", "reviews", "feedback"],
  },
  Ecwid: {
    path: "ecwid",
    keywords: ["ecwid"],
  },
};

const lightspeedRSeries = {
  "Store Assignment": {
    path: "lightspeed-r-series/store-assignment",
    keywords: ["r series", "r-series", "lightspeed stores"],
  },
  "Work Order Automation": {
    path: "lightspeed-r-series/workorder-automation",
    keywords: ["r series", "r-series", "lightspeed workorder automation"],
  },
  "Special Order Message": {
    path: "lightspeed-r-series/special-order-message",
    keywords: ["r series", "r-series", "lightspeed special order message"],
  },
};

const lightspeedXSeries = {
  "Store Assignment": {
    path: "lightspeed-x-series/store-assignment",
    keywords: ["x-series", "x series", "lightspeed stores"],
  },
  "Receipt Message Template": {
    path: "lightspeed-x-series/receipt-template",
    keywords: ["x-series", "x series", "lightspeed special order message"],
  },
};

const google = {
  Connection: {
    path: "google/oauth",
    keywords: ["google oauth", "google connection"],
  },
  "Store Assignment": {
    path: "google/store-assignment",
    keywords: ["google", "google location", "google store"],
  },
};

const meta = {
  Messenger: {
    path: "meta/messenger",
    keywords: ["facebook", "meta", "messenger"],
  },
};

const payments = {
  Connection: {
    path: "payments/connection",
    keywords: ["payments", "stripe", "payment request", "payments"],
  },
  General: {
    path: "payments/general",
    keywords: [
      "payments",
      "message template",
      "successful payment url",
      "successful payment link",
    ],
  },
  Deposits: {
    path: "payments/deposits",
    keywords: [
      "payments",
      "payments deposit",
      "deposits",
      "lightspeed payments",
    ],
  },
};

export const settingsIndex = {
  general,
  features,
  payments,
  "lightspeed r series": lightspeedRSeries,
  "lightspeed x series": lightspeedXSeries,
  google,
  "Meta / Facebook": meta,
};
