import React from "react";

const BadgeCount = ({ hidden, children, isActive }) => {
  if (!children || hidden) return null;
  return (
    <div className={`badge ${isActive ? "bg-light text-black" : "bg-dark"}`}>
      {children}
    </div>
  );
};

export default BadgeCount;
