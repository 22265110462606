import React, { useEffect } from "react"
import { Route, Routes, useNavigate, useParams } from "react-router-dom"
import GoogleLocationAssignment from "../../GoogleMyBusiness/Settings/GoogleLocationAssignment"
import ReviewsSetup from "../../Reviews/ReviewsSetup"
import { useEventHook } from "../../services/events"
import { useGet } from "../../lib/api"
import Loader from "../../components/Loader"
import APIResponseError from "../../components/APIResponseError"
import GoogleOAuthReconnect from "../../GoogleMyBusiness/Settings/GoogleOAuthReconnect"
import FeaturePermissionCheck from "../../components/FeaturePermissionCheck"
import DefaultNoPermissionWarning from "../../components/DefaultNoPermissionWarning"

const ContactAccountManager = () => {
  return <div />
}

const LocationGoogleSettings = () => {
  const [get, isLoading, error, resp] = useGet()
  const navigate = useNavigate()

  useEventHook("google-connection-status-change", () => {
    navigate("/settings/location/google/oauth")
    get("/shop/{exid}/integration?name=google")
  })

  useEffect(() => {
    get("/shop/{exid}/integration?name=google")
  }, [])

  if (isLoading) {
    return <Loader />
  }

  if (error) return <APIResponseError response={error} />
  if (!resp) return null
  for (const integration of resp.data.data) {
    if (integration.id === "google" && integration.status !== "installed") {
      return <ReviewsSetup />
    }
  }

  return (
    <FeaturePermissionCheck
      permissionKey="google_settings"
      fallbackComponent={<DefaultNoPermissionWarning />}>
      <Routes>
        <Route path="store-assignment" element={<GoogleLocationAssignment />} />
        <Route path="oauth" element={<GoogleOAuthReconnect />} />
      </Routes>
    </FeaturePermissionCheck>
  )
}

export default LocationGoogleSettings
