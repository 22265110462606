import React from "react"
import "./MessageInvoiceCard.css"
import InvoiceCard from "./InvoiceCard"

export const MessageInvoiceCard = ({ message }) => {
  const messageContext = message?.meta?.context

  if (!messageContext) {
    return (
      <div className="card">
        <b>
          A payment link detected for this message but unable to find associated
          data.
        </b>
      </div>
    )
  }
  return (
    <InvoiceCard
      status={messageContext.status}
      total={messageContext.total / 100}
      currency={messageContext.currency}
      description={messageContext.description}
      depositStatus={messageContext.deposit_status}
      depositError={messageContext.deposit_error_message}
      invoiceId={messageContext.invoice_id}
      externalLink={messageContext.external_link}
    />
  )
}

export default MessageInvoiceCard
