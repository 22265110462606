/* eslint-disable no-return-await */
import { get, post } from "../../api"
import { getShopExternalId } from "../../auth"

export const sendWorkorderQuote = async ({ mobilePhone, id }) => {
  await get(`/shop/{exid}/workorder/${id}/send_receipt?method=sms`, {
    method: "sms",
    mobile_phone: mobilePhone,
  })
}

export const fetchLightspeedUiDataHandler = async ({
  primaryId,
  primaryType,
}) => {
  const params = {
    primary_id: primaryId,
    primary_type: primaryType,
  }
  return await get("/shop/{exid}/lightspeed/ui", params)
}

export const sendSpecialOrderMessage = async ({
  phone,
  firstName,
  lastName,
  title,
}) => {
  const data = {
    to: phone,
    first_name: firstName,
    last_name: lastName,
    title,
  }

  const response = await post("/shop/{exid}/specialorder/message/", data)
  return response.data
}

export const sendVendReceipt = ({ saleId, phoneNumber }) => {
  post("/shop/{exid}/vend/receipt/", {
    sale_id: saleId,
    phone_number: phoneNumber,
  })
}

export const getUnreadCount = async () => {
  const response = await get("/shop/{exid}/inbox/unread")
  return response.data
}

export const getMessages = async () => {
  const response = await get(
    "/shop/9/message/feed-v2?contact_phone_number=3365753816"
  )
  return response.data
}
