/* eslint-disable no-throw-literal */
import React from "react"
import SimpleForm from "../components/Form/SimpleForm"
import { put } from "../lib/api"
import PasswordField from "../components/Form/PasswordField"
import { useState } from "../lib/overmind"

const passwordRequirements = [
  "Password must be at least 10 characters long.",
  "Password must be hard to guess.",
  "Password must match.",
]

const UserChangePassword = ({ userId }) => {
  const state = useState().account
  return (
    <div>
      <div className="fw-semibold">Change User Password</div>
      <SimpleForm
        apiSubmitHandler={async (data) => {
          if (data.password !== data.confirm_password) {
            throw { source: "ik", message: "Passwords must match" }
          }
          await put(
            `/account/${state.accountId}/user/${userId}/change-password`,
            {
              password: data.password,
            }
          )
        }}
        initialValues={{}}>
        <div className="fs-14 mb-2">
          {passwordRequirements?.map((text) => (
            <div className=" me-1">
              <i className="bi-check" />
              {text}
            </div>
          ))}
        </div>

        <PasswordField
          label="New Password"
          className="form-control form-control-sm"
        />
        <PasswordField
          label="Confirm Password"
          name="confirm_password"
          className="form-control form-control-sm"
        />
      </SimpleForm>
    </div>
  )
}

export default UserChangePassword
