import { overmindInstance } from ".."
import * as pn from "../../phoneNumber"

export const getAccountCountryCode = () =>
  overmindInstance.state.account.data?.shop?.country || "US"

export const formatPhoneNumberWithAccountCountry = (
  phoneNumber,
  format = "national"
) => {
  if (!phoneNumber) return ""
  return pn.formatPhoneNumber(phoneNumber, getAccountCountryCode(), format)
}

export const checkPhoneNumberValidity = (phoneNumber) =>
  pn.checkPhoneNumberValidity(phoneNumber, getAccountCountryCode())

export const accountIsLoaded = () =>
  overmindInstance.state.account.data !== null

export const getAccountId = () => overmindInstance.state.account.accountId
