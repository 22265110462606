import React from "react"
import Modal from "../../components/Modal"
import SimpleForm from "../../components/Form/SimpleForm"
import LabeledField from "../../components/Form/LabeledField"
import { post } from "../../lib/api"
import { useState } from "../../lib/overmind"

export const reviewsTestMessageModalId = "reviews-test-message-modal"

const ReviewsTestMessageModal = () => {
  const state = useState().account

  const user = state?.data?.user

  const apiSubmitHandler = async (data) => {
    await post("/shop/{exid}/reviews/test-message", data)
  }
  return (
    <Modal title="Send a test message" id={reviewsTestMessageModalId}>
      <p>
        You can send yourself a test message with your current config to see
        what your users will see.
      </p>
      <SimpleForm
        initialValues={{
          first_name: user?.first_name || "Michael",
          last_name: user?.last_name || "Scott",
        }}
        apiSubmitHandler={apiSubmitHandler}>
        <LabeledField
          required
          label="Phone Number"
          name="phone_number"
          className="form-control form-control-sm"
          style={{ maxWidth: "250px" }}
        />
        <div className="mt-4 ">
          <div className="fw-bold">Optional Merge Fields</div>
          <div className="row">
            <div className="col">
              <LabeledField
                label="First Name"
                name="first_name"
                className="form-control form-control-sm"
              />
            </div>
            <div className="col">
              <LabeledField
                label="Last Name"
                name="last_name"
                className="form-control form-control-sm"
              />
            </div>
          </div>
        </div>
      </SimpleForm>
    </Modal>
  )
}

export default ReviewsTestMessageModal
