import React from "react";
import { Link } from "react-router-dom";
import DefaultIntegrationCard from "./DefaultIntegrationCard";
import InstalledBadge from "../../components/InstalledBadge";

const InstalledIntegrationCard = (props) => {
  return (
    <DefaultIntegrationCard badge={<InstalledBadge />} {...props}>
      <Link className="btn btn-sm btn-outline-secondary" to={props.url}>
        Configure
      </Link>
    </DefaultIntegrationCard>
  );
};

export default InstalledIntegrationCard;
