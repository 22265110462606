import React, { useEffect } from "react"
import { useActions, useState } from "../../lib/overmind"
import Toast from "."

const bootstrap = require("bootstrap")

const ToastManager = () => {
  const state = useState().toast
  const actions = useActions().toast

  useEffect(() => {
    const toastElList = [].slice.call(document.querySelectorAll(".toast"))
    toastElList.map((toastEl) => {
      const t = new bootstrap.Toast(toastEl, null)
      t.show()
      return t
    })
  })

  if (!state.pending?.length) return null

  return (
    <div
      className="toast-container position-fixed p-3 bottom-0 start-0"
      style={{ zIndex: 5 }}>
      {state.pending.map((toast) => (
        <Toast
          key={toast.id}
          id={toast.id}
          title={
            typeof toast.title === "function" ? toast.title() : toast.title
          }
          body={typeof toast.body === "function" ? toast.body() : toast.body}
          onHidden={() => actions.clear(toast.id)}
          autohide={toast.autohide}
          className={toast.className || ""}
        />
      ))}
    </div>
  )
}

export default ToastManager
