/* eslint-disable camelcase */
import React from "react"
import Loader from "../components/Loader"
import LocationFilter from "./LocationFilter"
import LocationLoadError from "./LocationLoadError"
import BadgeCount from "../components/BadgeCount"

const LocationList = ({
  locations,
  isLoading,
  error,
  hideUnreadCount,
  shopExternalId,
  onLocationClick,
  onUpdateFilter,
  isLoadingUnreadCount,
}) => {
  return (
    <ul
      className="location-dropdown-menu dropdown-menu dropdown-menu-end dropdown-menu-md-start pt-0 overflow-x-hidden"
      style={{ minWidth: "300px", maxWidth: "350px" }}>
      <LocationFilter
        disabled={isLoading}
        hideUnreadCount={hideUnreadCount}
        onUpdateFilter={onUpdateFilter}
      />
      <Loader className="py-3 text-center" hidden={!isLoading} />
      <LocationLoadError error={error} />
      <li className="text-center py-3" hidden={locations?.length || isLoading}>
        No location found.
      </li>
      {locations &&
        locations.map(({ id, name, unread_count }) => {
          const isActive = id === shopExternalId
          const activeClass = isActive ? "active" : ""
          return (
            <li key={id} onClick={() => onLocationClick(id)}>
              <a
                className={`dropdown-item ${activeClass} p-2 py-2 d-flex gap-2`}
                role="button">
                <i className="bi-shop-window mx-2" />
                <span className="overflow-auto">{name}</span>
                <div className="ms-auto">
                  <BadgeCount hidden={hideUnreadCount} isActive={isActive}>
                    {unread_count}
                  </BadgeCount>
                </div>
                <div hidden={!isLoadingUnreadCount} className="ms-auto">
                  <Loader className="text-secondary" />
                </div>
              </a>
            </li>
          )
        })}
    </ul>
  )
}

export default LocationList
