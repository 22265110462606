import React from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "../lib/overmind";
import { Tooltip } from "../components/Tooltip";

const ContactPageHeader = () => {
  const navigate = useNavigate();
  const state = useState().contacts;

  return (
    <div className="d-flex">
      <h4 className="fw-bold mb-4 me-auto">Contacts</h4>
      <div className="text-end">
        <Tooltip
          disabled={state.canCreate}
          title="Please go to your Point of Sale to create a new contact."
          placement="left"
        >
          <button
            type="button"
            disabled={!state.canCreate || state.isLoading}
            className="btn btn-primary mt-1"
            onClick={() => navigate("/contacts/create")}
          >
            Create Contact
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

export default ContactPageHeader;
