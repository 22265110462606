import React from "react";
import { useCookiesEnabled } from "../lib/cookie";
import GeneralCookieWarning from "./GeneralCookieWarning";

const CookieWarning = () => {
  const cookiesEnabled = useCookiesEnabled();
  if (cookiesEnabled) return null;
  return <GeneralCookieWarning />;
};

export default CookieWarning;
