import React from "react";
import { useActions, useState } from "../lib/overmind";

const baseClass = "flex-fill text-center text-decoration-none";
const activeClass = `${baseClass} text-black`;
const inactiveClass = `${baseClass} text-secondary`;

const InboxThreadOpenCloseTab = () => {
  const { filter } = useState().activeInbox;
  const actions = useActions().activeInbox;

  const onClick = (showClosed) => {
    if (filter.showClosed === showClosed) return;
    actions.updateFilter({ showClosed, includeThreadId: null });
  };

  const showClosed = filter ? filter.showClosed : false;

  return (
    <div className="d-flex justify-content-center align-content-center py-2 position-relative">
      <div
        role="button"
        className={!showClosed ? activeClass : inactiveClass}
        onClick={() => onClick(false)}
      >
        <i className="bi bi-envelope-open me-1" style={{ fontSize: "12px" }} />
        Open{" "}
        {!showClosed && <span className="inbox-active-tab inbox-active-tab" />}
      </div>
      <div
        role="button"
        className={showClosed ? activeClass : inactiveClass}
        onClick={() => onClick(true)}
      >
        <i className="bi bi-check" />
        Closed{" "}
        {showClosed && (
          <span className="inbox-active-tab inbox-active-tab--end" />
        )}
      </div>
    </div>
  );
};

export default InboxThreadOpenCloseTab;
