import React from "react";
import Loader from "../components/Loader";

const ContactButton = ({
  icon,
  onClick,
  disabled,
  isLoading,
  href,
  target,
}) => (
  <a
    role="button"
    href={href}
    className={`btn btn-sm btn-outline-secondary w-100 ${
      disabled && "link-disabled"
    }`}
    onClick={onClick}
    target={target}
    rel="noreferrer"
  >
    {isLoading ? <Loader /> : <i className={`${icon} text-black`} />}
  </a>
);

export default ContactButton;
