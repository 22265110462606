import React from "react"
import { logout } from "../lib/auth"
import StripeBillingPortal from "./StripeBillingPortal"

const AccountPastDue = () => {
  return (
    <div className="vh-100 vw-100">
      <div className="">
        <div className="alert alert-warning text-center d-flex justify-content-center flex-column align-items-center">
          <h4 className="alert-heading">Account is past due.</h4>
          <p style={{ maxWidth: "75ch" }}>
            Please update your billing details to open up the app.
          </p>
        </div>
        <button
          type="button"
          className="btn btn-link position-absolute text-secondary"
          style={{ top: "10px", right: "20px" }}
          onClick={() => {
            logout()
            window.location.reload()
          }}>
          Logout
        </button>
      </div>
      <div>
        <StripeBillingPortal />
      </div>
    </div>
  )
}

export default AccountPastDue
