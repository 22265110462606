import React from "react";
import { Route, Routes } from "react-router-dom";
import OvermindDataTable from "../components/DataTable/OvermindDataTable";
import FeatureContainer from "../components/FeatureContainer";
import NpsSurveyConfig from "./NpsSurveyConfig";
import { useState } from "../lib/overmind";
import UserPermissionCheck from "../components/UserPermissionCheck";
import NpsDetailOffcanvas from "./NpsDetailOffcanvas";
import { NpsLiveOverview } from "./NpsOverview.jsx";
import NpsLocationOverview from "./NpsLocationOverview.jsx";
import FeaturePermissionCheck from "../components/FeaturePermissionCheck";
import NpsUpgrade from "./NpsUpgrade";
import NpsDemoOverview from "./NpsUpgrade/NpsDemoOverview";

const useNavItems = () => {
  const state = useState().account;

  return [
    {
      title: "Overview",
      path: "./",
    },
    {
      title: "Locations",
      path: "./locations",
    },
    {
      title: "Replies",
      path: "./replies",
    },
    {
      title: "Survey Config",
      path: "./survey",
      hidden: !state.data.config.portal.survey.visible,
    },
  ];
};

const NPS = ({ useDemo }) => {
  const navItems = useNavItems();

  return (
    <FeaturePermissionCheck
      permissionKey="nps"
      upgradeComponent={<NpsUpgrade />}
    >
      <UserPermissionCheck permissionKey="view_customer_feedback">
        <FeatureContainer navItems={navItems}>
          <Routes>
            <Route
              path="/"
              element={useDemo ? <NpsDemoOverview /> : <NpsLiveOverview />}
            />
            make
            <Route path="/locations" element={<NpsLocationOverview />} />
            <Route
              path="replies/*"
              element={<OvermindDataTable overmindPropertyKey="nps" />}
            />
            <Route path="survey" element={<NpsSurveyConfig />} />
          </Routes>
        </FeatureContainer>
        <NpsDetailOffcanvas />
      </UserPermissionCheck>
    </FeaturePermissionCheck>
  );
};

export default NPS;
