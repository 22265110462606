import React from "react"
import SimpleForm from "../../components/Form/SimpleForm"
import { SettingsFactory } from "../../components/Settings/SettingsFactory"
import { get, put } from "../../lib/api"

const fields = [
  {
    label: "Message Body",
    as: "textarea",
    body: "(Optional) A message can be sent to your customer automatically when you send a payment request.",
    props: {
      mergeFields: [
        {
          name: "Shop",
          value: "{shop}",
        },
        {
          name: "First Name",
          value: "{first name}",
        },
        {
          name: "Last Name",
          value: "{last name}",
        },
      ],
      label: "Message Body",
      type: "text",
      name: "message_template",
      rows: 4,
    },
  },

  {
    label: "Success Redirect Link",
    body: "Your customers will be redirected here after they pay their invoice. Typically this is your business website.",
    props: {
      label: "Redirect Link",
      type: "text",
      name: "redirect_url",
    },
  },
]

const PaymentsSettingsForm = ({ settings }) => {
  const apiSubmitHandler = async (values) => {
    if (values.redirect_url && !values.redirect_url?.includes("http")) {
      values.redirect_url = `https://${values.redirect_url}`
    }
    await put("/shop/{exid}/invoice/settings", values)
  }

  return (
    <div className="mb-5">
      <SimpleForm initialValues={settings} apiSubmitHandler={apiSubmitHandler}>
        <SettingsFactory fields={fields} />
      </SimpleForm>
    </div>
  )
}

export default PaymentsSettingsForm
