import React from "react"

const OffcanvasSection = ({ hidden, children }) => {
  if (hidden) return null
  return (
    <>
      <div className="px-3">{children}</div>
      <div
        className="border-top border-bottom my-3"
        style={{ height: "15px", backgroundColor: "rgb(252,252,252)" }}
      />
    </>
  )
}

export default OffcanvasSection
