import React from "react";
import ChangePasswordForm from "./ChangePasswordForm";
import Modal from "../components/Modal";

const ChangePasswordModal = () => {
  return (
    <Modal title="Change password" id="change-password-modal">
      <ChangePasswordForm />
      <button
        className="float-start btn btn-outline-secondary btn-sm"
        type="button"
        data-bs-dismiss="modal"
        id="modal-close-btn"
      >
        Done
      </button>
    </Modal>
  );
};

export default ChangePasswordModal;
