import React, { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import ReCAPTCHA from "react-google-recaptcha"
import SimpleForm from "../components/Form/SimpleForm"
import Container from "../components/Container"
import Row from "../components/Row"
import Col from "../components/Col"

import { post, reloadAxios } from "../lib/api"
import { isLoggedIn, login } from "../lib/auth"
import * as overmind from "../lib/overmind"
import LabeledField from "../components/Form/LabeledField"
import { history } from "../lib/history"
import { AppConfig } from "../services/config"
import { useQuery } from "../lib/query"
import { cookiesEnabled } from "../lib/cookie"

function LoginComponent({ onLoginSuccess }) {
  const actions = overmind.useActions().user
  const captchaRef = useRef()
  const [captchaValue, setCaptchaValue] = useState("")
  const query = useQuery()

  const redirectUrl = window.location.href.split("redirect_url=")[1]

  useEffect(() => {
    if (isLoggedIn()) {
      history.push(redirectUrl || "/")
    }
  }, [])

  const apiSubmitHandler = async (data) => {
    try {
      data["g-recaptcha-response"] = captchaValue
      const response = await post("/shop/login", data)
      const token = response.data?.api_token
      const forcePasswordChange = response.data.force_password_change
      actions.update({ forcePasswordChange })

      login({ token })
      reloadAxios()
      onLoginSuccess(true)
      history.push(redirectUrl || "/")
    } catch (error) {
      setCaptchaValue(null)
      captchaRef.current.reset()
      throw error
    }
  }

  return (
    <Container>
      <Row className="justify-content-center">
        <Col variant="md-3 pt-5">
          <h5 className="mb-3">Login to Ikeono</h5>
          <div
            hidden={!query.get("password_reset")}
            className="alert alert-info mb-3">
            Your password was changed successfully. You may login now.
          </div>
          <SimpleForm
            resetForm={false}
            submitButtonProps={{
              disabled: !captchaValue,
            }}
            id="login-form"
            initialValues={{}}
            apiSubmitHandler={apiSubmitHandler}>
            <LabeledField
              label="Email"
              name="email"
              type="email"
              className="form-control"
              required
            />
            <LabeledField
              label="Password"
              name="password"
              type="password"
              className="form-control"
              required
            />
            <Link
              to="/forgot-password"
              className="text-muted d-block text-end mb-3">
              Forgot password?
            </Link>
            <ReCAPTCHA
              className="mb-3"
              ref={captchaRef}
              sitekey={AppConfig.RecaptchaKey}
              onExpired={() => {
                setCaptchaValue(null)
              }}
              onError={() => {
                captchaRef.current.reset()
              }}
              onChange={(token) => {
                setCaptchaValue(token)
              }}
            />
            <div hidden={cookiesEnabled()} className="alert alert-warning">
              Cookies are disabled. You will not be able to login until they are
              enabled.
            </div>
          </SimpleForm>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col variant="md-4 text-center mt-4">
          <Link className="mt-4" to="/signup">
            Don&apos;t have an account? Sign up for free.
          </Link>
        </Col>
      </Row>
    </Container>
  )
}

export default LoginComponent
