import React from "react"

const MessengerPageNameBadge = ({ hidden, children }) => {
  if (hidden || !children) return null
  return (
    <div
      className="badge text-messenger-blue ms-2"
      style={{ border: "1px solid var(--messenger-blue)" }}>
      <i className="bi-messenger me-1" />
      {children}
    </div>
  )
}

export default MessengerPageNameBadge
