import { Field } from "formik"
import React from "react"
import StandardFormGroup from "../../components/Form/StandardFormGroup"

const BusinessTypeRadioGroup = () => (
  <StandardFormGroup label="Business type">
    <div className="d-flex flex-row gap-2">
      <div className="form-check">
        <Field
          className="form-check-input"
          type="radio"
          name="business_type"
          value="private company"
          id="private"
        />
        <label className="form-check-label" htmlFor="private">
          Private
        </label>
      </div>
      <div className="form-check">
        <Field
          className="form-check-input"
          type="radio"
          name="business_type"
          value="nonprofit"
          id="non_profit"
        />
        <label className="form-check-label" htmlFor="non_profit">
          Non-profit
        </label>
      </div>
      <div className="form-check">
        <Field
          className="form-check-input"
          type="radio"
          name="business_type"
          value="sole proprietor"
          id="sole_proprietor"
        />
        <label className="form-check-label" htmlFor="sole_proprietor">
          Sole proprietor
        </label>
      </div>
    </div>
  </StandardFormGroup>
)

export default BusinessTypeRadioGroup
