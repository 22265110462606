import React from "react"
import ReviewColumn from "./ReviewColumn"
import OverviewColumn from "./OverviewColumn"
import ReviewsHeader from "./ReviewsHeader"

const ReviewsBaseOverview = ({ reviews, dataPoints, children, redact }) => {
  return (
    <div className="mt-3">
      <ReviewsHeader />
      {children}
      <div className="row ">
        <div className="col">
          <ReviewColumn reviews={reviews} redact={redact} />
        </div>
        <div className="col-3">
          <OverviewColumn {...dataPoints} redact={redact} />
        </div>
      </div>
    </div>
  )
}

export default ReviewsBaseOverview
