import React from "react"
import { useNavigate } from "react-router-dom"
import SimpleForm from "../components/Form/SimpleForm"
import LabeledField from "../components/Form/LabeledField"
import { useQuery } from "../lib/query"
import { post } from "../lib/api"

const PublicChangePassword = () => {
  const query = useQuery()
  const token = query.get("token")
  const navigate = useNavigate()

  const apiSubmitHandler = async (data) => {
    const resp = await post("/password-reset", data)
    // Legacy endpoint that doesn't return approciate HTTP status codes
    if (resp.data?.status === "ERR") {
      const error = new Error(resp.data?.message)
      error.source = "ik"
      throw error
    }
    navigate("/login?password_reset=true")
  }
  return (
    <div className="container">
      <div className="row justify-content-center mt-3 mt-lg-5">
        <div className="col col-md-6 col-lg-4 ">
          <h5>Change your password now</h5>
          <fieldset disabled={!token}>
            <SimpleForm
              initialValues={{ token, password: "" }}
              apiSubmitHandler={apiSubmitHandler}
              resetForm
              autoComplete="off">
              <LabeledField
                label="New password"
                name="password"
                type="password"
                className="form-control form-control-sm"
                minLength={10}
              />
            </SimpleForm>
          </fieldset>
        </div>
      </div>
    </div>
  )
}

export default PublicChangePassword
