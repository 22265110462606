import React from "react"

import NotificationSubscribe from "../../../NotificationPreference/NotificationSubscribe"
import * as notification from "../../../services/notification"
import { Cookie } from "../../cookie"
import { isMobileDevice } from "../../device"
import { inIframe } from "../../environment"

export const refreshStatus = async (overmind) => {
  const state = overmind.state.pushNotification

  state.status = null
  state.isDenied = null
  state.isSubscribed = null
  state.error = null
  try {
    const status = await notification.currentPermission()
    state.status = status
    state.isDenied = status === "denied"
    state.isSubscribed = status === "granted"
  } catch (error) {
    state.error = error
  }
}

export const onInitializeOvermind = async (overmind, instance) => {
  instance.reaction(
    ({ account }) => account.data,
    async () => {
      const state = overmind.state.pushNotification
      const { actions } = overmind

      const cookie = new Cookie("ik_push_notification_toast_shown")
      const hasToastBeenShownRecently = cookie.get() === "true"
      await actions.pushNotification.refreshStatus()
      const { incompleteProfile } = overmind.state.account
      if (
        state.isSubscribed ||
        hasToastBeenShownRecently ||
        isMobileDevice() ||
        overmind.state.account.incompleteProfile ||
        incompleteProfile === undefined ||
        inIframe()
      ) {
        return
      }

      const toastId = "enable-notification-modal"

      const onComplete = () => {
        actions.toast.clear(toastId)
      }

      actions.toast.show({
        title: "Enable Notifications",
        body: () => <NotificationSubscribe onComplete={onComplete} />,
        id: toastId,
      })

      cookie.set(true, { "max-age": "2592000" }) // Once a month
    }
  )
}
