import React, { useEffect } from "react"
import ReviewsBaseOverview from "./ReviewsBaseOverview"
import { useGet } from "../../lib/api"
import { useState } from "../../lib/overmind"
import Loader from "../../components/Loader"
import APIResponseError from "../../components/APIResponseError"
import ReviewsSetup from "../ReviewsSetup"
import { useEventHook } from "../../services/events"
import GoogleLocationAssignment from "../../GoogleMyBusiness/Settings/GoogleLocationAssignment"

const ReviewsOverviewManager = ({ redact, noReviewsHandler, children }) => {
  const [get, isLoading, error, resp] = useGet()
  const googleLocationId = useState().account.data.shop.google_location_id

  const get_ = () => {
    get("/account/{account_id}/reviews", {
      location_id: googleLocationId,
    })
  }

  useEffect(() => {
    if (resp && !resp.data?.reviews) {
      noReviewsHandler && noReviewsHandler()
    }
  }, [resp])

  useEffect(() => {
    get_()
  }, [googleLocationId])
  useEventHook("google-connection-status-change", get_)

  if (isLoading) return <Loader />
  if (error) return <APIResponseError response={error} />
  if (!resp) return null

  if (resp.data.connection_status.needs_setup === true) {
    return <ReviewsSetup />
  }

  if (!googleLocationId) {
    return <GoogleLocationAssignment />
  }

  const dataPoints = {
    averageRating: resp.data.average_rating,
    totalReviewCount: resp.data.total_review_count,
    initialAverageRating: resp.data.initial_average_rating,
    initialTotalReviewCount: resp.data.initial_total_review_count,
  }

  return (
    <ReviewsBaseOverview
      reviews={resp.data.reviews}
      dataPoints={dataPoints}
      redact={redact}>
      {children}
    </ReviewsBaseOverview>
  )
}

export default ReviewsOverviewManager
