import React from "react";
import AccountSetupFeatureList from "../components/AccountSetupFeatureList";

const BrowserExtensionSetupDetail = () => {
  return (
    <AccountSetupFeatureList title="What to expect with the browser extension?">
      {[
        "Quickly reply to messages without leaving your POS.",
        "Instantly notify customers when their special order comes in.",
        "Text receipts, quotes, images, and videos.",
      ]}
    </AccountSetupFeatureList>
  );
};

export default BrowserExtensionSetupDetail;
