import React from "react"

const DataCard = ({ title, body, subtitle }) => {
  return (
    <div className="col">
      <div className="card mb-3">
        <div className="card-body">
          <div className="fw-semibold fs-15 mb-1">{title}</div>
          <div className="h2 fw-bold mb-2">{body}</div>
          <div className="fs-14 text-secondary fw-semibold">{subtitle}</div>
        </div>
      </div>
    </div>
  )
}

export default DataCard
