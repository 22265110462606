import React from "react";
import { Env, EnvEnum } from "../lib/environment";
import PageDebugOptions from "./PageDebugOptions";

const PageDebugDropdown = () => {
  if (Env === EnvEnum.production) {
    return null;
  }

  return (
    <div className="nav-item dropdown me-2 d-none d-md-block">
      <a
        href="#"
        className="nav-link d-flex align-items-center justify-content-center text-decoration-none text-white dropdown-toggle"
        id="dropdownUser3"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <i className=" bi-bug  text-white me-1" style={{ fontSize: "20px" }} />
      </a>
      <div
        className="dropdown-menu text-small shadow dropdown-menu-end px-3 pt-1 main-menu"
        style={{ width: "300px" }}
      >
        <span>Page Debug Options</span>
        <hr />
        <PageDebugOptions />
      </div>
    </div>
  );
};

export default PageDebugDropdown;
