import React, { useRef } from "react";
import Button, { ButtonVarint } from "../components/Button";
import OffcanvasFooter from "../components/OffCanvas/OffcanvasFooter";

const UserDetailFooter = ({
  isLoading,
  hideDeleteButton,
  formId,
  onDelete,
}) => {
  const cancelButtonRef = useRef();

  return (
    <OffcanvasFooter>
      <Button
        ref={cancelButtonRef}
        className="px-3"
        variant={ButtonVarint.secondary}
        data-bs-dismiss="offcanvas"
      >
        Cancel
      </Button>
      <Button
        type="button"
        hidden={hideDeleteButton}
        className="ms-2"
        variant={ButtonVarint.dangerOutline}
        onClick={() => {
          if (!confirm("Are you sure?")) return;
          onDelete();
          cancelButtonRef.current?.click(); // Work around. For some reason toggling the canvas does not work
        }}
      >
        Delete
      </Button>
      <Button
        showLoader={isLoading}
        form={formId}
        className="btn btn-primary btn-sm ms-auto px-3"
      >
        Save
      </Button>
    </OffcanvasFooter>
  );
};

export default UserDetailFooter;
