/* eslint-disable no-shadow */
import React from "react"
import { json } from "overmind"
import { formatPhoneNumberWithAccountCountry } from "../lib/overmind/account/util"
import InboxRowStatus from "./InboxRowStatus"
import { useMixpanelTrackingRef } from "../services/mixpanel"
import { prependStringToKeys } from "../lib/arrayUtil"
import { supportPhoneNumber } from "../lib/ikeono"
import InboxRowSelect from "./InboxRowSelect"

const ThreadTag = ({ id, title, isActive }) => {
  if (id === -1) {
    return (
      <i
        className="bi-messenger me-2"
        style={{ color: isActive ? null : "var(--messenger-blue)" }}
      />
    )
  }
  return <span className="inbox-row-badge">{title}</span>
}

const InboxRow = (props) => {
  const {
    firstName,
    lastName,
    receiverId,
    snippet,
    dateLastUpdated,
    hasNewMessage,
    isActive,
    tags,
    status,
    threadType,
  } = props
  let title = null
  if (firstName || lastName) {
    title = `${firstName || ""} ${lastName || ""}`
  } else {
    title = formatPhoneNumberWithAccountCountry(receiverId)
  }
  const trackingRef = useMixpanelTrackingRef(
    "click thread row",
    prependStringToKeys(props, "thread ")
  )

  let customerCareClass = ""
  // eslint-disable-next-line no-constant-condition
  if (receiverId === supportPhoneNumber) {
    title = "Ikeono Support 👋"
    customerCareClass = `inbox-row-customer-care ${
      isActive && "inbox-row-customer-care-active"
    }`
  }

  const tagsComputed = json(tags) || []
  if (threadType === "messenger") {
    tagsComputed.unshift({ id: -1, slug: "messenger", title: "Messenger" })
  }

  return (
    <div
      ref={trackingRef}
      className={`inbox-row border-bottom py-2 pe-2 ps-4 position-relative h-100 ${
        isActive ? "inbox-row-active" : ""
      } ${customerCareClass}`}>
      <div className="inbox-row-new-message-indicator d-flex gap-1  flex-column">
        <InboxRowSelect threadId={props.threadId} />
        {hasNewMessage && (
          <i
            className={`bi-circle-fill ${
              isActive ? "text-white" : "text-primary"
            }`}
          />
        )}
      </div>
      <div className="d-flex justify-content-between">
        <span
          title={title}
          className={`text-capitalize text-nowrap me-1 overflow-hidden ${
            hasNewMessage && "fw-bold"
          }`}>
          {title}
        </span>
        <span className="inbox-row-date justify-self-end text-nowrap">
          {dateLastUpdated}
        </span>
      </div>
      <div className="d-flex flex-row gap-1 align-items-center">
        <InboxRowStatus status={status} />
        <div className={`inbox-row-snippet ${hasNewMessage ? "fw-bold" : ""}`}>
          {snippet}
        </div>
      </div>
      <div className="pt-2 overflow-hidden" style={{ height: "30px" }}>
        {tagsComputed?.map(({ id, title }) => (
          <ThreadTag
            key={`${title}-${id}`}
            id={id}
            title={title}
            isActive={isActive}
          />
        ))}
      </div>
    </div>
  )
}

export default InboxRow
