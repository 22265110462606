import React from "react"
import { useParams } from "react-router-dom"
import OvermindDataTable from "../components/DataTable/OvermindDataTable"
import { useActions } from "../lib/overmind"
import { usePosConnectedEvent } from "../lib/pos"

const ContactTable = () => {
  const { id } = useParams()
  const actions = useActions().contacts
  const queryParams = { includeId: id }

  usePosConnectedEvent(() => {
    actions.get(queryParams)
  })

  return (
    <OvermindDataTable
      overmindPropertyKey="contacts"
      additionalQueryParams={queryParams}
    />
  )
}

export default ContactTable
