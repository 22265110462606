import React from "react"
import DataTable from "../../components/DataTable"
import PosNegLabel from "../../components/PosNegLabel"

const NpsLocationDataTable = ({ data }) => {
  if (!data) return null
  const [rows, setRows] = React.useState()

  React.useEffect(() => {
    setRows(
      data?.map((location) => {
        return {
          columns: [
            {
              value: location.short_name,
            },
            {
              value: (
                <PosNegLabel
                  startValue={location.previous?.nps_score}
                  endValue={location.current?.nps_score}>
                  {location.current.nps_score}
                </PosNegLabel>
              ),
            },
            {
              value: location.previous.nps_score,
            },
            {
              value: location.ytd.nps_score,
            },
            {
              value: location.current.total_promoters,
            },
            {
              value: location.current.total_neutral,
            },
            {
              value: (
                <PosNegLabel
                  startValue={location.previous.total_detractors}
                  endValue={location.current.total_detractors}>
                  {location.current.total_detractors}
                </PosNegLabel>
              ),
            },
            {
              value: (
                <PosNegLabel
                  startValue={location.previous.total_sent}
                  endValue={location.current.total_sent}>
                  {location.current.total_sent}
                </PosNegLabel>
              ),
            },
            {
              value: location.previous.total_sent || "0",
            },
            {
              value: location.current.response_rate,
            },
            {
              value: location.previous.response_rate,
            },
          ],
        }
      })
    )
  }, [data])

  const headers = [
    "Location name",
    "NPS Score",
    "Previous NPS Score",
    "YTD Score",
    "Promoters",
    "Neutral",
    "Detractors",
    "Total Sent",
    "Previous Total Sent",
    "Response Rate",
    "Previous Response Rate",
  ]
  return <DataTable headers={headers} rows={rows} />
}

export default NpsLocationDataTable
