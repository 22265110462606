/* eslint-disable no-unused-expressions */
import Pusher from "pusher-js";
import { useEffect } from "react";
import { AppConfig } from "./config";

// Pusher.logToConsole = true

class EventManager {
  init(externalId) {
    if (!this.pusher) {
      this.pusher = new Pusher(AppConfig.PusherKey, {
        cluster: "us3",
      });
    }

    const shopChannelName = `shop-${externalId}-channel`;

    if (this.channel) {
      this.channel.unsubscribe(shopChannelName);
    }

    this.channel = this.pusher.subscribe(shopChannelName);
  }

  bind(eventName, handler) {
    this.channel.bind(eventName, handler);
  }

  unbind(eventName, handler) {
    this.channel.unbind(eventName, handler);
  }

  bindGlobal(handler) {
    this.channel.bind_global(handler);
  }

  unbindGlobal() {
    if (!this.channel) return;
    this.channel.unbind_global();
  }
}

const sharedEventManager = new EventManager();
export default sharedEventManager;

export const useEventHook = (eventName, callback) => {
  useEffect(() => {
    const handler = (data) => {
      callback && callback(data);
    };
    sharedEventManager.bind(eventName, handler);
    return () => sharedEventManager.unbind(eventName, handler);
  }, []);
};
