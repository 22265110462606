import React from "react"
import * as api from "../../../lib/api"
import SimpleForm from "../../Form/SimpleForm"
import MergeFieldsTextArea from "../../Form/MergeFieldsTextArea"
import FeaturePermissionCheck from "../../FeaturePermissionCheck"
import { CommonMergeFields, MergeField } from "../../Form/MergeFields"
import { PricingPlans } from "../../UpgradeAccountButtonV2"

const mergeFields = [
  CommonMergeFields.firstName,
  CommonMergeFields.lastName,
  new MergeField(
    "Item Title",
    "{title}",
    "The item name of the special order."
  ),
]

const LightspeedSpecialOrder = () => {
  return (
    <FeaturePermissionCheck
      permissionKey="special_order_module"
      plan={PricingPlans.standard}
      redirectPath="/settings/location/lightspeed-r-series/special-order-message">
      <div className="d-flex gap-5">
        <div className="w-100">
          <SimpleForm
            id="specialOrderForm"
            apiGetHandler={async () => {
              const response = await api.get("/shop/{exid}/specialorder/")
              if (response.data) {
                response.data.enabled = true
              }
              return response
            }}
            apiSubmitHandler={(values) =>
              api.post("/shop/{exid}/specialorder/", values)
            }>
            <MergeFieldsTextArea
              id="message"
              name="message"
              as="textarea"
              mergeFields={mergeFields}
            />
          </SimpleForm>
        </div>

        <img
          alt="Feature "
          height="155px"
          className="rounded shadow"
          src="https://portal.ikeono.com/img/ikeono-special-order-feature.png"
        />
      </div>
    </FeaturePermissionCheck>
  )
}

export default LightspeedSpecialOrder
