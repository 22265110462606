import React from "react";

const SimpleHeaderBodyBanner = ({ title, body }) => (
  <div className="d-flex text-center flex-column align-items-center mt-5 m-auto">
    <h4>{title}</h4>
    <p className="text-secondary">{body}</p>
  </div>
);

export default SimpleHeaderBodyBanner;
