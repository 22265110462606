/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect } from "react"
import { NavLink as RouterNavLink } from "react-router-dom"
import "./style.css"

function isElementInViewport(el) {
  const rect = el.getBoundingClientRect()

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight ||
        document.documentElement.clientHeight) /* or $(window).height() */ &&
    rect.right <=
      (window.innerWidth ||
        document.documentElement.clientWidth) /* or $(window).width() */
  )
}

export const NavLink = (props) => {
  const [isActive, setIsActive] = React.useState(false)
  const [didScroll, setDidScroll] = React.useState(false)

  useEffect(() => {
    if (!isActive || didScroll) return
    const ele = document.getElementById(props.to)
    if (ele && !isElementInViewport(ele)) {
      ele.scrollIntoView()
      setDidScroll(true)
    }
  }, [isActive])

  return (
    <li className="nav-item " id={props.to}>
      <RouterNavLink className="nav-link ps-2 py-2 text-black" {...props}>
        {(navProps) => {
          if (navProps.isActive) {
            setIsActive(true)
          }
          return props.children
        }}
      </RouterNavLink>
    </li>
  )
}
export const NavItem = (props) => {
  return (
    <li className="nav-item">
      <a
        className={({ isActive }) =>
          `nav-link ps-2 py-2  ${isActive ? "active text-white" : "text-black"}`
        }
        {...props}
      />
    </li>
  )
}

export const SectionTitle = ({ children }) => (
  <div className="fs-16 fw-bold text-capitalize">{children}</div>
)

export const Divider = () => <div className="pt-4" />

const VerticalNav = ({ children, style }) => {
  return (
    <ul
      className="settings-nav nav flex-column position-sticky"
      style={{ top: "45px", ...style }}>
      {children}
    </ul>
  )
}

export default VerticalNav
