import ContactNameColumn from "../../../Contact/ContactNameColumn";

export const makeTableData = (contacts) => {
  const rows =
    contacts &&
    contacts.map((c) => ({
      id: c.id,
      url: c.id,
      data_bs_target: "#contact-detail-offcanvas",
      columns: [
        {
          value: `${c.first_name || ""} ${c.last_name || ""}`,
          type: "class",
          class: ContactNameColumn,
        },
        {
          value: c.mobile_phone_number,
          type: "phonenumber",
          className: "text-nowrap",
        },
        {
          value: c.email,
          type: "email",
          className: "text-lowercase",
        },
        {
          value: c.date_created,
          type: "datetime",
          className: "text-nowrap",
        },
      ],
    }));
  return {
    headers: ["Name", "Phone", "Email", "Date Created"],
    rows,
    filter: [
      {
        placeholder: "Search",
        type: "input",
        key: "query",
      },
    ],
  };
};
