import {AccountEinUpdateModalId} from "../Account/AccountEinUpdateModal"
import { BrowserExtensionInstallModalId } from "../BrowserExtension/BrowserExtensionInstallModal"
import { ReviewsFeatureUpgradeModalId } from "../Reviews/Setup/ReviewsFeatureUpgradeModal"
import { toggleModal } from "../components/Modal"

class DebugOption {
  constructor(title, onClick) {
    this.title = title
    this.onClick = onClick
  }
}

export const pageOptions = {
  "/reviews*": [
    new DebugOption("Toggle Feature Upgrade", (state) => {
      const m = state.account.data.config.portal.feedback_loop_module
      m.needs_account_upgrade = !m.needs_account_upgrade
    }),
  ],
  "/*": [
    new DebugOption("Toggle Browser Ext Install Modal", () => {
      toggleModal(BrowserExtensionInstallModalId)
    }),
    new DebugOption("Reviews Upgrade Modal", () => {
      toggleModal(ReviewsFeatureUpgradeModalId)
    }),
    new DebugOption("EIN Update Modal", () => {
      toggleModal(AccountEinUpdateModalId)
    }),
  ],
}
