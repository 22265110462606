// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-website-widget {
  max-width: 300px;
  border-radius: 30px;
  border-bottom-left-radius: 5px;
  padding: 5px;
}

.card-website-widget img {
  height: 40px;
  width: 40px;
}
`, "",{"version":3,"sources":["webpack://./src/MessageCard/MessageWidgetCard.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,8BAA8B;EAC9B,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,WAAW;AACb","sourcesContent":[".card-website-widget {\n  max-width: 300px;\n  border-radius: 30px;\n  border-bottom-left-radius: 5px;\n  padding: 5px;\n}\n\n.card-website-widget img {\n  height: 40px;\n  width: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
