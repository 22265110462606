import React from "react";
import LightspeedConnectButton from "./LightspeedConnectButton";

const LightspeedDisconnectedWarningToast = () => (
  <div className="my-2">
    <h6 className="text-nowrap fw-bold">
      Lightspeed R-Series needs reconnecting.
    </h6>
    <p className="fs-16">
      Ikeono has become disconnected from Lightspeed and needs to be
      reconnected.
    </p>
    <LightspeedConnectButton />
  </div>
);

export default LightspeedDisconnectedWarningToast;
