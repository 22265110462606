import React from "react";
import { useFormikContext } from "formik";
import LabeledField from "./LabeledField";

const StartEndDateControl = ({ children }) => {
  const { values } = useFormikContext();

  return (
    <div className="d-flex gap-3 align-items-center">
      <div className="fs-13">
        <label>Start Date</label>
        <LabeledField
          className="form-control form-control-sm"
          max={values?.end_date}
          type="date"
          name="start_date"
        />
      </div>
      <div className="fs-13 ">
        <label>End Date</label>
        <LabeledField
          className="form-control form-control-sm"
          min={values?.start_date}
          type="date"
          name="end_date"
        />
      </div>
      {children}
    </div>
  );
};

export default StartEndDateControl;
