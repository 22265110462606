import * as React from "react";
import { FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import InfiniteLoader from "react-window-infinite-loader";
import Loader from "./Loader";

const LoaderRow = ({ style }) => (
  <div style={style}>
    <div className="d-flex w-100 h-100 justify-content-center align-items-center">
      <Loader />
      <small className="ms-2 text-secondary">Loading more conversations</small>
    </div>
  </div>
);

const InfiniteList = ({
  RowClass,
  itemSize = 30,
  itemCount = 0,
  heightOffset = 0,
  hasNextPage = false,
  isNextPageLoading = false,
  loadNextPageCallback,
  errorRow,
}) => {
  const computedItemCount = itemCount;

  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  const loadMoreItems = isNextPageLoading ? () => {} : loadNextPageCallback;
  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = (index) => !hasNextPage || index < itemCount - 1;

  const RowWrapper = (props) => {
    if (errorRow && props.index === computedItemCount - 1) {
      return <div {...props}>{errorRow}</div>;
    }
    if (!isItemLoaded(props.index)) {
      return <LoaderRow style={props.style} />;
    }
    return <RowClass {...props} />;
  };

  return (
    <AutoSizer>
      {({ height, width }) => {
        return (
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={computedItemCount}
            loadMoreItems={loadMoreItems}
          >
            {({ ref, onItemsRendered }) => {
              return (
                <FixedSizeList
                  ref={ref}
                  onItemsRendered={onItemsRendered}
                  height={height - heightOffset}
                  width={width}
                  itemSize={itemSize}
                  itemCount={computedItemCount}
                >
                  {RowWrapper}
                </FixedSizeList>
              );
            }}
          </InfiniteLoader>
        );
      }}
    </AutoSizer>
  );
};

export default InfiniteList;
