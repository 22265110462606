export const state = {
  threads: [],
  indexedThreads: {},
  isLoading: false,
  apiError: null,
  unreadCount: 0,
  pageOffset: 0,
  hasNextPage: true,
  inboxId: null,
  selectedThreads: new Set(),
  multiSelectEnabled: false,
  filter: {
    showClosed: false,
    includeThreadId: null,
    query: null,
    showUnopened: false,
  },
};
