import { getRandomInt } from "../../number";
import { slugify } from "../../stringUtil";

export const show = ({ state }, toast) => {
  const id = toast.id ? slugify(toast.id) : `random-${getRandomInt()}`;
  toast.id = id;
  state.toast.pendingIndex[id] = toast;
};

export const clear = ({ state }, id) => {
  if (id) {
    delete state.toast.pendingIndex[id];
  } else {
    state.toast.pendingIndex = {};
  }
};
