import React, { useEffect } from "react";
import { useActions } from "../../lib/overmind";
import { supportEmail } from "../../lib/ikeono";

const useIntegrationErrorHook = (error) => {
  const actions = useActions().toast;

  useEffect(() => {
    if (!error) return;
    actions.show({
      title: "There was an error. Please try again",
      body: `If this error keeps happening please reach out to ${supportEmail}`,
      autohide: true,
    });
  }, [error]);
};

export default useIntegrationErrorHook;
