/* eslint-disable camelcase */
import React from "react";
import { useState } from "../lib/overmind";
import { AppConfig } from "../services/config";
import "./StripeConnectButton.css";
import { inIframe } from "../lib/environment";

const urlEncodeObject = (params) => {
  let data = Object.entries(params);
  data = data.map(
    ([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`
  );
  return data.join("&");
};

const StripeConnectButton = ({ treatment }) => {
  const state = useState().account;

  const params = {
    response_type: "code",
    client_id: AppConfig.StripeConnectClientId,
    scope: "read_write",
    state: state.shopExternalId,
  };
  if (state.data.account) {
    try {
      const {
        name,
        country,
        phone_number,
        website_url,
        city,
        region,
        postal_code,
        address_line_1,
      } = state.data.shop;
      const { email } = state.data.user;

      email && (params["stripe_user[email]"] = email);
      name && (params["stripe_user[business_name]"] = name);
      country && (params["stripe_user[country]"] = country);
      phone_number && (params["stripe_user[phone_number]"] = phone_number);
      website_url && (params["stripe_user[url]"] = website_url);
      address_line_1 &&
        (params["stripe_user[street_address]"] = address_line_1);
      city && (params["stripe_user[city]"] = city);
      region && (params["stripe_user[state]"] = region);
      postal_code && (params["stripe_user[zip]"] = postal_code);
    } catch (error) {
      console.error(error);
    }
  }

  const urlParams = urlEncodeObject(params);

  const computedClass =
    treatment === "link"
      ? "btn btn-link text-secondary"
      : "stripe-connect light dark";
  return (
    <a
      className={computedClass}
      style={{ boxSizing: "initial" }}
      href={`https://connect.stripe.com/oauth/authorize?${urlParams}`}
      rel="noreferrer"
      target={inIframe() ? "_blank" : null}
    >
      <span>Connect with Stripe</span>
    </a>
  );
};

export default StripeConnectButton;
