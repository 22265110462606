import React from "react";

export const getTreatment = (status) => {
  if (status === "open") {
    return "light";
  }
  if (status === "paid") {
    return "success";
  }

  return "secondary";
};

const PaymentStatusBadge = ({ children }) => {
  return (
    <span className={`badge text-bg-${getTreatment(children)} text-capitalize`}>
      {children}
    </span>
  );
};

export default PaymentStatusBadge;
