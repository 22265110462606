import React from "react";
import Checkbox from "../../components/Form/Checkbox";

const TemporarilyClosedForBusiness = ({ onChange }) => (
  <div>
    <span className="mb-2 d-block">
      <i className="" />
      You can temporarily close your business which will trigger your{" "}
      <a href="#location-automated-response">autoresponse</a> anytime someone
      messages.{" "}
    </span>
    <Checkbox
      id="closed_for_business"
      label="Closed for business"
      onChange={(e) => onChange(e.target.checked)}
    />
  </div>
);

export default TemporarilyClosedForBusiness;
