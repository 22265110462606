/* eslint-disable no-shadow */
import React from "react"
import DebugOptionButton from "./DebugOptionButton"
import { pageOptions } from "."

const PageDebugOptions = () => {
  const matches = Object.keys(pageOptions).filter((p) => {
    const match = !!window.location.pathname.match(p)
    if (match) return p
    return null
  })
  const options = matches?.map((pathKey) => {
    const options = pageOptions[pathKey]
    return options.map((option, idx) => {
      return (
        <DebugOptionButton key={`${pathKey}-${idx}`} onClick={option.onClick}>
          {option.title}
        </DebugOptionButton>
      )
    })
  })
  if (!options?.length) {
    return <div>No options for this page</div>
  }
  return options
}

export default PageDebugOptions
