import React from "react"
import SimpleForm from "../components/Form/SimpleForm"
import LabeledField from "../components/Form/LabeledField"
import { useActions, useState } from "../lib/overmind"
import InfoBubble from "../lib/InfoBubble"
import PosVisibility from "../components/PosVisibility"
import { formatPhoneNumberWithAccountCountry } from "../lib/overmind/account/util"
import { Tooltip, useTooltips } from "../components/Tooltip"
import ExternalLink from "../components/ExternalLink"

const ContactForm = ({ contact, onSuccess, onDeleteSuccess, hideDelete }) => {
  const actions = useActions().contacts
  const state = useState().contacts
  useTooltips()

  const apiSubmitHandler = async (data) => {
    if (contact?.id) {
      data.id = contact.id
      data.vendor_source = contact.vendor_source
      await actions.update(data)
      onSuccess && onSuccess(data)
    } else {
      const response = await actions.create(data)
      onSuccess && onSuccess(response)
    }
  }

  const onDelete = () => {
    if (!confirm("Are you sure you want to delete this contact?")) return
    actions.delete_(contact.id)
    onDeleteSuccess && onDeleteSuccess()
  }

  const phoneIsReadonly =
    !!contact?.mobile_phone_number && contact?.vendor_source !== 6
  const phoneRequired = contact?.vendor_source !== 6
  const isDisabled = !state.canCreate && !contact?.id
  const canDelete = state.canCreate

  return (
    <>
      <PosVisibility showLightspeed>
        <InfoBubble className="mb-2" hidden={!contact?.vendor_url}>
          This will update the customer in Lighspeed R-Series.{" "}
          <ExternalLink href={contact?.vendor_url}>
            Open Contact in your Point of Sale
          </ExternalLink>
        </InfoBubble>
      </PosVisibility>
      <SimpleForm
        initialValues={contact || {}}
        apiSubmitHandler={apiSubmitHandler}
        resetForm
        autoComplete="off"
        submitButtonPortalNodeId="contactFormSubmitButtonPortal">
        <fieldset disabled={isDisabled}>
          <LabeledField
            label="First Name"
            name="first_name"
            className="form-control form-control-sm"
            maxLength={50}
          />
          <LabeledField
            label="Last Name"
            name="last_name"
            className="form-control form-control-sm"
            maxLength={50}
          />
          <LabeledField
            disabled={phoneIsReadonly}
            label="Phone number"
            name="mobile_phone_number"
            value={
              contact?.mobile_phone_number &&
              formatPhoneNumberWithAccountCountry(contact?.mobile_phone_number)
            }
            className="form-control form-control-sm"
            required={phoneRequired}
          />
          <LabeledField
            label="Email"
            name="email"
            className="form-control form-control-sm"
            type="email"
            maxLength={255}
          />
          <input type="hidden" name="vendor_id" value={contact?.vendor_id} />
          <input
            type="hidden"
            name="vendor_source"
            value={contact?.vendor_source}
          />

          <div className="d-flex pt-2">
            <Tooltip
              disabled={canDelete}
              title="Please open this contact in your Point of Sale to delete it.">
              <button
                disabled={!canDelete}
                type="button"
                hidden={!contact?.id || hideDelete}
                className="btn btn-outline-danger float-start btn-sm me-auto"
                onClick={onDelete}>
                Delete
              </button>
            </Tooltip>
            <div className="ms-auto" id="contactFormSubmitButtonPortal" />
          </div>
        </fieldset>
      </SimpleForm>
    </>
  )
}

export default ContactForm
