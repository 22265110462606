import React from "react";
import { useActions, useState } from "../../lib/overmind";
import ThreadActionItem from "./ThreadActionItem";

const UnreadActionItem = ({ hideBorder }) => {
  const actions = useActions().activeThread;
  const state = useState().activeThread;

  const hasUnreadMessages = state.detail?.hasUnreadMessages;
  const title = hasUnreadMessages ? "Mark read" : "Mark unread";
  const description = hasUnreadMessages
    ? "Mark this conversation as read."
    : "Mark this conversation as unread.";
  const iconName = hasUnreadMessages ? "bi-square" : "bi-circle-square";
  return (
    <ThreadActionItem
      onClick={actions.toggleUnread}
      title={title}
      description={description}
      iconName={iconName}
      hideBorder={hideBorder}
    />
  );
};

export default UnreadActionItem;
