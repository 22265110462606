import React from "react";
import SplitPanelFeatureUpgrade, {
  ListItem,
} from "../FeatureUpgrade/SplitPanelFeatureUpgrade";
import { PricingPlans } from "../components/UpgradeAccountButtonV2";

const WebchatUpgrade = () => {
  return (
    <SplitPanelFeatureUpgrade
      title="Upgrade to unlock webchat tool!"
      plan={PricingPlans.standard}
      imageUrl="/webchat-example-1.png"
      redirectPath="/settings/location/website-widget"
      learnMoreUrl="https://www.ikeono.com/webchat"
    >
      <div className="d-flex gap-3 flex-column lead fw-medium">
        <p className="h4">
          Never miss a conversation－ customize your webchat in 5 minutes and
          start engaging with website visitors.
        </p>
        <ListItem>
          Assist multiple customers simultaneously and reduce customer support
          costs by enabling{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.ikeono.com/blog/how-web-chat-can-boost-your-business-3-steps-for-a-successful-implementation"
          >
            staff to multi-task
          </a>
          .
        </ListItem>
        <ListItem>
          A digital greeter and a lead generator that works on any website.
        </ListItem>
        <ListItem>
          Give website visitors a quick way to get their questions answered.
        </ListItem>
      </div>
    </SplitPanelFeatureUpgrade>
  );
};

export default WebchatUpgrade;
