import React from "react"
import { useState } from "../../lib/overmind"
import IneligibleNextSteps from "./Ineligible/IneligibleNextStepsBanner"
import { SupportAnchor } from "../../lib/ikeono"
import WaitPatientlyCard from "./Eligible/WaitPatientlyCard"

const PendingAccountVerificationDetail = () => {
  const state = useState().account

  if (!state.setupStatus) return null
  const isHostable = state.setupStatus?.is_phone_hostable
  const isLoaSigned = state.setupStatus?.is_loa_signed

  return (
    <div
      className="d-flex flex-column m-auto mt-4 gap-3"
      style={{ maxWidth: "600px" }}>
      <div hidden={!isHostable}>
        <WaitPatientlyCard hidden={!isLoaSigned} />
      </div>
      <IneligibleNextSteps hidden={isHostable} />
      <div className="px-3 mt-2">
        <div>
          <b>Need help?</b>
        </div>
        <div>
          Please contact us if you have any issues or questions!{" "}
          <SupportAnchor />
        </div>
      </div>
    </div>
  )
}

export default PendingAccountVerificationDetail
