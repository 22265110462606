import React from "react"
import { Link } from "react-router-dom"
import Dropdown, { DropDownDivider } from "../components/Dropdown"
import { useActions, useState } from "../lib/overmind"
import Loader from "../components/Loader"
import TemplateZeroState from "./TemplateZeroState"
import { getShopExternalId } from "../lib/auth"
import SectionHeader from "../components/SectionHeader"
import "./style.css"

export const TemplatesDropDown = ({ onClick }) => {
  const actions = useActions().templates
  const state = useState().templates
  const [filter, setFilter] = React.useState("")

  const get = () => {
    actions.get({
      shopId: getShopExternalId(),
    })
  }

  return (
    <Dropdown
      dropup
      onOpen={() => get()}
      title={
        <>
          <i className="bi-card-text me-1" />
          Templates
        </>
      }
      className="btn btn-link text-black btn-sm text-decoration-none">
      {state.responseError && (
        <div className="text-center py-2">
          <p style={{ fontSize: "14px" }}>
            There was an error. Please try again.
          </p>
          <button
            type="button"
            className="btn btn-sm btn-outline-primary"
            onClick={get}>
            Retry
          </button>
        </div>
      )}
      <Loader hidden={!state.isLoading} />
      <div
        className="overflow-y-scroll d-flex flex-column template-dropdown"
        style={{
          maxHeight: "calc(100vh - 200px)",
          minHeight: "200px",
        }}>
        <div className="d-flex align-items-center">
          <div>
            <SectionHeader className="fw-bold ms-3 mt-2 mb-0">
              Templates
            </SectionHeader>
            <Link
              hidden={state.isLoading}
              className="text-secondary text-nowrap ms-3 fs-13"
              to="/templates">
              Manage Templates
            </Link>
          </div>
          <input
            placeholder="Search for a template"
            className="form-control mx-3 ms-auto "
            style={{ maxWidth: "250px" }}
            value={filter}
            onChange={(e) => {
              setFilter(e.target.value)
              actions.updateFilter(e.target.value)
            }}
          />
        </div>
        <DropDownDivider />
        {state.templates?.map(({ id, key, value }) => {
          return (
            <li
              className="dropdown-item "
              onClick={() => {
                onClick(value)
              }}
              key={id}>
              <div className="d-flex flex-column py-2 cursor-pointer overflow-x-hidden">
                <b>{key}</b>
                <span
                  className="fs-14  text-secondary"
                  style={{ width: "70ch" }}>
                  {value}
                </span>
              </div>
            </li>
          )
        })}
        {!state.isLoading && !state.templates?.length && <TemplateZeroState />}
      </div>
    </Dropdown>
  )
}
