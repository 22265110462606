import React from "react"
import { PosSystem } from "../../pos"
import LightspeedDisconnectedWarningToast from "../../../components/Lightspeed/LightspeedDisconnectedWarningToast"
import VendDisconnectedWarningToast from "../../../components/Vend/VendDisconnectedWarningToast"
import { isBrowserExtensionEmbed } from "../../../BrowserExtension"
import { LocalStorageKey } from "../../localStorageKey"

const posConnectionWarningToastShownOnKey = new LocalStorageKey(
  "ikPosConnectionWarningToastShownOn"
)

export const get = async ({ state, effects }, props) => {
  if (
    (state.integrations.integraitons && !props?.forceReload) ||
    state.integrations.isLoading
  )
    return
  try {
    state.integrations.isLoading = true
    state.integrations.apiError = null
    const response = await effects.integrations.get(
      props?.params,
      props?.requestConfig
    )
    state.integrations.integrations = response.data

    // index integrations by key
    const index = {}
    for (let i = 0; i < response.data?.length; i++) {
      const integration = response.data[i]
      index[integration.id] = integration
    }
    state.integrations.integrationsIndex = index
  } catch (error) {
    console.log(error)
    state.integrations.apiError = error
  }
  state.integrations.isLoading = false
}

export const showPosConnectionStatusWarning = async ({
  state,
  actions,
  effects,
}) => {
  if (state.integrations.apiError || state.integrations.isLoading) return

  const accountPosSystem = state.account.posSystem
  if (accountPosSystem === PosSystem.Standalone) return

  await actions.integrations.get({ forceReload: true })

  const lightspeedRSeries =
    state.integrations.integrationsIndex.lightspeed_r_series
  const lightspeedXSeries =
    state.integrations.integrationsIndex.lightspeed_x_series

  let toastDetail = {
    title: () => (
      <span>
        <i className="bi bi-exclamation-triangle-fill text-danger me-1" />
        Action Required!
      </span>
    ),
    body: null,
    className: "top-0 end-0",
    autohide: false,
  }
  if (
    accountPosSystem === PosSystem.LightspeedRetail &&
    lightspeedRSeries.status !== "installed"
  ) {
    toastDetail.body = () => <LightspeedDisconnectedWarningToast />
  } else if (
    accountPosSystem === PosSystem.LightspeedXSeries &&
    lightspeedXSeries.status !== "installed"
  ) {
    toastDetail.body = () => <VendDisconnectedWarningToast />
  } else {
    toastDetail = null
  }

  if (
    !!posConnectionWarningToastShownOnKey.exists() &&
    posConnectionWarningToastShownOnKey.getTimeDifferenceFromNow() < 46400
  ) {
    return
  }

  if (toastDetail) {
    actions.toast.show(toastDetail)
    posConnectionWarningToastShownOnKey.setValueAsDateNow()
  }
}

export const onInitializeOvermind = ({ actions, state }, instance) => {
  instance.reaction(
    ({ account }) => account.data,
    () => {
      if (
        !state.account.data ||
        state.account.pendingAccountVerification ||
        isBrowserExtensionEmbed()
      )
        return

      setTimeout(async () => {
        actions.integrations.showPosConnectionStatusWarning()
      }, 2000)
    }
  )
}
