import React from "react";

const LinkOpenBadge = ({ count, hidden, hasUrls }) => {
  if (hidden) return null;

  if (!count && hasUrls) {
    return (
      <div className="badge rounded-pill text-bg-light mt-2">
        <i className="bi-hourglass text-secondary me-1" />
        <span className="text-secondary">Waiting for link to be opened.</span>
      </div>
    );
  }

  return (
    <div className="badge rounded-pill text-bg-light mt-2">
      <i className="bi-check-circle-fill text-success me-1" />
      <span className="text-secondary">
        Link was opened <span hidden={count <= 1}>{count} times.</span>
      </span>
    </div>
  );
};

export default LinkOpenBadge;
