/* eslint-disable camelcase */
import React from "react";
import PdfAttachmentCard from "./PdfAttachmentCard";
import VideoCard from "./VideoCard";
import AttachmentCard from "./AttachmentCard";
import MessageCardDetails from "./MessageCardDetails";
import DownloadAttachmentCard from "./DownloadAttachmentCard";

const AttachmentCardFactory = ({ message, bottomRef }) => {
  if (!message.attachments) return null;
  return message.attachments?.map(({ url, content_type }, idx) => {
    let card = null;
    if (content_type === "application/pdf") {
      card = (
        <PdfAttachmentCard
          key={idx}
          url={url}
          moveToEnd={message.is_from_shop}
          dateSent={message.date_sent}
          bottomRef={bottomRef}
        />
      );
    } else if (content_type?.includes("video")) {
      card = <VideoCard url={url} contentType={content_type} />;
    } else if (content_type?.includes("image")) {
      card = (
        <AttachmentCard
          key={idx}
          url={url}
          moveToEnd={message.is_from_shop}
          bottomRef={bottomRef}
        />
      );
    } else {
      card = (
        <DownloadAttachmentCard
          key={idx}
          url={url}
          contentType={content_type}
        />
      );
    }

    return (
      <MessageCardDetails key={idx} message={message}>
        {card}
      </MessageCardDetails>
    );
  });
};

export default AttachmentCardFactory;
