import React from "react"
import { SupportAnchor } from "./lib/ikeono"
import { useState } from "./lib/overmind"
import InfoBubble from "./lib/InfoBubble"

const AccountLocked = () => {
  const state = useState().account

  const message = state.accountLockedMessage
  if (!message) return null

  return (
    <div className="d-flex flex-row justify-content-center gap-2 mt-5">
      <i
        className="bi-exclamation-triangle text-warning"
        style={{ fontSize: "25px" }}
      />
      <div>
        <h4>Please contact us about your account.</h4>
        <InfoBubble className="my-3 fs-16 py-1" style={{ width: "45ch" }}>
          {message}
        </InfoBubble>
        <p>
          For further assistance please contact us at <SupportAnchor />
        </p>
      </div>
    </div>
  )
}

export default AccountLocked
