import React from "react"
import * as Sentry from "@sentry/react"
import { SupportAnchor } from "../lib/ikeono"
import { logout } from "../lib/auth"

const FallBack = () => (
  <div className="d-flex flex-row justify-content-center gap-3 mt-5">
    <i
      className="bi-exclamation-triangle text-warning"
      style={{ fontSize: "30px" }}
    />
    <div>
      <h3>There was an error</h3>
      <p style={{ maxWidth: "450px" }}>
        We apologize for the inconvenience. We have received an error report but
        if you need faster support please reach out to us at <SupportAnchor />
      </p>
      <a className="btn btn-primary me-3" href="/">
        Reload page
      </a>
      <button
        type="button"
        className="btn btn-outline-secondary"
        onClick={() => logout()}>
        Logout
      </button>
    </div>
  </div>
)

const ErrorBoundary = ({ children }) => {
  return (
    <Sentry.ErrorBoundary fallback={<FallBack />}>
      {children}
    </Sentry.ErrorBoundary>
  )
}

export default ErrorBoundary
