import React, { useEffect } from "react";
import UserPermissionCheck from "../components/UserPermissionCheck";
import FeaturePermissionCheck from "../components/FeaturePermissionCheck";
import ReviewsUpgrade from "./Setup/ReviewsUpgrade";
import { useState } from "../lib/overmind";
import { delete_ } from "../lib/api";
import { getShopExternalId } from "../lib/auth";
import ReviewsRoutes from "./Setup/ReviewsRoutes";
import Container from "../components/Container";

export const supportedCountries = ["US", "CA", "AU"];

const Reviews = () => {
  const state = useState().account;

  useEffect(() => {
    if (state.data.ui_metadata.unread_review_count) {
      delete_("/account/{account_id}/reviews/unread", {
        shop_id: getShopExternalId(),
      });
    }
  }, []);

  return (
    <div
      hidden={!supportedCountries.includes(state.country)}
      className="overflow-scroll pb-4"
      style={{ maxHeight: "calc(100vh - 60px" }}
    >
      <UserPermissionCheck permissionKey="view_customer_feedback">
        <FeaturePermissionCheck
          permissionKey="feedback_loop_module"
          upgradeComponent={<ReviewsUpgrade />}
        >
          <Container>
            <ReviewsRoutes />
          </Container>
        </FeaturePermissionCheck>
      </UserPermissionCheck>
    </div>
  );
};

export default Reviews;
