export const state = {
  contacts: null,
  offset: 0,
  hasMoreData: true,
  query: null,
  selectedContact: null,
  isLoading: false,
  isDirty: true,
  canCreate: true,
};
