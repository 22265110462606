/* eslint-disable no-shadow */
import React, { useEffect, useState } from "react"

const hours = Array.from({ length: 12 }, (v, idx) => idx + 1).map((v) =>
  v.toString()
)
const minutes = Array.from({ length: 60 }, (v, idx) => idx).map((v) =>
  v.toString().padStart(2, "0")
)

const TimeControl = ({
  id,
  label,
  value,
  onChange,
  defaultPeriod = "AM",
  required = false,
}) => {
  const [hour, setHour] = React.useState("")
  const [minute, setMinute] = React.useState("")
  const [period, setPeriod] = React.useState("")

  useEffect(() => {
    if (!value) {
      setHour("")
      setMinute("")
      setPeriod(defaultPeriod)
      return
    }

    const parts = value.split(":")
    let hour = parseInt(parts[0])
    let period = hour > 11 ? "PM" : "AM"
    if (hour === 0) {
      hour = "12"
      period = "AM"
    } else if (hour > 12) {
      hour -= 12
    }
    setHour(hour.toString())
    setMinute(parts[1])
    setPeriod(period)
  }, [value])

  const updateValue = ({ hour, minute, period }) => {
    if (hour?.trim() === "") {
      setHour("")
      setMinute("")
      setPeriod(defaultPeriod)
      onChange(null)
      return
    }

    let computedHour = parseInt(hour)
    if (period === "AM" && hour === "12") {
      computedHour = 0
    } else if (period === "PM" && computedHour !== 12) {
      computedHour += 12
    }

    const minuteStr = (minute || "00").toString().padStart(2, "0")
    const newTime = `${computedHour
      .toString()
      .padStart(2, "0")}:${minuteStr}:00`
    onChange(newTime)
  }

  return (
    <div>
      <div className="input-group input-group-sm" style={{ width: "280px" }}>
        <label className="input-group-text d-none d-md-block" htmlFor={id}>
          {label}
        </label>
        <label
          className="input-group-text d-md-none"
          htmlFor={id}
          style={{ width: "45px" }}>
          {label}
        </label>
        <select
          required={required}
          className="form-select"
          value={hour}
          onChange={(e) => {
            setHour(e.target.value)
            updateValue({ hour: e.target.value, minute, period })
          }}>
          <option />
          {hours.map((h) => (
            <option key={h} value={h}>
              {h}
            </option>
          ))}
        </select>
        <select
          className="form-select"
          value={minute}
          onChange={(e) => {
            setMinute(e.target.value)
            updateValue({ hour, minute: e.target.value, period })
          }}>
          <option />
          {minutes.map((v) => (
            <option key={v} value={v}>
              {v}
            </option>
          ))}
        </select>
        <select
          value={period}
          onChange={(e) => {
            setPeriod(e.target.value)
            updateValue({ hour, minute, period: e.target.value })
          }}
          className="form-select"
          style={{ maxWidth: "79px" }}>
          <option value="AM">AM</option>
          <option value="PM">PM</option>
        </select>
      </div>
    </div>
  )
}

export default TimeControl
