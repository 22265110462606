import React from "react";
import NpsDemoOverview from "./NpsDemoOverview";
import { PricingPlans } from "../../components/UpgradeAccountButtonV2";
import DiscoveredFeatureUpgrade from "../../FeatureUpgrade/DiscoveredFeatureUpgrade";
import { ListItem } from "../../FeatureUpgrade/SplitPanelFeatureUpgrade";
import ExternalLink from "../../components/ExternalLink";

const NpsUpgrade = () => {
  return (
    <div className="container">
      <div className="opacity-25">
        <NpsDemoOverview />
      </div>
      <div className="position-absolute top-0 mt-5 start-0 vw-100">
        <div className="d-flex justify-content-center align-items-center  mt-5">
          <DiscoveredFeatureUpgrade
            featureName="nps"
            plan={PricingPlans.premium}
          >
            <p className="lead fs-18 mb-4">
              Use the efficiency of a text message to ask for your NPS, and
              catch any interactions that need repairing before customers leave
              a public review.
            </p>
            <div className="d-flex flex-column gap-3 mb-4">
              <ListItem>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.ikeono.com/blog/what-is-nps"
                >
                  Get insights
                </a>{" "}
                on customer sentiment and boost return rates.
              </ListItem>
              <ListItem>
                Access real-time analytics to gauge and improve business
                performance.
              </ListItem>
              <ListItem>
                Harness the power of text messaging, the{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.ikeono.com/blog/how-to-collect-customer-feedback"
                >
                  highest ROI
                </a>{" "}
                channel, to effortlessly collect genuine feedback.
              </ListItem>
            </div>
            <ExternalLink href="https://www.ikeono.com/blog/what-is-nps">
              Learn more about NPS.
            </ExternalLink>
          </DiscoveredFeatureUpgrade>
        </div>
      </div>
    </div>
  );
};

export default NpsUpgrade;
