/* eslint-disable import/no-named-as-default */
import React from "react"
import ContinueButton from "../components/ContinueButton"
import { useState } from "../../lib/overmind"
import Browser from "../../lib/browser"
import {
  Body,
  PrimaryButton,
  SecondaryButton,
} from "./BrowserExtensionSetupButtons"
import { AppConfig } from "../../services/config"
import { usePageViewHook } from "../../services/mixpanel"
import {
  ChromeExtensionAppIcon,
  FirefoxExtensionAppIcon,
} from "../../IntegrationAppIcon"

const BrowserExtensionSetup = ({ onComplete }) => {
  const state = useState().browserExtensionEventBus
  const browser = Browser.current()
  usePageViewHook("browser extension installation")

  let options = [
    {
      component: "Install Firefox Browser Extension",
      icon: <FirefoxExtensionAppIcon />,
      url: AppConfig.FirefoxExtensionUrl,
    },
    {
      component: "Install Chrome Browser Extension",
      icon: <ChromeExtensionAppIcon />,
      url: AppConfig.ChromeExtensionUrl,
    },
  ]

  if (browser === Browser.chrome) {
    options = options.reverse()
  }

  return (
    <div className="d-flex flex-column gap-md-4 gap-2">
      <PrimaryButton
        onClick={() => window.open(options[0].url)}
        icon={options[0].icon}
        installed={state.isExtensionInstalled}>
        {options[0].component}
      </PrimaryButton>
      <SecondaryButton
        icon={options[1].icon}
        onClick={() => window.open(options[1].url)}>
        {options[1].component}
      </SecondaryButton>
      <ContinueButton
        onClick={() => onComplete({ action: "continue without setup" })}>
        Continue without installing
      </ContinueButton>
    </div>
  )
}

export default BrowserExtensionSetup
