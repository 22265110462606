export const reduce = require("lodash/reduce");

export const findAndDelete = (arr, comparator) => {
  if (!arr) return;
  if (!comparator) return;

  const index = arr.findIndex(comparator);
  if (index < 0) return;
  arr.splice(index, 1);
};

export const prependStringToKeys = (collection, string) => {
  if (!collection) return;
  const props = {};
  reduce(
    collection,
    (result, v, k) => {
      result[`${string}${k}`] = v;
      return result;
    },
    props,
  );
  return props;
};
