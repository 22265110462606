import React from "react"
import ExternalLink from "../components/ExternalLink"
import { SupportAnchor } from "../lib/ikeono"
import DepositStatusBadge from "./DepositStatusBadge"
import InfoBubble from "../lib/InfoBubble"
import DetailTable, { TableRow } from "../components/DetailTable"

const DepositInformation = ({ children }) => {
  if (children === "pending") {
    return (
      <span className="fs-14">
        A deposit will be added to the Lightspeed Customer when the invoice is
        paid.
      </span>
    )
  }
  if (children === "success" || children === "complete") {
    return (
      <span className="fs-14">
        A deposit was successfully applied to the Lightspeed Customer.
      </span>
    )
  }
  if (children === "error") {
    return (
      <span className="fs-14">
        There was an error adding the deposit. A credit has not been applied to
        the Lightspeed Customer.
      </span>
    )
  }
  if (children === "void") {
    return (
      <span className="fs-14">
        There was not enough information to add a credit to the Lightspeed
        Customer. A deposit will <b>not</b> be added.
      </span>
    )
  }

  return (
    <span className="fs-14">
      Please contact <SupportAnchor /> for more information.
    </span>
  )
}

const PaymentDetailLightspeedDepositStatus = ({
  status,
  errorMessage,
  posUrl,
  posTransactionId,
  posPrimaryType,
}) => {
  return (
    <>
      <b className="d-block mb-1">Lightspeed Retail</b>
      <DetailTable>
        <TableRow title="Source">
          <span className="text-capitalize">
            {posPrimaryType} {posTransactionId ? `#${posTransactionId}` : ""}
          </span>
          <div>
            <i className="fs-14 text-secondary">No information.</i>
          </div>
        </TableRow>
        <TableRow hidden={!errorMessage} title="Error Detail">
          <span className="text-capitalize fs-13">
            <i className="bi-exclamation-triangle text-danger me-1" />
            {errorMessage}
          </span>
        </TableRow>
        <TableRow title="Customer Credit">
          <DepositStatusBadge>{status}</DepositStatusBadge>
          <InfoBubble className="mt-2">
            <DepositInformation>{status}</DepositInformation>
          </InfoBubble>
        </TableRow>
        <TableRow title="Customer Account">
          <ExternalLink className="fs-14" href={posUrl} hidden={!posUrl}>
            View in Lightspeed
          </ExternalLink>
          <div className="fs-14 text-secondary">
            <i>No contact associated.</i>
          </div>
        </TableRow>
      </DetailTable>
    </>
  )
}

export default PaymentDetailLightspeedDepositStatus
