/* eslint-disable camelcase */
import React, { useEffect, useRef } from "react"
import "./style.css"
import LocationList from "./LocationList"

const LocationSelectDropDown = ({
  hideUnreadCount,
  hidden,
  buttonClassName,
  onSelect,
  selectedId,
  onShown,
  title,
  locations,
  error,
  isLoading,
  onUpdateFilter,
  isLoadingUnreadCount,
}) => {
  if (hidden) return null
  const ref = useRef()

  const get = async () => {
    onShown && onShown()
  }

  useEffect(() => {
    ref.current?.addEventListener("show.bs.dropdown", get)
    return () => ref.current?.removeEventListener("show.bs.dropdown", get)
  }, [])

  return (
    <div className="dropdown-center">
      <div className="location-dropdown" ref={ref}>
        <button
          className={
            buttonClassName || "location-dropdown-btn px-3 pe-4 py-1 text-white"
          }
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false">
          {title} <i className="bi-chevron-down ms-1" />
        </button>
        <div>
          <LocationList
            hideUnreadCount={hideUnreadCount}
            shopExternalId={selectedId}
            onLocationClick={(externalId) => onSelect(externalId)}
            locations={locations}
            error={error}
            isLoading={isLoading}
            onUpdateFilter={onUpdateFilter}
            isLoadingUnreadCount={isLoadingUnreadCount}
          />
        </div>
      </div>
    </div>
  )
}

export default LocationSelectDropDown
