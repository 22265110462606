import React from "react"

const TemplateRow = ({ title, body }) => {
  return (
    <div className="m-2">
      <h6 className="fw-bold">{title}</h6>
      <span className="fs-15">{body}</span>
    </div>
  )
}

export default TemplateRow
