import React from "react";
import { Link } from "react-router-dom";
import { SupportAnchor } from "../lib/ikeono";

const questions = [
  {
    q: "I don’t see Ikeono in my Point of Sale (i.e. Lightspeed or Vend)",
    a: [
      <p>
        You need to download the Browser Extension. This is required on each
        computer you use your Point of Sale. You can find the extensions here:{" "}
        <Link to="/settings/location/browser-extension">Setup</Link>
      </p>,
    ],
  },
  {
    q: " I can’t text Work Order Quotes or Receipts.",
    a: [
      <p>
        Verify your Lightspeed API connection is good to go. The connection can
        be broken if there is an employee change or during the negotiation
        period with Lightspeed. You can check your connection status here:{" "}
        <Link to="/settings/location/lightspeed-r-series/store-assignment">
          Verify Settings
        </Link>
      </p>,
    ],
  },
  {
    q: "My Work Order or Feedback Automation isn’t sending.",
    a: [
      <p>
        <b>Lightspeed Connection Broken:</b> Verify your Lightspeed API
        connection is good to go. The connection can be broken if there is an
        employee change or during the negotiation period with Lightspeed. You
        can check your connection status here:{" "}
        <Link to="/settings/location/lightspeed-r-series/store-assignment">
          Click here
        </Link>
      </p>,
      <p>
        <b>Wrong Lightspeed Connection:</b>Verify your Location is correct.
        Lightspeed separates locations in the API and this is how we are able to
        send multi-location specific messaging to the right location. You can
        verify that here:{" "}
        <Link to="/settings/location/lightspeed-r-series/store-assignment">
          Click here
        </Link>
      </p>,
      <p>
        <b>Business Hours</b> Check your business hours in Ikeono. Automation
        will only send messages during business hours, otherwise the messages
        will queue and send the next time you’re open. You can check business
        hours here: <Link to="/settings/location/store-hours">Click here</Link>
      </p>,
    ],
  },
  {
    q: "I can’t integrate Ikeono on an iPad or Android Tablet",
    a: [
      "iPad’s and Android tablets do not currently support browser extensions so we are unable to integrate as deeply.  You can download the Ikeono App on the App Store or access it on portal.ikeono.com ",
      "Things like Work Order and Feedback Automation will still be triggered because they are triggered by the API.  ",
    ],
  },
  {
    q: "An Ikeono Invoice was paid, but isn’t showing as a deposit on the customer’s account",
    a: [
      <p>
        Verify deposits are configured and enabled. These aren’t enabled by
        default as there are some decisions to be made. You can enable them
        here:<Link to="/settings/location/payments/deposits">Click here</Link>
      </p>,
      <p>
        Invoices sent from the iPad app and main Ikeono Portal are not able to
        be added as a deposit because we are missing important information to do
        it safely. The payments were captured just fine, you’ll just need to
        recognize the payment in Lightspeed manually.{" "}
      </p>,
    ],
  },
  {
    q: "What merge fields are supported by Ikeono?",
    a: [
      "Each module has different supported merge fields.  For example, we are able to support more merge fields in the Work Order Automation because we are pulling data from the API.  Each module will reflect which merge fields are supported in them.",
    ],
  },
];

const AccordianItem = ({ id, title, body, parentId }) => (
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target={`#${id}`}
      >
        {title}
      </button>
    </h2>
    <div
      id={id}
      className="accordion-collapse collapse"
      data-bs-parent={`#${parentId}`}
    >
      <div className="accordion-body">{body}</div>
    </div>
  </div>
);

const AppFaq = () => {
  return (
    <div>
      <h5 className="fw-bold mb-3 pb-0">Frequently Asked Questions</h5>
      <div className="accordion" id="faqAccordion">
        {questions.map((question, index) => (
          <AccordianItem
            id={`item${index}`}
            title={question.q}
            body={question.a}
            parentId="faqAccordion"
          />
        ))}
      </div>
    </div>
  );
};

export default AppFaq;
