/* eslint-disable default-case */
import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber"
import { overmindInstance } from "./overmind"

const phoneUtil = PhoneNumberUtil.getInstance()

export const isPossibleNumber = (phoneNumber) =>
  phoneUtil.isPossibleNumber(phoneNumber)

export const checkPhoneNumberValidity = (phoneNumber) => {
  if (phoneNumber === "+16666666") return
  try {
    const countryCode =
      overmindInstance.state.account.data?.shop?.country || "US"
    const number = phoneUtil.parseAndKeepRawInput(phoneNumber, countryCode)
    const reasonCode = phoneUtil.isPossibleNumberWithReason(number)

    switch (reasonCode) {
      case PhoneNumberUtil.ValidationResult.TOO_SHORT:
        return "Phone number is too short."
      case PhoneNumberUtil.ValidationResult.TOO_LONG:
        return "Phone number has too many digits."
      case PhoneNumberUtil.ValidationResult.INVALID_COUNTRY_CODE:
        return "Phone number has invalid country code"
    }

    if (!phoneUtil.isValidNumber(number)) {
      return "Phone number does not appear to be valid."
    }
  } catch (error) {
    console.log(phoneNumber)
    console.log(error)
    return "Phone number does not appear to be valid."
  }
}

const pnfFormatMap = {
  international: PhoneNumberFormat.INTERNATIONAL,
  national: PhoneNumberFormat.NATIONAL,
  E164: PhoneNumberFormat.E164,
}

export const formatPhoneNumber = (
  phoneNumber,
  countryCode,
  format = "national"
) => {
  try {
    const phoneNumberParsed = phoneUtil.parseAndKeepRawInput(
      phoneNumber,
      countryCode
    )
    return phoneUtil.format(phoneNumberParsed, pnfFormatMap[format])
  } catch {
    return phoneNumber
  }
}

export const makePhoneCall = (phoneNumber) => {
  window.location.href = `tel:${phoneNumber}`
}
