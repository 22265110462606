import React from "react";
import Browser from "./lib/browser";

const Icon = ({
  iconName,
  backgroundColor,
  children,
  width = "40px",
  height = "40px",
  fontSize,
  className,
}) => (
  <div
    className={`d-grid align-items-center rounded text-white justify-content-center flex-shrink-0 ${className}`}
    style={{ backgroundColor, justifySelf: "center", width, height }}
  >
    <i hidden={!iconName} className={iconName} style={{ fontSize }} />
    {children}
  </div>
);

export const GoogleAppIcon = (props) => (
  <Icon iconName="bi-google" backgroundColor="rgb(48, 125,246)" {...props} />
);
export const EcwidAppIcon = () => (
  <Icon iconName="bi-cart" backgroundColor="black" />
);
export const StripeAppIcon = (props) => (
  <Icon iconName="bi-credit-card-fill" backgroundColor="#309975" {...props} />
);
export const WebchatAppIcon = (props) => (
  <Icon
    iconName="bi-robot"
    backgroundColor="black"
    fontSize="20px"
    {...props}
  />
);
export const MessengerAppIcon = () => (
  <Icon iconName="bi-messenger" backgroundColor="var(--messenger-blue)" />
);
export const AscendAppIcon = () => (
  <Icon iconName="bi-image" backgroundColor="#045E85" />
);
export const ReviewsAppIcon = () => (
  <Icon iconName="bi-star" backgroundColor="#045E85" />
);
export const ChromeExtensionAppIcon = () => (
  <Icon iconName="bi-browser-chrome" backgroundColor="black" />
);
export const FirefoxExtensionAppIcon = () => (
  <Icon iconName="bi-browser-firefox" backgroundColor="#E66000" />
);
export const PushNotificationAppIcon = () => (
  <Icon iconName="bi-bell-fill" backgroundColor="black" />
);
export const LightspeedRSeriesAppIcon = () => (
  <Icon backgroundColor="#000">
    <img src="/lightspeed-app-icon-white.png" height="24px" />
  </Icon>
);
export const LightspeedXSeriesAppIcon = () => (
  <Icon backgroundColor="#D8D8D8">
    <img src="/lightspeed-app-icon-black.png" height="24px" />
  </Icon>
);
export const BrowserExtensionAppIcon = () => (
  <Icon iconName={Browser.icon()} backgroundColor="#C7417B" />
);

export const ReportsAppIcon = () => (
  <Icon iconName="bi-file-spreadsheet" className="bg-success" />
);
