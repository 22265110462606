import React, { useEffect } from "react";
import { useActions } from "../lib/overmind";
import { SupportAnchor, supportPhoneNumber } from "../lib/ikeono";
import Button from "../components/Button";

const AppContact = () => {
  const actions = useActions().activeInbox;
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const onClick = async () => {
    setIsLoading(true);
    setError(null);
    try {
      await actions.createThread({
        receiverId: supportPhoneNumber,
        name: `Ikeono Support`,
        navigateToThread: true,
      });
    } catch (e) {
      setError(e);
      console.log(e);
    }
    setIsLoading(false);
  };

  return (
    <div className="card">
      <div className="card-body">
        <div className="d-flex flex-column align-items-center gap-3">
          <h6 className="fw-bold mb-0">Ikeono Support</h6>
          <span>
            If the FAQ did not resolve your issue then please contact us.
          </span>
          <div className="d-flex gap-2 align-items-center">
            <SupportAnchor />
            <span>－or－</span>
            <Button showLoader={isLoading} onClick={onClick}>
              Chat with Support
            </Button>
          </div>
          <span className="fs-14 text-secondary">
            Please note, support is limited on the weekends.
          </span>
          <div className="text-danger mt-3" hidden={!error}>
            There was an error please contact us via email. <SupportAnchor />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppContact;
