import React from "react";
import ExternalLink from "../components/ExternalLink";

const DownloadAttachmentCard = ({ url }) => {
  return (
    <div className="card" style={{ maxWidth: "300px" }}>
      <div className="card-body">
        <h6>
          <i className="bi-file-earmark" /> Download File
        </h6>
        <p className="small mb-2">
          This is an attachment we are unable to show a preview for. Please
          download it.
        </p>
        <ExternalLink href={url}>Download</ExternalLink>
      </div>
    </div>
  );
};

export default DownloadAttachmentCard;
