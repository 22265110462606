import React from "react";
import ExternalLink from "../components/ExternalLink";
import { useState } from "../lib/overmind";

const PosDepositConfirmation = ({ deposit, addDeposit, onChange }) => {
  const state = useState().account;

  if (!deposit) return null;
  return (
    <div className="card my-2 p-2">
      <div className="my-2" hidden={state.customerDepositsEnabled}>
        <small>
          <i className="bi-x-circle exclamation-circle text-warning" /> You have
          customer deposits disabled.{" "}
          <ExternalLink href="/settings/location/payments">
            Learn more here
          </ExternalLink>
        </small>
      </div>
      <div className="form-check">
        <input
          checked={addDeposit}
          disabled={!state.customerDepositsEnabled}
          className="form-check-input"
          type="checkbox"
          id="flexCheckDefault"
          onChange={(e) => {
            onChange(e.checked);
          }}
        />
        <label className="form-check-label" htmlFor="flexCheckDefault">
          <small>
            <span className="fw-bold mb-1">Add customer deposit</span>
          </small>
          <small className="d-block">
            A deposit will be created in this customer&apos;s Credit Account
            after successful payment.
          </small>
        </label>
      </div>
    </div>
  );
};

export default PosDepositConfirmation;
