/* eslint-disable react/no-unescaped-entities */
import React from "react"
import { Link } from "react-router-dom"
import Row from "../components/Row"
import Col from "../components/Col"
import SignupForm from "./SignupForm"
import { privacyPolicyUrl, termsOfUseUrl } from "../lib/ikeono"
import SplitDetailContainer from "./components/SplitDetailContainer"

const SignupAd = () => (
  <div className="p-3 text-white d-flex flex-column flex-grow-1 justify-content-center align-items-center">
    <div
      className="position-relative"
      style={{ maxHeight: "calc(100vh - 40px)" }}>
      <img
        alt="Example"
        style={{ maxHeight: "calc(100vh - 40px)" }}
        className="object-fit-contain rounded mw-100"
        src="/signup-step-1.png"
      />
      <div className="position-absolute bottom-0 m-3 m-lg-4">
        <div className="fw-bold" style={{ maxWidth: "40ch" }}>
          “In a day and age where it's difficult to get attention and engagement
          from consumers, I'm thankful that we have Ikeono as part of our
          technology stack.”
        </div>
      </div>
    </div>
  </div>
)

const Signup = ({ onSuccess }) => {
  return (
    <SplitDetailContainer detailComponent={<SignupAd />}>
      <div className="container-fluid">
        <Row className="justify-content-center px-0 mx-0">
          <Col variant="md-7 mt-3 mt-lg-5" style={{ maxWidth: "450px" }}>
            <div className="mb-4">
              <h5 className="mb-1 fw-bold">Get started today. It's free.</h5>
              <div>
                Grow with tools for texting customers, getting reviews, and
                making sales. <b>No credit card required.</b>
              </div>
            </div>
            <SignupForm onSuccess={onSuccess} />
            <small
              className="text-secondary d-block text-center mt-3"
              style={{ fontSize: "13px" }}>
              By continuing, you agree that you have read and accept Ikeono's{" "}
              <a href={termsOfUseUrl} target="_blank" rel="noreferrer">
                Terms of Service
              </a>{" "}
              and{" "}
              <a href={privacyPolicyUrl} target="_blank" rel="noreferrer">
                Privacy Policy
              </a>
              .
            </small>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col variant="md-4 text-center mt-5 text-nowrap">
            Already have an account?{" "}
            <Link className="mt-3" to="/login">
              Login
            </Link>
          </Col>
        </Row>
      </div>
    </SplitDetailContainer>
  )
}

export default Signup
