import React from "react";
import AccountSetupFeatureList from "../components/AccountSetupFeatureList";

const IntegrationDetail = () => {
  return (
    <div>
      <AccountSetupFeatureList title="Integrate with your point of sale to">
        {[
          "Bring texting to your sales and customer profile screens.",
          "Send payment requests to get paid fast.",
          "Make the most of your POS.",
          "Text receipts with 1 click.",
        ]}
      </AccountSetupFeatureList>
    </div>
  );
};

export default IntegrationDetail;
