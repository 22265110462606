/* eslint-disable no-undef */
import React from "react"
import OvermindDataTable from "../DataTable/OvermindDataTable"
import { toggleOffcanvas } from "../OffCanvas"
import Button from "../Button"
import InfoBubble from "../../lib/InfoBubble"
import LightspeedWorkorderZeroState from "./LightspeedWorkorderZeroState"
import LightspeedCreateWorkorderAutomationOffcanvas, {
  createWorkorderOffcanvasId,
} from "./LightspeedCreateWorkorderAutomationOffcanvas"
import LightspeedEditWorkorderAutomationOffcanvas, {
  editWorkorderOffcanvasId,
} from "./LightspeedEditWorkorderAutomationOffcanvas"
import { useActions } from "../../lib/overmind"
import FeaturePermissionCheck from "../FeaturePermissionCheck"
import { PricingPlans } from "../UpgradeAccountButtonV2"

const LightspeedWorkorderAutomation = () => {
  const actions = useActions().lightspeed

  return (
    <FeaturePermissionCheck
      permissionKey="workorder_status_automation"
      plan={PricingPlans.premium}
      redirectPath="/settings/location/lightspeed-r-series/workorder-automation">
      <div className="d-flex">
        <InfoBubble variant="warning" className="py-2 fs-16">
          Heads up! The workorder must remain in the status for at least 15
          minutes. Changing the status will reset the timer.
        </InfoBubble>
        <div className="ms-auto">
          <Button
            type="button"
            data-bs-target={`#${createWorkorderOffcanvasId}`}
            data-bs-toggle="offcanvas">
            Create
          </Button>
        </div>
      </div>
      <div className="text-end" />
      <OvermindDataTable
        overmindPropertyKey="lightspeed"
        ZeroStateComponent={<LightspeedWorkorderZeroState />}
        rowClickHandler={(rowData) => {
          actions.setSelectedEditRecordId(rowData?.id)
          toggleOffcanvas(editWorkorderOffcanvasId)
        }}
      />

      <LightspeedCreateWorkorderAutomationOffcanvas />
      <LightspeedEditWorkorderAutomationOffcanvas />
    </FeaturePermissionCheck>
  )
}

export default LightspeedWorkorderAutomation
