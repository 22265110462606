import React from "react";

const WebchatCodeCreator = ({
  token,
  color,
  isOpen,
  placement,
  bottomOffset,
  locations,
}) => {
  const placementValue = placement === "bottom_left_corner";

  const locationsValue = JSON.stringify(locations);

  const widgetCode = `(function(e,o){if(e.ikeono){console.error("Ikeono embed already included");return}e.ikeono={};var n=["init"];e.ikeono._beforeLoadCallQueue=[];n.forEach(o=>{e.ikeono[o]=function(){e.ikeono._beforeLoadCallQueue.push([o,arguments])}});var t=o.createElement("script");t.type="text/javascript";t.async=true;t.src="https://widget.ikeono.com/ikwshim.js";var i=o.getElementsByTagName("script")[0];i.parentNode.insertBefore(t,i)})(window,document);ikeono.init('${token}', '${color}', ${isOpen}, ${placementValue}, ${bottomOffset}, ${locationsValue});`;

  const download = () => {
    const element = document.createElement("a");
    element.setAttribute(
      "href",
      `data:text/plain;charset=utf-8,${encodeURIComponent(widgetCode)}`
    );
    element.setAttribute("download", "ikeonoChatWidget.js");
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  return (
    <div>
      <textarea
        className="form-control px-3"
        readOnly
        style={{ width: "100%", height: "150px", fontSize: "15px" }}
        value={`<script>${widgetCode}</script>`}
        onFocus={(e) => e.target.select()}
        onSelect={(e) => e.target.select()}
      />
      <div className="alert alert-light mt-4">
        <p>
          You may need to <b>upload a file</b> instead of copying and pasting
          code into your website provider. Sites such as Smartetailing require
          this.
        </p>
        <button className="btn btn-link ps-0" type="button" onClick={download}>
          <i className="bi-box-arrow-down" /> Download ikeonoWebchatWidget.js
        </button>
      </div>
    </div>
  );
};

export default WebchatCodeCreator;
