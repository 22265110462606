import React from "react"
import FeaturePermissionCheck from "../components/FeaturePermissionCheck"
import MessengerSettings from "./MessengerSettings"
import MessengerUpgrade from "./MessengerUpgrade"
import MessengerInstall from "./MessengerInstall"
import Loader from "../components/Loader"
import { useGet } from "../lib/api"

const MessengerSetup = () => {
  const [get, isLoading, error, resp] = useGet()

  React.useEffect(() => {
    get("/shop/{exid}/integration?name=messenger")
  }, [])

  if (isLoading || !resp) {
    return <Loader />
  }
  const isMessengerInstalled = resp.data.data[0].status === "installed"
  return (
    <FeaturePermissionCheck
      permissionKey="messenger"
      upgradeComponent={<MessengerUpgrade />}>
      <MessengerSettings
        pages={resp.data.data[0].metadata.pages}
        hidden={!isMessengerInstalled}
        onReload={() => {
          get("/shop/{exid}/integration?name=messenger")
        }}
      />
      <MessengerInstall hidden={isMessengerInstalled} />
    </FeaturePermissionCheck>
  )
}

export default MessengerSetup
