import React from "react";

const InboxThreadsZeroState = () => {
  return (
    <div className="d-flex flex-column gap-1 align-items-center pt-5 text-secondary">
      <i
        className="bi bi-inbox"
        style={{ fontSize: "30px", lineHeight: "20px" }}
      />
      <p>No conversations yet.</p>
    </div>
  );
};

export default InboxThreadsZeroState;
