import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { captureException } from "../services/sentry";
import Loader from "../components/Loader";
import { get } from "../lib/api";
import { ContactSupportCta } from "../lib/ikeono";

const SpecializedFileDownload = () => {
  const { filename } = useParams();
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const startDownload = async () => {
    try {
      setShowError(false);
      setShowSuccess(false);
      const response = await get(`/sbc/download?fn=${filename}`, {
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      setShowSuccess(true);
    } catch (error) {
      console.log(error);
      captureException(error);
      setShowError(true);
    }
  };

  useEffect(() => {
    startDownload();
  }, []);

  if (showError) {
    return (
      <div className="container pt-5 text-center">
        <h5>Unable to download this file.</h5>
        <ContactSupportCta />
      </div>
    );
  }

  if (showSuccess) {
    return (
      <div className="container pt-5 text-center">
        <span className="alert alert-success">Complete</span>
      </div>
    );
  }

  return (
    <div className="container pt-5 text-center">
      <Loader />
      <span className="ml-2">
        Please wait while your file is being downloaded...
      </span>
    </div>
  );
};

export default SpecializedFileDownload;
