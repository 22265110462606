// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sms-card {
  padding: 11px 15px;
  max-width: 75%;
  border-radius: 30px;
  display: inline-block;
  overflow-wrap: anywhere;
}

@media (min-width: 767.98px) {
  .sms-card {
    max-width: 430px;
  }
}

.sms-card--from-shop {
  background-color: var(--bs-primary);
  color: white;
  text-align: left;
  border-bottom-right-radius: 5px;
}

.sms-card--to-shop {
  background-color: rgb(235, 235, 235);
  border-bottom-left-radius: 2px;
}

.sms-card-info {
  font-size: 13px;
  font-weight: 500;
}

.mms-media-img {
  max-width: 270px;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  border: 1px solid #f5f5f5 !important;
}

.mms-placeholder {
  height: 400px;
  width: 200px;
}

@media (max-width: 300px) {
  .mms-media-img {
    max-width: 210px;
  }
}

.mms-media-container {
  margin-top: 16px;
  margin-bottom: 16px;
}

.mms-downloadable-card {
  width: 330px;
}

@media (max-width: 300px) {
  .mms-downloadable-card {
    width: 210px;
  }
}

.message-error {
  font-size: 13px;
  max-width: 450px;
}

.review-request-card-link {
  max-width: 300px !important;
}
`, "",{"version":3,"sources":["webpack://./src/MessageCard/MessageCard.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,cAAc;EACd,mBAAmB;EACnB,qBAAqB;EACrB,uBAAuB;AACzB;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF;;AAEA;EACE,mCAAmC;EACnC,YAAY;EACZ,gBAAgB;EAChB,+BAA+B;AACjC;;AAEA;EACE,oCAAoC;EACpC,8BAA8B;AAChC;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;EAChB,eAAe;EACf,8DAA8D;EAC9D,oCAAoC;AACtC;;AAEA;EACE,aAAa;EACb,YAAY;AACd;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE;IACE,YAAY;EACd;AACF;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":[".sms-card {\n  padding: 11px 15px;\n  max-width: 75%;\n  border-radius: 30px;\n  display: inline-block;\n  overflow-wrap: anywhere;\n}\n\n@media (min-width: 767.98px) {\n  .sms-card {\n    max-width: 430px;\n  }\n}\n\n.sms-card--from-shop {\n  background-color: var(--bs-primary);\n  color: white;\n  text-align: left;\n  border-bottom-right-radius: 5px;\n}\n\n.sms-card--to-shop {\n  background-color: rgb(235, 235, 235);\n  border-bottom-left-radius: 2px;\n}\n\n.sms-card-info {\n  font-size: 13px;\n  font-weight: 500;\n}\n\n.mms-media-img {\n  max-width: 270px;\n  border-radius: 10px;\n  overflow: hidden;\n  cursor: pointer;\n  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;\n  border: 1px solid #f5f5f5 !important;\n}\n\n.mms-placeholder {\n  height: 400px;\n  width: 200px;\n}\n\n@media (max-width: 300px) {\n  .mms-media-img {\n    max-width: 210px;\n  }\n}\n\n.mms-media-container {\n  margin-top: 16px;\n  margin-bottom: 16px;\n}\n\n.mms-downloadable-card {\n  width: 330px;\n}\n\n@media (max-width: 300px) {\n  .mms-downloadable-card {\n    width: 210px;\n  }\n}\n\n.message-error {\n  font-size: 13px;\n  max-width: 450px;\n}\n\n.review-request-card-link {\n  max-width: 300px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
