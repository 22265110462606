/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useRef, useEffect } from "react"

export const DropDownDivider = () => (
  <li>
    <hr className="dropdown-divider" />
  </li>
)

export const DropdownItem = (props) => (
  <li
    className="dropdown-item  text-truncate overflow-hidden"
    onClick={props.onClick}>
    {props.children}
  </li>
)

const Dropdown = ({
  onOpen,
  children,
  title = "Click",
  dropup,
  className,
  dropdownMenuClassName,
  containerClass,
}) => {
  const ref = useRef()

  useEffect(() => {
    if (!onOpen) return
    const eventHandler = () => onOpen()
    ref.current?.addEventListener("shown.bs.dropdown", eventHandler)
    return () =>
      ref.current?.removeEventListener("show.bs.dropdown", eventHandler)
  }, [])

  return (
    <div
      className={
        containerClass || `btn-group  ${dropup ? "dropup-center dropup" : ""}`
      }>
      <button
        ref={ref}
        type="button"
        className={className}
        data-bs-toggle="dropdown">
        {title}
      </button>
      <ul className={`dropdown-menu vw-sm-100 ${dropdownMenuClassName || ""}`}>
        {children}
      </ul>
    </div>
  )
}

export default Dropdown
