import React from "react";
import { FeatureTierBadge } from "../components/FeatureTierBadge";
import { ReportsAppIcon } from "../IntegrationAppIcon";
import UpgradeAccountButtonV2, {
  PricingPlans,
} from "../components/UpgradeAccountButtonV2";

const ReportUpgrade = ({ hidden }) => {
  if (hidden) return null;
  return (
    <div
      className="position-absolute bottom-0 end-0 mb-5 w-100"
      style={{ zIndex: 100 }}
    >
      <div className="d-flex justify-content-center align-items-center mt-5">
        <div className="card shadow">
          <div className="card-header bg-transparent">
            <FeatureTierBadge premium className="me-2" />
            Upgrade today!
          </div>
          <div className="card-body mb-0 pb-0">
            <div className="d-flex gap-3 m-2 justify-content-center">
              <ReportsAppIcon />
              <div>
                <h5>Reports</h5>
                <p style={{ maxWidth: "40ch" }}>
                  Please upgrade to start accessing insightful reports that can
                  help you get the most out of Ikeono.
                </p>
              </div>
            </div>
          </div>
          <div className="w-100 text-center mb-4">
            <UpgradeAccountButtonV2
              featureName="NPS"
              plan={PricingPlans.premium}
              redirectPath="/nps"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportUpgrade;
