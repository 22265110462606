import React from "react"
import { useNavigate } from "react-router-dom"
import { logout } from "../lib/auth"
import { useState } from "../lib/overmind"
import { NotificationPreferenceModalId } from "../NotificationPreference"

const MainMenuUserDropdown = () => {
  const state = useState().account
  const navigate = useNavigate()
  return (
    <div className="nav-item dropdown ms-2 d-none d-md-block">
      <a
        href="#"
        className="nav-link d-flex align-items-center justify-content-center text-decoration-none text-white dropdown-toggle"
        id="dropdownUser3"
        data-bs-toggle="dropdown"
        aria-expanded="false">
        <i
          className="bi-person-circle   text-white me-1"
          style={{ fontSize: "20px" }}
        />
        {state.userName}
      </a>
      <ul className="dropdown-menu text-small shadow dropdown-menu-end py-3 px-3 main-menu">
        <li style={{ margin: "0px 12px" }}>
          <div className="d-flex flex-row align-items-center">
            <i
              className="bi-person-circle text-secondary"
              style={{ fontSize: "30px" }}
            />
            <div>
              <h6 className="mb-0 text-capitalize">{state.userName}</h6>
              <span style={{ fontSize: "13px" }}>{state.userEmail}</span>
            </div>
          </div>
        </li>
        <hr className="my-2" />
        <li
          className="dropdown-item cursor-pointer"
          role="button"
          onClick={() => navigate("/settings")}>
          <i className="bi-gear" />
          Account settings
        </li>
        <li hidden className="dropdown-item">
          <i className="bi-person" />
          Edit profile
        </li>
        <li
          className="dropdown-item cursor-pointer"
          data-bs-toggle="modal"
          data-bs-target="#change-password-modal">
          <i className="bi-envelope" />
          Change password
        </li>
        <li
          className="dropdown-item cursor-pointer"
          data-bs-toggle="modal"
          data-bs-target={`#${NotificationPreferenceModalId}`}>
          <i className="bi-bell" />
          Notification preferences
        </li>
        <li
          className="dropdown-item cursor-pointer"
          onClick={() => navigate("/settings/support")}>
          <i className="bi-question-circle" />
          Support
        </li>
        <li
          className="dropdown-item cursor-pointer"
          role="button"
          onClick={() => navigate("/logout")}>
          <i className="bi-box-arrow-right" />
          Sign out
        </li>
      </ul>
    </div>
  )
}

export default MainMenuUserDropdown
