import * as React from "react";

const Toast = ({
  id,
  title,
  body,
  onHidden,
  className = "",
  autohide = false,
}) => {
  const ref = React.useRef();

  React.useEffect(() => {
    ref.current.addEventListener("hidden.bs.toast", () => onHidden());
  }, []);

  return (
    <div
      ref={ref}
      id={id}
      className="toast hide"
      data-bs-autohide={autohide}
      data-bs-delay="3000"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div className="toast-header">
        <strong className="me-auto">{title}</strong>
        <small>Now</small>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="toast"
          aria-label="Close"
        />
      </div>
      <div className="toast-body" style={{ background: "white" }}>
        {body}
      </div>
    </div>
  );
};

export default Toast;
