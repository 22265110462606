import React from "react";
import { Route, Routes } from "react-router";
import PaymentDetail from "./PaymentDetail";
import PaymentTable from "./PaymentTable";
import CreatePaymentRequestModal from "./CreatePaymentRequest/CreatePaymentRequestModal";
import Container from "../components/Container";

const Payments = () => {
  return (
    <Container>
      <Routes>
        <Route path="/*" element={<PaymentTable />} />
      </Routes>
      <PaymentDetail />
      <CreatePaymentRequestModal />
    </Container>
  );
};

export default Payments;
