/* eslint-disable prefer-const */
import React from "react"
import { useSearchParams } from "react-router-dom"
import { useGet } from "../../lib/api"
import Loader from "../../components/Loader"
import APIResponseError from "../../components/APIResponseError"
import { supportEmail } from "../../lib/ikeono"
import { closePopup } from "../../lib/popup"
import GoogleLocationAssignment from "./GoogleLocationAssignment"

const GoogleOAuthExchange = () => {
  const [searchParams] = useSearchParams()
  const [get, isLoading, error, resp] = useGet()
  const [localError, setLocalError] = React.useState()

  const onLoad = async () => {
    const state = searchParams.get("state")
    const code = searchParams.get("code")
    const scope = searchParams.get("scope")
    const oauthError = searchParams.get("error")

    if (oauthError) {
      const paramError = new Error(
        `There was an error. Please close this window, and try again. Or contact us at ${supportEmail} Reason:${oauthError}`
      )
      paramError.source = "ik"
      setLocalError(paramError)
      return
    }
    if (!state || !code || !scope) {
      const paramError = new Error(
        `Missing required URL params. Please close this window, and try again. Or contact us at ${supportEmail}`
      )
      paramError.source = "ik"
      setLocalError(paramError)
      return
    }

    await get("/google/oauth/exchange", { state, code, scope })
  }

  React.useEffect(() => {
    onLoad()
  }, [])

  if (isLoading) {
    return (
      <div className="container mt-5">
        <Loader />
        <div className="text-center mt-3">
          Connecting to your Google Account. Please do not close or refresh this
          window.
        </div>
      </div>
    )
  }

  if (error || localError) {
    return (
      <div className="container mt-5 d-flex justify-content-center flex-column">
        <APIResponseError response={error || localError} />
        <button
          type="button"
          className="btn btn-link mt-3"
          onClick={closePopup}>
          Close Window
        </button>
      </div>
    )
  }

  return (
    <div className="container mt-5 d-flex justify-content-center flex-column">
      <GoogleLocationAssignment />
      <button type="button" className="btn btn-link mt-3" onClick={closePopup}>
        Close Window
      </button>
    </div>
  )
}

export default GoogleOAuthExchange
