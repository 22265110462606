/* eslint-disable no-shadow */
import React from "react";
import UpgradeAccountButtonV2 from "../components/UpgradeAccountButtonV2";

export const ListItem = ({ children }) => (
  <div className="d-flex gap-3">
    <img src="/green-check.svg" style={{ width: "22px" }} />
    <div>{children}</div>
  </div>
);

const SplitPanelFeatureUpgrade = ({
  title,
  children,
  imageUrl,
  learnMoreUrl,
  plan,
  redirectPath,
}) => {
  return (
    <div
      style={{ maxWidth: "1100px" }}
      className="container mt-5 d-flex gap-4 justify-content-center align-items-center"
    >
      <img src={imageUrl} style={{ maxWidth: "400px" }} />
      <div className="d-flex flex-column gap-3">
        <h2 className="fw-bold">{title}</h2>
        {children}
        <div className="d-grid gap-2 d-md-flex justify-content-md-start mt-4">
          <UpgradeAccountButtonV2
            plan={plan}
            featureName={title}
            redirectPath={redirectPath}
          />
          <a
            hidden={!learnMoreUrl}
            target="_blank"
            href={learnMoreUrl}
            className="btn btn-link text-secondary align-self-start my-auto"
            rel="noreferrer"
          >
            Learn more
          </a>
        </div>
      </div>
    </div>
  );
};

export default SplitPanelFeatureUpgrade;
