const timezones = Array.from(
  new Set([
    "America/Dawson_Creek",
    "America/Denver",
    "America/Halifax",
    "America/Los_Angeles",
    "America/New_York",
    "America/Puerto_Rico",
    "America/Regina",
    "America/Santiago",
    "America/Vancouver",
    "America/Whitehorse",
    "Australia/Adelaide",
    "Australia/Brisbane",
    "Australia/Darwin",
    "Australia/Hobart",
    "Australia/Melbourne",
    "Australia/Perth",
    "Australia/Sydney",
    "Canada/Atlantic",
    "Canada/Central",
    "Canada/Eastern",
    "Canada/Mountain",
    "Canada/Newfoundland",
    "Canada/Pacific",
    "GMT",
    "US/Alaska",
    "US/Arizona",
    "US/Central",
    "US/Eastern",
    "US/Hawaii",
    "US/Mountain",
    "US/Pacific",
    Intl.DateTimeFormat().resolvedOptions().timeZone,
  ])
).sort()

export default timezones
