import React from "react";

const StandardFormGroup = ({ label, fieldId, children, className }) => {
  return (
    <div className={`${className}`}>
      <label
        htmlFor={fieldId}
        className="form-label mb-1"
        style={{ fontSize: "15px", fontWeight: 500 }}
      >
        {label}
      </label>
      {children}
    </div>
  );
};

export default StandardFormGroup;
