import React from "react"
import { useLocation, useNavigate } from "react-router-dom"
import ThreadActionItem from "./ThreadActionItem"

const CreateContactActionItem = ({ hidden }) => {
  if (hidden) return null
  const location = useLocation()
  const navigate = useNavigate()

  return (
    <ThreadActionItem
      title="Create contact"
      description="Create a new contact."
      iconName="bi-person-fill-add"
      buttonProps={{
        onClick: () => {
          navigate(`${location.pathname}/detail/create`)
        },
      }}
    />
  )
}

export default CreateContactActionItem
