/* eslint-disable no-plusplus */
/* eslint-disable */

export class SmsSegmentCalculator {
  gsm7bitChars =
    "@£$¥èéùìòÇ\\nØø\\rÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ !\\\"#¤%&'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà";

  gsm7bitExChar = "\\^{}\\\\\\[~\\]|€";

  gsm7bitRegExp = RegExp(`^[${this.gsm7bitChars}]*$`);

  gsm7bitExRegExp = RegExp(`^[${this.gsm7bitChars}${this.gsm7bitExChar}]*$`);

  gsm7bitExOnlyRegExp = RegExp(`^[\\${this.gsm7bitExChar}]*$`);

  GSM_7BIT = "GSM_7BIT";

  GSM_7BIT_EX = "GSM_7BIT_EX";

  UTF16 = "UTF16";

  messageLength = {
    GSM_7BIT: 160,
    GSM_7BIT_EX: 160,
    UTF16: 70,
  };

  multiMessageLength = {
    GSM_7BIT: 153,
    GSM_7BIT_EX: 153,
    UTF16: 67,
  };

  count = (text) => {
    const encoding = this.detectEncoding(text);
    let { length } = text;
    if (encoding === this.GSM_7BIT_EX) {
      length += this.countGsm7bitEx(text);
    }
    let per_message = this.messageLength[encoding];
    if (length > per_message) {
      per_message = this.multiMessageLength[encoding];
    }
    const messages = Math.ceil(length / per_message);
    let remaining = per_message * messages - length;
    if (remaining === 0 && messages === 0) {
      remaining = per_message;
    }
    return {
      encoding,
      length,
      per_message,
      remaining,
      messages,
    };
  };

  detectEncoding = (text) => {
    switch (false) {
      case text.match(this.gsm7bitRegExp) == null:
        return this.GSM_7BIT;
      case text.match(this.gsm7bitExRegExp) == null:
        return this.GSM_7BIT_EX;
      default:
        return this.UTF16;
    }
  };

  countGsm7bitEx = (text) => {
    const _results = [];
    for (let _i = 0, _len = text.length; _i < _len; _i++) {
      const char2 = text[_i];
      if (char2.match(this.gsm7bitExOnlyRegExp) != null) {
        _results.push(char2);
      }
    }
    return _results.length;
  };
}

const sharedSmsSegmentCalculator = new SmsSegmentCalculator();

const calculateSegments = (message) => {
  if (!message) return { segments: 0 };
  return {
    segments: sharedSmsSegmentCalculator.count(message).messages,
  };
};

export const calculateSegmentsWithTitle = (message) => {
  const { segments } = calculateSegments(message);
  const messageText = segments === 1 ? "message" : "messages";
  return `${segments} ${messageText}`.toString();
};

export const smsMessageCharacterLimit = 1600;

export const isMessageOverTheCharacterLimit = (body) => {
  return body?.length > smsMessageCharacterLimit;
};

/* eslint-enable */
