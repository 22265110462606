import React from "react"
import SimpleForm from "../components/Form/SimpleForm"
import { SettingsFactory } from "../components/Settings/SettingsFactory"
import { get, put } from "../lib/api"
import MinDelaySelect from "../components/Form/MinDelaySelect"
import PosSalesWorkorderCheckbox from "../components/Form/PosSalesWorkorderCheckbox"
import EmailListControl from "../components/Form/EmailListControl"
import ReviewLinkDescription from "../Reviews/Settings/ReviewLinkDescription"
import SectionHeader from "../components/SectionHeader"
import { FeatureTierBadge } from "../components/FeatureTierBadge"

const fields = [
  {
    label: "Message",
    as: "textarea",
    body: "The first message your contact receives prompting them for an NPS Score between 1 and 10",
    props: {
      mergeFields: [
        {
          name: "Shop",
          value: "{shop}",
        },
        {
          name: "First Name",
          value: "{first name}",
        },
        {
          name: "Last Name",
          value: "{last name}",
        },
      ],
      label: "Message Body",
      type: "text",
      name: "body",
      rows: 4,
    },
  },
  {
    label: "Promoter Response (9-10)",
    as: "textarea",
    body: "If your contact replies with a 9 or 10 this message will be sent. This is a good place to ask for a review.",
    props: {
      label: "Promoter Response",
      type: "text",
      name: "positive_reply_body",
      rows: 4,
    },
  },
  {
    label: "Neutral Response (7-8)",
    as: "textarea",
    body: "If your contact replies with a 7 or 8 they are neutral about your company. Use this to ask for feedback.",
    props: {
      label: "Neutral Response",
      type: "text",
      name: "neutral_reply_body",
      rows: 4,
    },
  },
  {
    label: "Detractor Response (1-6)",
    as: "textarea",
    body: "This indicates an unsatisfied customer who had a bad impression or experience with your company.",
    props: {
      label: "Detractor Response",
      type: "text",
      name: "negative_reply_body",
      rows: 4,
    },
  },
  {
    label: "Minimum Delay",
    as: "component",
    component: <MinDelaySelect />,
  },
  {
    label: "Review Link",
    body: <ReviewLinkDescription />,
    props: {
      label: "Review Link",
      type: "text",
      name: "review_link",
    },
  },
  {
    label: "Minimum Sale Amount",
    props: {
      name: "min_sale_amt",
      type: "number",
      step: ".01",
      min: "0",
    },
  },
  {
    label: "Automation Enabled",
    component: (
      <PosSalesWorkorderCheckbox warningMessage="Enabling NPS will disable Reviews if you have it enabled." />
    ),
  },
  {
    label: "Alert Emails",
    component: <EmailListControl />,
  },
]

const NpsSettings = () => {
  const apiGetHandler = () => get("/shop/{exid}/nps/settings")
  const apiSubmitHandler = (data) => {
    if (data.review_link && !data.review_link.includes("https")) {
      data.review_link = `https://${data.review_link}`
    }
    put("/shop/{exid}/nps/settings", data)
  }

  return (
    <div className="mb-5">
      <SectionHeader className="mb-3">
        NPS Settings <FeatureTierBadge className="ms-3" premium />
      </SectionHeader>
      <SimpleForm
        apiGetHandler={apiGetHandler}
        apiSubmitHandler={apiSubmitHandler}>
        <SettingsFactory fields={fields} />
      </SimpleForm>
    </div>
  )
}

export default NpsSettings
