export const stringOnlyContainsNumbers = (str) => /^[0-9]+$/.test(str)

export const extractNumbers = (str) => {
  // Given a string ABC1234 only retunr the digits, 1234
  if (!str) return ""
  const matches = str.match(/\d/g)
  if (!matches) return ""
  return matches.join("")
}

export const getRandomInt = (max = 100) => Math.floor(Math.random() * max)

export const percentageChange = (startValue, endvalue) => {
  return parseInt(((endvalue - startValue) / (startValue || 1)) * 100)
}
