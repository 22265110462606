import React, { useEffect } from "react";

/* eslint-disable no-undef */
const bootstrap = require("bootstrap");

export const enableTooltips = () => {
  const tooltipTriggerList = document.querySelectorAll(
    '[data-bs-toggle="tooltip"]',
  );
  const tooltipList = [...tooltipTriggerList].map(
    (tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl),
  );
};

export const useTooltips = () => {
  useEffect(() => {
    setTimeout(enableTooltips, 100);
  });
};

export const Tooltip = ({ title, children, placement = "right", disabled }) => {
  useTooltips();

  if (disabled) return children;
  return (
    <span
      data-bs-toggle="tooltip"
      data-bs-placement={placement}
      data-bs-title={title}
    >
      {children}
    </span>
  );
};
