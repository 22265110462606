import React, { useState } from "react";
import Modal, {
  ModalVariant,
  hideModal,
  toggleModal,
} from "../components/Modal";
import { useConfigureInvoicePrompt } from "./ConfigureInvoicePrompt";
import CreateInvoiceForm from "./CreateInvoiceForm";
import { useActions } from "../lib/overmind";

export const toggleInvoiceModal = () => {
  toggleModal("payment-request-modal");
};

const InboxInvoice = ({ initialLineItems, posDeposit }) => {
  const configureInvoicePrompt = useConfigureInvoicePrompt();
  const [lineItems, setLineItems] = useState();
  const actions = useActions().activeThread;

  let children = null;
  let modalVariant = ModalVariant.md;

  if (configureInvoicePrompt) {
    children = configureInvoicePrompt;
    modalVariant = ModalVariant.lg;
  } else {
    children = (
      <>
        <div className="mb-2">
          The information will be added to the payment request.
        </div>
        <CreateInvoiceForm
          initialLineItems={initialLineItems}
          posDeposit={posDeposit}
          onSuccess={(data) => {
            actions.updateInvoiceAttachment(data);
            window.postMessage({
              name: "com.ikeono.updateInboxMessageInput",
              message: data.message_template,
            });
          }}
        />
        <button
          type="button"
          className="btn-close d-none"
          data-bs-dismiss="modal"
          id="modal-close-btn"
        />
      </>
    );
  }

  return (
    <Modal
      title="Request Payment"
      id="payment-request-modal"
      variant={modalVariant}
      onHide={() => setLineItems([{ amount: "", description: "" }])}
    >
      {children}
    </Modal>
  );
};

export default InboxInvoice;
