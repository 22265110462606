import React, { useState } from "react";
import SettingsCard from "../Settings/SettingsCard";
import LightspeedConnectButton from "./LightspeedConnectButton";
import UserPermissionCheck from "../UserPermissionCheck";

const LightspeedOauth = ({ children }) => {
  return (
    <UserPermissionCheck permissionKey="can_authorize_pos_connection">
      <SettingsCard title="Connect your Lightspeed Account">
        {children || (
          <p>
            Send your current customeres messages, invoice links, automated
            messages and more when you connect Lightspeed.
          </p>
        )}
        <LightspeedConnectButton />
      </SettingsCard>
    </UserPermissionCheck>
  );
};

export default LightspeedOauth;
