/* eslint-disable */
import React from "react"
import { postMessageToParent } from "../lib/overmind/browserExtensionEventBus/parentWindow"
import BrowserExtensionCreateInvoiceFormContainer from "./BrowserExtensionCreateInvoiceFormContainer"

class BrowserExtensionCreateInvoice extends React.Component {
  /*
    Need to use a component here to handle the window events.
    Setting the state in an useEffect hook in a functional
    component doesn't trigger a render.
    */
  state = { data: null }

  constructor(props) {
    super(props)

    window.addEventListener("message", (message) => {
      const { name, parameters } = message.data
      if (name !== "com.ikeono.load_invoice_data") return
      this.setState({
        data: parameters,
      })
    })
  }

  componentDidMount() {
    const payload = {
      name: "payments_standalone_new_invoice_did_load",
    }
    postMessageToParent(payload)
  }

  render() {
    const { data } = this.state
    if (!data) return <div>Waiting for data.</div>
    let customerName = null

    const {
      phoneNumber,
      firstName,
      lastName,
      saleAmount,
      saleDescription,
      email,
      primaryId,
      primaryType,
      customerId,
      employeeId,
    } = data

    customerName = `${firstName || ""} ${lastName || ""}`.trim()

    return (
      <div className="m-2">
        <h6 style={{ marginTop: "-5px" }}>Create Invoice</h6>
        <BrowserExtensionCreateInvoiceFormContainer
          customerName={customerName}
          phoneNumber={phoneNumber}
          total={saleAmount}
          lineItemDescription={saleDescription}
          email={email}
          primaryId={primaryId}
          primaryType={primaryType}
          customerId={customerId}
          employeeId={employeeId}
        />
      </div>
    )
  }
}

export default BrowserExtensionCreateInvoice
