import React from "react"
import { AppConfig } from "../services/config"

const LegacyPortalFrame = ({ path, style, className }) => {
  const url = `${AppConfig.LegacyPortalHost}${path}?embed=true`
  return (
    <iframe
      title="Portal"
      className={`border-none w-100 ${className}`}
      style={style}
      src={url}
    />
  )
}

export default LegacyPortalFrame
