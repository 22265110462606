import React, { useState } from "react";
import APIResponseError from "../APIResponseError";
import Button, { ButtonSize } from "../Button";
import useVendOAuthHook from "./VendOauthHook";

const VendConnectButton = () => {
  const [isLoading, error, onClick] = useVendOAuthHook();

  return (
    <>
      <Button
        onClick={onClick}
        showLoader={isLoading}
        className="shadow d-flex align-items-center"
        size={ButtonSize.medium}
        style={{
          backgroundColor: "#41af4b",
          border: "none",
          fontWeight: "500",
        }}
      >
        <span className="ps-1">Connect X-Series</span>
      </Button>
      {error && <APIResponseError response={error.response} />}
    </>
  );
};

export default VendConnectButton;
