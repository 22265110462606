/* eslint-disable import/prefer-default-export */
export const AppConfig = {
  PusherKey: process.env.REACT_APP_PUSHER_KEY,
  ApiHost: process.env.REACT_APP_API_HOST,
  LegacyPortalHost: process.env.REACT_APP_LEGACY_PORTAL_HOST,
  RecaptchaKey: process.env.REACT_APP_RECAPTCHA_KEY,
  FacebookAppId: process.env.REACT_APP_FACEBOOK_APP_ID,
  FacebookConfigId: process.env.REACT_APP_FACEBOOK_CONFIG_ID,
  MixpanelProjectId: process.env.REACT_APP_MIXPANEL_PROJECT_ID,
  CurrentHost: `${window.location.protocol}//${window.location.host}`,
  ChromeExtensionUrl:
    "https://chrome.google.com/webstore/detail/ikeono-chat/jelpkpjedmadbgclhonjkodjepmmbckf",
  FirefoxExtensionUrl: `${process.env.REACT_APP_API_HOST}/v2/extension/firefox/latest`,
  LegacyFirefoxExtensionUrl: "https://firefox-extension.s3.amazonaws.com/LEGACY-13129284baae49e886c4-3.39.xpi",
  StripeConnectClientId: process.env.REACT_APP_STRIPE_CONNECT_CLIENT_ID,
  StripePublishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
}
