import React, { useState } from "react"
import { Link } from "react-router-dom"
import ReCAPTCHA from "react-google-recaptcha"
import Col from "../components/Col"
import Container from "../components/Container"
import LabeledField from "../components/Form/LabeledField"
import SimpleForm from "../components/Form/SimpleForm"
import Row from "../components/Row"
import { post } from "../lib/api"
import { AppConfig } from "../services/config"

const ForgotPasswordSuccess = (props) => (
  <div {...props}>
    <div className="alert alert-success">Success! Please check your email.</div>
    <Link to="/login">Go home</Link>
  </div>
)

const ForgotPassword = () => {
  const [showSuccess, setShowSuccess] = useState(false)
  const captchaRef = React.useRef()
  const [captchaValue, setCaptchaValue] = useState("")

  const apiSubmitHandler = async (data) => {
    setShowSuccess(false)
    if (showSuccess) return
    data["g-recaptcha-response"] = captchaValue
    await post("/forgot-password", data)
    setShowSuccess(true)
  }

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col variant="md-4">
          <h4>Let&apos;s get you signed in </h4>
          <p className="mb-4">
            Enter the email associated with your Ikeono account and we&apos;ll
            send you a temporary access code.
          </p>
          <SimpleForm
            apiSubmitHandler={apiSubmitHandler}
            initialValues={{}}
            submitButtonProps={{
              disabled: !captchaValue,
            }}
            disableSuccess>
            <LabeledField
              label="Email"
              name="email"
              type="email"
              className="form-control"
              required
            />
            <ReCAPTCHA
              className="my-3"
              ref={captchaRef}
              sitekey={AppConfig.RecaptchaKey}
              onExpired={() => {
                setCaptchaValue(null)
              }}
              onError={() => {
                captchaRef.current.reset()
              }}
              onChange={(token) => {
                setCaptchaValue(token)
              }}
            />
            <ForgotPasswordSuccess hidden={!showSuccess} />
          </SimpleForm>
        </Col>
      </Row>
    </Container>
  )
}

export default ForgotPassword
