import React from "react"
import Button from "../components/Button"
import { useActions } from "../lib/overmind"
import APIResponseError from "../components/APIResponseError"

const ChangePaymentStatus = ({ invoiceId, hidden, onSuccess }) => {
  if (hidden) return null
  const [isLoading, setIsLoading] = React.useState()
  const [error, setError] = React.useState()

  const actions = useActions().payments

  const onClick = () => {
    const didConfirm = confirm("Are you sure you want to void this payment?")
    if (!didConfirm) return
    setIsLoading(true)
    try {
      actions.voidPayment(invoiceId)
      onSuccess("void")
    } catch (e) {
      console.log(e)
      setError(e)
    }
    setIsLoading(false)
  }

  return (
    <div>
      <Button
        showLoader={isLoading}
        onClick={onClick}
        type="button"
        variant="btn-link"
        className="p-0 fs-14 text-secondary ms-1 mt-1">
        Void this payment
      </Button>
      <APIResponseError response={error} />
    </div>
  )
}

export default ChangePaymentStatus
