import React, { useState, createRef, useEffect } from "react";

const StatusText = ({ completed, children }) => {
  const icon = completed
    ? "bi-check-circle text-success"
    : "bi-check-circle text-secondary";
  return (
    <span className="d-block mt-2">
      <i className={`${icon} me-1`} style={{ fontSize: "16px" }} />
      {children}
    </span>
  );
};

const PaymentsExternalLinkCopyToClipboard = ({ hostedPageUrl }) => {
  const [inputRef] = useState(createRef());
  const [showCopiedConfirmation, setShowCopiedConfirmation] = useState(false);

  useEffect(() => {
    setShowCopiedConfirmation(false);
  }, [hostedPageUrl]);

  const onClick = () => {
    const copyText = inputRef.current;
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */
    document.execCommand("copy");
    setShowCopiedConfirmation(true);
  };

  return (
    <div>
      <textarea
        style={{ border: "none" }}
        className="text-primary w-100"
        value={hostedPageUrl}
        ref={inputRef}
        rows={3}
      />
      <button
        type="button"
        className="btn btn-sm btn-link text-secondary pl-0 ml-0 d-inline-block mr-2"
        onClick={onClick}
      >
        Copy to clipboard
      </button>
      {showCopiedConfirmation && (
        <span className="badge bg-success text-white">Copied</span>
      )}
    </div>
  );
};

const BrowserExtensionInvoiceSuccess = ({
  onDoneClick,
  invoice,
  didSendEmail,
  didSendSMS,
}) => {
  return (
    <div className="mt-2">
      <div className="">
        <div className="text-center">
          <i
            className="bi-check-circle text-success"
            style={{ fontSize: "60px" }}
          />
          <h5>Invoice created.</h5>
        </div>
        <hr />
        <div className="mt-4">
          <StatusText completed={didSendSMS}>
            Sent customer an SMS message
          </StatusText>
        </div>
        <hr />
        <div className="mt-4">
          <p className="" style={{ fontSize: "14px" }}>
            You can additionally copy this link and send to your customer via
            another medium.
          </p>
          <PaymentsExternalLinkCopyToClipboard
            hostedPageUrl={invoice?.hostedPageUrl}
          />
        </div>
        <button
          type="button"
          className="btn btn-outline-primary mt-5 w-100"
          onClick={onDoneClick}
        >
          Done
        </button>
      </div>
    </div>
  );
};

export default BrowserExtensionInvoiceSuccess;
