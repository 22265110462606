import React from "react";
import Loader from "../components/Loader";

const InboxMessageLoading = () => (
  <div className="h-100 w-100 d-grid justify-content-center align-content-center">
    <h6 className="text-secondary d-flex gap-1">
      <Loader />
      Loading messages
    </h6>
  </div>
);

export default InboxMessageLoading;
