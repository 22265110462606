import React from "react"
import { Link } from "react-router-dom"
import OvermindDataTable from "../components/DataTable/OvermindDataTable"

const PaymentTable = () => {
  return (
    <div>
      <div className="my-3 d-flex align-items-center">
        <h5 className="my-0">Payments</h5>
        <Link to="create" className="btn btn-sm btn-primary ms-auto">
          New Payment Request
        </Link>
      </div>
      <OvermindDataTable overmindPropertyKey="payments" />
    </div>
  )
}

export default PaymentTable
