/* eslint-disable no-unused-expressions */
import { useEventHook } from "../services/events";
import { useActions } from "./overmind";

export const PosSystem = {
  LightspeedRetail: "lightspeed-retail",
  LightspeedXSeries: "vend",
  Standalone: "standalone",
  Ascend: "ascend",
};

export const usePosConnectedEvent = (callback) => {
  const actions = useActions().account;
  const handler = (data) => {
    actions.updatePosSystem(data.pos_system);
    callback && callback();
  };
  useEventHook("pos-connected", handler);
};
