import React from "react";

const ExternalLink = ({ hidden, href, children, className }) => {
  if (hidden) return null;
  return (
    <a
      className={className}
      style={{ textDecoration: "none" }}
      target="_blank"
      href={href}
      rel="noreferrer"
    >
      <u>{children}</u>{" "}
      <i style={{ fontSize: "13px" }} className="bi-box-arrow-up-right" />
    </a>
  );
};

export default ExternalLink;
