import React from "react"

const linkify = require("linkify-it")().tlds(require("tlds"))

const FormatText = (props) => {
  let s = props.children
  const matches = linkify.match(s)

  if (matches) {
    for (const idx in matches) {
      const match = matches[idx]
      s = s.replace(
        match.text,
        `<a target="_blank" href="${match.url}" class="${props.linkClass}">${match.text}</a>`
      )
    }
  }

  return (
    <span
      style={{ whiteSpace: "pre-line" }}
      dangerouslySetInnerHTML={{ __html: s }}
    />
  )
}

export default FormatText
