import { setSentryEnv } from "../../../services/sentry"
import * as auth from "../../auth"

export const getAccount = async ({ state, actions, effects }, locationId) => {
  state.account.isLoading = true
  state.account.apiError = null

  let computedLocationId = locationId
  if (!locationId) {
    computedLocationId = auth.getShopExternalId() || ""
  }

  let isDirty = false
  if (
    state.account.shopExternalId &&
    state.account.shopExternalId !== computedLocationId
  ) {
    isDirty = true
  }

  try {
    actions.activeInbox.clearCurrentThread()

    const data = await effects.account.getAccount(computedLocationId)
    auth.setShopExternalId(computedLocationId || data.shop.external_id)
    auth.setShopId(data.shop.id)
    state.account.data = data

    actions.user.update({
      forcePasswordChange: state.account.data.user.force_password_change,
    })
  } catch (error) {
    const status = error.response?.status
    if (status === 401 || status === 404 || status === 403) {
      auth.logout()
    }
    state.account.apiError = error
  }
  state.account.isLoading = false

  if (isDirty) {
    actions.browserExtensionEventBus.postUserChangedLocation()
  }

  setSentryEnv()
}

export const acceptTerms = async ({ state, effects }) => {
  effects.account.acceptTerms()
  state.account.data.user.accepted_tos = true
}

export const updatePosSystem = ({ state }, posSystem) => {
  state.account.data.shop.pos_system = posSystem
}

export const getSetupStatus = async ({ state, effects }) => {
  const data = await effects.account.getSetupStatus(
    state.account.shopExternalId
  )
  state.account.setupStatus = data?.data
}

export const changePhoneNumber = async ({ state, effects }, phoneNumber) => {
  const data = await effects.account.changePhoneNumber(
    state.account.shopExternalId,
    {
      phoneNumber,
    }
  )
  state.account.setupStatus = data?.data
  return data?.data
}

export const updateSetupStatus = ({ state }, data) => {
  state.account.setupStatus = data
}

export const updateReviewTemplate = ({ state }, newTemplate) => {
  state.account.data.config.portal.feedback_loop_module.template = newTemplate
}

export const debugChange = ({ state }, f) => {
  f(state)
}

export const setGoogleLocationId = ({ state }, googleLocationId) => {
  state.account.data.shop.google_location_id = googleLocationId
}
