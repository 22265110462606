import React from "react"
import FacebookLogin from "./FacebookLogin"
import FacebookDisconnect from "./FacebookDisconnect"
import { SettingsFactory } from "../components/Settings/SettingsFactory"
import MessengerConnected from "./MessengerConnected"
import SectionHeader from "../components/SectionHeader"

const MessengerSettings = ({ pages, onReload, hidden }) => {
  if (hidden) return null

  const fields = [
    {
      label: "Status",
      as: "component",
      component: <MessengerConnected pages={pages} />,
    },
    {
      label: "Setup",
      body: "Your Facebook account is connected and ready to start using. But you can reconfigure your settings.",
      as: "component",
      component: (
        <div>
          <FacebookLogin onLogin={onReload} />
        </div>
      ),
    },
    {
      label: "Disconnect",
      body: "Revoke access to your Facebook Account. Please note you will not receive notifications or be able to reply to messages within Ikeono.",
      as: "component",
      component: <FacebookDisconnect onDisconnect={onReload} />,
    },
  ]

  return (
    <>
      <SectionHeader className="mb-3">Messenger Settings</SectionHeader>
      <SettingsFactory fields={fields} />
    </>
  )
}

export default MessengerSettings
