import React from "react"
import Modal, { ModalVariant } from "../../components/Modal"
import FeatureQuickGlance from "../../components/FeatureQuickGlance"
import { ReviewsAppIcon } from "../../IntegrationAppIcon"

export const ReviewsFeatureUpgradeModalId = "reviews-feature-upgrade-modal"

const ReviewsFeatureUpgradeModal = () => {
  return (
    <Modal
      title="Upgrade today"
      id={ReviewsFeatureUpgradeModalId}
      variant={ModalVariant.lg}>
      <FeatureQuickGlance
        className="mt-4 mb-5"
        appIcon={<ReviewsAppIcon />}
        title="Reviews"
        body="Asking for a review at checkout is not your team’s favorite thing. Keep those interactions organic and friendly, then follow up with a quick text asking about their experience"
        imgSrc="/reviews-example-1.png"
        path="/inbox/reviews"
        learnMorePath="https://www.ikeono.com/reviews"
      />
    </Modal>
  )
}

export default ReviewsFeatureUpgradeModal
