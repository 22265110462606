import React from "react";
import EinField from "./EinField";

export const accountNameField = {
  label: "Legal Company Name",
  body: " This is the company name that you use with the IRS when filing taxes. For example, My Company LLC. And then you can set your doing business name as RideAway Bikes.",
  props: {
    type: "text",
    name: "legal_name",
  },
};

export const einField = {
  label: "EIN",
  body: (
    <div>
      Your EIN is used to validate your business for texting. Why is this
      needed?{" "}
      <a
        href="https://www.ikeono.com/blog/a2p-10dlc"
        target="_blank"
        rel="noreferrer"
      >
        Read more.
      </a>
    </div>
  ),
  as: "component",
  component: <EinField />,
};
