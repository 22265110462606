import React, { useRef } from "react"
import OvermindDataTable from "../components/DataTable/OvermindDataTable"
import OffCanvas, {
  hideOffcanvas,
  toggleOffcanvas,
} from "../components/OffCanvas"
import { useActions, useState } from "../lib/overmind"
import Button, { ButtonVarint } from "../components/Button"
import TemplateForm from "./TemplateForm"
import OffcanvasFooter from "../components/OffCanvas/OffcanvasFooter"
import { getShopExternalId } from "../lib/auth"
import SectionHeader from "../components/SectionHeader"

const createOffcanvasId = "createTemplatesOffcanvas"
const editOffcanvasId = "editTemplatesOffcanvas"

const Title = () => (
  <div className="d-flex align-items-center mb-3">
    <SectionHeader className="mb-0">Message Templates</SectionHeader>
    <Button
      className="ms-auto"
      type="button"
      onClick={() => toggleOffcanvas(createOffcanvasId)}>
      Create New Templates
    </Button>
  </div>
)

const TemplatesSettings = ({ shopId }) => {
  const actions = useActions().templates
  const state = useState().templates
  const cancelButtonRef = useRef()
  const createCancelButtonRef = useRef()

  return (
    <>
      <Title />
      <div className="card">
        <OvermindDataTable
          overmindPropertyKey="templates"
          additionalQueryParams={{
            shopId: getShopExternalId(),
          }}
          rowClickHandler={(rowData) => {
            actions.setSelectedEditRecordId(rowData?.id)
            toggleOffcanvas(editOffcanvasId)
          }}
        />
        <OffCanvas datatarget={createOffcanvasId} title="Create Template">
          <TemplateForm
            template={{ key: "", value: "" }}
            onSuccess={() => {
              createCancelButtonRef.current?.click()
            }}
          />
          <OffcanvasFooter>
            <Button
              ref={createCancelButtonRef}
              variant={ButtonVarint.secondaryOutline}
              data-bs-dismiss="offcanvas">
              Cancel
            </Button>
          </OffcanvasFooter>
        </OffCanvas>
        <OffCanvas datatarget={editOffcanvasId} title="Edit Template">
          <TemplateForm
            template={state.selectedEditRecord}
            onSuccess={() => {
              cancelButtonRef.current?.click()
            }}
          />
          <OffcanvasFooter>
            <Button
              ref={cancelButtonRef}
              variant={ButtonVarint.secondaryOutline}
              data-bs-dismiss="offcanvas">
              Cancel
            </Button>
            <Button
              className="ms-auto"
              variant={ButtonVarint.dangerOutline}
              onClick={() => {
                if (!confirm("Are you sure?")) return
                actions.deleteSelectedEditRecord()
                cancelButtonRef.current?.click() // Work around. For some reason toggling the canvas does not work
                hideOffcanvas(editOffcanvasId)
              }}>
              Delete
            </Button>
          </OffcanvasFooter>
        </OffCanvas>
      </div>
    </>
  )
}

export default TemplatesSettings
