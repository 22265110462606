import React, { useEffect, useState } from "react"
import * as overmind from "../lib/overmind"

const InboxSearchInput = () => {
  const [query, setQuery] = useState(null)
  const actions = overmind.useActions().activeInbox
  const state = overmind.useState().activeInbox
  const [currentInbox, setCurrentInbox] = useState(state.filter?.inboxName)

  useEffect(() => {
    // Reset the query on inbox change
    if (currentInbox !== state.filter.inboxName) {
      setQuery(null)
      setCurrentInbox(state.filter?.inboxName)
    }
  }, [state.filter])

  useEffect(() => {
    if (query === null) return
    const delayDebounceFn = setTimeout(() => {
      actions.updateFilter({ query })
    }, 850)

    return () => clearTimeout(delayDebounceFn)
  }, [query])

  return (
    <input
      value={query || ""}
      onChange={(e) => {
        setQuery(e.target.value)
      }}
      onKeyDown={(e) => {
        if (e.key !== "Enter") return
        actions.updateFilter({ query })
      }}
      maxLength={50}
      className="form-control form-control-sm"
      placeholder="Search by phone number or name"
      data-lpignore="true"
      autoComplete="off"
    />
  )
}

export default InboxSearchInput
