import React from "react"

const ReviewsHeader = () => {
  return (
    <div className="d-flex mt-2 mb-2">
      <h5 className="fw-semibold  me-auto ">Reviews</h5>
      {/* <button type="button" className="btn btn-primary align-self-start">
        Send Review Request
      </button> */}
    </div>
  )
}

export default ReviewsHeader
