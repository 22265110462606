import { get } from "../../api"

export const getLocations = async ({
  query,
  showOnlyUnread,
  includeUnreadCounts,
}) => {
  const response = await get("/user/shops", {
    q: query,
    include_unread_counts: includeUnreadCounts || false,
    show_only_unread: showOnlyUnread,
  })
  const { data } = response
  return data
}
