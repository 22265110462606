/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useRef } from "react";

import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";

const bootstrap = require("bootstrap");

const EmojiPickerDropDown = ({ onEmojiSelect }) => {
  const ref = useRef();

  const onSelect = (emojiData) => {
    onEmojiSelect(emojiData.native);
    if (!ref.current) return;
    // eslint-disable-next-line no-undef
    const dropdown = new bootstrap.Dropdown(ref.current);
    dropdown.toggle();
  };
  return (
    <div className="dropup">
      <i
        ref={ref}
        className="bi-emoji-smile"
        role="button"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        data-bs-auto-close="outside"
      />
      <ul className="dropdown-menu" style={{ border: "none" }}>
        <Picker data={data} onEmojiSelect={onSelect} theme="light" />
      </ul>
    </div>
  );
};

export default EmojiPickerDropDown;
