import React from "react"

const Badge = ({ variant, children }) => {
  return (
    <span
      className={`d-inline-flex fw-semibold px-2 text-${variant}-emphasis bg-${variant}-subtle border border-${variant}-subtle rounded-2 align-self-start justify-content-center align-items-center`}>
      {children}
    </span>
  )
}

export default Badge
