/* eslint-disable no-shadow */
import React, { useEffect, useState } from "react"
import { Link, Route, Routes, useLocation, useNavigate } from "react-router-dom"
import { useModalDisplayManager } from "../components/ModalHook"
import NavigateBackLink from "../components/NavigateBackLink"
import OffCanvas, { OffcanvasManager } from "../components/OffCanvas"
import ContactDetail from "../Contact/ContactDetail"
import ContactForm from "../Contact/ContactForm"
import * as overmind from "../lib/overmind"

const inboxDetailOffCanvasId = "inbox-detail-offcanvas"

const getThreadRootPath = () => {
  return window.location.pathname
    .replace("/edit", "")
    .replace("/create", "")
    .replace("/detail", "")
}

const InboxDetail = ({ threadId }) => {
  const [contact, setContact] = useState()
  const state = overmind.useState().activeThread
  const actions = overmind.useActions()

  const navigate = useNavigate()
  useModalDisplayManager(
    inboxDetailOffCanvasId,
    ["/inbox/.*/{uid}/detail", "/inbox/.*/{uid}/detail/create"],
    true
  )

  useEffect(() => {
    setContact(state.contact)
  }, [state.contact])

  const updateContact = (contact) => {
    actions.activeThread.updateContact(contact)
    actions.activeInbox.updateContact({ threadId, contact })
  }

  return (
    <OffCanvas
      datatarget={inboxDetailOffCanvasId}
      onHide={() => {
        navigate(getThreadRootPath())
      }}>
      <Routes>
        <Route
          path="detail"
          element={
            <ContactDetail
              firstName={contact?.first_name}
              lastName={contact?.last_name}
              phoneNumber={contact?.mobile_phone_number}
              email={contact?.email}
              vendorId={contact?.vendor_id}
              vendorSource={contact?.vendor_source}
              vendorUrl={contact?.vendor_url}
              dateCreated={contact?.date_created}
            />
          }
        />
        <Route
          path="detail/edit"
          element={
            <>
              <NavigateBackLink style={{ position: "absolute", top: "5px" }} />
              <ContactForm
                hideDelete
                contact={contact}
                onSuccess={updateContact}
              />
            </>
          }
        />
        <Route
          path="detail/create"
          element={
            <ContactForm
              contact={contact}
              onSuccess={(contact) => {
                updateContact(contact)
                navigate(getThreadRootPath())
              }}
            />
          }
        />
      </Routes>
    </OffCanvas>
  )
}

export default InboxDetail
