/* eslint-disable */
import axios from "axios"
import { AppConfig } from "../services/config"
import { captureException } from "../services/sentry"
import { getApiToken, getShopExternalId, getShopId } from "./auth"
import { mixpanel } from "../services/mixpanel"
import React, { useState } from "react"
import { getAccountId } from "./overmind/account/util"

const axiosShared = axios.create({
  baseURL: `${AppConfig.ApiHost}/v2`,
  timeout: 25000,
})
axiosShared.defaults.headers.common["X-Ikeono-Client"] = "webapp-v2"

reloadAxios()

export function reloadAxios() {
  const token = getApiToken()
  const value = token ? `Bearer ${getApiToken()}` : null
  axiosShared.defaults.headers.common.Authorization = value
}

const authorizationSet = () => {
  return !!axiosShared.defaults.headers.common.Authorization
}

const formatUrl = (url) => {
  return url
    .replace("{exid}", getShopExternalId())
    .replace("{account_id}", getAccountId())
}

const trackApiError = (error) => {
  try {
    const eventProps = {
      status: error.response.status,
      method: error.response.config.method,
      url: error.response.config.url,
      "request data": error.response.config.data,
      "status text": error.response.statusText,
      "response data": error.response.data,
    }
    mixpanel.track("API Error", eventProps)
  } catch (error) {
    console.log(error)
  }
}

export const get = async (url, params, requestConfig) => {
  const formattedUrl = formatUrl(url)

  console.log(`🚀 GET: ${formattedUrl}`, { params, ...requestConfig })
  try {
    if (!authorizationSet() && getApiToken()) {
      // The chat_component extension frame may be stale
      // Try refreshing the token, if it hasn't been set
      reloadAxios()
    }

    return await axiosShared.get(formattedUrl, {
      params,
      ...requestConfig,
    })
  } catch (error) {
    captureException(error)
    trackApiError(error)
    throw error
  }
}

export const post = async (url, data, config) => {
  const formattedUrl = formatUrl(url)
  console.log(`🚀 POST: ${formattedUrl}`, data)

  try {
    return await axiosShared.post(formattedUrl, data, config)
  } catch (error) {
    captureException(error)
    trackApiError(error)
    throw error
  }
}

export const usePost = async () => {
  const [isLoading, setIsLoading] = useState()
  const [error, setError] = useState(null)
  const [resp, setResp] = useState(null)

  const execute = async (url, data, config) => {
    setIsLoading(true)
    setError(null)
    setResp(null)
    try {
      const resp = await post(url, data, config)
      setResp(resp)
      return resp
    } catch (e) {
      setError(e)
    }
    setIsLoading(false)
  }

  return [execute, isLoading, error, resp]
}

export const useGet = () => {
  const [isLoading, setIsLoading] = useState()
  const [error, setError] = useState(null)
  const [resp, setResp] = useState(null)

  const execute = async (url, params, requestConfig) => {
    setIsLoading(true)
    setError(null)
    setResp(null)
    try {
      const resp = await get(url, params, requestConfig)
      setResp(resp)
      setIsLoading(false)
      return resp
    } catch (e) {
      setError(e)
    }
    setIsLoading(false)
  }

  return [execute, isLoading, error, resp]
}

export const put = async (url, data, config) => {
  const formattedUrl = formatUrl(url)
  console.log(`🚀 PUT: ${formattedUrl}`, data)

  try {
    return await axiosShared.put(formattedUrl, data, config)
  } catch (error) {
    captureException(error)
    trackApiError(error)
    throw error
  }
}

export const delete_ = async (url, data) => {
  const formattedUrl = formatUrl(url)
  console.log(`🚀 DELETE: ${formattedUrl}`, data)

  try {
    return await axiosShared.delete(formattedUrl, { data })
  } catch (error) {
    captureException(error)
    trackApiError(error)
    throw error
  }
}
