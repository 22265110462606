import React from "react";

const IneligibleNextSteps = ({ hidden }) => {
  return (
    <div
      hidden={hidden}
      className="mt-lg-4 mt-0 p-3 text-warning-emphasis bg-warning-subtle border border-warning-subtle rounded-3"
    >
      <div>
        <b>We will conact you within 24 hours.</b>
      </div>
      <div>
        A member of the Ikeono team will reach out to you to provide you with a
        local phone number.
      </div>
    </div>
  );
};

export default IneligibleNextSteps;
