import * as Sentry from "@sentry/react"
import { overmindInstance } from "../lib/overmind"
import { isLoggedIn } from "../lib/auth"
import { Env, inIframe, inPortalIframe } from "../lib/environment"

export const initSentry = () => {
  Sentry.init({
    dsn: "https://cc3949b39f8d4440b03b13fa7c040890@o214571.ingest.sentry.io/4504164962467840",
    environment: Env,
    tracesSampleRate: 1.0,
    ignoreErrors: [
      "play",
      "interact with the document first",
      "NotAllowedError",
    ],
  })
}

export const setSentryEnv = () => {
  try {
    const { account } = overmindInstance.state

    if (!account) {
      console.log("Warning unable to set Sentry props")
      return
    }

    Sentry.setUser({ email: account.userEmail })
    Sentry.setTag("is_logged_in", isLoggedIn())
    Sentry.setTag("shop_id", account.shopExternalId)
    Sentry.setTag("account_id", account.accountId)
    Sentry.setTag("environment", Env)
    Sentry.setTag("incomplete_profile", account.incompleteProfile)
    Sentry.setTag("is_trial_expired", account.isTrialExpired)
    Sentry.setTag("in_iframe", inIframe())
    Sentry.setTag("in_portal_iframe", inPortalIframe())
  } catch (e) {
    console.log(e)
  }
}

export const captureException = (exception, context) => {
  Sentry.captureException(exception, context)
}
