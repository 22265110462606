import React from "react"
import Modal, { ModalVariant } from "../../components/Modal"
import ReviewsSettings from "../Settings/ReviewsSettings"

export const ReviewSetupModalId = "reviews-setup-modal"

const ReviewSetupModal = () => {
  return (
    <Modal
      title="Configure your settings"
      id={ReviewSetupModalId}
      variant={ModalVariant.lg}>
      <ReviewsSettings />
    </Modal>
  )
}

export default ReviewSetupModal
