import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import OffCanvas from "../components/OffCanvas"
import { useGet } from "../lib/api"
import Loader from "../components/Loader"
import APIResponseError from "../components/APIResponseError"
import NpsDetail from "./NpsDetail"
import { useModalDisplayManager } from "../components/ModalHook"

const npsDetailOffCanvasId = "nps-detail-offcanvas"

const NpsDetailOffcanvas = () => {
  const params = useParams()
  const [get, isLoading, error, resp] = useGet()

  useEffect(() => {
    if (!params["*"]) return
    if (params["*"].includes("records")) return
    const id = params["*"].split("/")[1]
    if (!id) return
    get(`/shop/{exid}/nps/detail/${id}`)
  }, [params])

  useModalDisplayManager(npsDetailOffCanvasId, ["/nps/replies/{uid}"], true)

  return (
    <OffCanvas bodyClass="p-0" title="Detail" datatarget={npsDetailOffCanvasId}>
      <Loader hidden={!isLoading} />
      <APIResponseError response={error} />
      <NpsDetail data={resp?.data?.data} />
    </OffCanvas>
  )
}

export default NpsDetailOffcanvas
