import React from "react";

export const NoDepositWarning = ({
  value,
  onConfirmationChange,
  className,
  hidden,
}) => {
  if (hidden) return null;

  const update = (val) => {
    onConfirmationChange(val);
  };

  return (
    <div className={className}>
      <div className="form-check fs-16">
        <input
          checked={value}
          onChange={(e) => update(e.target.checked)}
          className="form-check-input border-black"
          type="checkbox"
          id="flexCheckDefault"
        />
        <label
          onClick={() => update(!value)}
          className="form-check-label"
          htmlFor="flexCheckDefault"
        >
          I understand a deposit will not be added to this contact because it
          was not created within Lightspeed Retail.
        </label>
      </div>
    </div>
  );
};

export default NoDepositWarning;
