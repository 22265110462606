import React from "react"
import { isDataUrl } from "../lib/contentType"

const PdfAttachmentCard = ({ url, moveToEnd, dateSent }) => {
  if (!url) return null

  const treatment = moveToEnd ? "text-white" : "text-black"

  // If the url is a base64 data type then we need to specify a filename
  // so the browser downloads it properly
  const fileName = isDataUrl(url)
    ? `Ikeono PDF Attachment ${dateSent || ""}.pdf`
    : null

  return (
    <div
      className={`d-flex flex-row py-2 ${
        moveToEnd ? "justify-content-end" : ""
      }`}>
      <div
        className={`sms-card ${
          moveToEnd
            ? "sms-card sms-card--from-shop"
            : "sms-card sms-card--from-shop"
        }`}
        style={{ width: "220px" }}>
        <div className="card-body d-flex gap-2 align-items-center">
          <i
            className="bi-file-earmark-pdf-fill"
            style={{ fontSize: "30px" }}
          />
          <div className="d-flex flex-column">
            <b className="fs-15" style={{ lineHeight: "15px" }}>
              PDF Attachment
            </b>
            <a
              download={fileName}
              className={`fs-14 ${treatment}`}
              target="_blank"
              href={url}
              rel="noreferrer">
              Download
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PdfAttachmentCard
