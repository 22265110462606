import React from "react";
import { useState } from "../lib/overmind";
import LightspeedDisconnectedWarning from "../components/Lightspeed/LightspeedDisconnectedWarning";
import VendDisconnectedWarningToast from "../components/Vend/VendDisconnectedWarningToast";

const PointOfSaleDisconnected = () => {
  let component = null;
  const state = useState();

  if (
    state.integrations.integrationsIndex?.lightspeed_r_series.status ===
    "warning"
  ) {
    component = <LightspeedDisconnectedWarning />;
  } else if (
    state.integrations.integrationsIndex?.lightspeed_x_series.status ===
    "warning"
  ) {
    component = <VendDisconnectedWarningToast />;
  }

  return <div hidden={!component}>{component}</div>;
};

export default PointOfSaleDisconnected;
