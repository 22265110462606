import { useFormikContext } from "formik";
import React from "react";

const FilterDropDown = ({ hidden, title, options, name }) => {
  const { values, setFieldValue } = useFormikContext();

  if (hidden) return null;

  return (
    <div className="align-self-start mt-auto">
      <button
        className="btn btn-sm btn-outline-secondary dropdown-toggle"
        type="button"
        data-bs-toggle="dropdown"
      >
        {values[`${name}Title`] || title}
      </button>
      <ul className="dropdown-menu">
        <li
          onClick={() => {
            setFieldValue(name, null);
            setFieldValue(`${name}Title`, null);
          }}
        >
          <a className="dropdown-item" href="#">
            All
          </a>
        </li>
        {options.map((option) => {
          return (
            <li
              onClick={() => {
                setFieldValue(name, option.value);
                setFieldValue(`${name}Title`, option.title);
              }}
              key={option.value}
            >
              <a className="dropdown-item" href="#">
                {option.title}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default FilterDropDown;
