/* eslint-disable react/display-name */
import React, { forwardRef } from "react"

export const ButtonVarint = {
  primary: "btn-primary",
  primaryOutline: "btn-outline-primary",
  secondary: "btn-secondary",
  secondaryOutline: "btn-outline-secondary",
  dangerOutline: "btn-outline-danger",
}

export const ButtonSize = {
  small: "btn-sm",
  medium: "btn-md",
  large: "btn-lg",
}

const Button = forwardRef((props, ref) => {
  const { showLoader, children, variant, size, className } = props
  const buttonProps = { ...props }
  delete buttonProps.showLoader
  delete buttonProps.children
  delete buttonProps.variant
  delete buttonProps.size
  delete buttonProps.className
  return (
    <button
      ref={ref}
      type="submit"
      className={`text-nowrap btn ${variant || ButtonVarint.primary} ${
        size || ButtonSize.small
      } ${className}`}
      disabled={showLoader}
      {...buttonProps}>
      {showLoader && (
        <span
          className="spinner-border spinner-border-sm me-1"
          role="status"
          aria-hidden="true"
          style={{ height: "10px", width: "10px" }}
        />
      )}
      {children}
    </button>
  )
})

export default Button
