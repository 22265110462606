import React from "react";
import { formatCurrency } from "../../lib/currency";
import InboxAttachmentDeleteButton from "./InboxAttachmentDeleteButton";

const InboxInvoiceAttachment = ({ total, onDelete, hidden }) => {
  if (hidden) return null;
  return (
    <div
      className="d-flex flex-row  align-items-center gap-2 px-3 rounded-1 position-relative"
      style={{ backgroundColor: "var(--bs-gray-200", height: "60px" }}
    >
      <div
        className="d-flex align-items-center justify-content-center text-white rounded-1"
        style={{ backgroundColor: "#4dd4ac", height: "35px", width: "35px" }}
      >
        <i className="bi-currency-dollar" />
      </div>
      <div className="d-flex flex-column">
        <span>{formatCurrency(total)}</span>
        <span style={{ fontSize: "14px" }}>Payment link</span>
      </div>
      <InboxAttachmentDeleteButton onClick={onDelete} />
    </div>
  );
};

export default InboxInvoiceAttachment;
