import React from "react"
import "../style.css"
import ContinueButton from "../components/ContinueButton"
import { PosSystem, usePosConnectedEvent } from "../../lib/pos"
import LightspeedRSeriesIntegrationCard from "./LightspeedRSeriesIntegrationCard"
import { useState } from "../../lib/overmind"
import LightspeedXSeriesIntegrationCard from "./LightspeedXSeriesIntegrationCard"
import { usePageViewHook } from "../../services/mixpanel"
import AscendIntegrationCard from "./AscendIntegrationCard"

const IntegrationSetup = ({ onComplete }) => {
  const state = useState().account
  usePosConnectedEvent()
  usePageViewHook("integration setup")

  return (
    <div>
      <div className="d-flex gap-lg-4 gap-3 flex-column">
        <LightspeedRSeriesIntegrationCard
          hidden={
            state.posSystem !== PosSystem.LightspeedRSeries &&
            state.posSystem !== PosSystem.Standalone
          }
        />
        <LightspeedXSeriesIntegrationCard
          hidden={
            state.posSystem !== PosSystem.LightspeedXSeries &&
            state.posSystem !== PosSystem.Standalone
          }
        />
        <AscendIntegrationCard
          hidden={
            state.posSystem !== PosSystem.Ascend &&
            state.posSystem !== PosSystem.Standalone
          }
        />
        <ContinueButton
          hidden={state.posSystem !== PosSystem.Standalone}
          onClick={() => {
            onComplete({ action: "continue without setting up" })
          }}>
          Continue without setting up
        </ContinueButton>
        <ContinueButton
          className="bg-primary text-white"
          onClick={() => onComplete({ action: "continue setup" })}
          hidden={state.posSystem === PosSystem.Standalone}>
          <i className="bi-check-circle-fill me-2" />
          Success! Continue
        </ContinueButton>
      </div>
    </div>
  )
}

export default IntegrationSetup
