import React from "react";

const NotificationSubscribeSuccess = ({ hidden }) => {
  if (hidden) return null;
  return (
    <div className="py-3">
      <span>
        <i className="bi-check-circle text-success me-2" />
        Your notifications are enabled.
      </span>
    </div>
  );
};

export default NotificationSubscribeSuccess;
