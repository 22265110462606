import React from "react"

const NpsCustomerDetail = ({ customerName, customerPhone }) => {
  return (
    <div>
      <div className="fw-semibold">Customer Detail</div>
      <div hidden={customerName || customerPhone}>
        <i className="text-secondary">There is no contact information.</i>
      </div>
      <div className="text-capitalize">{customerName}</div>
      <div>{customerPhone}</div>
    </div>
  )
}

export default NpsCustomerDetail
