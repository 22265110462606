import React from "react"
import { Link } from "react-router-dom"
import InstalledBadge from "../components/InstalledBadge"

const MessengerConnected = ({ pages }) => {
  return (
    <div>
      <div className="d-inline-block mb-2" style={{ maxWidth: "50ch" }}>
        <div className="mb-2">
          <InstalledBadge />
        </div>
        <span className="fs-15">
          Your Facebook account is connected and ready to start using.
          Conversations will show up in your inbox.{" "}
          <Link to="/inbox/all">Open Inbox</Link>
        </span>
      </div>
      <div>
        <b className="d-block">Connected Pages</b>
        <i
          hidden={!!pages?.length}
          style={{ display: "inline-block", maxWidth: "40ch" }}>
          No pages connected. You need to reconnect Facebook and select pages.
        </i>
        <ul>
          {pages?.map((pageName) => (
            <li key={pageName}>{pageName}</li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default MessengerConnected
