import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="d-flex flex-column w-100 h-100 mt-5 align-items-center">
      <i className="bi-robot text-secondary" style={{ fontSize: "70px" }} />
      <h4>Page not found</h4>
      <Link to="/inbox/all">Go home</Link>
    </div>
  );
};

export default NotFound;
