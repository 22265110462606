import { Field } from "formik"
import React from "react"

const Select = ({ values, ...props }) => {
  return (
    <Field className="form-select w-auto" as="select" {...props}>
      <option />
      {values?.map(({ id, name }) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))}
    </Field>
  )
}

export default Select
