import React, { useEffect, useRef, useState } from "react";
import StandardFormGroup from "../components/Form/StandardFormGroup";

const LocationFilter = ({ disabled, hideUnreadCount, onUpdateFilter }) => {
  const [query, setQuery] = useState(null);
  const [showOnlyUnread, setShowOnlyUnread] = useState(null);
  const inputRef = useRef();

  useEffect(() => {
    if (query === null) return;
    const delayDebounceFn = setTimeout(() => {
      if (query === null) return;
      onUpdateFilter({ query, showOnlyUnread });
    }, 850);

    return () => clearTimeout(delayDebounceFn);
  }, [query]);

  useEffect(() => {
    if (showOnlyUnread === null) return; // Ignore unless user actually clicked it
    onUpdateFilter({ query, showOnlyUnread });
  }, [showOnlyUnread]);

  useEffect(() => {
    inputRef.current.focus();
  });

  return (
    <div className="px-2  mb-3 pt-2 position-sticky top-0 bg-white">
      <StandardFormGroup label="Filter on shop name">
        <input
          onKeyDown={(e) => {
            if (e.key !== "Enter") return;
            onUpdateFilter({ query, showOnlyUnread });
          }}
          ref={inputRef}
          disabled={disabled}
          className="form-control form-control-sm"
          placeholder="Filter location"
          onChange={(e) => {
            setQuery(e.target.value);
          }}
        />
      </StandardFormGroup>
      <div className="form-check" hidden={hideUnreadCount}>
        <input
          className="form-check-input"
          type="checkbox"
          value=""
          id="flexCheckDefault"
          onChange={(e) => setShowOnlyUnread(e.target.checked)}
        />
        <label
          className="form-check-label"
          htmlFor="flexCheckDefault"
          onClick={(e) => e.stopPropagation()}
        >
          Shops with unread messages
        </label>
      </div>
    </div>
  );
};

export default LocationFilter;
