import React, { useEffect } from "react";
import { useGet } from "../lib/api";
import Loader from "../components/Loader";
import APIResponseError from "../components/APIResponseError";
import { AppConfig } from "../services/config";
import ManageSubscriptionInfo from "./ManageSubscriptionInfo";
import { useState } from "../lib/overmind";
import { getPricingTableFor } from "./PricingTableManager";

const StripeBillingPortal = ({ overridePosSystem }) => {
  const [get, isLoading, error, resp] = useGet();
  const [data, setData] = React.useState(false);
  const { account } = useState();

  useEffect(() => {
    get("/account/{account_id}/billing/portal");
  }, []);

  useEffect(() => {
    if (!resp) return;
    setData(resp.data);
  }, resp);

  if (error) return <APIResponseError response={error} />;
  if (isLoading || !resp) return <Loader />;

  if (data.active_plan) {
    return <ManageSubscriptionInfo billingPortalUrl={data.url} />;
  }

  return (
    <stripe-pricing-table
      pricing-table-id={getPricingTableFor(
        overridePosSystem || account.posSystem
      )}
      publishable-key={AppConfig.StripePublishableKey}
      client-reference-id={account.data.account.exid}
      customer-email={`${account.userEmail}__${account.country}`}
    />
  );
};

export default StripeBillingPortal;
