import React from "react"
import StripeBillingPortal from "./StripeBillingPortal"
import { logout } from "../lib/auth"

const TrialExpired = () => {
  return (
    <div className="vh-100 vw-100">
      <div className="">
        <div className="alert alert-warning text-center d-flex justify-content-center flex-column align-items-center">
          <h4 className="alert-heading">Trial Expired for the Premium tier!</h4>
          <p style={{ maxWidth: "75ch" }}>
            You have been experiencing the full power of the Ikeono Premium
            tier. Please subscribe to continue leveraging text messaging,
            payments, and reviews.
          </p>
        </div>
        <button
          type="button"
          className="btn btn-link position-absolute text-secondary"
          style={{ top: "10px", right: "20px" }}
          onClick={() => {
            logout()
            window.location.reload()
          }}>
          Logout
        </button>
      </div>
      <div>
        <StripeBillingPortal />
      </div>
    </div>
  )
}

export default TrialExpired
