/* eslint-disable no-useless-escape */

export const containsUrl = (v) => {
  const expression =
    /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
  const regex = new RegExp(expression)
  return v?.match(regex)
}

export const containsGoogleUrl = (v) =>
  v?.includes("google.com") || v?.includes("g.page")

export const containsFacebookUrl = (v) => v?.includes("facebook.com")

export const prependHttps = (v) => {
  if (!v) return
  if (!v?.includes("http://") && !v?.includes("https://")) {
    return `https://${v}`
  }
  return v
}
