import moment from "moment"
import { isMobileDevice } from "../../device"
import { history } from "../../history"
import { getFirstNameAndLastName } from "../../stringUtil"

export * from "./multiSelectActions"
const _ = require("lodash")

const limit = 50

export const getInbox = async ({ state, actions, effects }) => {
  if (state.activeInbox.isLoading || !state.activeInbox.hasNextPage) return

  state.activeInbox.isLoading = true
  state.activeInbox.apiError = null

  const { filter, pageOffset } = state.activeInbox
  try {
    const data = await effects.activeInbox.getInbox({
      inboxName: filter.inboxName,
      offset: pageOffset,
      limit,
      includeThreadId: filter.includeThreadId,
      isClosed: filter.showClosed,
      isUnopened: filter.showUnopened,
      query: filter.query,
    })
    const inboxData = data?.data

    // Index threads
    for (const thread of inboxData.threads) {
      state.activeInbox.indexedThreads[thread.id] = thread
    }
    actions.activeInbox.refreshThreads()

    // Update filter
    state.activeInbox.hasNextPage =
      inboxData?.threads?.length > 0 && inboxData?.threads?.length >= limit
    state.activeInbox.pageOffset += limit
    state.activeInbox.inboxId = inboxData.inbox_id
  } catch (e) {
    console.log(e)
    state.activeInbox.apiError = e
  }

  state.activeInbox.isLoading = false
}

export const refreshThreads = ({ state }) => {
  const threads = _.values(state.activeInbox?.indexedThreads)
  threads.sort((a, b) => {
    const aDate = moment(a.date_last_interaction)
    const bDate = moment(b.date_last_interaction)
    if (aDate < bDate) return 1
    if (aDate > bDate) return -1
    return 0
  })

  state.activeInbox.threads = threads
}

export const updateFilter = async (overmind, filter) => {
  const state = overmind.state.activeInbox
  const actions = overmind.actions.activeInbox

  if (filter.inboxName && filter.inboxName !== state.filter.inboxName) {
    filter.query = ""
  }

  const computedFilter = { ...state.filter, ...filter }
  if (JSON.stringify(computedFilter) === JSON.stringify(state.filter)) return

  state.filter = computedFilter
  state.threads = []
  state.indexedThreads = {}
  state.pageOffset = 0
  state.hasNextPage = true

  await actions.getInbox()
}

export const setConversationUnreadCount = ({ state }, unreadCount) => {
  state.activeInbox.unreadCount = unreadCount
}

export const updateLocally = ({ actions, state }, updatedThread) => {
  if (!state.activeInbox.indexedThreads) {
    state.activeInbox.indexedThreads = {}
  }

  const thread = state.activeInbox.indexedThreads[updatedThread.id]

  if (thread) {
    thread.snippet = updatedThread.snippet
    thread.display_name = updatedThread.display_name
    thread.has_unread_messages = updatedThread.has_unread_messages
    thread.inboxes = updatedThread.inboxes
    thread.status_id = updatedThread.status_id
    thread.date_last_interaction = updatedThread.date_last_interaction
  } else {
    // Check to see if this thread belongs in the current open/closed tab of the inbox. If the thread is archived, and we're
    // only showing open threads then don't add it.
    if (updatedThread.archived !== state.activeInbox.filter.showClosed) return

    // Check to see if the user has filtered on an inbox and the thread is apart of the inbox
    const activeInboxName = state.activeInbox.filter?.inboxName
    if (activeInboxName !== "all") {
      const isInCurrentInbox = updatedThread.inboxes?.some(
        ({ slug }) => slug === activeInboxName
      )
      if (!isInCurrentInbox) return
    }

    state.activeInbox.indexedThreads[updatedThread.id] = updatedThread
  }

  actions.activeThread.updateThreadLocally(updatedThread)
  actions.activeInbox.refreshThreads()
}

export const deleteThread = ({ state, actions, effects }, threadId) => {
  const index = state.activeInbox.threads.findIndex((c) => c.id === threadId)
  if (index < 0) return

  delete state.activeInbox.indexedThreads[threadId]
  effects.activeInbox.deleteThread(threadId)
  actions.activeInbox.refreshThreads()

  const { threads } = state.activeInbox
  let nextIndex = Math.min(index - 1, threads.length)
  if (nextIndex < 0) {
    if (threads.length > 0) {
      nextIndex = 0
    } else {
      actions.activeInbox.clearCurrentThread()
      return
    }
  }

  actions.activeInbox.navigateTo(threads[nextIndex].id)
}

export const archive = ({ state, actions, effects }, threadId) => {
  const index = state.activeInbox.threads.findIndex((c) => c.id === threadId)
  if (index < 0) return

  effects.activeInbox.updateThread({ threadId, archived: true })

  delete state.activeInbox.indexedThreads[threadId]
  actions.activeInbox.refreshThreads()

  const { threads } = state.activeInbox
  let nextIndex = Math.min(index - 1, threads.length)
  if (nextIndex < 0) {
    if (threads.length > 0) {
      nextIndex = 0
    } else {
      actions.activeInbox.navigateToFirstThread()
      return
    }
  }

  actions.activeInbox.navigateTo(threads[nextIndex].id)
}

export const unarchive = async ({ state, actions, effects }, threadId) => {
  await effects.activeInbox.updateThread({ threadId, archived: false })
  actions.activeInbox.updateFilter({ showClosed: false })
  state.activeThread.detail.archived = false
}

export const navigateTo = ({ state, actions }, threadId) => {
  const inboxName = state.activeInbox.filter?.inboxName || "all"
  history.push(`/inbox/${inboxName}/${threadId}`)
  actions.activeThread.get(threadId)
}

export const navigateToFirstThread = ({ state, actions }) => {
  const { threads } = state.activeInbox
  if (!threads?.length) {
    const inboxName = state.activeInbox.filter?.inboxName || "all"

    actions.activeInbox.clearCurrentThread()
    history.push(`/inbox/${inboxName}`, { replace: true })
    return
  }
  actions.activeInbox.navigateTo(threads[0].id)
}

export const createThread = async (
  overmind,
  {
    name,
    vendorId,
    vendorSource,
    receiverId,
    inboxId,
    navigateToThread,
    hidden,
    email,
  }
) => {
  const effects = overmind.effects.activeInbox
  const actions = overmind.actions.activeInbox

  const { firstName, lastName } = getFirstNameAndLastName(name)

  const data = await effects.createThread({
    firstName,
    lastName: lastName || null,
    vendorId,
    vendorSource,
    receiverId,
    inboxId,
    hidden,
    email,
  })
  actions.addThread(data.thread)

  if (navigateToThread) {
    actions.navigateToFirstThread()
  }

  // if (data.thread_did_change_inboxes) {
  //   const body = `<p>This conversation moved from <b>${data.previous_inbox?.title}</b> to <b>${data.current_inbox?.title}</b></p>`
  //   overmind.actions.toast.show({
  //     title: 'Head\'s up',
  //     body
  //   })
  // }

  return data.thread
}

export const addThread = ({ state, actions }, thread) => {
  if (!thread) return
  if (!state.activeInbox.indexedThreads) {
    state.activeInbox.indexedThreads = {}
  }

  state.activeInbox.indexedThreads[thread.id] = thread
  actions.activeInbox.refreshThreads()
}

export const removeThreadLocally = ({ state, actions }, threadId) => {
  if (!state.activeInbox.indexedThreads) return
  delete state.activeInbox.indexedThreads[threadId]
  actions.activeInbox.refreshThreads()
}

export const clearCurrentThread = ({ state }) => {
  state.activeThread.messages = []
  state.activeThread.conversationId = null
  state.activeThread.detail = null
  state.activeInbox.filter.includeThreadId = null
}

export const markThreadAsRead = ({ state, effects }, threadId) => {
  const thread = state.activeInbox.indexedThreads[threadId]
  if (!thread || !thread.has_unread_messages) return

  thread.has_unread_messages = false

  if (state.activeThread.conversationId === thread.id) {
    state.activeThread.detail.hasUnreadMessages = false
  }

  effects.activeThread.updateThreadUnreadStatus({
    threadId,
    hasUnreadMessages: false,
  })
}

export const getActiveInboxDetail = ({ state }) =>
  state.inboxes.inboxes?.find(
    ({ slug }) => slug === state.activeInbox.filter.inboxName
  )

export const updateContact = ({ state }, { threadId, contact }) => {
  const thread = state.activeInbox?.indexedThreads[threadId]
  if (!thread) return
  thread.contact = contact
}
