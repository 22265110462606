import React from "react";
import Loader from "../../../components/Loader";

const WaitPatientlyCard = ({ hidden }) => {
  return (
    <div
      hidden={hidden}
      className="mt-lg-4 mt-3 p-3 text-success-emphasis bg-success-subtle border border-success-subtle rounded-3"
    >
      <div>
        <b>You are ready to go!</b>
      </div>
      <div>
        We will send you an email shortly letting you know your account is
        ready!
      </div>
      <div className="mt-2">
        <Loader
          inline
          className="text-secondary me-1"
          spinnerStyle={{ "--bs-spinner-animation-speed": "2.5s" }}
        />
        We are hosting your phone number
      </div>
    </div>
  );
};

export default WaitPatientlyCard;
