import React from "react";

const NotificationDeniedWarning = ({ hidden }) => {
  if (hidden) return null;

  return (
    <div className="">
      <i className="bi-x-circle text-danger me-2" />
      Permission to send notifications was denied.
    </div>
  );
};

export default NotificationDeniedWarning;
