import React from "react";

const ListItem = ({ children }) => (
  <div className="d-flex">
    <i
      className="bi-check-circle-fill me-2"
      style={{ color: "rgb(105, 217, 149)" }}
    />
    {children}
  </div>
);

const AccountSetupFeatureList = ({
  title,
  children,
  style,
  className = "justify-content-center mt-2 mt-md-5",
}) => {
  return (
    <div className={`d-flex ${className}`} style={style}>
      <div>
        <h4 hidden={!title} className="mb-3">
          {title}
        </h4>
        <div className="d-flex flex-column gap-3">
          {children.map((feature, idx) => (
            <ListItem key={idx}>{feature}</ListItem>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AccountSetupFeatureList;
