// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.integration-card:hover {
  box-shadow: var(--bs-box-shadow);
}

@media (min-width: 767.98px) {
  .integration-card {
    min-width: 330px !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Signup/style.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;AAClC;;AAEA;EACE;IACE,2BAA2B;EAC7B;AACF","sourcesContent":[".integration-card:hover {\n  box-shadow: var(--bs-box-shadow);\n}\n\n@media (min-width: 767.98px) {\n  .integration-card {\n    min-width: 330px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
