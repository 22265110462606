import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGet } from "../lib/api";
import APIResponseError from "../components/APIResponseError";
import Loader from "../components/Loader";
import ReportsNav from "./ReportsNav";
import ReportRouter from "./ReportRouter";

const index = () => {
  const [get, isLoading, error, resp] = useGet();
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    get("/account/{account_id}/report/list");
  }, []);

  useEffect(() => {
    if (!resp?.data || params["*"] !== "") return;
    navigate(`./${resp.data.reports[0].report_id}`);
  }, [params, resp]);

  if (error) return <APIResponseError response={error} />;
  if (isLoading || !resp) return <Loader />;

  return (
    <div className="d-flex">
      <ReportsNav reports={resp.data?.reports} />
      <ReportRouter />
    </div>
  );
};

export default index;
