import React, { useEffect } from "react";
import Incident from "./Incident";
import IncidentUpdate from "./IncidentUpdate";
import ImpactBadge from "./ImpactBadge";
import ExternalLink from "../../components/ExternalLink";
import Loader from "../../components/Loader";
import { statusPageUrl } from "../../lib/ikeono";
import { useGet } from "../../lib/api";
import APIResponseError from "../../components/APIResponseError";
import AppStatusOperational from "./AppStatusOperational";

const AppStatus = () => {
  const [get, isLoading, error, resp] = useGet();

  useEffect(() => {
    get("system_status");
  }, []);

  if (isLoading) return <Loader />;
  if (error) return <APIResponseError response={error} />;
  if (!resp) return null;

  return (
    <div>
      <div className="d-flex gap-3 mb-2">
        <h5 className="fw-bold mb-3">System Status</h5>
        <h6 className="mb-4">
          <ImpactBadge
            impact={resp.data.status?.indicator}
            description={resp.data.status?.description}
          />
        </h6>
        <ExternalLink href={statusPageUrl} className="text-primary ms-auto">
          View Status Page
        </ExternalLink>
      </div>
      <AppStatusOperational
        hidden={!resp.data.status?.indicator === "none"}
        re
      />
      {resp.data.incidents?.map((incident) => (
        <Incident
          name={incident.name}
          startedAt={incident.started_at}
          updatedAt={incident.updated_at}
          impact={incident.impact}
          status={incident.status}
        >
          {incident.incident_updates?.map((update) => (
            <IncidentUpdate
              status={update.status}
              body={update.body}
              updatedAt={update.updated_at}
            />
          ))}
        </Incident>
      ))}
    </div>
  );
};

export default AppStatus;
