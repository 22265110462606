import React from "react"
import APIResponseError from "../components/APIResponseError"

const LocationLoadError = ({ error, onReload }) => {
  if (!error) return null
  return (
    <div className="p-2 text-center">
      <APIResponseError response={error.response} />
      <button
        type="button"
        className="btn btn-primary btn-sm"
        onClick={onReload}>
        Reload
      </button>
    </div>
  )
}
export default LocationLoadError
