import * as api from "../../api";

export const get = async (accountId) => {
  const response = await api.get(`/account/${accountId}/user`);
  return response.data;
};

export const update = async (accountId, userId, user) => {
  const response = await api.put(`/account/${accountId}/user/${userId}`, user);
  return response.data;
};

export const create = async (accountId, user) => {
  const response = await api.post(`/account/${accountId}/user`, user);
  return response.data;
};

export const delete_ = async (accountId, userId) => {
  await api.delete_(`/account/${accountId}/user/${userId}`);
};
