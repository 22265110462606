import React from "react";

const IntegrationCard = ({ icon, title, children, onClick }) => (
  <div
    role="button"
    className="card integration-card cursor-pointer"
    onClick={onClick}
  >
    <div className="card-body d-flex flex-row gap-3 align-items-center">
      {icon}
      <div className="me-0 me-md-5">
        <h5 hidden={!title} className="fw-bold mb-0">
          {title}
        </h5>
        <div>{children}</div>
      </div>
      <div className="ms-auto align-self-center">
        <i className="bi-chevron-right" />
      </div>
    </div>
  </div>
);

export default IntegrationCard;
