import React from "react";
import { useActions } from "./lib/overmind";
import { privacyPolicyUrl } from "./lib/ikeono";

const AcceptTermsOfService = () => {
  const actions = useActions().account;

  return (
    <div className="d-flex justify-content-center align-items-center">
      <div className="card">
        <div className="card-header">Accept Terms of Service</div>
        <div className="card-body">
          <p className="mb-1">
            Please accept our Terms of Service before continuing to use Ikeono.
          </p>
          <p className="mb-4">
            <a
              href="https://ikeono.com/terms-of-use"
              target="_blank"
              rel="noreferrer"
            >
              View Terms of Service{" "}
            </a>{" "}
            <a
              className="ms-2"
              href={privacyPolicyUrl}
              target="_blank"
              rel="noreferrer"
            >
              View Privacy Policy
            </a>
          </p>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              actions.acceptTerms();
            }}
          >
            Accept
          </button>
        </div>
      </div>
    </div>
  );
};

export default AcceptTermsOfService;
