import React from "react";

const WebchatInstallGuide = ({ hideWarning }) => {
  return (
    <>
      <p>Install this on your website. Click on the box to copy the code.</p>
      <div className="alert alert-warning py-2" hidden={hideWarning}>
        <b className="d-block">Important</b> You will need to reinstall the code
        snippet on your website for new settings to take place.
      </div>
    </>
  );
};

export default WebchatInstallGuide;
