import React from "react";
import SimpleForm from "./SimpleForm";
import StartEndDateControl from "./StartEndDateControl";

const InlineStartEndDateForm = ({ apiSubmitHandler, initialValues }) => {
  return (
    <SimpleForm
      disableSuccess
      hideSubmitButton
      apiSubmitHandler={apiSubmitHandler}
      initialValues={initialValues}
    >
      <StartEndDateControl>
        <button
          className="btn btn-sm btn-primary align-self-start mt-auto  "
          type="submit"
        >
          Update
        </button>
      </StartEndDateControl>
    </SimpleForm>
  );
};

export default InlineStartEndDateForm;
