import React from "react"
import { useActions } from "../lib/overmind"

const DebugOptionButton = ({ children, onClick }) => {
  const actions = useActions()

  return (
    <button
      type="button"
      className="btn ps-0 btn-link"
      onClick={() => {
        actions.account.debugChange(onClick)
      }}>
      {children}
    </button>
  )
}
export default DebugOptionButton
