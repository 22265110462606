import React from "react"
import FormatText from "../components/FormatText"
import { useState } from "../lib/overmind"
import "./MessageCard.css"
import LinkOpenBadge from "./LinkOpenBadge"

const SmsCard = ({ idx, message }) => {
  const state = useState().activeThread // Pull the message based on idx so the status updates automatically
  message = message || state.messages[idx]

  if (!message?.body) return null

  const isFromShop = message.is_from_shop
  const linkClass = isFromShop ? "text-white" : ""
  const urlClickCount = message.meta?.context?.url_click_count
  const hasUrls = message.meta?.context?.has_urls

  return (
    <div className={`w-100 ${isFromShop && "text-end"}`}>
      <div
        className={`sms-card ${
          isFromShop ? "sms-card--from-shop" : "sms-card--to-shop"
        }`}>
        <FormatText linkClass={linkClass}>{message.body}</FormatText>
        <div className="d-block">
          <LinkOpenBadge
            hasUrls={hasUrls}
            count={urlClickCount}
            hidden={!urlClickCount}
          />
        </div>
      </div>
    </div>
  )
}

export default SmsCard
