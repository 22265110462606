import * as api from "../../api"

export const sendMessage = async ({
  threadId,
  body,
  invoice,
  attachment,
  referenceId,
}) => {
  const payload = new FormData()
  if (body) {
    payload.set("body", body)
  }
  if (invoice) {
    payload.set("invoice", JSON.stringify(invoice))
  }

  if (attachment) {
    payload.set("attachment0", attachment)
  }
  if (referenceId) {
    payload.set("reference_id", referenceId)
  }

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  }
  const response = await api.post(
    `/shop/{exid}/thread/${threadId}/message`,
    payload,
    config
  )
  return response.data
}

export const get = async (id) => {
  const response = await api.get(`/shop/{exid}/thread/${id}`)
  return response.data
}

export const addMarketingOptOut = async (phoneNumber) => {
  await api.post("/shop/{exid}/campaign/optout", {
    phone_number: phoneNumber,
  })
}

export const removeMarketingOptOut = async (phoneNumber) => {
  await api.delete_("/shop/{exid}/campaign/optout", {
    phone_number: phoneNumber,
  })
}

export const addMute = async (phoneNumber) => {
  await api.post("/shop/{exid}/message/block", {
    phone_number: phoneNumber,
  })
}

export const removeMute = async (phoneNumber) => {
  await api.delete_("/shop/{exid}/message/block", {
    phone_number: phoneNumber,
  })
}

export const assignThreadToInbox = async ({ threadId, newInboxId }) => {
  const payload = {
    new_inbox_id: newInboxId,
  }
  const response = await api.put(
    `/shop/{exid}/thread/${threadId}/assigned-inbox`,
    payload
  )
  return response.data
}

export const updateThreadUnreadStatus = async ({
  threadId,
  hasUnreadMessages,
}) => {
  const payload = {
    has_unread_messages: hasUnreadMessages,
  }
  const response = await api.put(`/shop/{exid}/thread/${threadId}`, payload)
  return response.data
}

export const updateThread = async ({ threadId }) => {
  await api.put(`/shop/{exid}/thread/${threadId}`, {})
}
