const startCase = require("lodash/fp/startCase");

export const snakeToTitleCase = (val) => {
  const sentence = val.toLowerCase().split("_");
  for (let i = 0; i < sentence.length; i++) {
    sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
  }

  return sentence.join(" ");
};

export const getRandomString = () => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < 15; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const firstTwoLetters = (word1, word2) => {
  if (!word1 && !word2) return "";

  if (word2) {
    return word1.substring(0, 1) + word2.substring(0, 1);
  }
  return word1?.substring(0, 2);
};

export const firstCharacter = (word1, word2) =>
  word1?.substring(0, 1) || word2?.substring(0, 1) || "";

export const slugify = (str) =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");

export const titleCase = (str) => startCase(str);

export const getFirstNameAndLastName = (s) => {
  if (!s) return { firstName: null, lastName: null };
  const parts = s.trim().split(" ");

  const firstName = parts.length && parts[0];
  const lastName = parts.slice(1).join(" ");

  return {
    firstName: firstName || null,
    lastName: lastName || null,
  };
};

export const formatName = (firstName, lastName, limit) => {
  const name = `${firstName || ""} ${lastName || ""}`.trim();
  if (limit && name) {
    return name.substring(0, limit);
  }
  return name;
};
