import React from "react";

const InboxAttachmentDeleteButton = ({ onClick }) => {
  return (
    <button
      type="button"
      className="position-absolute bg-black text-white btn rounded-circle d-flex justify-content-center align-items-center p-0"
      style={{
        top: "-4px",
        right: "-4px",
        fontSize: "14px",
        width: "16px",
        height: "16px",
      }}
      onClick={onClick}
    >
      <i className="bi-x" />
    </button>
  );
};

export default InboxAttachmentDeleteButton;
