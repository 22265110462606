import * as api from "../../api"

export const get = async () => {
  const response = await api.get("/shop/{exid}/inbox")
  return response.data
}

export const getInbox = async ({
  inboxName,
  offset,
  limit,
  includeThreadId,
  isClosed,
  isUnopened,
  query,
}) => {
  const sanitizedQuery = query?.trim()

  const params = {
    offset,
    limit,
    include_thread_id: includeThreadId,
    is_archived: isClosed,
    is_unopened: isUnopened,
    query: sanitizedQuery || null,
  }
  const response = await api.get(`/shop/{exid}/inbox/${inboxName}`, params)
  return response.data
}

export const createThread = async ({
  firstName,
  lastName,
  receiverId,
  inboxId,
  vendorId,
  vendorSource,
  hidden,
  email,
}) => {
  const response = await api.post("/shop/{exid}/thread", {
    first_name: firstName,
    last_name: lastName,
    receiver_id: receiverId,
    inbox_id: inboxId,
    contact_vendor_id: vendorId,
    contact_vendor_source: vendorSource,
    email,
    hidden,
  })
  return response.data.data
}

export const updateThread = async ({ threadId, archived }) => {
  await api.put(`/shop/{exid}/thread/${threadId}`, { archived })
}

export const deleteThread = async (threadId) => {
  await api.delete_(`/shop/{exid}/thread/${threadId}`)
}

export const bulkUpdate = async ({
  threadIds,
  archived,
  hasUnreadMessages,
}) => {
  await api.put("/shop/{exid}/thread/bulk", {
    thread_ids: threadIds,
    archived,
    has_unread_messages: hasUnreadMessages,
  })
}

export const assignSelectedThreadsToInbox = async ({ threadIds, inboxId }) => {
  await api.put("/shop/{exid}/thread/bulk", {
    thread_ids: threadIds,
    inbox_id: inboxId,
  })
}
