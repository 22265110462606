import React from "react"
import { Link } from "react-router-dom"

const TemplateZeroState = () => {
  return (
    <div className="d-flex align-items-center text-secondary flex-column text-center p-3  gap-1">
      <div>No templates yet.</div>
      <Link to="/templates">Create your first</Link>
    </div>
  )
}

export default TemplateZeroState
