import React from "react"

const SplitDetailContainer = ({ children, detailComponent, showLogout }) => {
  return (
    <div className="d-flex flex-wrap flex-lg-nowrap vh-100">
      <div className="flex-lg-1 overflow-scroll vh-lg-100 position-relative">
        {children}
      </div>
      <div className="flex-lg-1 bg-light vh-0 vh-lg-100 vw-50 w-100 mt-3 mt-lg-0 px-3 px-lg-0 pb-4 pb-lg-0">
        {detailComponent}
      </div>
      <a
        hidden={!showLogout}
        className="position-absolute"
        style={{ top: "10px", right: "20px" }}
        href="/logout">
        Logout
      </a>
    </div>
  )
}

export default SplitDetailContainer
