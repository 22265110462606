/* eslint-disable no-shadow */
import React, { useState } from "react"
import { post } from "../lib/api"
import APIResponseError from "../components/APIResponseError"
import AccountSetupFeatureList from "../Signup/components/AccountSetupFeatureList"

const AscendOauth = () => {
  const [isLoading, setIsLoading] = useState()
  const [error, setError] = useState()

  const onClick = async () => {
    setIsLoading(true)
    setError(null)
    try {
      await post("/shop/{exid}/ascend/authorize")
      window.location.replace("/close")
    } catch (error) {
      console.log(error)
      setError(error)
    }
    setIsLoading(false)
  }

  return (
    <div className="m-5">
      <AccountSetupFeatureList title="Authorize Ascend" className="">
        {[
          "Sync your contacts to Ikeono",
          "Send SMS/MMS messages to your contacts",
          "Send feedback requests",
        ]}
      </AccountSetupFeatureList>
      <div className="my-3">
        <button
          type="button"
          disabled={isLoading}
          className="btn btn-primary"
          style={{ backgroundColor: "#045E85" }}
          onClick={onClick}>
          <i className="bi-image me-2" />
          Connect Ascend
        </button>
      </div>
      <APIResponseError response={error} />
    </div>
  )
}

export default AscendOauth
