import React from "react";
import Badge from "../../components/Badge";

const variantMap = {
  minor: "warning",
  major: "danger",
  critical: "danger",
  none: "success",
};

const ImpactBadge = ({ impact, description }) => {
  const variant = variantMap[impact] || "secondary";
  return <Badge variant={variant}>{description || impact}</Badge>;
};

export default ImpactBadge;
