/* eslint-disable no-shadow */
import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import * as overmind from "../../lib/overmind"
import { checkPhoneNumberValidity } from "../../lib/phoneNumber"
import CloseButton from "./CloseButton"
import InboxLoadingStateContainer from "./InboxLoadingStateContainer"
import "./style.css"

const InboxCreateNewContact = () => {
  const state = overmind.useState().threadCreateContacts
  const activeInboxState = overmind.useState().activeInbox
  const actions = overmind.useActions()
  const params = useParams()
  const navigate = useNavigate()

  const [name, setName] = useState(state.selectedContact?.name)
  const [phone, setPhone] = useState(state.selectedContact?.phoneNumber)
  const [phoneError, setPhoneError] = useState(null)

  const [isCreating, setIsCreating] = useState(false)
  const [error, setError] = useState(null)

  const onCreateThreadWithoutContact = async () => {
    const phoneStatus = checkPhoneNumberValidity(phone)
    if (phoneStatus) {
      setPhoneError(phoneStatus)
      return
    }

    setError(null)
    setIsCreating(true)
    try {
      await actions.activeInbox.createThread({
        receiverId: phone,
        name,
        navigateToThread: true,
        inboxId: activeInboxState.inboxId,
        vendorId: state.selectedContact?.vendorId,
        vendorSource: state.selectedContact?.vendorSource,
        email: state.selectedContact?.email,
      })
      actions.threadCreateContacts.clearSearch()
    } catch (error) {
      console.log(error)
      setError(error.response)
    }
    setIsCreating(false)
  }

  return (
    <InboxLoadingStateContainer isCreating={isCreating} error={error}>
      <div className="mt-2 mb-3 d-flex flex-column">
        <div className="d-flex align-items-center mb-2">
          <button
            type="button"
            onClick={() => {
              navigate(`/inbox/${params.inboxName}/new`, { replace: true })
            }}
            className="btn btn-text ps-0">
            <i className="bi-arrow-left" /> Back to search
          </button>
          <span className="ms-auto">
            <CloseButton />
          </span>
        </div>
        <span
          className="text-uppercase text-secondary mt-2"
          style={{ fontWeight: "800", fontSize: "12px" }}>
          Send to:
        </span>
        <input
          value={name}
          onChange={(e) => setName(e.target.value)}
          className=" inbox-contact-search-input glow-underline"
          placeholder="Enter contact name"
          maxLength={50}
        />
        <span
          className="text-uppercase text-secondary mt-5"
          style={{ fontWeight: "800", fontSize: "12px" }}>
          Phone number:
        </span>
        <input
          value={phone}
          onChange={(e) => {
            setPhone(e.target.value)
            setPhoneError(null)
          }}
          className=" inbox-contact-search-input glow-underline"
          placeholder="Enter phone number"
        />
        <span className="mt-3 text-danger" hidden={!phoneError}>
          {phoneError}
        </span>

        <div className="mt-5">
          <button
            type="button"
            className="btn btn-primary"
            onClick={onCreateThreadWithoutContact}>
            <i className="bi-pencil me-2" />
            Start conversation
          </button>
        </div>
      </div>
    </InboxLoadingStateContainer>
  )
}

export default InboxCreateNewContact
