import React from "react"
import { Link } from "react-router-dom"

const Img = ({ hidden, ...props }) => {
  if (hidden) return null
  return <img {...props} />
}

const FeatureQuickGlance = ({
  appIcon,
  title,
  body,
  imgSrc,
  path,
  learnMorePath,
  ctaButtonTitle = "Get Started",
  className,
}) => {
  const location = window.location.href
  const isExtensionSiderail =
    location.includes("chat_component") ||
    location.includes("external/payments/new")

  return (
    <div className={`d-flex gap-3 m-2 justify-content-center ${className}`}>
      {appIcon}
      <div>
        <h5>{title}</h5>
        <p style={{ maxWidth: "40ch" }}>{body}</p>
        <div>
          <Link
            hidden={isExtensionSiderail}
            className="btn btn-primary"
            to={path}>
            {ctaButtonTitle}
          </Link>
          <a
            hidden={!isExtensionSiderail}
            className="btn btn-primary"
            href={path}
            target="_blank"
            rel="noreferrer">
            {ctaButtonTitle}
          </a>
          <a
            className="btn btn-link text-secondary"
            href={learnMorePath}
            target="_blank"
            rel="noreferrer">
            Learn more
          </a>
        </div>
        <Img
          hidden={!imgSrc}
          alt="Example"
          width="250px"
          src={imgSrc}
          className="rounded shadow d-inline d-md-none mt-4"
        />
      </div>
      <Img
        hidden={!imgSrc}
        alt="Example"
        width="250px"
        src={imgSrc}
        className="rounded shadow d-none d-md-inline"
      />
    </div>
  )
}

export default FeatureQuickGlance
