import React from "react"
import { useState } from "../lib/overmind"
import FormatText from "../components/FormatText"
import LinkOpenBadge from "./LinkOpenBadge"
import ReviewRequestBanner from "../Reviews/ReviewRequestBanner"

const ReviewRequestCard = ({ idx }) => {
  const state = useState().activeThread // Pull the message based on idx so the status updates automatically
  const message = state.messages[idx]

  if (!message?.body) return null

  const isFromShop = message.is_from_shop
  const urlClickCount = message.meta?.context?.url_click_count
  const hasUrls = message.meta?.context?.has_urls

  return (
    <div className={`w-100 ${isFromShop && "text-end"}`}>
      <div
        className={`sms-card ms-auto py-3 px-4 ${
          isFromShop
            ? "sms-card--from-shop card text-black"
            : "sms-card--to-shop"
        }`}
        style={{ backgroundColor: "white" }}>
        <ReviewRequestBanner className="mb-2" />
        <FormatText linkClass="d-block review-request-card-link">
          {message.body}
        </FormatText>
        <LinkOpenBadge
          hasUrls={hasUrls}
          count={urlClickCount}
          hidden={!urlClickCount && !hasUrls}
        />
      </div>
    </div>
  )
}

export default ReviewRequestCard
