import React from "react";
import InboxAttachmentDeleteButton from "./InboxAttachmentDeleteButton";
import ReviewRequestBanner from "../../Reviews/ReviewRequestBanner";

const InboxReviewRequestAttachmentPreview = ({ onDelete, hidden }) => {
  if (hidden) return null;
  return (
    <div
      className="d-flex flex-row  align-items-center gap-2 px-3 rounded-1 position-relative"
      style={{ backgroundColor: "var(--bs-gray-200", height: "60px" }}
    >
      <ReviewRequestBanner />
      <InboxAttachmentDeleteButton onClick={onDelete} />
    </div>
  );
};

export default InboxReviewRequestAttachmentPreview;
