import React, { useEffect } from "react";
import { Browser } from "../lib/browser";
import BrowserExtensionInstalledBadge from "./BrowserExtensionInstalledBadge";
import BrowserExtensionMissingBadge from "./BrowserExtensionMissingBadge";
import * as overmind from "../lib/overmind";

import DownloadChromeExtensionButton from "./DownloadChromeExtensionButton";
import DownloadFirefoxExtensionButton from "./DownloadFirefoxExtensionButton";

const BrowserExtensionDownload = ({ hideStatus }) => {
  const state = overmind.useState().browserExtensionEventBus;
  const browser = Browser.current();

  let badge = null;
  if (state.isExtensionInstalled !== null) {
    badge = state.isExtensionInstalled ? (
      <BrowserExtensionInstalledBadge />
    ) : (
      <BrowserExtensionMissingBadge />
    );
  }

  const buttons = [
    <DownloadChromeExtensionButton
      key="chrome"
      isPrimary={browser === Browser.chrome}
    />,
    <DownloadFirefoxExtensionButton
      key="firefox"
      isPrimary={browser === Browser.firefox}
    />,
  ];
  return (
    <div>
      {hideStatus ? null : badge}
      <div className="d-flex flex-row align-items-center gap-3">
        {browser === Browser.chrome ? buttons : buttons.reverse()}
      </div>
    </div>
  );
};

export default BrowserExtensionDownload;
