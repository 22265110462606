import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import { ChromePicker } from "react-color";

const ColorPickerControl = (props) => {
  const [color, setColor] = React.useState();
  const { values, setFieldValue } = useFormikContext();

  const onChange = (newColor) => {
    setColor(newColor.hex);
    setFieldValue(props?.id || "color", newColor.hex);
  };

  useEffect(() => {
    if (!values || !props?.id) return;
    setColor(values[props?.id]);
  }, [values]);

  return <ChromePicker color={color} onChangeComplete={onChange} {...props} />;
};

export default ColorPickerControl;
