import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import InboxDetail from "../Inbox/InboxDetail"
import { useActions, useState } from "../lib/overmind"
import InboxMessageInput from "./InboxMessageInput"
import InboxMessageList from "./InboxMessageList"
import InboxMessageListHeader from "./InboxMessageListHeader"
import ThreadDragAndDrop from "./ThreadDragAndDrop"

const ThreadContainer = () => {
  const params = useParams()
  const actions = useActions()
  const state = useState().activeThread
  const actualThreadId = params.threadId
  const activeInboxName = params.inboxName

  useEffect(() => {
    if (!actualThreadId) return
    actions.activeThread.get(actualThreadId)
  }, [actualThreadId])

  if (!actualThreadId) {
    return <div>No threadId</div>
  }

  return (
    <ThreadDragAndDrop>
      <div
        className="w-100 d-flex flex-column"
        style={{ height: "calc(100vh - 70px)" }}>
        <InboxMessageListHeader
          threadId={actualThreadId}
          activeInboxName={activeInboxName}
        />
        <InboxMessageList
          threadId={actualThreadId}
          isLoading={state.isLoading}
          messages={state.messages}
          apiError={state.apiError}
          actions={actions}
        />
        <InboxMessageInput threadId={actualThreadId} />
        <InboxDetail threadId={actualThreadId} />
      </div>
    </ThreadDragAndDrop>
  )
}

export default ThreadContainer
