/* eslint-disable no-continue */
/* eslint-disable guard-for-in */
/* eslint-disable no-use-before-define */
export class Cookie {
  constructor(key) {
    this.key = key
  }

  get() {
    return getCookie(this.key)
  }

  set(value, options) {
    setCookie(this.key, value, options)
  }

  delete() {
    setCookie(this.key, "", {
      "max-age": -1,
      path: "/",
      domain: domain(),
    })
    setCookie(this.key, "", {
      "max-age": -1,
      path: "/",
      domain: domain(),
      partitioned: true,
    })
  }
}

export const domain = () => {
  const { host } = window.location

  if (host.includes("ngrok")) {
    return host
  }
  const separate = window.location.host.split(".")
  separate.shift()
  return separate.join(".")
}

function getCookie(name) {
  if (typeof document === "undefined") return
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length >= 2) {
    return parts.pop().split(";").shift()
  }
}

function setCookie(name, value, options = {}) {
  options = {
    path: "/",
    domain: domain(),
    secure: true,
    sameSite: "none",
    // partitioned: true,
    ...options,
  }

  try {
    if (navigator && navigator.userAgent) {
      const parts = navigator.userAgent.split(" ")

      // eslint-disable-next-line no-restricted-syntax
      let version = 0
      for (let i = 0; i < parts.length; i += 1) {
        const part = parts[i]
        if (!part.includes("Chrome/")) continue
        const chromeParts = part.split("Chrome/")
        const versionStr = chromeParts[1].split(".")[0]
        // eslint-disable-next-line radix
        version = parseInt(versionStr)
      }

      if (version >= 78) {
        if (window.location.href.includes("https")) {
          options.secure = true
          options.sameSite = "none"
        }
      }
    }
  } catch (error) {
    console.error(error)
  }

  if (options.expires && options.expires.toUTCString) {
    options.expires = options.expires.toUTCString()
  } else {
    const date = new Date()
    date.setDate(date.getDate() + 90)
    options.expires = date.toUTCString()
  }

  let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`

  for (const optionKey in options) {
    updatedCookie += `; ${optionKey}`
    const optionValue = options[optionKey]
    if (optionValue !== true) {
      updatedCookie += `=${optionValue}`
    }
  }

  if (typeof document === "undefined") return
  document.cookie = updatedCookie
}

export const cookiesEnabled = () => {
  try {
    const cookie = new Cookie("ik_cookie_enabled")
    cookie.set("true")
    return cookie.get() === "true"
  } catch (e) {
    return false
  }
}

export const useCookiesEnabled = () => cookiesEnabled()
