import React from "react"
import { percentageChange } from "../lib/number"

const PosNegLabel = ({
  children,
  hidden,
  startValue,
  endValue,
  defaultNullValue = "0",
  inline,
}) => {
  if (hidden) return null
  const value = percentageChange(startValue, endValue)
  if (!value) {
    return children
  }
  return (
    <div className={`d-${inline ? "inline" : "flex"} gap-1`}>
      {children || defaultNullValue}{" "}
      <span
        className={`text-${
          value > 0 ? "success" : "danger"
        } text-nowrap fs-14 d-${inline ? "inline" : "flex"} align-items-center`}
        hidden={!value}>
        <i
          className={
            value > 0 ? "bi-caret-up-fill fs-11" : "bi-caret-down-fill fs-11"
          }
        />
        {value}%
      </span>
    </div>
  )
}

export default PosNegLabel
