import React from "react"
import { SupportAnchor } from "../lib/ikeono"
import UpgradeAccountButtonV2 from "../components/UpgradeAccountButtonV2"

const DefaultFeatureUpgradePlan = ({ plan, featureName, redirectPath }) => {
  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col text-center">
          <h5>
            <i className="bi-stars me-2 text-warning fw-bolder" />
            Please upgrade your plan to access this feature.
          </h5>
          <p>
            Contact us at <SupportAnchor /> to learn more.{" "}
          </p>
          <UpgradeAccountButtonV2
            hidden={!plan}
            featureName={featureName}
            plan={plan}
            redirectPath={redirectPath}
          />
        </div>
      </div>
    </div>
  )
}

export default DefaultFeatureUpgradePlan
