import React from "react"

export const SettingsDescription = ({ title, children }) => (
  <>
    <b>{title}</b>
    <p className="fs-14" style={{ maxWidth: "35ch" }}>
      {children}
    </p>
  </>
)

const SettingsRow = ({ children, title, body, hidden }) => {
  if (hidden) return null
  if (title || body) {
    return (
      <div className="d-flex  flex-row">
        <div style={{ width: "50%" }}>
          <SettingsDescription title={title}>{body}</SettingsDescription>
        </div>
        <div style={{ width: "50%" }}>{children}</div>
      </div>
    )
  }

  return (
    <div className="d-flex  flex-row">
      <div style={{ width: "50%" }}>{children[0]}</div>
      <div style={{ width: "50%" }}>{children[1]}</div>
    </div>
  )
}

export default SettingsRow
