/* eslint-disable no-shadow */
/* eslint-disable camelcase */
import React, { useEffect } from "react"
import PhoneNumberEligibleInfoCard from "./Eligible/PhoneNumberEligibleInfoCard"
import PhoneNumberIneligibleInfoCard from "./Ineligible/PhoneNumberIneligibleInfoCard"
import { useActions, useState } from "../../lib/overmind"
import Loader from "../../components/Loader"
import APIResponseError from "../../components/APIResponseError"
import { useMixpanel } from "../../services/mixpanel"

const PendingAccountVerification = () => {
  const actions = useActions().account
  const state = useState().account
  const [isLoading, setIsLoading] = React.useState()
  const [error, setError] = React.useState()
  const mixpanel = useMixpanel()

  const getData = async () => {
    setIsLoading(true)
    setError(null)
    try {
      await actions.getSetupStatus()
    } catch (error) {
      setError(error)
    }

    mixpanel.track("page view", {
      "page name": "pending account verification",
      "is phone hostable": state.setupStatus?.is_phone_hostable,
      "phone number": state.setupStatus?.phone_number,
      "is loa signed": state.setupStatus?.is_loa_signed,
      "is account verified": state.setupStatus?.is_account_verified,
      "phone number line provider":
        state.setupStatus?.phone_number_line_provider,
    })

    setIsLoading(false)
  }

  useEffect(getData, [])

  if (isLoading) {
    return <Loader />
  }
  if (error) {
    return <APIResponseError response={error} />
  }
  if (!state.setupStatus) return null
  const isHostable = state.setupStatus.is_phone_hostable

  return (
    <div className="d-flex flex-column gap-3">
      <PhoneNumberEligibleInfoCard hidden={!isHostable} />
      <PhoneNumberIneligibleInfoCard hidden={isHostable} />
    </div>
  )
}

export default PendingAccountVerification
