import * as api from "../../api";

export const get = async ({ query, offset, includeId }) => {
  const response = await api.get("/shop/{exid}/customer/", {
    q: query,
    offset,
    include_id: includeId,
  });
  return response.data;
};

export const editContact = async (newContact) => {
  const response = await api.put(
    `/shop/{exid}/customer/${newContact.id}`,
    newContact,
  );
  return response.data;
};

export const createContact = async (newContact) => {
  const response = await api.post("/shop/{exid}/customer", newContact);
  return response.data?.message;
};

export const delete_ = async (contactId) => {
  const response = await api.delete_(`/shop/{exid}/customer/${contactId}`);
  return response.data?.message;
};
