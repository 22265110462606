import React from "react"
import DataCard from "../../components/DataCard"
import Stars from "../../components/Stars"
import HistoricalChangeInfo from "./HistoricalChangeInfo"
import ReviewCountDataCard from "./ReviewCountDataCard"

const OverviewColumn = ({
  averageRating,
  initialAverageRating,
  totalReviewCount,
  initialTotalReviewCount,
  redact,
}) => {
  return (
    <div className="container">
      <div className="row mb-4">
        <div className="col-12">
          <div>
            <div className="d-flex gap-2 align-items-center mb-1">
              <img src="/google-web-light-round.svg" />
              <h5 className="fw-semibold m-0">Your average rating</h5>
            </div>
            <div
              className={`d-flex gap-3 align-items-center `.concat(
                redact ? "blur-10" : ""
              )}>
              <span className="fw-bold" style={{ fontSize: "35px" }}>
                {Number(averageRating || 0).toFixed(1)}
              </span>
              <Stars rating={averageRating} style={{ fontSize: "30px" }} />
            </div>
          </div>
          <HistoricalChangeInfo
            averageRating={averageRating}
            initialAverageRating={initialAverageRating}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <ReviewCountDataCard
            redact={redact}
            totalReviewCount={totalReviewCount}
            initialTotalReviewCount={initialTotalReviewCount}
          />
        </div>
        {/* <div className="col-12">
          <DataCard title="Total Requesets" body="1234" subtitle="All Time" />
        </div> */}
      </div>
    </div>
  )
}

export default OverviewColumn
