import React from "react"
import { RegionDropdown as RegionDropDownSelector } from "react-country-region-selector"
import { useFormikContext } from "formik"
import StandardFormGroup from "./StandardFormGroup"
import { countryCodeMap } from "./CountryDropDown"

const regionLookup = {
  "United States": "State",
  Australia: "State",
  Canada: "Province",
}

const regionLabel = (country) => {
  return regionLookup[country] || "Region"
}

const RegionDropDown = () => {
  const { values, setFieldValue } = useFormikContext()
  const country = countryCodeMap[values?.country_code]

  return (
    <StandardFormGroup label={regionLabel()}>
      <RegionDropDownSelector
        id="regionInput"
        name="region"
        required
        blankOptionLabel={`Select ${regionLabel(country)}`}
        defaultOptionLabel={`Select ${regionLabel(country)}`}
        onChange={(newRegion) => setFieldValue("region", newRegion)}
        value={values?.region}
        country={country}
        className="form-control form-control-sm"
      />
    </StandardFormGroup>
  )
}

export default RegionDropDown
