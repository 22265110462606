import React from "react";
import UserPermissionCheck from "../components/UserPermissionCheck";
import SpecializedFileDownload from "./SpecializedFileDownload";

const SpecializedFileDownloadWithPermissionCheck = () => {
  return (
    <UserPermissionCheck permissionKey="can_download_files">
      <SpecializedFileDownload />
    </UserPermissionCheck>
  );
};

export default SpecializedFileDownloadWithPermissionCheck;
