import React from "react";
import Button from "../components/Button";

const InboxThreadListError = ({ onReload }) => {
  return (
    <div className="p-3 alert alert-danger m-3">
      <p>There was an error. Please try again.</p>

      <Button type="button" onClick={() => onReload()}>
        Reload
      </Button>
    </div>
  );
};

export default InboxThreadListError;
