import React from "react";
import LabeledField from "./Form/LabeledField";
import timezones from "../lib/timezone";

const TimezoneSelect = (props) => (
  <LabeledField
    label="Timezone"
    required
    name="timezone"
    id="timezone"
    as="select"
    {...props}
  >
    <option value=""> </option>
    {timezones.map((tz) => (
      <option key={tz} value={tz}>
        {tz}
      </option>
    ))}
  </LabeledField>
);

export default TimezoneSelect;
