import React from "react"
import { GoogleAppIcon } from "../IntegrationAppIcon"
import Stars from "../components/Stars"

const ReviewRequestBanner = (props) => (
  <div className={`d-flex gap-2 ${props?.className || ""}`}>
    <GoogleAppIcon width="35px" height="35px" />
    <div>
      <Stars rating={5} style={{ fontSize: "14px" }} />
      <div className="fs-13 fw-bold " style={{ marginTop: "-2px" }}>
        Review Request
      </div>
    </div>
  </div>
)

export default ReviewRequestBanner
