import React, { useEffect } from "react"
import { useActions, useReaction, useState } from "../lib/overmind"
import InboxChangeDropDown from "../Thread/ThreadActions/InboxChangeDropDown"
import { formatPhoneNumberWithAccountCountry } from "../lib/overmind/account/util"
import MuteActionItem from "../Thread/ThreadActions/MuteActionItem"
import Dropdown from "../components/Dropdown"
import MarketingOptOutItem from "../Thread/ThreadActions/MarketingOptOutItem"
import { formatName } from "../lib/stringUtil"

const BrowserExtensionSiderailHeader = ({ threadId, hidden }) => {
  if (hidden) return null
  const state = useState()
  const reaction = useReaction()
  const actions = useActions()
  const { contact } = state.activeThread
  const inboxes = actions.activeThread.getAssignedInboxes()
  const assignedInbox = inboxes?.length && inboxes[0]

  // Workaround to force render when an inbox changes
  const x = state.activeInbox.threads

  return (
    <div className="d-flex p-2 ps-1 border-bottom flex-column flex-md-row mx-2">
      <div>
        <span className="fw-bold text-capitalize">
          {formatName(contact?.first_name, contact?.last_name, 27)}
        </span>
        <span className="fs-6 ms-2 text-secondary fs-14">
          {formatPhoneNumberWithAccountCountry(contact?.mobile_phone_number)}
        </span>
        <span
          className="badge border border-secondary text-secondary ms-2"
          hidden={!state.activeThread.detail?.receiverIsMuted}>
          <i className="bi-bell-slash" /> Muted
        </span>
      </div>
      <div
        className={`ms-md-auto d-flex flex-row gap-2 align-items-center me-2 ${
          state.activeThread.isLoading ? "visually-hidden" : ""
        }`}>
        <span hidden={!assignedInbox} className="inbox-row-badge">
          {assignedInbox?.title}
        </span>
        <InboxChangeDropDown
          threadId={threadId}
          inboxId={state.activeInbox.inboxId}
        />
        <Dropdown
          className="btn btn-link text-black fs-14 dropdown-toggle px-0"
          title="Actions">
          <MarketingOptOutItem />
          <MuteActionItem />
        </Dropdown>
      </div>
    </div>
  )
}

export default BrowserExtensionSiderailHeader
