import React from "react"
import LabeledField from "./LabeledField"

const PasswordField = (props) => {
  return (
    <LabeledField
      name="password"
      type="password"
      className="form-control"
      minLength={10}
      required
      {...props}
    />
  )
}

export default PasswordField
