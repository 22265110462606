import React, { useRef } from "react"
import { Route, Routes, useNavigate, useParams } from "react-router-dom"
import ThreadContainer from "../Thread/ThreadContainer"
import InboxThreadList from "./InboxThreadList"
import InboxThreadOpenCloseTab from "./InboxThreadOpenCloseTab"
import InboxHeader from "./InboxHeader"
import InboxNewThreadContainer from "./InboxNewThreadContainer"
import InboxCreateNewContact from "./InboxNewThreadContainer/InboxCreateNewContact"
import InboxMultiSelectActions from "./InboxMultiSelectActions"
import { useState } from "../lib/overmind"

const ThreadList = () => {
  const params = useParams()
  const { threadId, inboxName } = params
  const state = useState().activeInbox
  const heightOffset = state.multiSelectEnabled ? "52px" : "9px"

  return (
    <div
      className="border-end"
      style={{ height: `calc(100% - ${heightOffset})` }}>
      <div>
        <InboxHeader inboxName={inboxName} />
        <InboxThreadOpenCloseTab />
        <InboxMultiSelectActions />
      </div>
      <InboxThreadList
        heightOffset={120}
        includeThreadId={threadId}
        inboxName={inboxName}
      />
    </div>
  )
}

const MobileInbox = () => {
  return (
    <Routes>
      <Route
        index
        path=":inboxName/new/create"
        element={<InboxCreateNewContact />}
      />
      <Route
        index
        path=":inboxName/new"
        element={<InboxNewThreadContainer />}
      />
      <Route
        index
        path=":inboxName/:threadId/*"
        element={<ThreadContainer />}
      />
      <Route index path=":inboxName" element={<ThreadList />} />
    </Routes>
  )
}

export default MobileInbox
