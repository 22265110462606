/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-shadow */
import { derived } from "overmind";

export const state = {
  // Conversation
  messages: null,
  receiverPhoneNumber: null,
  uiMetadata: {
    addCustomerDeposits: null,
    depositMessageTemplate: null,
    optedOutOfMarketing: null,
    receiverIsMuted: null,
  },
  isLoading: null,
  error: null,

  // Attachments
  attachment: null,
  invoiceAttachment: null,
  invoiceAttachmentTotal: derived(
    (state) => state.invoiceAttachment?.total * 0.01,
    // return state.invoiceAttachment?.reduce((sum, item) => sum + item.amount, 0)
  ),
};
