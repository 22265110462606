import { postMessageToParent } from "./parentWindow";

export const ToastState = Object.freeze({
  SUCCESS: { backgroundColor: "#608b4f" },
  WARNING: { backgroundColor: "#a44039" },
  ERROR: { backgroundColor: "#a44039" },
});

export const postShowToastNotification = (body, state = ToastState.SUCCESS) => {
  const payload = {
    name: "show_toast",
    parameters: {
      body,
      backgroundColor: state.backgroundColor,
    },
  };
  postMessageToParent(payload);
};
