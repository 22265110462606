import React, { useState } from "react"
import Modal from "../components/Modal"
import InboxCreateForm from "./InboxCreateForm"

export const inboxCreateModalId = "inbox-create-modal"

const InboxCreateModal = () => {
  const [values, setValues] = useState({ name: "" })
  return (
    <Modal
      title="Create Inbox"
      id={inboxCreateModalId}
      onHide={() => setValues({ name: "" })}>
      <InboxCreateForm
        values={values}
        onComplete={() =>
          document.getElementById("modal-close-btn-create-modal").click()
        }
      />
      <button
        type="button"
        className="btn-close d-none"
        data-bs-dismiss="modal"
        id="modal-close-btn-create-modal"
      />
    </Modal>
  )
}

export default InboxCreateModal
