import React from "react"
import NpsOverview from "../NpsOverview.jsx"

const NpsDemoOverview = () => {
  const [resp, setResp] = React.useState()

  const get = () => {
    setResp({
      data: {
        current: {
          start_date: "2022-02-28T05:00:00Z",
          end_date: "2022-03-08T04:59:59Z",
          data: {
            0: 1,
            1: 3,
            2: 2,
            3: 0,
            4: 0,
            5: 1,
            6: 0,
            7: 8,
            8: 10,
            9: 15,
            10: 30,
            11: 0,
            12: 0,
          },
          totalSent: 90,
          detractor_total: 3,
          promoter_total: 30,
          neutral_total: 17,
          nps_score: 97,
          detractor_note_count: 0,
          detractor_records: [
            {
              id: "39f3028e-c9f5-4d35-87aa-3ec141b15bd3",
              note: "I scheduled a follow-up service appointment for the customer to further diagnose and address the bike's recurring issues, waiving any service charges as a goodwill gesture.",
              score: 4,
              source: "survey",
              date_sent: "2022-09-14 16:15:41.965221+00:00",
            },
            {
              id: "a564496a-0b19-4bf2-ac94-1bc25bd8f63b",
              note: "The bike purchased by the customer had ongoing issues, particularly with gear shifting, despite our attempts to address them through a tune-up.",
              score: 1,
              source: "survey",
              date_sent: "2022-08-27 00:09:55.286172+00:00",
            },
          ],
          total_replies: 87,
        },
        previous: {
          shop_id: 9,
          start_date: "2022-02-20T05:00:00Z",
          end_date: "2022-02-28T04:59:00Z",
          data: {
            0: 0,
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            6: 0,
            7: 0,
            8: 0,
            9: 0,
            10: 0,
            11: 0,
            12: 0,
          },
          totalSent: 84,
          detractor_total: 0,
          promoter_total: 0,
          neutral_total: 0,
          nps_score: 95,
          detractor_note_count: 0,
          detractor_records: null,
          total_replies: 82,
        },
        ytd: {
          data: {
            0: 0,
            1: 1,
            2: 0,
            3: 0,
            4: 1,
            5: 0,
            6: 0,
            7: 0,
            8: 0,
            9: 1,
            10: 8,
            11: 0,
            12: 0,
          },
          shop_id: 9,
          end_date: "None",
          nps_score: 90,
          totalSent: 0,
          start_date: "None",
          neutral_total: 0,
          total_replies: 11,
          promoter_total: 9,
          detractor_total: 2,
          detractor_records: [],
          detractor_note_count: 1,
        },
      },
    })
  }
  return (
    <div className="mt-3">
      <NpsOverview get={get} resp={resp} />
      <div className="text-center my-4">
        Example Data, go ahead and upgrade 😀
      </div>
    </div>
  )
}

export default NpsDemoOverview
