import React from "react"
import GoogleOAuthButton from "./Settings/GoogleOAuthButton"

const GoogleNoLocationsWarning = ({ hidden }) => {
  if (hidden) return null
  return (
    <div className="card mb-4">
      <div className="card-body d-flex gap-2">
        <i
          className="bi-info-circle-fill text-warning "
          style={{ fontSize: "20px" }}
        />
        <div className="d-flex flex-column gap-3">
          <span>
            <b className="me-1">Heads up!</b>You do not have any Google
            locations. Usually this happens if you have connected the wrong
            account such as a personal Google account instead of your Business
            account. You can optionally reconnect your account.
          </span>
          <div>
            <GoogleOAuthButton />
          </div>
        </div>
      </div>
    </div>
  )
}

export default GoogleNoLocationsWarning
