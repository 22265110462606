import sharedEventManager from "../../../services/events"

export const onInitializeOvermind = ({ actions, state }, instance) => {
  const eventHandler = (eventName, eventPayload) => {
    if (eventName === "conversation-unread-count-change") {
      actions.activeInbox.setConversationUnreadCount(eventPayload.unread_count)
    } else if (eventName === "thread-new-message") {
      console.log(`tmp: ${eventName} eventPayload`, eventPayload)
      actions.activeThread.addNewMessage({
        sid: eventPayload.sid,
        threadId: eventPayload.thread_id,
        receiverId: eventPayload.receiver_id,
        body: eventPayload.body,
        status: eventPayload.status,
        is_from_shop: eventPayload.is_from_shop,
        date_sent: eventPayload.date_sent,
        error_message: eventPayload.error_message,
        meta: eventPayload.meta,
        attachments: eventPayload.attachments,
        reference_id: eventPayload.reference_id,
      })
    } else if (eventName === "thread-updated") {
      actions.activeInbox.updateLocally(eventPayload)
    } else if (eventName === "sms-message-updated") {
      actions.activeThread.updateMessageLocally({
        sid: eventPayload.sid,
        status: eventPayload.status,
        errorMessage: eventPayload.error_message,
      })
    } else if (eventName === "ping") {
      alert("pong")
    } else if (eventName === "inbox-updated") {
      actions.inboxes.updateLocally(eventPayload)
    } else if (eventName === "shop-has-unread-messages-updated") {
      actions.inboxes.refreshLocationHasUnreadMessages(
        eventPayload.unread_count
      )
      actions.browserExtensionEventBus.postUnreadCountChange(
        eventPayload.unread_count
      )
    } else if (eventName === "account_is_current") {
      window.location.reload()
    } else if (eventName === "pos-connected") {
      actions.integrations.get()
    } else if (eventName === "account_status_change") {
      actions.account.updateSetupStatus(eventPayload)
    } else if (eventName === "review-unread-count-change") {
      const eventUserId = state.account.data.user.id
      if (eventUserId === eventPayload.user_id) {
        state.account.data.ui_metadata.unread_review_count = eventPayload.count
      }
    } else if (eventName === "subscription-cancelled") {
      window.location.reload()
    }
  }

  instance.reaction(
    ({ account }) => account.data,
    (account) => {
      if (!account?.shop?.external_id) return
      sharedEventManager.init(account?.shop?.external_id)
      sharedEventManager.bindGlobal(eventHandler)
    }
  )
}
