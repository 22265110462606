import React from "react"
import StripeConnectButton from "../StripeConnectButton"

const PaymentsOauth = ({ data }) => {
  return (
    <div className="d-flex">
      <div style={{ flex: ".7" }}>
        <p>
          By connecting your account you will be able to send your customers an
          invoice they can pay via text messages.
        </p>
        <ul className="pl-2">
          <li>Accept payments without interacting</li>
          <li className="my-2">
            Customers can pay before they get to your store
          </li>
          <li>No setup fees, monthly fees, or hidden fees</li>
        </ul>
        <div className="text-left my-3 card">
          <div className="card-header">Fee</div>
          <div className=" card-body">
            <h3 className="text-left  d-inline">
              {data?.stripe?.application_fee}%
            </h3>
            <span className="mx-2">+</span>
            <span>Stripe Fees</span>
          </div>
        </div>
        <div hidden={!data?.stripe?.connected} className="alert alert-success">
          <i className="bi-check-circle me-1" />
          Your account is connected.
        </div>
        <div>
          <StripeConnectButton treatment={data?.stripe?.connected && "link"} />
        </div>
      </div>
    </div>
  )
}

export default PaymentsOauth
