import React from "react"
import NpsEditNote from "./NpsEditNote"

const NpsManageNote = ({ id, note, score, onChange }) => {
  const [editing, setEditing] = React.useState(false)

  if (editing)
    return (
      <NpsEditNote
        id={id}
        note={note}
        score={score}
        onChange={(newNote) => {
          setEditing(false)
          onChange(newNote)
        }}
        onCancel={() => setEditing(false)}
      />
    )

  return (
    <div>
      <div className="d-flex w-100">
        <div className="fw-semibold">Private Note</div>
        <button
          onClick={() => setEditing(true)}
          className="btn btn-link fs-14 ms-auto p-0"
          type="button">
          {note ? "Edit Note" : "Add Note"}
        </button>
      </div>
      <p>{note}</p>
    </div>
  )
}

export default NpsManageNote
