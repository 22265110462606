import React from "react"

const LocationSettingsFilter = ({ settingsIndex, onChange }) => {
  const [query, setQuery] = React.useState("")

  React.useEffect(() => {
    if (!query?.length && settingsIndex) {
      onChange(settingsIndex)
      return
    }
    const filteredSection = {
      /*
      Example Key/Value

      General: {
        "Store Hours" : {
          path: "store-hours",
          keywords: ["stores hours"]
        }
      }
      */
    }

    for (const settingSection of Object.keys(settingsIndex)) {
      const sectionSettings = settingsIndex[settingSection]
      const filteredItems = {}
      for (const sectionSetting of Object.keys(sectionSettings)) {
        for (const kw of sectionSettings[sectionSetting].keywords) {
          if (kw.includes(query)) {
            filteredItems[sectionSetting] = sectionSettings[sectionSetting]
          }
        }
      }

      if (!Object.keys(filteredItems).length) continue
      filteredSection[settingSection] = filteredItems
    }

    onChange(filteredSection)
  }, [query])

  return (
    <div className="mb-3 me-2">
      <input
        value={query || ""}
        onChange={(e) => {
          setQuery(e.target.value)
        }}
        maxLength={50}
        className="form-control form-control-sm"
        placeholder="Search for a setting..."
        data-lpignore="true"
      />
    </div>
  )
}

export default LocationSettingsFilter
