import React from "react";

const PhoneNumberEligibleInfoCard = ({ hidden }) => {
  return (
    <div hidden={hidden}>
      <p>
        🎉 Good news! Your phone number is eligible for hosting, and now we will
        text-enable your number with Ikeono.
      </p>
      <div className="mt-lg-4 mt-3 p-3 text-light-emphasis bg-light-subtle border border-light-subtle rounded-3">
        <b className="mb-2 d-block">What&lsquo;s next</b>
        <ul className="d-flex flex-column gap-3">
          <li>
            We will send you a Letter of Authorization from HelloSign shortly!
            <img
              alt="example"
              className="w-100 shadow-sm rounded object-fit-cover "
              src="/hellosign-example-1.png"
              style={{ height: "35px" }}
            />
          </li>
          <li>
            After we receive this back signed, we work a little magic on the
            backend. Registering your business with the TCR can take up to 7
            days.
          </li>
          <li>
            Once the account is fully activated, you will receive an email from
            hello@ikeono.com and your account will open up!
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PhoneNumberEligibleInfoCard;
