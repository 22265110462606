import React, { useState, useEffect } from "react"
import SimpleForm from "../components/Form/SimpleForm"
import LabeledField from "../components/Form/LabeledField"
import { useActions } from "../lib/overmind"
import * as api from "../lib/api"
import MergeFieldsTextArea from "../components/Form/MergeFieldsTextArea"
import { CommonMergeFields } from "../components/Form/MergeFields"

const TemplateForm = ({ template, onSuccess }) => {
  const [internalTemplate, setInternalTemplate] = useState(template)
  const actions = useActions().templates

  useEffect(() => {
    setInternalTemplate(template)
  }, [template])

  return (
    <SimpleForm
      resetForm
      initialValues={internalTemplate || { key: "", value: "" }}
      apiSubmitHandler={async (values) => {
        let resp
        if (internalTemplate?.id) {
          resp = await api.put(
            `/shop/{exid}/message/template/${internalTemplate.id}/`,
            values
          )
        } else {
          resp = await api.post("/shop/{exid}/message/template/", values)
        }
        actions.updateOrAdd(resp.data)
        // setInternalTemplate(null)
        onSuccess()
        return resp
      }}>
      <LabeledField
        label="Template Name"
        id="key"
        name="key"
        required
        maxLength={50}
      />
      <MergeFieldsTextArea
        label="Template Body"
        id="value"
        name="value"
        as="textarea"
        required
        rows={5}
        maxLength={1000}
        mergeFields={[CommonMergeFields.firstName, CommonMergeFields.lastName]}
      />
    </SimpleForm>
  )
}

export default TemplateForm
