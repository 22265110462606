import React from "react"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js"
import { Doughnut } from "react-chartjs-2"

ChartJS.register(ArcElement, Tooltip, Legend)

const promoterBgColor = "rgba(75, 192, 192, 0.5)"
const neutralBgColor = "rgba(255, 206, 86, 0.5)"
const detractorBgColor = "rgba(255, 99, 132, 0.5)"

const Label = ({ title, total, subtitle, backgroundColor }) => (
  <div className="d-flex gap-1">
    <div
      style={{
        height: "13px",
        width: "13px",
        borderRadius: "10px",
        backgroundColor,
      }}
    />
    <div>
      <div className="fw-semibold mb-2" style={{ lineHeight: "13px" }}>
        {title}
      </div>
      <div className="fw-bold">
        <span className="h5 fw-semibold">{total}</span>
        <span className="text-secondary ms-2 fw-normal">{subtitle}</span>
      </div>
    </div>
  </div>
)

const emptyPieChartData = {
  labels: [],
  datasets: [
    {
      label: "Total",
      data: [1],
      backgroundColor: ["#f3f4f4"],
      borderColor: ["#f4f5f5"],
      borderWidth: 1,
    },
  ],
}

const TotalRepliesLabel = ({ totalReplies }) => {
  return (
    <div
      className="text-center"
      style={{
        position: "absolute",
        left: "calc(50% - 35px)",
        top: "calc(50% - 30px)",
      }}>
      <div className="fs-14 text-secondary" style={{ lineHeight: "15px" }}>
        Total
        <br />
        Responses
      </div>
      <span className="h2 fw-bold">{totalReplies || 0}</span>
    </div>
  )
}

const NpsScorePieChart = ({
  promoterTotal,
  detractorTotal,
  neutralTotal,
  totalReplies,
}) => {
  const data = {
    labels: [],
    datasets: [
      {
        label: "Total",
        data: [promoterTotal, detractorTotal, neutralTotal],
        backgroundColor: [promoterBgColor, detractorBgColor, neutralBgColor],
        borderColor: [
          "rgba(75, 192, 192, 1)",
          "rgba(255, 99, 132, 1)",
          "rgba(255, 206, 86, 1)",
        ],
        borderWidth: 1,
      },
    ],
  }

  const computedData = totalReplies ? data : emptyPieChartData

  return (
    <div
      className="d-flex justify-content-center align-items-center gap-5"
      style={{ height: "300px" }}>
      <div className="d-flex flex-column gap-5">
        <Label
          title="Promoters"
          total={promoterTotal}
          backgroundColor={promoterBgColor}
        />
        <Label
          title="Neutral"
          total={neutralTotal}
          backgroundColor={neutralBgColor}
        />
        <Label
          title="Detractors"
          total={detractorTotal}
          backgroundColor={detractorBgColor}
        />
      </div>
      <div className="position-relative">
        <Doughnut data={computedData} />
        <TotalRepliesLabel totalReplies={totalReplies} />
      </div>
    </div>
  )
}

export default NpsScorePieChart
