import React from "react";
import CreateInvoiceForm from "../../Invoice/CreateInvoiceForm";

const CreatePaymentRequestForm = ({ hidden, onSuccess }) => {
  if (hidden) return null;
  return (
    <div>
      <p className="mb-3">
        <small>The information will be added to the payment request.</small>
      </p>
      <CreateInvoiceForm hideDepositWarning onSuccess={onSuccess} />
    </div>
  );
};

export default CreatePaymentRequestForm;
