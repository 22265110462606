import React from "react"
import ExternalLink from "../components/ExternalLink"

const ManageSubscriptionInfo = ({ billingPortalUrl }) => {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center mt-5 text-center">
      <div>
        <h5 className="mb-3">Manage my Subscription</h5>
        <p className="text-center" style={{ maxWidth: "50ch" }}>
          To manage your payment method or change your subscription please open
          the billing portal
        </p>
        <ExternalLink href={billingPortalUrl} className="btn btn-primary">
          Open Billing Portal
        </ExternalLink>
      </div>
    </div>
  )
}

export default ManageSubscriptionInfo
