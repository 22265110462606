import React from "react"
import { NavLink } from "react-router-dom"

const MainMenuNavItem = ({
  to,
  hidden,
  className,
  children,
  showNotificationBadge = false,
}) => {
  if (hidden) return null
  return (
    <li className={`nav-item mx-1 ${className}`}>
      <NavLink
        className={({ isActive }) =>
          `nav-link ${isActive ? "active" : "text-white"}`
        }
        to={to}>
        {({ isActive }) => {
          return (
            <>
              <span className="d-flex align-items-center gap-2">
                {children}{" "}
                <i
                  className="bi-circle-fill text-primary"
                  style={{
                    fontSize: "8px",
                    display: showNotificationBadge ? "block" : "none",
                  }}
                />
              </span>

              <div className="nav-link-active" hidden={!isActive} />
            </>
          )
        }}
      </NavLink>
    </li>
  )
}

export default MainMenuNavItem
