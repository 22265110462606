import React from "react"
import LabeledField from "../components/Form/LabeledField"
import SimpleForm from "../components/Form/SimpleForm"
import { post } from "../lib/api"

const ChangePasswordForm = () => {
  return (
    <SimpleForm
      apiSubmitHandler={async (values) => {
        await post("/change-password", values)
      }}
      initialValues={{}}>
      <LabeledField
        label="Current password"
        name="current_password"
        type="password"
        className="form-control"
        required
      />
      <LabeledField
        label="New password"
        name="new_password"
        type="password"
        className="form-control"
        minLength={10}
        required
      />
    </SimpleForm>
  )
}

export default ChangePasswordForm
