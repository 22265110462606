import React from "react"
import LocationSelectDropDown from "."
import { useActions, useState } from "../lib/overmind"

const ManagedLocationSelectDropdown = ({ onSelect }) => {
  const actions = useActions().location
  const state = useState().location
  const accountState = useState().account
  const [isLoadingUnreadCount, setIsLoadingUnreadCount] = React.useState(false)

  const selectedId = accountState.shopExternalId
  let { shopName } = accountState
  if (selectedId && state.indexedLocations) {
    const location = state.indexedLocations[selectedId]
    shopName = location?.name.substring(0, 40) || accountState.shopName
  }

  return (
    <LocationSelectDropDown
      onShown={async () => {
        actions.get()
        setIsLoadingUnreadCount(true)
        try {
          await actions.get({ includeUnreadCounts: true })
        } catch (e) {
          console.log(e)
        }
        setIsLoadingUnreadCount(false)
      }}
      error={state.apiError}
      isLoading={state.isLoading}
      locations={state.locations}
      title={shopName}
      selectedId={selectedId}
      onUpdateFilter={actions.updateFilter}
      onSelect={onSelect}
      isLoadingUnreadCount={isLoadingUnreadCount}
    />
  )
}

export default ManagedLocationSelectDropdown
