import React from "react"
import TemplateRow from "../../../Templates/TemplateRow"
import { reportError } from "../../errorHandler"

const buildDataTable = (templates) => {
  const rows = templates?.map((t) => ({
    id: t.id,
    columns: [
      {
        value: () => <TemplateRow title={t.key} body={t.value} />,
        type: "function",
      },
    ],
  }))

  return {
    headers: [],
    rows,
  }
}

export const get = async ({ state, effects }, { shopId }) => {
  if (state.templates.isLoading || state.templates.apiData) return
  state.templates.responseError = null
  state.templates.isLoading = true
  try {
    const data = await effects.templates.get(shopId)
    state.templates.tableData = buildDataTable(data)
    state.templates.apiData = data
    state.templates.templates = data
  } catch (e) {
    reportError(e)
    state.templates.responseError = e
  }

  state.templates.isLoading = false
}

export const setSelectedEditRecordId = ({ state }, id) => {
  state.templates.selectedEditRecordId = id
}

export const updateOrAdd = ({ state }, template) => {
  const idx = state.templates.apiData?.findIndex((c) => c.id === template.id)
  if (idx < 0) {
    if (!state.templates.apiData?.length) {
      state.templates.apiData = [template]
    } else {
      state.templates.apiData.unshift(template)
    }
  } else {
    state.templates.apiData[idx] = template
  }
  state.templates.tableData = buildDataTable(state.templates.apiData)
}

export const deleteSelectedEditRecord = ({ state, effects }) => {
  state.templates.apiData = state.templates.apiData.filter(
    (c) => c.id !== state.templates.selectedEditRecordId
  )
  state.templates.tableData = buildDataTable(state.templates.apiData)
  effects.templates.delete_(state.templates.selectedEditRecordId)
  state.templates.selectedEditRecordId = null
}

export const onInitializeOvermind = ({ actions, state }, instance) => {
  instance.reaction(
    ({ account }) => account.data,
    () => {
      state.templates.templates = null
      state.templates.apiData = null
      state.templates.tableData = null
      state.templates.responseError = null
    }
  )
}

export const updateFilter = ({ state }, filter) => {
  const filteredTemplates = state.templates.apiData?.filter(
    (template) =>
      template.key.includes(filter) || template.value.includes(filter)
  )
  state.templates.templates = filteredTemplates
}
