import React from "react";
import ExternalLink from "../components/ExternalLink";
import UpgradeAccountButtonV2 from "../components/UpgradeAccountButtonV2";

const DiscoveredFeatureUpgrade = ({
  plan,
  featureName,
  redirectPath,
  children,
}) => {
  return (
    <div className="card" style={{ maxWidth: "900px" }}>
      <div
        className="card-header bg-black text-white fw-bolder h5 d-flex gap-2 py-3 text-center align-items-center justify-content-center text-uppercase"
        style={{ letterSpacing: "3px" }}
      >
        <img src="/sparkle-left.svg" height="25px" />
        {plan?.name} feature
        <img src="/sparkle-right.svg" height="25px" />
      </div>
      <div className="d-flex">
        <div className="flex-grow-1 p-5 w-50">
          <h5 className="text-nowrap fw-bold">
            You discovered a premium feature!
          </h5>
          {children}
        </div>
        <div className="flex-grow-1 bg-light d-flex justify-content-center align-items center w-50">
          <div className="d-flex flex-column gap-2 justify-content-center align-items-center w-100">
            <h5>Premium</h5>
            <h2>$120/month</h2>
            <div className="mt-2">
              <UpgradeAccountButtonV2
                featureName={featureName}
                plan={plan}
                redirectPath={redirectPath}
              />
            </div>
            <ExternalLink
              className="text-black mt-3"
              href="https://www.ikeono.com/pricing"
            >
              See what else you get for premium
            </ExternalLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiscoveredFeatureUpgrade;
