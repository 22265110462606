import React from "react";
import { useNavigate } from "react-router-dom";
import { useActions } from "../../lib/overmind";

const CloseButton = () => {
  const navigate = useNavigate();
  const actions = useActions().threadCreateContacts;

  return (
    <span className="text-end fw-bold">
      <i
        role="button"
        className="bi bi-x-lg"
        onClick={() => {
          actions.clearSearch();
          navigate(-1);
        }}
      />
    </span>
  );
};

export default CloseButton;
