import React from "react"
import { NavLink } from "react-router-dom"
import InboxFolderIcon from "./InboxFolderIcon"
import { useMixpanelTrackingRef } from "../services/mixpanel"

const InboxListItem = ({
  slug,
  hasUnreadMessages,
  inboxEditModalId,
  title,
  children,
}) => {
  const trackingRef = useMixpanelTrackingRef("click inbox row", {
    slug,
    title,
    hasUnreadMessages,
  })

  return (
    <NavLink
      ref={trackingRef}
      style={{ paddingTop: "7px", paddingBottom: "7px" }}
      className={({ isActive }) => {
        return isActive
          ? "nav-link inbox-list-item-active fs-15 bg-dark"
          : "nav-link text-dark fs-15"
      }}
      to={`/inbox/${slug}`}>
      {({ isActive }) => (
        <div className="d-flex text-capitalize">
          <InboxFolderIcon hasUnreadMessages={hasUnreadMessages} />
          <span className="w-100 ms-2">
            {children ? children({ isActive }) : title}
          </span>
          <span
            role="button"
            hidden={!isActive || !inboxEditModalId || title === "Web Leads"}
            data-bs-toggle="modal"
            data-bs-target={`#${inboxEditModalId}`}
            className="text-white bg-primary-emphasis p-0  ms-auto"
            onClick={(e) => {
              e.preventDefault()
            }}>
            <i className="bi-three-dots px-1 inbox-list-item-action-btn rounded" />
          </span>
        </div>
      )}
    </NavLink>
  )
}

export default InboxListItem
