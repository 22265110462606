import React from "react";
import { Route, Routes, useParams } from "react-router-dom";
import SectionHeader from "../../components/SectionHeader";
import PaymentsOauth from "../../Payments/Settings/PaymentsOauth";
import PaymentsSettingsForm from "../../Payments/Settings/PaymentsSettingsForm";
import PaymentsLightspeedRSeriesDepositForm from "../../Payments/Settings/PaymentsLightspeedRSeriesDepositForm";
import { usePosConnectedEvent } from "../../lib/pos";
import APIResponseError from "../../components/APIResponseError";
import Loader from "../../components/Loader";
import * as api from "../../lib/api";

const LocationPaymentsSettings = () => {
  const [data, setData] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const get = async () => {
    try {
      setIsLoading(true);
      const response = await api.get("/shop/{exid}/invoice/settings");
      setData(response.data);
      setIsLoading(false);
    } catch (e) {
      setError(e);
    }
  };

  usePosConnectedEvent(() => {
    get();
  });

  React.useEffect(() => {
    get();
  }, []);

  if (error) {
    return <APIResponseError response={error.response} />;
  }

  if (isLoading || !data) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  const params = useParams();
  return (
    <>
      <SectionHeader className="text-capitalize mb-4">
        {params["*"].replaceAll("-", " ")}
      </SectionHeader>
      <Routes>
        <Route path="connection" element={<PaymentsOauth data={data} />} />
        <Route
          path="general"
          element={<PaymentsSettingsForm settings={data.settings} />}
        />
        <Route
          path="deposits"
          element={
            <PaymentsLightspeedRSeriesDepositForm
              settings={data.settings}
              registers={data.pos_data?.registers}
              paymentTypes={data.pos_data?.payment_types}
              posDataError={data.pos_data_error}
              employees={data.pos_data?.employees}
            />
          }
        />
      </Routes>
    </>
  );
};

export default LocationPaymentsSettings;
