/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow */
import { derived } from "overmind";

export const state = {
  /* start DataTable interface */
  tableData: null,
  isLoading: false,
  responseError: null,
  /* end */

  apiData: null,
  selectedEditRecordId: null,
  selectedEditRecord: derived((state) => {
    if (!state.selectedEditRecordId) return null;
    return state.apiData?.find((c) => c.id === state.selectedEditRecordId);
  }),
};
