/* eslint-disable no-shadow */
import { derived } from "overmind"
import { Env, EnvEnum } from "../../environment"

export const state = {
  data: null,
  isLoading: true,
  accountLoadError: null,
  shopExternalId: derived((state) => state.data?.shop?.external_id),
  shopName: derived((state) => state.data?.shop?.name),
  pendingAccountVerification: derived(
    (state) => state.data?.shop?.pending_account_verification
  ),
  incompleteProfile: derived((state) => state.data?.shop?.incomplete_profile),
  acceptedToS: derived((state) => state.data?.user?.accepted_tos),
  accountId: derived((state) => state.data?.account.exid),
  customerDepositsEnabled: derived(
    (state) => state.data?.config.portal.payments.customer_deposits_enabled
  ),
  userName: derived((state) => {
    const firstName = state.data?.user.first_name || ""
    const lastName = state.data?.user.last_name || ""
    return `${firstName} ${lastName}`.trim()
  }),
  country: derived((state) => state.data?.shop.country),
  userEmail: derived((state) => state.data?.user.email),
  daysLeftOnTrial: derived((state) => state.data?.days_left_on_trial),
  isTrialExpired: derived((state) => state.data?.is_trial_expired),
  isPastDue: derived((state) => state.data?.account.is_past_due),
  isReadonly: derived((state) => state.data?.user?.is_readonly),
  posSystem: derived((state) => state.data?.shop?.pos_system),
  accountMessage: derived(
    (state) => state.data?.ui_metadata?.account_ui_message
  ),
  accountLockedMessage: derived(
    (state) => state.data?.account?.account_locked_reason
  ),
  paymentsConfigured: derived(
    (state) => state.data?.config.portal.payments.is_stripe_connected
  ),
  posCustomerDepositsEnabled: derived(
    (state) => state.data?.config.portal.payments.customer_deposits_enabled
  ),
  isIkeonoAccount: derived((state) => {
    return Env === EnvEnum.production && state.accountId === 214
  }),
}
