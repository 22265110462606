import React from "react";

const InvoiceRow = ({
  idx,
  amount,
  description,
  onAdd,
  onRemove,
  onChange,
  addDisabled,
}) => (
  <div className="d-flex flex-row gap-2 align-items-end">
    <div className="flex-fill">
      <label
        hidden={idx !== 0}
        htmlFor="exampleFormControlInput1"
        className="form-label fw-bold"
      >
        Description
      </label>
      <input
        className="form-control border-dark"
        id={`description-${idx}`}
        name={`description-${idx}`}
        value={description}
        required
        style={{ flex: 1 }}
        // groupClassName="flex-fill"
        onChange={(e) => onChange(idx, { description: e.target.value })}
      />
    </div>
    <div>
      <label
        hidden={idx !== 0}
        htmlFor="exampleFormControlInput1"
        className="form-label fw-bold"
      >
        Amount
      </label>
      <input
        className="form-control border-dark"
        id={`amount-${idx}`}
        name={`amount-${idx}`}
        type="number"
        step=".01"
        min="1.00"
        value={amount}
        required
        style={{ maxWidth: "120px" }}
        onChange={(e) => onChange(idx, { amount: parseFloat(e.target.value) })}
      />
    </div>
    <div className="d-flex gap-2 d-none">
      <button
        disabled={addDisabled}
        className="btn btn-link text-bg-light"
        type="button"
        onClick={() => onAdd(idx + 1)}
      >
        <i className="bi-plus-lg" />
      </button>
      <button
        className="btn btn-link text-secondary p-0"
        type="button"
        onClick={() => onRemove(idx)}
      >
        <i className="bi-trash" />
      </button>
    </div>
  </div>
);
export default InvoiceRow;
