import React from "react"
import { Route, Routes, useParams } from "react-router-dom"
import LightspeedStoreAssignment from "../../components/Lightspeed/LightspeedStoreAssignment"
import SectionHeader from "../../components/SectionHeader"
import VendReceiptForm from "../../components/Vend/VendReceiptForm"
import LightspeedConnectionCheck from "../../components/Lightspeed/LightspeedConnectionCheck"

const LocationVendSettings = () => {
  const params = useParams()

  return (
    <LightspeedConnectionCheck useVend>
      <SectionHeader className="text-capitalize mb-4">
        {params["*"].replaceAll("-", " ")}
      </SectionHeader>
      <Routes>
        <Route
          path="store-assignment"
          element={<LightspeedStoreAssignment />}
        />
        <Route path="receipt-template" element={<VendReceiptForm />} />
      </Routes>
    </LightspeedConnectionCheck>
  )
}

export default LocationVendSettings
