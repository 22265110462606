import React from "react";

const SettingsCard = ({ title, children, footerChildren, hidden }) => {
  if (hidden) return null;
  return (
    <div className="card border-secondary mb-4">
      <div
        className="card-header bg-transparent border-secondary"
        style={{ fontWeight: "600" }}
      >
        {title}
      </div>
      <div className="card-body">{children}</div>
      {footerChildren && (
        <div className="card-footer bg-transparent border-secondary text-end">
          {footerChildren}
        </div>
      )}
    </div>
  );
};

export default SettingsCard;
