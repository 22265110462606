import React, { useState } from "react";
import * as api from "../../lib/api";
import { reportError } from "../../lib/errorHandler";
import APIResponseError from "../APIResponseError";
import Button, { ButtonSize } from "../Button";
import SettingsCard from "../Settings/SettingsCard";

function EcwidOauth() {
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();
  const [isConnected, setIsConnected] = useState();

  const onClick = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const { data } = await api.get("/ecwid/oauth-install?shop_id={exid}");
      if (data.error) {
        setIsConnected(data.error);
      } else {
        window.location.href = data.url;
      }
    } catch (e) {
      reportError(e);
      setError(e);
    }
    setIsLoading(false);
  };

  return (
    <SettingsCard title="Connect your Ecwid Account">
      <p>Adds a new payment type to collect Stripe Secure payments.</p>
      <Button
        onClick={onClick}
        showLoader={isLoading}
        className="shadow my-4 d-flex align-items-center"
        size={ButtonSize.medium}
        style={{ backgroundColor: "#000", border: "none", fontWeight: "500" }}
      >
        Connect Ecwid
      </Button>
      {isConnected && <span className="text-success">{isConnected}</span>}
      {error && <APIResponseError response={error.response} />}
    </SettingsCard>
  );
}

export default EcwidOauth;
