import React from "react";
import { useActions, useState } from "../../lib/overmind";
import ThreadActionItem from "./ThreadActionItem";

const MuteActionItem = ({ hidden }) => {
  if (hidden) return null;
  const state = useState().activeThread;
  const actions = useActions().activeThread;

  const isMuted = state.detail?.receiverIsMuted;
  const title = isMuted ? "Unmute" : "Mute";
  const icon = isMuted ? "bi-bell" : "bi-bell-slash";
  const description = isMuted
    ? "Start receiving messages from this number."
    : "Stop receiving messages from this number.";

  return (
    <ThreadActionItem
      onClick={actions.toggleMute}
      title={title}
      description={description}
      iconName={icon}
    />
  );
};

export default MuteActionItem;
