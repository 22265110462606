/* eslint-disable no-shadow */
import React, { useEffect, useState } from "react";
import * as overmind from "../lib/overmind";
import { post } from "../lib/api";
import APIResponseError from "../components/APIResponseError";
import InvoiceRow from "./InvoiceRow";
import TotalRow from "./TotalRow";
import PosDepositConfirmation from "./PosDepositConfirmation";
import NoDepositWarning from "./NoDepositWarning";
import Button from "../components/Button";
import InvoiceSettingsWarning from "./InvoiceSettingsWarning";

const CreateInvoiceForm = ({
  initialLineItems,
  posDeposit,
  hideDepositWarning,
  onSuccess,
}) => {
  const reaction = overmind.useReaction();
  const [lineItems, setLineItems] = useState([{ amount: "", description: "" }]);
  const [force, setForce] = useState(true);
  const state = overmind.useState();
  const [error, setError] = useState(null);
  const [addDeposit, setAddDeposit] = useState(false);
  const [confirmNoDeposit, setConfirmNoDeposit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showInvoiceSettingsWarning, setShowInvoiceSettingsWarning] =
    useState(false);
  const noDepositWarningHidden =
    !state.account.posCustomerDepositsEnabled ||
    !!posDeposit ||
    hideDepositWarning;

  const reset = () => {
    setLineItems([{ amount: "", description: "" }]);
    setAddDeposit(false);
    setConfirmNoDeposit(false);
  };

  useEffect(() => {
    if (initialLineItems && initialLineItems.length) {
      setLineItems(initialLineItems);
    } else {
      reset();
    }
    setAddDeposit(!!posDeposit && state.account.customerDepositsEnabled);
  }, [initialLineItems, posDeposit]);

  // Reset component state
  useEffect(() =>
    reaction(({ activeThread }) => activeThread.conversationId, reset)
  );

  const computeTotal = () => {
    return lineItems.reduce((sum, item) => sum + item.amount, 0);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setShowInvoiceSettingsWarning(false);
    if (!noDepositWarningHidden && !confirmNoDeposit) {
      alert("Please confirm a deposit will not be added to the customer.");
      return;
    }

    setError(null);
    setIsLoading(true);
    try {
      const customerPhone =
        state.activeThread.detail?.threadType === "sms"
          ? state.activeThread.detail?.receiverId
          : null;
      const data = {
        customer_phone: customerPhone,
        line_items: lineItems,
        customer_name: `${state.activeThread.contact?.first_name || ""} ${
          state.activeThread.contact?.last_name || ""
        }`,
        pos_deposit: posDeposit,
        screen_context: window.location.pathname,
      };

      if (posDeposit) {
        data.pos_deposit = {
          primary_id: posDeposit.primaryId,
          primary_type: posDeposit.primaryType,
          customer_id: posDeposit.customerId,
          employee_id: posDeposit.employeeId,
          add_customer_deposit_when_paid: addDeposit,
          screen_context: window.location.pathname,
        };
      }

      try {
        const response = await post("/shop/{exid}/invoice-v2", data);
        onSuccess(response.data);
        // '/* This close button is a workaround. For some reason programatically trying to close the modal fails.
        document.getElementById("modal-close-btn").click();
      } catch (error) {
        if (error.response?.data?.detail?.includes("336")) {
          setShowInvoiceSettingsWarning(true);
        } else {
          throw error;
        }
      }
    } catch (error) {
      setError(error);
    }
    setIsLoading(false);
  };

  return (
    <div>
      <form id="payment-request-form" onSubmit={onSubmit}>
        <div className="d-flex gap-2 flex-column">
          {lineItems.map((item, key) => {
            return (
              <InvoiceRow
                idx={key}
                key={key}
                amount={item.amount}
                description={item.description}
                addDisabled={key === 4}
                onAdd={(nextIdx) => {
                  if (lineItems.length >= 5) return;
                  lineItems.splice(nextIdx, 0, { amount: "", description: "" });
                  setForce(!force);
                }}
                onRemove={(idx) => {
                  lineItems.splice(idx, 1);
                  if (!lineItems.length) {
                    lineItems.push({ amount: "", description: "" });
                  }
                  setForce(!force);
                }}
                onChange={(idx, value) => {
                  lineItems[idx] = { ...lineItems[idx], ...value };
                  setForce(!force);
                }}
              />
            );
          })}
        </div>
      </form>
      <TotalRow total={computeTotal()} />
      <PosDepositConfirmation
        deposit={posDeposit}
        addDeposit={addDeposit}
        onChange={(checked) => setAddDeposit(checked)}
      />
      <NoDepositWarning
        hidden={noDepositWarningHidden}
        className="px-2"
        value={confirmNoDeposit}
        onConfirmationChange={(val) => setConfirmNoDeposit(val)}
      />
      <InvoiceSettingsWarning hidden={!showInvoiceSettingsWarning} />
      <APIResponseError response={error} />
      <div className="text-end mt-3">
        <Button form="payment-request-form" showLoader={isLoading}>
          Create payment link
        </Button>
      </div>
    </div>
  );
};

export default CreateInvoiceForm;
