import React, { useEffect } from "react"
import moment from "moment"
import { useGet } from "../../lib/api"
import NpsDashboard from "./NpsDashboard"
import Loader from "../../components/Loader"
import APIResponseError from "../../components/APIResponseError"
import { formatDate, toISOString } from "../../lib/date"
import { useState } from "../../lib/overmind"
import InlineStartEndDateForm from "../../components/Form/InlineStartEndDateForm"

const NpsOverview = ({ get, isLoading, error, resp }) => {
  const state = useState().account

  const startDate = moment().subtract(7, "days")
  const endDate = moment()

  useEffect(() => {
    get("/shop/{exid}/nps/report", {
      start_date: startDate.toISOString(),
      end_date: endDate.toISOString(),
    })
  }, [])

  return (
    <div>
      <div className="d-flex mb-3 align-items-center">
        <h5 className="fw-semibold  me-auto">
          Nps Overview{" "}
          <span className="h6 text-secondary">− {state.shopName}</span>
        </h5>
        <InlineStartEndDateForm
          apiSubmitHandler={(data) => {
            get("/shop/{exid}/nps/report", {
              start_date: toISOString(data.start_date),
              end_date: toISOString(data.end_date),
            })
          }}
          initialValues={{
            start_date: formatDate(startDate, "YYYY-MM-DD"),
            end_date: formatDate(endDate, "YYYY-MM-DD"),
          }}
        />
      </div>
      <Loader hidden={!isLoading} />
      <APIResponseError response={error} />
      <NpsDashboard data={resp?.data} />
    </div>
  )
}

export default NpsOverview

export const NpsLiveOverview = () => {
  const [get, isLoading, error, resp] = useGet()

  return (
    <NpsOverview get={get} isLoading={isLoading} error={error} resp={resp} />
  )
}
