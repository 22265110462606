import React from "react"
import { useActions, useState } from "../lib/overmind"

const InboxRowSelect = ({ threadId }) => {
  const state = useState().activeInbox
  const actions = useActions().activeInbox

  const onClick = (e) => {
    actions.toggleSelectThread(threadId)
    e.stopPropagation()
  }

  return (
    <input
      checked={state.selectedThreads.has(threadId)}
      hidden={!state.multiSelectEnabled}
      type="checkbox"
      onClick={onClick}
      className="mb-2"
    />
  )
}

export default InboxRowSelect
