import React from "react";
import SettingsCard from "../Settings/SettingsCard";
import VendConnectButton from "./VendConnectButton";

const VendOauth = () => (
  <SettingsCard title="Connect your Lightspeed X-Series Account (formerly Vend)">
    <p>
      Send your current customeres messages, invoice links, automated messages
      and more when you connect Lightspeed X-Series.
    </p>
    <VendConnectButton />
  </SettingsCard>
);

export default VendOauth;
