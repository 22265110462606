import React from "react";
import { SupportAnchor } from "../lib/ikeono";
import { useState } from "../lib/overmind";
import SimpleHeaderBodyBanner from "./SimpleHeaderBodyBanner";

const UserPermissionCheck = ({ children, permissionKey }) => {
  const userPermissions = useState().account?.data?.user?.permissions;
  if (!userPermissions)
    return (
      <div>
        Unable to validate user perfmissions. Contact support. <SupportAnchor />
      </div>
    );
  if (!permissionKey) {
    throw new Error("permission key is required");
  }
  if (!Object.hasOwn(userPermissions, permissionKey)) {
    throw new Error(`Invalid permissin key: ${permissionKey}`);
  }

  if (!userPermissions[permissionKey]) {
    return (
      <SimpleHeaderBodyBanner
        title="You do not have access to this feature."
        body="Pleae contact the owner of this account to give you access."
      />
    );
  }

  return children;
};

export default UserPermissionCheck;
