import React from "react";
import { firstCharacter } from "../lib/stringUtil";

const colorTable = {
  a: "--bs-primary",
  b: "--bs-success",
  c: "--bs-danger",
  d: "--bs-warning",
  e: "--bs-info",
  f: "--bs-success-border-subtle",
  g: "--bs-primary-bg-subtle",
  h: "--bs-primary-border-subtle",
  i: "--bs-success",
  j: "--bs-warning-bg-subtle",
  k: "--bs-info-text",
  l: "--bs-warning",
  m: "--bs-primary",
  n: "--bs-success-border-subtle",
  o: "--bs-danger-border-subtle",
  p: "--bs-info-border-subtle",
  q: "--bs-dark-border-subtle",
  r: "--bs-primary-bg-subtle",
  s: "--bs-warning-border-subtle",
  t: "--bs-primary",
  u: "--bs-danger-text",
  v: "--bs-success-bg-subtle",
  w: "--bs-warning-bg-subtle",
  x: "--bs-info-bg-subtle",
  y: "--bs-success-text",
  z: "--bs-danger-bg-subtle",
  default: "--bs-primary",
};

const getColor = (v) => {
  if (!v) return colorTable.default;
  const firstLetter = v.substring(0, 1).toLowerCase();
  const color = colorTable[firstLetter];
  return `var(${color || colorTable.default})`;
};

const FirstLetterBadge = ({ children, style }) => {
  return (
    <div
      className="text-white d-flex justify-content-center align-items-center text-capitalize"
      style={{
        flexShrink: 0,
        fontSize: "13px",
        fontWeight: 900,
        width: "25px",
        height: "25px",
        backgroundColor: getColor(children),
        borderRadius: "5px",
        ...style,
      }}
    >
      {firstCharacter(children).toUpperCase()}
    </div>
  );
};

export default FirstLetterBadge;
