import React from "react"
import { Field } from "formik"
import SimpleForm from "../../components/Form/SimpleForm"
import { put } from "../../lib/api"
import Button from "../../components/Button"
import FieldsetReadonlyManager from "../../components/FieldsetReadonlyManager"
import { useState } from "../../lib/overmind"

const ReplyForm = ({ body, reviewId, hidden, onSuccess }) => {
  const { account } = useState()
  const [isLoading, setIsLoading] = React.useState(false)

  const apiSubmitHandler = async (data) => {
    setIsLoading(true)
    try {
      data.review_id = reviewId
      await put(`/account/{account_id}/reviews/reply`, data)
      onSuccess({
        body: data.body,
      })
    } finally {
      setIsLoading(false)
    }
  }

  if (hidden) return null
  return (
    <SimpleForm
      initialValues={{ body }}
      hideSubmitButton
      apiSubmitHandler={apiSubmitHandler}>
      <FieldsetReadonlyManager
        disabledTooltipTitle="You are unable to reply to or edit reviews/replies. Please contact your account owner."
        disabled={!account.data.user.permissions.can_edit_reviews}>
        <div className="d-flex gap-3">
          <Field
            id="body"
            name="body"
            placeholder="Reply to this review"
            className="form-control"
          />
          <Button
            className="btn btn-primary"
            type="submit"
            showLoader={isLoading}>
            Reply
          </Button>
        </div>
      </FieldsetReadonlyManager>
    </SimpleForm>
  )
}

export default ReplyForm
