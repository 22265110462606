import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import OffCanvas, {
  hideOffcanvas,
  toggleOffcanvas,
} from "../components/OffCanvas"
import * as overmind from "../lib/overmind"
import UserDetailFooter from "./UserDetailFooter"
import UserDetailForm from "./UserDetailForm"
import OffcanvasSection from "../components/OffCanvas/OffCanvasSection"
import UserChangePassword from "./UserChangePassword"

const getFormDataFromUserDetail = (userDetail) => {
  const formData = {
    ...userDetail,
  }

  // Set locations
  if (userDetail) {
    userDetail.employee_at_shops.forEach((location) => {
      formData[`shop_${location.id}`] = true
    })
  }

  return formData
}

const UserDetail = () => {
  const { id } = useParams()
  const actions = overmind.useActions().users
  const state = overmind.useState().users
  const [formData, setFormData] = useState()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState()

  useEffect(() => {
    if (!state.data) return // Users haven't loaded yet
    try {
      const userDetail = actions.getDetail(id)
      setFormData(getFormDataFromUserDetail(userDetail))
    } catch (error) {
      setFormData({ email: "", first_name: "", last_name: "" })
      navigate("/settings/users") // Invalid user id; go home
    }
  }, [id, state.data])

  useEffect(() => {
    setTimeout(() => {
      try {
        actions.getDetail(id)
        toggleOffcanvas("user-detail-offcanvas")
      } catch (error) {
        console.log(error)
      }
    }, 170)
  }, [])

  const apiSubmitHandler = async (data) => {
    setIsLoading(true)
    try {
      if (id) {
        await actions.update({ userId: id, user: data })
      } else {
        await actions.create({ user: data })
        hideOffcanvas("user-detail-offcanvas")
      }
    } catch (error) {
      setIsLoading(false)
      throw error
    }
    setIsLoading(false)
  }

  if (!state.data) return null

  return (
    <OffCanvas
      datatarget="user-detail-offcanvas"
      title="User Detail"
      bodyClass="p-0 mb-5">
      <OffcanvasSection hidden={!id}>
        <UserChangePassword userId={id} />
      </OffcanvasSection>
      <div className="mx-3 mb-4">
        <UserDetailForm
          formData={formData}
          apiSubmitHandler={apiSubmitHandler}
          shops={state.shops}
        />
      </div>

      <UserDetailFooter
        isLoading={isLoading}
        formId="user-detail-form"
        hideDeleteButton={!id}
        onDelete={async () => {
          await actions.delete_(id)
          navigate("/settings/users")
        }}
      />
    </OffCanvas>
  )
}

export default UserDetail
