import React from "react"
import { CountryDropdown as CountryDropDownSelector } from "react-country-region-selector"
import { useFormikContext } from "formik"
import StandardFormGroup from "./StandardFormGroup"

export const supportedCountries = {
  "United States": "US",
  Canada: "CA",
  Australia: "AU",
}

export const countryCodeMap = {
  US: "United States",
  CA: "Canada",
  AU: "Australia",
  ...supportedCountries,
}

const CountryDropDown = () => {
  const { values, setFieldValue } = useFormikContext()

  return (
    <StandardFormGroup label="Country">
      <CountryDropDownSelector
        id="countryControl"
        name="country_code"
        className="form-control form-control-sm"
        required
        value={countryCodeMap[values?.country_code]}
        whitelist={Object.values(supportedCountries)}
        onChange={(newCountry) => {
          setFieldValue("country_code", supportedCountries[newCountry])
        }}
      />
    </StandardFormGroup>
  )
}

export default CountryDropDown
