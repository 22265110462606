import { useFormikContext } from "formik"
import React, { useEffect, useState } from "react"
import * as url from "../../lib/url"

const defaultBodyWithoutReviewLink =
  "Thank you for shopping with us at {shop}!  If you have a minute, please reply here with any feedback you have."
const defaultBodyWithGoogleReviewLink =
  "Thank you for shopping with us at {shop}!  If you have a minute, it would really help us out if you could leave a review on Google!"
const defaultBodyWithFacebookReviewLink =
  "Thank you for shopping with us at {shop}!  If you have a minute, it would really help us out if you could leave a review on Facebook!"
const defaultBodyWithReviewLink =
  "Thank you for shopping with us at {shop}!  If you have a minute, it would really help us out if you could leave a review!"

const DefaultReviewSettingsManager = () => {
  const { setFieldValue, values } = useFormikContext()
  const [lastSeenReviewLink, setLastSeenReviewLink] = useState(null)

  const possibleMessages = [
    defaultBodyWithGoogleReviewLink,
    defaultBodyWithReviewLink,
    defaultBodyWithoutReviewLink,
    defaultBodyWithFacebookReviewLink,
  ]

  useEffect(() => {
    if (!values) return
    if (values.review_link === lastSeenReviewLink) return
    if (values.body && !possibleMessages.includes(values.body)) return // User changed the form. It's in their hands now

    if (!values.review_link) {
      setFieldValue("body", defaultBodyWithoutReviewLink)
    }
    if (values.review_link) {
      let body = defaultBodyWithReviewLink
      if (url.containsGoogleUrl(values.review_link)) {
        body = defaultBodyWithGoogleReviewLink
      } else if (url.containsFacebookUrl(values.review_link)) {
        body = defaultBodyWithFacebookReviewLink
      }

      setFieldValue("body", body)
    }
    setLastSeenReviewLink(values.review_link)
  }, [values])

  return null
}

export default DefaultReviewSettingsManager
