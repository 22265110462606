import React from "react"

const ReviewLinkDescription = ({ body }) => {
  return (
    <span>
      {body ||
        "Your review link will automatically be added at the end of the message as a best practice."}
      <a
        href="https://support.google.com/business/answer/3474122?hl=en"
        target="_,blank"
        rel="noreferrer">
        {" "}
        Find my link
      </a>
    </span>
  )
}

export default ReviewLinkDescription
