import React from "react"
import { useLocation, useNavigate } from "react-router-dom"
import ThreadActionItem from "./ThreadActionItem"

const EditThreadActionItem = ({ hidden }) => {
  if (hidden) return null
  const location = useLocation()
  const navigate = useNavigate()

  return (
    <ThreadActionItem
      title="Open contact"
      description="Open contact details"
      iconName="bi-pencil"
      buttonProps={{
        onClick: () => {
          navigate(`${location.pathname}/detail`)
        },
      }}
    />
  )
}

export default EditThreadActionItem
