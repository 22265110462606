import React from "react"
import LabeledField from "./LabeledField"

const makeFieldName = (storeId) => `ik_store_id__${storeId}`

const Row = ({ ikeonoStore, externalStores }) => {
  return (
    <>
      <label
        className="d-flex align-items-center"
        htmlFor={makeFieldName(ikeonoStore.id)}>
        <h6 className="mb-0 text-nowrap">
          <i className="bi-building me-1 fs-14" />
          {ikeonoStore.name}
        </h6>
      </label>
      <LabeledField
        className="form-select form-select-sm w-auto"
        as="select"
        id={makeFieldName(ikeonoStore.id)}
        name={makeFieldName(ikeonoStore.id)}
        data-ik-store-id={ikeonoStore.id}>
        <option />
        {externalStores?.map(({ id, name }) => {
          return (
            <option key={id} value={id}>
              {name}
            </option>
          )
        })}
      </LabeledField>
    </>
  )
}

const StoreAssignmentField = ({
  ikeonoStores,
  externalStores,
  title = "Point of Sale Stores",
}) => {
  return (
    <div
      className="d-grid"
      style={{
        gridTemplateColumns: "fit-content(40%) fit-content(40%)",
        columnGap: "40px",
        rowGap: "20px",
      }}>
      <h6 className="fw-bold mb-0" style={{ minWidth: "250px" }}>
        Ikeono Locations
      </h6>
      <h6 className="fw-bold mb-0" style={{ minWidth: "150px" }}>
        {title}
      </h6>
      {ikeonoStores?.map((store, id) => {
        return (
          <Row key={id} ikeonoStore={store} externalStores={externalStores} />
        )
      })}
    </div>
  )
}

export default StoreAssignmentField
