import React from "react"
import ReplyForm from "./ReplyForm"
import DeleteReply from "./DeleteReply"
import { formatRelativeDate } from "../../lib/date"

const ReviewReply = ({ hidden, updateTime, reviewId, body, onChange }) => {
  const [editMode, setEditMode] = React.useState(false)

  if (hidden) return null

  const relativeDate = formatRelativeDate(updateTime)

  return (
    <div className="d-flex gap-2">
      <i className="bi-reply" />
      <div className="d-flex flex-column w-100">
        <div className="d-flex gap-1 flex-column mb-2">
          <span className="fs-15 fw-bold">Reply from you</span>
          <span className="fs-13 text-secondary">
            Replied {relativeDate.usedRelativeDate ? "" : "on "}
            {relativeDate.dateString}
          </span>
        </div>
        <span>{body}</span>
        <div className="d-flex gap-2">
          <button
            type="button"
            onClick={() => {
              setEditMode(!editMode)
            }}
            className="btn btn-link text-secondary fs-15 px-0"
            style={{ textDecoration: "none" }}>
            Edit Reply
          </button>
          <DeleteReply
            reviewId={reviewId}
            onDelete={() => {
              onChange()
            }}
          />
        </div>
        <ReplyForm
          hidden={!editMode}
          body={body}
          reviewId={reviewId}
          onSuccess={(data) => {
            setEditMode(!editMode)
            onChange(data)
          }}
        />
      </div>
    </div>
  )
}

export default ReviewReply
