/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import moment from "moment"
import { json } from "overmind"
import { playNewMessageSound } from "../../audio"
import { mixpanel } from "../../../services/mixpanel"
import { prependStringToKeys } from "../../arrayUtil"
import { getContentTypeFromDataUrl } from "../../contentType"
import { overmindInstance } from ".."
import { getRandomInt } from "../../number"
import { isBrowserExtensionEmbed } from "../../../BrowserExtension"
import { getRandomString } from "../../stringUtil"

const _ = require("lodash")

setInterval(() => {
  overmindInstance.actions.activeThread.refresh()
}, 60000)

export const refresh = ({ state, actions }) => {
  for (const k in state.activeThread?.indexedMessages) {
    state.activeThread.indexedMessages[k].randomId = getRandomInt()
  }
  actions.activeThread.refreshMessages()

  const { threads } = state.activeInbox
  for (let i = 0; i < threads?.length; i++) {
    threads[i].randomId = getRandomInt()
  }
}

export const get = async ({ state, effects, actions }, id) => {
  if (state.activeThread.isLoading && state.activeThread.id === id) return
  if (state.activeThread.conversationId === id) return
  state.activeThread.conversationId = id
  state.activeThread.isLoading = true
  state.activeThread.apiError = null
  state.activeThread.detail = null
  try {
    const data = await effects.activeThread.get(id)

    if (state.activeThread.conversationId !== id) return // Another thread is active now

    state.activeThread.indexedMessages = {}
    if (data?.messages) {
      for (const message of data.messages) {
        state.activeThread.indexedMessages[message.sid] = message
      }
    }

    actions.activeThread.refreshMessages()

    state.activeThread.contact = data.contact

    state.activeThread.detail = {
      receiverId: data.receiver_id,
      receiverOptedOutOfMarketing: data.detail?.is_opted_out_of_marketing,
      receiverIsMuted: data.detail?.is_muted,
      hasUnreadMessages: data.detail?.has_unread_messages,
      archived: data.detail?.archived,
      threadType: data.detail?.thread_type,
      messengerPageName: data.detail?.messenger_page_name,
    }
    state.activeThread.receiverPhoneNumber =
      data.ui_metadata?.receiver_phone_number

    if (
      state.activeThread.detail.archived &&
      state.activeInbox.filter?.showClosed === false &&
      !isBrowserExtensionEmbed()
    ) {
      actions.activeInbox.updateFilter({ showClosed: true })
    }
  } catch (e) {
    state.activeThread.apiError = e
  }
  state.activeThread.isLoading = false
}

export const refreshMessages = ({ state }) => {
  const messages = _.values(state.activeThread.indexedMessages)
  messages.sort((a, b) => {
    const aDate = moment(a.date_sent)
    const bDate = moment(b.date_sent)
    if (aDate > bDate) return 1
    if (aDate < bDate) return -1
    return 0
  })

  state.activeThread.messages = messages
}

export const sendMessage = async ({ state, actions, effects }, body) => {
  if (
    !body &&
    !state.activeThread.invoiceAttachment &&
    !state.activeThread.attachment
  ) {
    throw new Error("Please send a message, image and/or payment.")
  }
  const referenceId = getRandomString()
  await effects.activeThread.sendMessage({
    threadId: state.activeThread.conversationId,
    body,
    invoice: json(state.activeThread.invoiceAttachment),
    attachment: json(state.activeThread.attachment?.file),
    referenceId,
  })

  let attachments = null

  if (state.activeThread.attachment) {
    attachments = [
      {
        content_type: getContentTypeFromDataUrl(
          state.activeThread.attachment.data
        ),
        url: state.activeThread.attachment.data,
      },
    ]
  }

  actions.activeThread.addNewMessage({
    threadId: state.activeThread.conversationId,
    date_sent: moment(),
    status:
      state.activeThread.detail?.threadType === "messenger"
        ? "delivered"
        : "queued",
    is_from_shop: true,
    sid: getRandomInt(),
    reference_id: referenceId,
    attachments,
  })

  mixpanel.track(
    "send message",
    prependStringToKeys(
      {
        body,
        threadId: state.activeThread.conversationId,
        hasAttachment: !!state.activeThread.attachment?.file,
        ...prependStringToKeys(
          state.activeThread.invoiceAttachment,
          "invoice "
        ),
      },
      "message "
    )
  )
}

export const updateAttachment = ({ state }, attachment) => {
  state.activeThread.attachment = attachment
}

export const toggleMarketingOptOut = async ({ state, effects }) => {
  let optedOutOfMarketing =
    state.activeThread.detail?.receiverOptedOutOfMarketing
  if (optedOutOfMarketing === null) {
    console.warn(
      "Attempted to opt out receiver when thread detail has not been loaded. This is a bug"
    )
    return
  }

  optedOutOfMarketing = !optedOutOfMarketing
  state.activeThread.detail.receiverOptedOutOfMarketing = optedOutOfMarketing

  const { receiverId } = state.activeThread.detail
  if (!optedOutOfMarketing) {
    await effects.activeThread?.removeMarketingOptOut(receiverId)
  } else {
    await effects.activeThread?.addMarketingOptOut(receiverId)
  }
}

export const toggleMute = async ({ state, effects }) => {
  let receiverIsMuted = state.activeThread.detail?.receiverIsMuted
  if (receiverIsMuted === null) {
    console.warn(
      "Attempted to opt out receiver when thread detail has not been loaded. This is a bug"
    )
    return
  }

  receiverIsMuted = !receiverIsMuted
  state.activeThread.detail.receiverIsMuted = receiverIsMuted

  const { receiverId } = state.activeThread.detail
  if (!receiverIsMuted) {
    await effects.activeThread?.removeMute(receiverId)
  } else {
    await effects.activeThread?.addMute(receiverId)
  }
}

export const addNewMessage = (o, newMessage) => {
  const state = o.state.activeThread

  if (!newMessage.is_from_shop) {
    playNewMessageSound()
  }

  if (newMessage?.threadId !== state.conversationId) return
  if (!state.indexedMessages) {
    state.indexedMessages = {}
  }

  const exists =
    newMessage.reference_id &&
    Object.values(state.indexedMessages)?.some(
      (m) => m.reference_id === newMessage.reference_id
    )
  if (exists) return
  state.indexedMessages[newMessage.sid] = newMessage
  o.actions.activeThread.refreshMessages()
}

export const assignThreadToInbox = async (
  { state, actions, effects },
  { threadId, newInbox }
) => {
  // Check to see if this thread is already in the inbox
  const thread = state.activeInbox.threads?.find((t) => t.id === threadId)
  const inboxIdx = thread?.inboxes?.findIndex((i) => i.id === newInbox?.id)
  if (inboxIdx > 0) return

  effects.activeThread.assignThreadToInbox({
    threadId,
    newInboxId: newInbox?.id,
  })

  if (newInbox) {
    thread.inboxes = [
      { id: newInbox.id, title: newInbox.title, slug: newInbox.slug },
    ]
  } else {
    thread.inboxes = []
  }
}

export const updateMessageLocally = (
  { actions, state },
  { sid, status, errorMessage }
) => {
  if (!state.activeThread?.indexedMessages) return
  const message = state.activeThread?.indexedMessages[sid]
  if (!message) return
  message.status = status
  message.error_message = errorMessage
  actions.activeThread.refreshMessages()
}

export const updateInvoiceAttachment = ({ state }, attachment) => {
  state.activeThread.invoiceAttachment = attachment
}

export const updateReviewRequestAttachment = ({ state }, attachment) => {
  state.activeThread.reviewRequestAttachment = attachment
}

export const toggleUnread = async ({ state, effects }) => {
  let hasUnreadMessages = state.activeThread.detail?.hasUnreadMessages
  if (hasUnreadMessages === null) {
    console.warn(
      "Attempted to opt out receiver when thread detail has not been loaded. This is a bug"
    )
    return
  }

  hasUnreadMessages = !hasUnreadMessages
  state.activeThread.detail.hasUnreadMessages = hasUnreadMessages
  effects.activeThread.updateThreadUnreadStatus({
    threadId: state.activeThread.conversationId,
    hasUnreadMessages,
  })
}

export const removeAllAttachments = ({ actions }) => {
  actions.activeThread.updateInvoiceAttachment(null)
  actions.activeThread.updateAttachment(null)
  actions.activeThread.updateReviewRequestAttachment(null)
}

export const updateThread = async ({ state, effects }) => {}

export const updateThreadLocally = ({ state }, updatedThread) => {
  const thread = state.activeThread.detail
  if (!thread || thread.conversationId !== updateThread.id) return

  thread.display_name = updatedThread.display_name
  thread.hasUnreadMessages = updatedThread.has_unread_messages
}

export const updateContact = ({ state }, newContact) => {
  state.activeThread.contact = newContact
}

export const setFocusedAttachmentUrl = ({ state }, url) => {
  state.activeThread.focusedAttachmentUrl = url
}

export const getAssignedInboxes = ({ state }) => {
  const threadId = state.activeThread.conversationId
  return state.activeInbox.threads.find((t) => t.id === threadId)?.inboxes
}
