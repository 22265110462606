import React from "react"
import { Route } from "react-router-dom"
import "./Inbox.css"
import InboxNewThreadContainer from "./InboxNewThreadContainer"
import InboxCreateNewContact from "./InboxNewThreadContainer/InboxCreateNewContact"
import ThreadContainer from "../Thread/ThreadContainer"
import { Threads } from "./Threads"
import Reviews from "../Reviews"
import MobileInbox from "./MobileInbox"
import { isMobileDevice } from "../lib/device"
import InboxContainer from "./InboxContainer"
import Redirect from "../components/Redirect"

export const inboxRoutes = () => {
  if (isMobileDevice()) {
    return (
      <>
        <Route index path="/" element={<Redirect to="/inbox/all" />} />
        <Route path="/inbox/*" element={<MobileInbox />} />
      </>
    )
  }
  return (
    <>
      <Route index path="/" element={<Redirect to="/inbox/all" />} />
      <Route path="/inbox" element={<Redirect to="/inbox/all" />} />
      <Route path="/inbox/*" element={<InboxContainer />}>
        <Route
          path="reviews/*"
          element={
            <div className="container">
              <Reviews />
            </div>
          }
        />
        <Route path=":inboxName/" element={<Threads />}>
          <Route index path="new" element={<InboxNewThreadContainer />} />
          <Route index path="new/create" element={<InboxCreateNewContact />} />
          <Route path=":threadId/*" element={<ThreadContainer />} />
        </Route>
      </Route>
    </>
  )
}
