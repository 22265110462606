import React from "react";
import { AppConfig } from "../services/config";
import Browser from "../lib/browser";

const DownloadFirefoxExtensionButton = ({ isPrimary }) => {
  const klass = isPrimary ? "btn btn-primary" : "btn-link text-secondary";
  const text = isPrimary ? "Download Firefox Extension" : "I'm using Firefox";

  const url =
    Browser.firefoxVersion() < 118
      ? AppConfig.LegacyFirefoxExtensionUrl
      : AppConfig.FirefoxExtensionUrl;

  return (
    <a className={klass} href={url} rel="noreferrer">
      <i hidden={!isPrimary} className="bi-browser-firefox me-2" />
      {text} v{Browser.firefoxVersion()}
    </a>
  );
};

export default DownloadFirefoxExtensionButton;
