import React from "react"
import { useState } from "../lib/overmind"
import { StripeAppIcon } from "../IntegrationAppIcon"
import FeatureQuickGlance from "../components/FeatureQuickGlance"

export const useConfigureInvoicePrompt = () => {
  const state = useState().account
  return state.paymentsConfigured ? null : <ConfigureInvoicePrompt />
}

const ConfigureInvoicePrompt = () => {
  return (
    <FeatureQuickGlance
      appIcon={<StripeAppIcon className="flex-shrink-0" />}
      title="Ikeono Payments"
      body="Text to pay gives retailers the ability to send a secure payment link to customers to capture full payment or deposits on invoices, quotes, or work orders."
      imgSrc="/payments-example.png"
      path="/settings/location/payments/connection"
      learnMorePath="https://www.ikeono.com/payments"
    />
  )
}

export default ConfigureInvoicePrompt
