/* eslint-disable no-unused-expressions */
import React, { useState } from "react";
import BrowserExtensionNewInvoiceForm from "./BrowserExtensionNewInvoiceForm";
import BrowserExtensionInvoiceSuccess from "./BrowserExtensionInvoiceSuccess";

const BrowserExtensionCreateInvoiceFormContainer = (props) => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [createdInvoicePayload, setCreatedInvoicePayload] = useState(null);

  const onComplete = (invoicePayload) => {
    setShowSuccess(true);
    setCreatedInvoicePayload(invoicePayload);
    props.onShowSuccess && props.onShowSuccess();
  };

  const onSucessDoneClick = () => {
    setShowSuccess(false);
    try {
      props.onDoneClick(createdInvoicePayload?.invoice);
    } catch (error) {
      console.error(error);
    }
    setCreatedInvoicePayload(null);
  };

  if (showSuccess) {
    return (
      <BrowserExtensionInvoiceSuccess
        onDoneClick={onSucessDoneClick}
        invoice={createdInvoicePayload?.invoice}
        didSendEmail={createdInvoicePayload?.didSendEmail}
        didSendSMS={createdInvoicePayload?.didSendSMS}
      />
    );
  }

  return (
    <div>
      <BrowserExtensionNewInvoiceForm {...props} onComplete={onComplete} />
    </div>
  );
};

export default BrowserExtensionCreateInvoiceFormContainer;
