import React from "react";
import APIResponseError from "../APIResponseError";
import { LightspeedAppIconImg } from "../Brands";
import Button, { ButtonSize } from "../Button";
import useLightspeedOAuthHook from "./LighspeedOAuthHook";

const LightspeedConnectButton = ({ children }) => {
  const [isLoading, error, onClick] = useLightspeedOAuthHook();

  return (
    <>
      <Button
        onClick={onClick}
        showLoader={isLoading}
        className="shadow d-flex align-items-center"
        size={ButtonSize.medium}
        style={{ backgroundColor: "#000", border: "none", fontWeight: "500" }}
      >
        <LightspeedAppIconImg height="22px" className="mr-2" />
        <span className="ps-1">Connect Lightspeed R-Series</span>
      </Button>
      {error && <APIResponseError response={error.response} />}
    </>
  );
};

export default LightspeedConnectButton;
