import React from "react";
import { Route, Routes } from "react-router-dom";
import Report from "./Report";

const ReportRouter = () => {
  return (
    <div className="w-100">
      <Routes>
        <Route path="/:reportId" element={<Report />} />
      </Routes>
    </div>
  );
};

export default ReportRouter;
