import React from "react"

const FormSuccess = (props) => (
  <div>
  <span
    {...props}
    className={`text-center my-0 me-3 alert alert-success py-1 px-3 ${
      props.className || ""
    }`}>
    <i className="bi-check-circle me-1 text-success" />
    Success
  </span>
  </div>

)

export default FormSuccess
