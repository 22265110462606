// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.attachment-modal-img {
  max-width: 100vw;
}

@media (min-width: 767.98px) {
  .attachment-modal-img {
    max-width: 80vw;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Thread/style.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".attachment-modal-img {\n  max-width: 100vw;\n}\n\n@media (min-width: 767.98px) {\n  .attachment-modal-img {\n    max-width: 80vw;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
