import React, { useEffect, useRef } from "react"
import { Outlet } from "react-router"
import "./Inbox.css"
import InboxListColumn from "./InboxListColumn"

const InboxContainer = () => {
  const ref = useRef()
  const [headerHeight, setHeaderHeight] = React.useState()

  useEffect(() => {
    if (
      ref.current?.clientHeight &&
      ref.current?.clientHeight !== headerHeight
    ) {
      setHeaderHeight(ref.current.clientHeight - 180)
    }
  })

  return (
    <div className="inbox-wrapper">
      <InboxListColumn />
      <Outlet />
    </div>
  )
}

export default InboxContainer
