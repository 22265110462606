import React from "react"
import { changePhoneNumberModalId } from "./Ineligible/ChangeLocationPhoneNumberModal"
import { useState } from "../../lib/overmind"
import { formatPhoneNumberWithAccountCountry } from "../../lib/overmind/account/util"

const PhoneInfoCard = () => {
  const state = useState().account

  return (
    <div className="p-3 text-primary-emphasis bg-light-subtle border border-light-subtle rounded-3">
      <div className="fs-16 mb-2">Your business phone number</div>
      <h4 className="mb-0">
        {formatPhoneNumberWithAccountCountry(state.setupStatus?.phone_number)}
      </h4>
      <div className="text-secondary">
        <small>{state.setupStatus?.phone_number_line_provider}</small>
      </div>
      <div hidden={state.setupStatus?.is_phone_hostable} className="mt-3">
        <div>
          If you have a different business phone number you would like to use.
          You can update it now. Otherwise we will provide you with a local
          number.
        </div>
        <button
          type="button"
          className="btn btn-outline-primary btn-sm mt-2 fs-16"
          data-bs-toggle="modal"
          data-bs-target={`#${changePhoneNumberModalId}`}>
          Change phone number
        </button>
      </div>
    </div>
  )
}

export default PhoneInfoCard
