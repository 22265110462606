import React from "react"
import APIResponseError from "../../components/APIResponseError"
import Loader from "../../components/Loader"
import "./style.css"

const InboxLoadingStateContainer = ({ isCreating, children, error }) => {
  const opacity = isCreating ? "50%" : null

  return (
    <div className="m-4 d-flex flex-column position-relative h-100">
      <APIResponseError response={error} />
      {opacity && <div style={{ opacity }}>{children}</div>}
      {!opacity && children}

      {isCreating && (
        <div className="top-0 end-0 w-100 h-100 position-absolute d-flex justify-content-center align-items-center">
          <Loader />
        </div>
      )}
    </div>
  )
}

export default InboxLoadingStateContainer
