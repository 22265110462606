import React from "react";

const ContinueButton = ({ children, onClick, className = "", hidden }) => (
  <div
    role="button"
    hidden={hidden}
    className={`card integration-card ${className}`}
    onClick={onClick}
  >
    <div className="card-body d-flex flex-row gap-3">
      <div>
        <span>{children}</span>
      </div>
      <div className="ms-auto align-self-center">
        <i className="bi-chevron-right" />
      </div>
    </div>
  </div>
);

export default ContinueButton;
