// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.widget-min {
    cursor: pointer;
    width: 122px;
    height: 48px;
    border-radius: 30px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    gap: 9px;
  
    svg {
      padding-top: 3px;
    }
  
    transition: transform 0.5s ease-in-out 0s,
    opacity 0.5s;
  }`, "",{"version":3,"sources":["webpack://./src/Webchat/style.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;IACjB,QAAQ;;IAER;MACE,gBAAgB;IAClB;;IAEA;gBACY;EACd","sourcesContent":[".widget-min {\n    cursor: pointer;\n    width: 122px;\n    height: 48px;\n    border-radius: 30px;\n    color: white;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    font-weight: bold;\n    gap: 9px;\n  \n    svg {\n      padding-top: 3px;\n    }\n  \n    transition: transform 0.5s ease-in-out 0s,\n    opacity 0.5s;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
