import React from "react"
import { useState } from "../lib/overmind"
import InformationTooltip from "./InformationTooltip"

const FieldsetReadonlyManager = ({
  children,
  disabled,
  disabledTooltipTitle,
}) => {
  const state = useState().account

  const isDisabled = state.isReadonly || disabled

  return (
    <fieldset disabled={isDisabled}>
      <span
        className="fw-bold mb-3"
        style={{ fontSize: "13px" }}
        hidden={!state.isReadonly}>
        <i className="bi-exclamation-triangle-fill me-1 text-warning" />
        You cannot send messages at this location.
      </span>
      <InformationTooltip
        title={disabledTooltipTitle}
        hideTooltip={!isDisabled || !disabledTooltipTitle}>
        {children}
      </InformationTooltip>
    </fieldset>
  )
}

export default FieldsetReadonlyManager
