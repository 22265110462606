import React from "react";
import InboxAttachmentDeleteButton from "./InboxAttachmentDeleteButton";
import InboxAttachmentPdfPreview from "./InboxAttachmentPdfPreview";

const InboxAttachmentPreview = ({ imageFileData, onRemove }) => {
  // TODO: Handle PDF
  if (!imageFileData) return null;
  const isPdf = imageFileData.includes("application/pdf");
  if (isPdf) return <InboxAttachmentPdfPreview onRemove={onRemove} />;
  return (
    <div className="position-relative pl-1 pr-1">
      <img
        style={{
          maxHeight: "100px",
          maxWidth: "100px",
          imageOrientation: "from-image",
        }}
        id="messageImageElement"
        src={imageFileData}
        alt="Media"
      />
      <InboxAttachmentDeleteButton onClick={onRemove} />
    </div>
  );
};

export default InboxAttachmentPreview;
