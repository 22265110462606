// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `main {
  display: grid;
  grid-template-columns: 4.1rem 1fr;
}

.wrapper {
  display: grid;
  grid-template-rows: auto 1fr;
}

.fs-7 {
  font-size: "14px";
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iCAAiC;AACnC;;AAEA;EACE,aAAa;EACb,4BAA4B;AAC9B;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":["main {\n  display: grid;\n  grid-template-columns: 4.1rem 1fr;\n}\n\n.wrapper {\n  display: grid;\n  grid-template-rows: auto 1fr;\n}\n\n.fs-7 {\n  font-size: \"14px\";\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
