/* eslint-disable class-methods-use-this */
/* eslint-disable no-use-before-define */
import React, { useEffect } from "react";
import * as mixpanelBrowser from "mixpanel-browser";
import $ from "jquery";
import { AppConfig } from "./config";
import { titleCase } from "../lib/stringUtil";
import { reduce } from "../lib/arrayUtil";

export const useMixpanelTrackingRef = (eventName, eventProps) => {
  const ref = React.useRef();

  useEffect(() => {
    $(ref.current).click(() => {
      mixpanel.track(eventName, eventProps);
    });
  }, [ref]);

  return ref;
};

export const useMixpanel = () => mixpanel;

const formatEventProps = (eventProps) => {
  if (!eventProps) return;
  const eventPropsFormatted = {};
  reduce(
    eventProps,
    (result, v, k) => {
      result[titleCase(k)] = v;
      return result;
    },
    eventPropsFormatted,
  );
  return eventPropsFormatted;
};

class MixpanelManager {
  init() {
    mixpanelBrowser.init(AppConfig.MixpanelProjectId, { debug: true });
  }

  identify(props) {
    mixpanelBrowser.identify(props);
  }

  setPeople(props) {
    mixpanelBrowser.people.set(formatEventProps(props));
  }

  register(props) {
    mixpanelBrowser.register(formatEventProps(props));
  }

  track(eventName, eventProps) {
    mixpanelBrowser.track(titleCase(eventName), formatEventProps(eventProps));
  }
}

export const mixpanel = new MixpanelManager();

export const usePageViewHook = (pageName) => {
  const mp = useMixpanel();

  useEffect(() => {
    mp.track("page view", {
      "page name": pageName,
    });
  }, []);
};
