import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useActions } from "../lib/overmind";
import InboxFilterDropDown from "./InboxFilterDropDown";
import InboxSearchInput from "./InboxSearchInput";

const InboxHeader = ({ inboxName }) => {
  const navigate = useNavigate();
  const actions = useActions().contacts;

  return (
    <div className="d-flex flex-column px-2 mb-2">
      <div className="d-flex mb-2 align-items-center pt-2">
        <h6 className="fw-bold d-inline mb-0">All Conversations</h6>
        <InboxFilterDropDown />
      </div>
      <div className="row">
        <div className="col pe-0">
          <InboxSearchInput />
        </div>
        <div className="col-auto pe-1 ps-1 me-2">
          <button
            onClick={() => {
              if (window.location.href.includes("/new")) {
                navigate(-1);
                actions.clearSearch();
              } else {
                navigate(`/inbox/${inboxName}/new`);
              }
            }}
            type="button"
            className="btn btn-primary btn-sm circle"
          >
            <i className="bi bi-pencil" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default InboxHeader;
