/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow */
import { derived } from "overmind";

export const state = {
  pending: derived((state) => {
    const list = [];
    for (const k in state.pendingIndex) {
      list.push(state.pendingIndex[k]);
    }
    return list;
  }),
  pendingIndex: {},
};
