export const formatCurrency = (amount, currency = "USD", locale = "en-US") => {
  if (!amount) {
    return "-";
  }
  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
  });

  return formatter.format(amount);
};
