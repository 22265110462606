import React from "react"
import { useState } from "../lib/overmind"
import { toggleModal } from "../components/Modal"
import { ReviewsFeatureUpgradeModalId } from "../Reviews/Setup/ReviewsFeatureUpgradeModal"
import { ReviewSetupModalId } from "../Reviews/Setup/ReviewsSetupModal"

const InboxMessageInputReviewAction = ({ onInsert }) => {
  const state = useState().account

  const config = state.data?.config.portal.feedback_loop_module

  const onClick = () => {
    if (config?.needs_account_upgrade) {
      toggleModal(ReviewsFeatureUpgradeModalId)
    } else if (!config.template) {
      toggleModal(ReviewSetupModalId)
    } else {
      onInsert(config.template)
    }
  }
  return (
    <button
      type="button"
      className="btn btn-link btn-sm text-black text-decoration-none"
      onClick={onClick}>
      <i className="bi-star me-1" />
      Review
    </button>
  )
}

export default InboxMessageInputReviewAction
