import React from "react"
import { AppConfig } from "../services/config"

const DownloadChromeExtensionButton = ({ isPrimary }) => {
  const klass = isPrimary ? "btn btn-primary" : "btn btn-link text-secondary"
  const text = isPrimary ? "Download Chrome Extension" : "I'm using Chrome"

  return (
    <a
      className={klass}
      href={AppConfig.ChromeExtensionUrl}
      target="_blank"
      rel="noreferrer">
      <i hidden={!isPrimary} className="bi-browser-chrome me-2" />
      {text}
    </a>
  )
}

export default DownloadChromeExtensionButton
