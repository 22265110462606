import React from "react";
import InfoBubble from "../lib/InfoBubble";
import { ContactSupportCta } from "../lib/ikeono";
import LightspeedConnectButton from "../components/Lightspeed/LightspeedConnectButton";

const LightspeedPaymentsSettingsDisconnectedWarning = ({ hidden, message }) => {
  if (hidden) return null;

  return (
    <InfoBubble
      className="fs-16 py-1 mb-4"
      variant="danger"
      style={{ maxWidth: "70ch" }}
    >
      <h6 className="fw-bold">Please check your POS Connection</h6>
      <p>
        {message} You will be unable to make any changes until your connection
        is restored.
      </p>
      <ContactSupportCta />
      <div className="mt-2">
        <LightspeedConnectButton />
      </div>
    </InfoBubble>
  );
};

export default LightspeedPaymentsSettingsDisconnectedWarning;
