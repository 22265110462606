import React from "react"
import GoogleReviewCard from "../ReviewCard"
import ReviewsZeroState from "../ReviewsZeroState"

const ReviewColumn = ({ reviews, redact }) => {
  return (
    <div className="d-flex flex-column gap-4">
      {reviews?.map((review) => (
        <GoogleReviewCard
          reviewId={review.id}
          reviewerImage={review.reviewer.profile_photo}
          reviewerName={review.reviewer.display_name}
          updateTime={review.update_time}
          body={review.comment}
          rating={review.rating}
          reviewReply={review.review_reply}
          redact={redact}
        />
      ))}
      <ReviewsZeroState hidden={!!reviews} />
    </div>
  )
}

export default ReviewColumn
