import React from "react";
import MessageCardDate from "./MessageCardDate";
import MessageCardStatus from "./MessageCardStatus";

const MessageCardInfoLine = ({ isFromShop, dateSent, status, senderName }) => {
  return (
    <div
      className={`sms-card-info d-flex gap-1 align-items-center mt-1 justify-content-${
        isFromShop ? "end" : "start"
      }`}
    >
      <span className="fs-12 fw-bold text-secondary">{senderName}</span>
      <MessageCardDate date={dateSent} />
      <MessageCardStatus status={status} />
    </div>
  );
};

export default MessageCardInfoLine;
