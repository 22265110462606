import React from "react"
import AccountDetailForm from "./AccountDetailForm"

const AccountUpdate = () => {
  return (
    <div className="container mb-5">
      <div className="row">
        <div className="col-8">
          <h5 className=" my-3">Account Details</h5>
          <AccountDetailForm />
        </div>
      </div>
    </div>
  )
}

export default AccountUpdate
