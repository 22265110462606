import React from "react";

const DataTableZeroState = () => {
  return (
    <div className="text-center mt-5 text-muted">
      <h5>No results.</h5>
      Please try adjusting your search parameters.
    </div>
  );
};

export default DataTableZeroState;
