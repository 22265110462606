import React from "react";
import ContinueButton from "../components/ContinueButton";
import IntegrationCard from "../IntegrationSetup/IntegrationCard";
import InstalledBadge from "../../components/InstalledBadge";

export const PrimaryButton = ({ onClick, children, icon }) => {
  return <IntegrationCard icon={icon} title={children} onClick={onClick} />;
};

export const SecondaryButton = ({ onClick, children, icon }) => (
  <ContinueButton
    icon={icon}
    onClick={onClick}
    className="opacity-25 btn-outline-secondary"
  >
    {children}
  </ContinueButton>
);

export const Body = ({ icon, children, isInstalled }) => (
  <span className="d-flex">
    <i className={`${icon} me-3`} />
    <span className="d-flex flex-column">
      {children}
      <InstalledBadge hidden={!isInstalled} />
    </span>
  </span>
);
