/* eslint-disable camelcase */
import React from "react";
import Modal, { toggleModal } from "../components/Modal";
import { useEventHook } from "../services/events";

const accountVerifiedModalId = "account-verified-modal";

const AccountVerifiedModal = () => {
  useEventHook("account_status_change", ({ is_account_verified }) => {
    if (!is_account_verified) return;
    toggleModal(accountVerifiedModalId);
  });
  return (
    <Modal id={accountVerifiedModalId} title="Your account is verified ">
      <p className="lead">
        You are ready to start sending messages, collecting reviews, and a whole
        lot more.
      </p>
      <a href="/" className="btn btn-primary btn-lg">
        Open your inbox
      </a>
    </Modal>
  );
};

export default AccountVerifiedModal;
