import React from "react"
import PosVisibility from "../../components/PosVisibility"
import ThreadActionItem from "./ThreadActionItem"

const OpenInLightspeedActionItem = ({
  firstName,
  lastName,
  phoneNumber,
  vendorUrl,
}) => {
  const searchParam =
    phoneNumber || `${firstName || ""} ${lastName || ""}`.trim()

  const url =
    vendorUrl ||
    `https://us.merchantos.com/?name=customer.listings.customers&form_name=listing&__sort_dir=ASC&end_date=0000-00-00&start_date=0000-00-00&customer_name=${searchParam}&tags=&neg_tags=&show_type=off&show_company=off&archived=off&customer_type_id=-1&discount_id=-1&tax_category_id=-1`

  return (
    <PosVisibility showLightspeed>
      <ThreadActionItem
        disabled={!searchParam}
        onClick={() => {
          window.open(url, "_blank")
        }}
        title="Open in Lightspeed Retail"
        description="Open this contact in your point of sale."
        iconName="bi-box-arrow-up-right"
      />
    </PosVisibility>
  )
}

export default OpenInLightspeedActionItem
