import React from "react";

function DetailTable({ children }) {
  return (
    <div
      className="d-grid gap-2 mb-4"
      style={{ gridTemplateColumns: "minmax(100px, auto) auto" }}
    >
      {children}
    </div>
  );
}
export default DetailTable;

export function TableRow({ title, children, className, hidden }) {
  if (hidden) return null;
  return (
    <>
      <small className="text-secondary ">{title}</small>
      <span className={className}>{children}</span>
    </>
  );
}
