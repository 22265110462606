import React from "react"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import { Bar } from "react-chartjs-2"

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
}

const labels = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]

const NpsScoreBarChart = ({ scoreData }) => {
  const data = {
    labels,
    datasets: [
      {
        label: "",
        data: Object.values(scoreData || {}),
        backgroundColor: [
          "rgba(255, 99, 132, 0.7)",
          "rgba(255, 99, 132, 0.7)",
          "rgba(255, 99, 132, 0.7)",
          "rgba(255, 99, 132, 0.7)",
          "rgba(255, 99, 132, 0.7)",
          "rgba(255, 99, 132, 0.7)",
          "rgba(255, 99, 132, 0.7)",
          "rgba(255, 206, 86, 0.7)",
          "rgba(255, 206, 86, 0.7)",
          "rgba(75, 192, 192, 0.7)",
          "rgba(75, 192, 192, 0.7)",
        ],
      },
    ],
  }
  return (
    <div
      className="d-flex justify-content-center w-100"
      style={{ height: "300px" }}>
      <Bar options={options} data={data} />
    </div>
  )
}

export default NpsScoreBarChart

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)
