import React from "react"

function Loader(props) {
  if (props.hidden) return null

  const computedClassName = `d-${
    props.inline ? "inline" : "block"
  } text-center ${props.className}`

  const computedProps = { ...props }
  delete computedProps.inline
  delete computedProps.className

  return (
    <div className={computedClassName} {...computedProps}>
      <span
        className={`spinner-border spinner-border-${props.variant || "sm"}`}
        role="status"
        aria-hidden="true"
        style={props.spinnerStyle}
      />
    </div>
  )
}

export default Loader
