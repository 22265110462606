import React from "react"
import { inboxCreateModalId } from "../InboxCreate/InboxCreateModal"

const InboxListZeroState = (props) => {
  if (props?.hidden) return null
  return (
    <div
      {...props}
      className={`text-secondary d-flex flex-column justify-content-center align-items-center  mt-2 ${
        props?.className || ""
      }`}>
      <i className="bi bi-inboxes" style={{ fontSize: "25px" }} />
      <span className="text-center">You have not created any inboxes yet.</span>
      <button
        type="button"
        className="btn btn-link text-nowrap"
        data-bs-toggle="modal"
        data-bs-target={`#${inboxCreateModalId}`}>
        Create your first one
      </button>
    </div>
  )
}

export default InboxListZeroState
