import React from "react";
import { Link } from "react-router-dom";
import DefaultIntegrationCard from "./DefaultIntegrationCard";

const WarningIntegrationCard = (props) => {
  const badge = (
    <span className="badge bg-warning py-1 px-2">
      {" "}
      <i className="bi-exclamation-circle-fill me-1" />
      Issues
    </span>
  );
  return (
    <DefaultIntegrationCard badge={badge} {...props}>
      <Link className="btn btn-sm btn-warning" to={props.url}>
        <i className="bi-exclamation-circle me-1" />
        Fix issues
      </Link>
    </DefaultIntegrationCard>
  );
};

export default WarningIntegrationCard;
