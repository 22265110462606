import React from "react"
import CopyToClipboard from "../../components/CopyToClipboard"
import { formatCurrency } from "../../lib/currency"

const CreatePaymentRequestSuccess = ({ hidden, data }) => {
  if (hidden) return null
  return (
    <div>
      <h3 className="mt-1">{formatCurrency(data.total * 0.01)}</h3>
      <h6 className="">
        <i className="bi bi-check-circle text-success" /> Success
      </h6>
      <p>
        Your payment request has been created. You can copy the below url and
        send it to your customers via email, sms, etc.
      </p>
      <CopyToClipboard>{data?.hosted_page_url}</CopyToClipboard>
      <div className="mt-2">
        <button
          type="button"
          className="btn btn-link ps-0 text-secondary"
          data-bs-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  )
}

export default CreatePaymentRequestSuccess
