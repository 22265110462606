import React, { useEffect } from "react"
import { logout } from "./lib/auth"
import Loader from "./components/Loader"

const Logout = ({ onLogout }) => {
  useEffect(() => {
    onLogout && onLogout()
    logout()
    window.location.reload()
  }, [])
  return (
    <div className="d-flex justify-content-center align-items-center vh-100 vw-100 flex-column">
      <Loader spinnerStyle={{ fontSize: "20px" }} variant="lg" />
      <p className="mt-2 d-block">Logging out... One moment please.</p>
    </div>
  )
}

export default Logout
