import React from "react";
import { Link } from "react-router-dom";

const DefaultIntegrationCard = ({
  title,
  body,
  icon,
  url,
  badge,
  children,
}) => {
  return (
    <div
      className="card p-3 shadow-smm"
      style={{ height: "230px", maxWidth: "300px" }}
    >
      <div className="d-flex flex-row">
        {icon}
        <div className="ms-auto">{badge}</div>
      </div>
      <h5 className="fw-bold mt-3 mb-1">{title}</h5>
      <div className="fs-15 mb-2">{body}</div>
      <div className="mt-auto">
        {children || (
          <Link className="btn btn-sm btn-outline-primary" to={url}>
            Add integration
          </Link>
        )}
      </div>
    </div>
  );
};

export default DefaultIntegrationCard;
