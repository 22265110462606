import React from "react"
import SmsCard from "../../MessageCard/SmsCard"
import MessageCardDetails from "../../MessageCard/MessageCardDetails"

const NpsReplies = ({ hidden, messages }) => {
  if (hidden) return null

  return (
    <div>
      <div className="fw-semibold mb-2">Replies</div>
      <div hidden={messages}>
        <i className="text-secondary">No messages</i>
      </div>
      <div className="d-flex flex-column  gap-3">
        {messages?.map((m, idx) => {
          const cardJustify = m.is_from_shop ? "ms-auto" : ""

          return (
            <div style={{ maxWidth: "315px" }} className={cardJustify}>
              <MessageCardDetails message={m}>
                <SmsCard key={m.sid} idx={idx} message={m} />
              </MessageCardDetails>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default NpsReplies
