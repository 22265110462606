import React from "react"
import { Field } from "formik"
import SimpleForm from "../../components/Form/SimpleForm"
import { useActions } from "../../lib/overmind"

const NpsEditScore = ({ id, score, onSave, onCancel }) => {
  const actions = useActions().nps
  const apiSubmitHandler = async (data) => {
    await actions.updateScore({ id, score: data.score })
    onSave(data.score)
  }

  return (
    <div style={{ minHeight: "61px" }}>
      <SimpleForm
        initialValues={{ score }}
        hideSubmitButton
        apiSubmitHandler={apiSubmitHandler}>
        <div className="d-flex gap-2">
          <div className="input-group">
            <span className="input-group-text">Score</span>
            <Field
              class="form-control"
              name="score"
              min="1"
              max="10"
              type="number"
              step="1"
            />
            <button
              className="btn btn-primary"
              type="submit"
              id="button-addon1">
              Save
            </button>
          </div>
          <button
            onClick={() => onCancel()}
            className="btn btn-link text-secondary p-0 align-self-start fs-14"
            type="button">
            Cancel
          </button>
        </div>
      </SimpleForm>
    </div>
  )
}

export default NpsEditScore
