import React from "react";
import LightspeedOauth from "./LightspeedOauth";

const LightspeedDisconnectedWarning = () => {
  return (
    <LightspeedOauth>
      <div className="d-flex align-items-center">
        <i
          style={{ fontSize: "21px" }}
          className="bi-exclamation-triangle-fill text-warning me-2"
        />
        You need to connect your Lightspeed to search for customers.
      </div>
    </LightspeedOauth>
  );
};

export default LightspeedDisconnectedWarning;
