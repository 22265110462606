import * as React from "react";

const Col = (props) => {
  const { variant, children } = props;
  return (
    <div {...props} className={`col-${variant}`}>
      {children}
    </div>
  );
};

export default Col;
