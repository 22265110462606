import React from "react"
import { useActions } from "../lib/overmind"

const AttachmentCard = ({ url, contentType, moveToEnd, bottomRef }) => {
  if (!url) return null

  const actions = useActions().activeThread
  return (
    <div
      className={`d-flex flex-row  ${moveToEnd ? "justify-content-end" : ""}`}>
      <div className="mms-media-img">
        <img
          data-bs-toggle="modal"
          data-bs-target="#mms-modal"
          className="w-100 mms-placeholder"
          src={url}
          onLoad={(e) => {
            e.target.classList.remove("mms-placeholder")
          }}
          onClick={() => {
            actions.setFocusedAttachmentUrl(url)
          }}
        />
      </div>
    </div>
  )
}

export default AttachmentCard
