import React, { useEffect } from "react";
import SimpleForm from "../components/Form/SimpleForm";
import { SettingsFactory } from "../components/Settings/SettingsFactory";
import { put, useGet } from "../lib/api";
import { prependHttps } from "../lib/url";
import WebchatLocationPicker from "./WebchatLocationPicker";
import Loader from "../components/Loader";
import APIResponseError from "../components/APIResponseError";
import FormChange from "../components/Form/FormChange";
import WebchatColorPicker from "./WebchatColorPicker";

const fields = [
  {
    label: "Selected Locations",
    body: "Select the locations your users can choose to send a message to.",
    component: (data) => {
      return <WebchatLocationPicker locations={data?.locations} />;
    },
  },
  {
    label: "Open",
    body: "Show opened widget form instead of the button when someone visits your website",
    as: "checkbox",
    props: {
      id: "open_on_load",
      name: "open_on_load",
      label: "Open on load",
    },
  },
  {
    label: "Bottom Offset",
    body: "Set this to a positive value in order to move the button up on your website. (i.e. To avoid other elements on your website)",
    props: {
      id: "bottom_offset",
      name: "bottom_offset",
      type: "number",
      label: "Bottom offset",
      suffix: "pixels",
    },
  },
  {
    label: "Button Placement",
    body: "Choose where the widget will show on your website",
    as: "select",
    props: {
      id: "button_placement",
      name: "button_placement",
      values: [
        {
          id: "bottom_right_corner",
          name: "Bototm Right Corner",
        },
        {
          id: "bottom_left_corner",
          name: "Bototm Left Corner",
        },
      ],
    },
  },
  {
    label: "Button Color",
    body: "Change the color of the button to match your brand",
    as: "color",
    component: () => {
      return <WebchatColorPicker />;
    },
  },

  {
    label: "Auto Reply Message",
    body: "Enter a message to send when you're open.",
    as: "textarea",
    props: {
      id: "auto_reply_message",
      name: "auto_reply_message",
      label: "Auto reply message",
    },
  },
  {
    label: "Email Notifications",
    body: "Receive an email when you receive a webchat message.",
    as: "checkbox",
    props: {
      id: "send_email_notification",
      name: "send_email_notification",
      label: "Send email notifications",
    },
  },
];

const WebchatForm = ({ onChange }) => {
  const [initialValues, setInitialValues] = React.useState({});
  const [get, isLoading, error, resp] = useGet();

  const apiSubmitHandler = async (data) => {
    data.website = prependHttps(data.website);
    data.review_link = prependHttps(data.review_link);
    await put(`/account/{account_id}/website-widget`, data);
  };

  useEffect(() => {
    get("/account/{account_id}/website-widget");
  }, []);

  useEffect(() => {
    if (!resp || !resp.data) return;
    const iValues = { ...resp.data };
    for (const location of resp.data.locations) {
      iValues[`shop__${location.shop_id}`] = true;
    }
    setInitialValues(iValues);
  }, [resp]);

  if (isLoading) return <Loader />;
  if (error) return <APIResponseError response={error} />;
  if (!resp) return null;

  return (
    <SimpleForm
      initialValues={initialValues}
      apiSubmitHandler={apiSubmitHandler}
    >
      <FormChange onChange={onChange} />
      <SettingsFactory
        fields={fields}
        data={{
          locations: resp.data.locations,
        }}
      />
    </SimpleForm>
  );
};

export default WebchatForm;
