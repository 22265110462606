import React from "react"
import InboxAttachmentDeleteButton from "./InboxAttachmentDeleteButton"

const InboxAttachmentPdfPreview = ({ onRemove }) => (
  <div className="d-flex card">
    <div className="py-2 px-3 d-flex gap-1 align-items-center">
      <i className="bi-file-earmark-pdf-fill" style={{ fontSize: "15px" }} />
      <div className="d-flex flex-column">
        <b className="fs-14">PDF Attachment</b>
      </div>
    </div>
    <InboxAttachmentDeleteButton onClick={onRemove} />
  </div>
)
export default InboxAttachmentPdfPreview
