import React from "react";
import EcwidOauth from "./EcwidOauth";

const Ecwid = () => {
  return (
    <div>
      <EcwidOauth />
    </div>
  );
};

export default Ecwid;
