import React from "react"
import "./style.css"
import ReviewReply from "./ReviewReply"
import Header from "./Header"
import ReplyForm from "./ReplyForm"

const GoogleReviewCard = ({
  reviewId,
  reviewerImage,
  reviewerName,
  rating,
  updateTime,
  body,
  reviewReply,
  redact,
}) => {
  const [managedReviewReply, setManagedReviewReply] =
    React.useState(reviewReply)

  const onReplyChange = (updatedReviewReply) => {
    setManagedReviewReply(
      updatedReviewReply ? { comment: updatedReviewReply.body } : null
    )
  }

  return (
    <div className="card">
      <div className="card-body">
        <div className="d-flex google-review-card gap-2">
          <img className="profile-image" src={reviewerImage} />
          <div className="d-flex flex-column w-100">
            <Header
              reviewerName={reviewerName}
              updateTime={updateTime}
              rating={rating}
              redact={redact}
            />
            <span className={`mt-2 mb-3 `.concat(redact ? "blur-3" : "")}>
              {body}
            </span>
            <ReviewReply
              hidden={!managedReviewReply}
              reviewId={reviewId}
              updateTime={managedReviewReply?.update_time}
              body={managedReviewReply?.comment}
              onChange={onReplyChange}
            />
            <ReplyForm
              hidden={!!managedReviewReply}
              body={managedReviewReply?.comment}
              reviewId={reviewId}
              onSuccess={onReplyChange}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default GoogleReviewCard
