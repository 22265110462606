export const EnvEnum = {
  development: "development",
  staging: "staging",
  production: "production",
};

export const Env = process.env.REACT_APP_IKEONO_ENV;

export const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const inPortalIframe = () => {
  const referrer = document.referrer || "";
  const inPortalFrame =
    referrer.includes("portal") || referrer.includes("portal-staging");
  return inPortalFrame && inIframe();
};
