import React from "react"
import { Link } from "react-router-dom"
import { useActions, useState } from "../lib/overmind"
import { useEventHook } from "../services/events"
import Stars from "../components/Stars"

const ReviewsEventManager = () => {
  const actions = useActions().toast
  const state = useState().account

  const redact =
    state.data.config.portal.google_reviews_dashboard.needs_account_upgrade

  useEventHook("new-review", (data) => {
    const body = () => (
      <div className="d-flex flex-column gap-1 mb-2 mx-2">
        <div className="d-flex gap-2">
          <img src="/google-web-light-round.svg" width="30px" height="30px" />
          <div className="d-flex flex-column" style={{ minHeight: "150px" }}>
            <span className="fw-bold fs-15 text-capitalize">
              {data.reviewer?.display_name}
            </span>
            <div className="text-secondary fs-13">Left a review just now!</div>
            <Stars
              rating={data.rating}
              style={{ fontSize: "20px" }}
              className={redact ? "blur-10" : ""}
            />
            <div className={"my-2 fs-15 ".concat(redact ? "blur-3" : "")}>
              {data.comment}
            </div>
            <div className="mt-auto">
              <Link
                className="btn btn-primary btn-sm py-1"
                to="/inbox/reviews"
                onClick={() => {
                  actions.clear()
                }}>
                Reply to review
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
    const toast = {
      title: () => <span>New Google Review</span>,
      body,
      className: "top-0 end-0",
      autohide: false,
    }

    actions.show(toast)
  })
  return null
}

export default ReviewsEventManager
