import React, { useEffect } from "react";
import { FeatureTierBadge } from "../../components/FeatureTierBadge";
import ReviewsDemoOverview from "../Overview/ReviewsDemoOverview";
import Loader from "../../components/Loader";
import ReviewsOverviewManager from "../Overview";
import { useGet } from "../../lib/api";
import { useState } from "../../lib/overmind";
import UpgradeAccountButtonV2, {
  PricingPlans,
} from "../../components/UpgradeAccountButtonV2";
import DiscoveredFeatureUpgrade from "../../FeatureUpgrade/DiscoveredFeatureUpgrade";
import { ListItem } from "../../FeatureUpgrade/SplitPanelFeatureUpgrade";
import ExternalLink from "../../components/ExternalLink";

const OverviewFactory = () => {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [useDemo, setUseDemo] = React.useState(false);
  const state = useState().account;
  const [get, isLoading, error, resp] = useGet();

  useEffect(() => {
    get("/shop/{exid}/integration", { name: "google" }, { timeout: 2000 });
    setIsLoaded(true);
  }, []);

  if (isLoading || !isLoaded) {
    return <Loader className="pt-5" />;
  }

  if (
    useDemo ||
    resp?.data.data[0].status !== "installed" ||
    !state.data.shop.google_location_id
  ) {
    return <ReviewsDemoOverview />;
  }
  return (
    <ReviewsOverviewManager
      redact
      noReviewsHandler={() => {
        setUseDemo(true);
      }}
    />
  );
};

const ReviewsUpgrade = () => {
  return (
    <div className="container position-relative" style={{ minHeight: "400px" }}>
      <div style={{ opacity: "60%", pointerEvents: "None" }}>
        <OverviewFactory />
      </div>
      <div
        className="position-absolute top-0 mt-5 w-100"
        style={{ left: "-60px" }}
      >
        <div className="d-flex justify-content-center align-items-center  mt-5">
          <DiscoveredFeatureUpgrade
            featureName="reviews"
            plan={PricingPlans.premium}
          >
            <p className="lead fs-18 mb-4">
              Are you leaving valuable reviews—and customers—on the table by not
              upgrading?
            </p>
            <div className="d-flex flex-column gap-3 mb-4">
              <ListItem>
                Increase review volume to boost{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.ikeono.com/blog/googlebusinessprofile"
                >
                  local SEO
                </a>{" "}
                and make your business easier to find.
              </ListItem>
              <ListItem>
                Boost your social proof,{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.ikeono.com/blog/3-facts-and-1-clever-workflow-to-get-more-google-reviews"
                >
                  88% of customers trust reviews like personal recommendations
                </a>
                .
              </ListItem>
              <ListItem>Start replying to reviews directly in Ikeono</ListItem>
            </div>
            <ExternalLink href="https://www.ikeono.com/reviews">
              Learn more about reviews.
            </ExternalLink>
          </DiscoveredFeatureUpgrade>
        </div>
      </div>
    </div>
  );
};

export default ReviewsUpgrade;
