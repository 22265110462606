import React from "react"
import DataCard from "../../components/DataCard"

const ReviewCountDataCard = ({
  totalReviewCount,
  initialTotalReviewCount,
  redact,
}) => {
  const delta = totalReviewCount - initialTotalReviewCount

  const body = (
    <span className={redact ? "blur-10" : ""}>{totalReviewCount}</span>
  )

  return (
    <DataCard
      title="Total Reviews"
      body={body}
      subtitle={
        <div
          hidden={delta <= 0 || !initialTotalReviewCount}
          className={redact ? "blur-3" : ""}>
          You have added{" "}
          <span className="badge bg-success">{delta} new reviews</span> since
          using Ikeono.
        </div>
      }
    />
  )
}

export default ReviewCountDataCard
