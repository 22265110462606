import React from "react";
import { Link } from "react-router-dom";
import { useState } from "../lib/overmind";

const EinWarning = () => {
  const { account } = useState();

  if (!account.data.ui_metadata.show_tcr_info_modal || account.country !== "US")
    return null;

  return (
    <div className="card border-black">
      <div
        className="card-header bg-transparent border-secondary"
        style={{ fontWeight: "600" }}
      >
        <div>
          <span className="bg-danger badge me-2"> Important</span>Update Account
          Details
        </div>
      </div>
      <div className="card-body">
        <p>
          We need additional information for your account{" "}
          <b>by August 18th, 2024</b> otherwise you are at risk of having your
          account locked until this information is provided.
        </p>
        <Link className="btn btn-primary" to="/settings/account">
          Open Account Details
        </Link>
      </div>
    </div>
  );
};

export default EinWarning;
