import React from "react"

const LightspeedWorkorderZeroState = () => (
  <div className="text-center my-5 text-secondary">
    <h5>No automations yet</h5>
    <p>
      <a
        role="button"
        className="text-underline"
        data-bs-target="#createWorkorderOffcanvas"
        data-bs-toggle="offcanvas">
        Create your first status configuration
      </a>
    </p>
  </div>
)

export default LightspeedWorkorderZeroState
