import React from "react";
import LightspeedStoreAssignment from "./LightspeedStoreAssignment";
import { closePopup } from "../../lib/popup";

const LightspeedRSeriesOauthComplete = () => {
  return (
    <div className="container mt-4">
      <h5>Assign your store location</h5>
      <LightspeedStoreAssignment
        onSubmit={() => {
          closePopup();
        }}
      />
    </div>
  );
};

export default LightspeedRSeriesOauthComplete;
