import React from "react";
import { formatDate } from "../../lib/date";
import ImpactBadge from "./ImpactBadge";
import Badge from "../../components/Badge";

const Incident = ({ name, status, updatedAt, impact, children }) => {
  return (
    <div className="card border-secondary mb-3">
      <div className="card-header pt-3">
        <div className="card-title  d-flex ">
          <div className=" d-flex flex-column me-auto">
            <h6 className="pb-0 mb-1 fw-bold">{name}</h6>
            <span className="fs-15 text-secondary">
              Last Update: {formatDate(updatedAt)}
            </span>
            <span className="fs-15 text-secondary text-capitalize">
              Status: <Badge>{status}</Badge>
            </span>
          </div>
          <div className="text-capitalize">
            <ImpactBadge impact={impact} />
          </div>
        </div>
      </div>
      <div className="card-body">{children}</div>
    </div>
  );
};

export default Incident;
