import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useActions } from "../../lib/overmind";
import { formatPhoneNumberWithAccountCountry } from "../../lib/overmind/account/util";

const ContactRow = ({
  name,
  phoneNumber,
  email,
  vendorId,
  vendorSource,
  onCreateThread,
}) => {
  const params = useParams();
  const actions = useActions().threadCreateContacts;
  const navigate = useNavigate();

  const onClick = () => {
    if (!phoneNumber) {
      actions.setSelectedContact({
        name,
        email,
        vendorId,
        vendorSource,
      });
      navigate(`/inbox/${params.inboxName}/new/create`, { replace: true });
    } else {
      onCreateThread({ name, phoneNumber, vendorId, vendorSource, email });
    }
  };
  return (
    <div
      role="button"
      className="d-flex w-100 h-100 align-items-center gap-2"
      onClick={onClick}
    >
      <i className="bi-person-circle" />
      <div className="f-flex flex-column">
        <div className="d-flex align-content-center fw-bold text-capitalize">
          {name}
        </div>
        <div className="inbox-contact-phone-number text-secondary">
          {formatPhoneNumberWithAccountCountry(phoneNumber)}
        </div>
      </div>
    </div>
  );
};

export default ContactRow;
