import React, { useEffect } from "react";
import IntegrationCard from "./IntegrationCard";
import { LightspeedXSeriesAppIcon } from "../../IntegrationAppIcon";
import Loader from "../../components/Loader";
import { PosSystem } from "../../lib/pos";
import { useState } from "../../lib/overmind";
import useVendOAuthHook from "../../components/Vend/VendOauthHook";
import { ConnectedBadge } from "../../components/InstalledBadge";
import useIntegrationErrorHook from "./IntegrationErrorHook";

const LightspeedXSeriesIntegrationCard = ({ hidden }) => {
  if (hidden) return null;
  const state = useState().account;
  const [isLoading, error, onClick] = useVendOAuthHook();
  useIntegrationErrorHook(error);

  return (
    <IntegrationCard
      icon={<LightspeedXSeriesAppIcon />}
      title={
        <span>
          Lightspeed X-Series <Loader hidden={!isLoading} inline />
        </span>
      }
      onClick={onClick}
    >
      <small className="d-block mb-1">Formerly Vend</small>
      <ConnectedBadge
        hidden={state.posSystem !== PosSystem.LightspeedXSeries}
      />
    </IntegrationCard>
  );
};

export default LightspeedXSeriesIntegrationCard;
