import React from "react";
import TemplatesSettings from "./TemplatesSettings";
import UserPermissionCheck from "../components/UserPermissionCheck";

const TemplateContainer = () => {
  return (
    <UserPermissionCheck permissionKey="view_settings">
      <div className="container my-4">
        <TemplatesSettings />
      </div>
    </UserPermissionCheck>
  );
};

export default TemplateContainer;
