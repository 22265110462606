export const update = async ({ state }, { forcePasswordChange }) => {
  state.user.forcePasswordChange = forcePasswordChange;
};

export const updateForcePasswordChange = async (
  { state },
  forcePasswordChange,
) => {
  state.user.forcePasswordChange = forcePasswordChange;
};
