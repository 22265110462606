// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.location-dropdown-btn {
  background-color: transparent;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid rgb(242, 242, 242);
  box-shadow: none;
  max-height: 50px;
}

.location-dropdown-btn-dark {
  border: 1px solid black !important;
  color: black !important;
}

@media (max-width: 575px) {
  .location-dropdown-btn {
    max-width: 150px;
  }
}

.location-dropdown-btn i {
  position: absolute;
}

.location-dropdown ul {
  max-height: 80vh;
  overflow: scroll;
}

@media (max-width: 575px) {
  .location-dropdown-menu {
    width: 100% !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/LocationSelectDropDown/style.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,eAAe;EACf,kBAAkB;EAClB,oCAAoC;EACpC,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,kCAAkC;EAClC,uBAAuB;AACzB;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE;IACE,sBAAsB;EACxB;AACF","sourcesContent":[".location-dropdown-btn {\n  background-color: transparent;\n  font-size: 14px;\n  border-radius: 5px;\n  border: 1px solid rgb(242, 242, 242);\n  box-shadow: none;\n  max-height: 50px;\n}\n\n.location-dropdown-btn-dark {\n  border: 1px solid black !important;\n  color: black !important;\n}\n\n@media (max-width: 575px) {\n  .location-dropdown-btn {\n    max-width: 150px;\n  }\n}\n\n.location-dropdown-btn i {\n  position: absolute;\n}\n\n.location-dropdown ul {\n  max-height: 80vh;\n  overflow: scroll;\n}\n\n@media (max-width: 575px) {\n  .location-dropdown-menu {\n    width: 100% !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
