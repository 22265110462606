import React from "react";

const BrowserExtensionInstalledBadge = ({ props }) => (
  <div className="alert alert-success py-1 px-3 d-inline-block " {...props}>
    <i className="bi-check-circle me-2" />
    The Ikeono extension is installed.
  </div>
);

export default BrowserExtensionInstalledBadge;
