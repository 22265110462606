import React, { useEffect } from "react";
import Modal from "../components/Modal";
import NotificationSubscribe from "./NotificationSubscribe";
import NotificationSubscribeSuccess from "./NotificationSubscribeSuccess";
import NotificationDeniedWarning from "./NotificationDeniedWarning";
import AudibleNotification from "../Messenger/AudibleNotification";
import { useActions, useState } from "../lib/overmind";

export const NotificationPreferenceModalId = "notification-preference-modal";

const NotificationPreferenceModal = () => {
  const { isSubscribed, isDenied } = useState().pushNotification;
  const actions = useActions().pushNotification;

  useEffect(() => {
    actions.refreshStatus();
  }, []);

  return (
    <Modal
      title="Manage notification preferences"
      id={NotificationPreferenceModalId}
    >
      <div className="mb-2">
        <b>Push notifications</b>
        <NotificationSubscribeSuccess hidden={!isSubscribed} />
        {!isSubscribed && !isDenied && <NotificationSubscribe />}
        <NotificationDeniedWarning hidden={!isDenied} />
      </div>

      <AudibleNotification />

      <div className="text-end mt-4">
        <button
          className="btn btn-outline-secondary"
          type="button"
          data-bs-dismiss="modal"
        >
          Done
        </button>
      </div>
    </Modal>
  );
};

export default NotificationPreferenceModal;
