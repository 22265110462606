import React from "react";
import { formatCurrency } from "../lib/currency";

const TotalRow = ({ total }) => (
  <div className="d-flex flex-row gap-2 mt-2">
    <div className="flex-fill">
      <input
        className="form-control fw-bold shadow-none"
        required
        style={{ flex: 1, border: "none" }}
        // groupClassName="flex-fill"
        value="Total"
        readOnly
      />
    </div>
    <div>
      <input
        className="form-control fw-bold shadow-none"
        required
        style={{ maxWidth: "120px", border: "none" }}
        value={`${formatCurrency(total)}`}
        readOnly
      />
    </div>
    <div className="d-flex gap-2 d-none">
      <button className="btn btn-link text-bg-light invisible" type="button">
        <i className="bi-plus-lg" />
      </button>
      <button
        className="btn btn-link text-secondary p-0 invisible"
        type="button"
      >
        <i className="bi-trash " />
      </button>
    </div>
  </div>
);

export default TotalRow;
