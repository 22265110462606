import React, { useEffect, useState } from "react"

const InboxCollapseButton = ({ isCollapsed, onClick }) => {
  return (
    <button
      type="button"
      className="btn btn-link text-secondary"
      onClick={onClick}
      title={`${isCollapsed ? "Expand" : "Collapse"} the inbox list`}>
      <i
        className={`bi-box-arrow-${
          isCollapsed ? "right" : "left"
        } position-absolute`}
        style={{ right: "12px", bottom: "8px" }}
      />
    </button>
  )
}

export default InboxCollapseButton
