import React from "react";
import APIResponseError from "../APIResponseError";

const DataTableError = ({ responseError, onClick }) => {
  if (!responseError) return null;
  return (
    <div className="text-center mt-2">
      <APIResponseError response={responseError}>
        <button
          type="button"
          className="btn btn-primary mt-3 d-block"
          onClick={onClick}
        >
          Reload
        </button>
      </APIResponseError>
    </div>
  );
};

export default DataTableError;
