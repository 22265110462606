import React, { useEffect } from "react"
import InboxList from "./InboxList"
import InboxCollapseButton from "./InboxCollapseButton"
import { LocalStorageKey } from "../lib/localStorageKey"
import InboxFolderIcon from "./InboxFolderIcon"
import { useState } from "../lib/overmind"

const key = new LocalStorageKey("ikInboxListIsCollapsed")

const OverallInboxFolderIcon = ({ hidden }) => {
  const state = useState().inboxes

  if (hidden) return null
  return (
    <div className="d-flex justify-content-center mt-1">
      <InboxFolderIcon hasUnreadMessages={state.locationHasUnreadMessages} />
    </div>
  )
}

const InboxListColumn = () => {
  const [isCollapsed, setIsCollapsed] = React.useState(key.isEnabled())

  useEffect(() => {
    key.set(isCollapsed)
  }, [isCollapsed])

  return (
    <div
      style={{ width: "250px", minWidth: isCollapsed ? null : "250px" }}
      className={`position-relative border-end bg-light overflow-y-scroll p-2 pb-4 d-none d-md-block ${
        isCollapsed ? "inbox-list-collapsed" : ""
      }`}>
      <div
        role="button"
        onClick={() => {
          setIsCollapsed(!isCollapsed)
        }}>
        <OverallInboxFolderIcon hidden={!isCollapsed} />
      </div>
      <InboxList hidden={isCollapsed} />
      <InboxCollapseButton
        isCollapsed={isCollapsed}
        onClick={() => {
          setIsCollapsed(!isCollapsed)
        }}
      />
    </div>
  )
}

export default InboxListColumn
