/* eslint-disable */
import React from "react";
import * as Sentry from "@sentry/browser";
import { get } from "../../lib/api";
import Loader from "../Loader";
import { isLoggedIn } from "../../lib/auth";
import $ from "jquery";
import "./style.css";

function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (decodeURIComponent(pair[0]) == variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  console.log("Query variable %s not found", variable);
}

export default class LightspeedSaleReceiptFormEmbed extends React.Component {
  constructor(props) {
    super(props);
    $("body").addClass("ls-body");

    const workorderId = getQueryVariable("workorder_id");
    const saleId = getQueryVariable("sale_id");
    const mode = getQueryVariable("mode");
    const title = mode === "receipt" ? "Send SMS Receipt" : "Send SMS Quote";

    this.state = {
      saleId,
      receiptType: mode === "receipt" ? "ls sale" : "ls workorder",
      workorderId,
      title,
      isLoading: false,
      showSuccess: false,
      errorMessage: null,
      showError: true,
      customerPhoneNumber: "",
      isLookingUpPhone: true,
    };

    this.showSuccessElement = this.showSuccessElement.bind(this);
    this.showErrorElement = this.showErrorElement.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (!isLoggedIn()) return;
    if (!this.state.workorderId && !this.state.saleId) {
      this.setState({
        customerPhoneNumber: "",
        isLookingUpPhone: null,
        errorMessage:
          "Unable to pull information needed. Please reload page or contact help@ikeono.com.",
      });
      return;
    }
    const saleId = this.state.saleId;
    const workorderId = this.state.workorderId;
    let endpoint = `/shop/{exid}/customer/phone?sale_id=${saleId}`;
    if (workorderId) {
      `/shop/{exid}/customer/phone?workorder_id=${workorderId}`;
    }

    get(endpoint)
      .then((response) => {
        this.setState({
          customerPhoneNumber: response.data.mobile_phone
            ? response.data.mobile_phone
            : "",
          isLookingUpPhone: false,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isLookingUpPhone: false,
        });
      });
  }

  showSuccessElement() {
    this.setState({
      showSuccess: true,
      showError: false,
      isLoading: false,
    });

    setTimeout(() => this.setState({ showSuccess: false }), 5000);
  }

  showErrorElement(message) {
    this.setState({
      showSuccess: false,
      showError: true,
      errorMessage: message,
      isLoading: false,
    });
  }

  errorElement() {
    if (!this.state.showError) {
      return;
    }
    return <span className="text-danger">{this.state.errorMessage}</span>;
  }

  handleSubmit(e) {
    e.preventDefault();
    const phoneNumber = $("#phoneNumber").val();

    if (!phoneNumber) {
      this.showErrorElement("Please enter a phone number");
      return;
    }

    this.setState({
      isLoading: true,
      showError: false,
      showSuccess: false,
    });

    let receiptPath = `/shop/{exid}/sale/${this.state.saleId}/send_receipt`;
    if (this.state.workorderId) {
      receiptPath = `/shop/{exid}/workorder/${this.state.workorderId}/send_receipt`;
    }

    get(receiptPath, { mobile_phone: phoneNumber })
      .then((response) => {
        const { data } = response;
        if (data.status === "ERR") {
          this.showErrorElement(
            data.message
              ? data.message
              : "There was an error sending. Please try again.",
          );
        } else {
          this.showSuccessElement();
        }
      })
      .catch((error) => {
        let message =
          error.response?.data?.detail ||
          "There was an error sending. Please try again.";

        this.showErrorElement(message);
        Sentry.captureException(error);
      });
  }

  loader() {
    if (!this.state.isLoading) {
      return null;
    }
    const width = $("#sendButton").width();
    const height = $("#sendButton").height();
    return (
      <div style={{ width, height, paddingTop: "6px" }}>
        <Loader />
      </div>
    );
  }

  formComponent() {
    const opacity = this.state.isLookingUpPhone ? 0.7 : 1.0;
    return (
      <form
        style={{ opacity }}
        className="form-inline"
        onSubmit={this.handleSubmit}
      >
        <input
          type="text"
          className="form-control mr-sm-2"
          id="phoneNumber"
          placeholder="Mobile Phone Number"
          value={this.state.customerPhoneNumber}
          onChange={(e) =>
            this.setState({
              customerPhoneNumber: e.target.value,
              showError: false,
            })
          }
          disabled={this.state.isLoading}
        />
        <button
          type="submit"
          id="sendButton"
          className="btn ls-button my-1"
          disabled={this.state.isLoading}
        >
          {!this.state.isLoading && "Send"}
          {this.loader()}
        </button>
      </form>
    );
  }

  lookupLoader() {
    if (!this.state.isLookingUpPhone) {
      return;
    }
    return (
      <div className="position-absolute" style={{ left: "90px" }}>
        <Loader />
      </div>
    );
  }

  tableComponent = () => {
    return (
      <table className="table text-left mb-0">
        <thead className="ls-thead">
          <tr>
            <th scope="col" className="pt-1 pb-1">
              <b>{this.state.title}</b>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row" className="pl-0">
              <div>
                {this.lookupLoader()}
                {this.formComponent()}
                {this.state.showSuccess && (
                  <span className="text-success">Sent successfully</span>
                )}
                {this.errorElement()}
              </div>
            </th>
          </tr>
        </tbody>
      </table>
    );
  };

  notLoggedInComponent = () => {
    return (
      <div className="alert alert-warning" role="alert">
        Please login at{" "}
        <a target="_blank" href="https://app.ikeono.com">
          portal.ikeono.com
        </a>{" "}
        and refresh this page to send receipts.
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <div>
          {isLoggedIn() ? this.tableComponent() : this.notLoggedInComponent()}
        </div>
      </React.Fragment>
    );
  }
}
/* eslint-enable */
