/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
import * as React from "react"
import $ from "jquery"

const bootstrap = require("bootstrap")

export const OffcanvasPlacement = {
  start: "start",
  end: "end",
  top: "top",
  bottom: "bottom",
}

export const toggleOffcanvas = (id) => {
  new bootstrap.Offcanvas(document.getElementById(id), {
    keyboard: false,
  })?.toggle()
}

export const isOffcanvasVisible = (id) => {
  return document.getElementById(id)?.className?.includes("show")
}

export const showOffcanvas = (id) => {
  new bootstrap.Offcanvas(document.getElementById(id), {
    keyboard: false,
  })?.show()
}

export const hideOffcanvas = (id) => {
  $(`#${id}CloseButton`).click()
  new bootstrap.Offcanvas(document.getElementById(id), {
    keyboard: false,
  }).hide()
}

export class OffcanvasManager {
  constructor(offcanvasId) {
    this.id = offcanvasId
  }

  toggle() {
    if (this.isVisible()) return
    toggleOffcanvas(this.id)
  }

  hide() {
    if (!this.isVisible()) return
    $("[data-bs-dismiss='offcanvas']").click()
  }

  isVisible() {
    return isOffcanvasVisible(this.id)
  }
}

const OffCanvas = ({
  datatarget,
  children,
  title,
  placement = OffcanvasPlacement.end,
  onShow,
  onHide,
  bodyClass = "",
}) => {
  const ref = React.useRef()
  const [showChildren, setShowChildren] = React.useState(false)

  React.useEffect(() => {
    // Only show children when the modal is visible to prevent unnecessary API calls
    const eventHandler = () => {
      setShowChildren(true)
      onShow && onShow()
    }
    ref.current?.addEventListener("show.bs.offcanvas", eventHandler)
    if (onHide) {
      ref.current?.addEventListener("hide.bs.offcanvas", onHide)
    }

    return () => {
      ref.current?.removeEventListener("show.bs.offcanvas", eventHandler)
      if (onHide) {
        ref.current?.removeEventListener("hide.bs.offcanvas", onHide)
      }
    }
  }, [])
  // https://getbootstrap.com/docs/5.0/components/offcanvas/
  return (
    <div
      ref={ref}
      className={`offcanvas offcanvas-${placement}`}
      tabIndex="-1"
      id={datatarget}
      aria-labelledby={`${datatarget}Label`}>
      <div className="offcanvas-header">
        <h5 id={`${datatarget}Label`} className="mb-0">
          {title}
        </h5>
        <button
          id={`${datatarget}CloseButton`}
          type="button"
          className="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        />
      </div>
      <div className={`offcanvas-body pt-0 ${bodyClass}`}>
        {showChildren && children}
      </div>
    </div>
  )
}

export default OffCanvas
