import React from "react";
import { useActions, useState } from "../../lib/overmind";
import ThreadActionItem from "./ThreadActionItem";

const MarketingOptOutItem = ({ hidden }) => {
  if (hidden) return null;
  const state = useState().activeThread;
  const actions = useActions().activeThread;

  const isOptedOut = state.detail?.receiverOptedOutOfMarketing;
  const title = isOptedOut
    ? "Subscribe to marketing messages"
    : "Remove from marketing messages";
  const icon = isOptedOut ? "bi-person-add" : "bi-person-x";
  const description = isOptedOut
    ? "This contact will start receiving your marketing messages."
    : "This contact will stop receiving your marketing messages.";

  return (
    <ThreadActionItem
      onClick={actions.toggleMarketingOptOut}
      title={title}
      description={description}
      iconName={icon}
    />
  );
};

export default MarketingOptOutItem;
