import React from "react"
import * as api from "../../lib/api"
import LabeledField, { LabeledFieldType } from "../Form/LabeledField"
import SimpleForm from "../Form/SimpleForm"

const VendReceiptForm = () => {
  return (
    <SimpleForm
      apiGetHandler={() => api.get("/shop/{exid}/vend/receipt/template")}
      apiSubmitHandler={(values) =>
        api.put("/shop/{exid}/vend/receipt/template", values)
      }
      submitButtonPortalNodeId="receiptTemplollateSubmitButton"
      submitButtonProps={{ children: "Save" }}>
      {(response) => (
        <LabeledField
          label="Selected Template"
          labelType={LabeledFieldType.formGroup}
          id="selected_template_id"
          name="selected_template_id"
          className="form-select w-auto"
          as="select">
          <option value="" />
          {response?.data?.templates?.map(({ id, name }) => (
            <option key={id} value={id}>
              {name}
            </option>
          ))}
        </LabeledField>
      )}
    </SimpleForm>
  )
}

export default VendReceiptForm
