import * as api from "../../api"

export const get = async (params) => {
  const response = await api.get("/shop/{exid}/nps", params)
  return response.data
}

export const getDetail = async (id) => {
  const response = await api.get(`/shop/{exid}/nps/detail/${id}`)
  return response.data
}

export const update = (id, data) => {
  return api.put(`/shop/{exid}/nps/detail/${id}`, data)
}
