import React from "react"
import { QRCodeCanvas } from "qrcode.react"
import { SettingsFactory } from "../components/Settings/SettingsFactory"
import { useState } from "../lib/overmind"

const Component = () => {
  const state = useState().account
  const qrCodeValue = `https://survey.ikeono.com/QyFYRz6e?sid=${state.shopExternalId}&source=qr`

  const filename = `NPS Survey ${state?.shopName || state.shopExternalId}.png`
  const onDownload = () => {
    const canvas = document.getElementById("1234")
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream")
    const downloadLink = document.createElement("a")
    downloadLink.href = pngUrl
    downloadLink.download = filename
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }

  return (
    <div className="d-flex ">
      <div className="card w-100">
        <div className="card-header">QR Code Link</div>
        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            Copy and paste this URL into any 3rd party platform that prints QR
            codes.
            <code className="d-block mt-2">{qrCodeValue}</code>
          </li>
        </ul>
      </div>
      <div className="d-flex flex-column align-items-center justify-content-center w-100">
        <QRCodeCanvas id="1234" value={qrCodeValue} />
        <button type="button" className="btn btn-link" onClick={onDownload}>
          Download Image
        </button>
      </div>
    </div>
  )
}

const NpsSurveyConfig = () => {
  return (
    <div>
      <h5 className="my-3">NPS Survey Config</h5>
      <Component />
    </div>
  )
}

export default NpsSurveyConfig
