// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-invoice {
  width: 275px;
}

.card-invoice-amount {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0px;
}

.card-invoice-description {
  margin: 2px;
  padding: 0px;
  font-size: 15px;
}

.card-invoice a {
  font-size: 14px;
}

.card-invoice-expired {
  font-size: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/MessageCard/MessageInvoiceCard/MessageInvoiceCard.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".card-invoice {\n  width: 275px;\n}\n\n.card-invoice-amount {\n  font-size: 24px;\n  font-weight: 700;\n  margin-bottom: 0px;\n}\n\n.card-invoice-description {\n  margin: 2px;\n  padding: 0px;\n  font-size: 15px;\n}\n\n.card-invoice a {\n  font-size: 14px;\n}\n\n.card-invoice-expired {\n  font-size: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
