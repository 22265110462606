import React from "react";
import InfoBubble from "../lib/InfoBubble";
import ExternalLink from "../components/ExternalLink";

const InvoiceSettingsWarning = ({ hidden }) => {
  if (hidden) return null;
  return (
    <InfoBubble variant="warning" className="mt-3 mx-2">
      <div>
        <b>Action Required</b>
        <div className="fs-16">
          Before sending an invoice you need to update your invoice settings.{" "}
          <ExternalLink
            href={`${window.location.origin}/settings/location/payments/deposits`}
          >
            Open Invoice Settings
          </ExternalLink>
        </div>
      </div>
    </InfoBubble>
  );
};

export default InvoiceSettingsWarning;
