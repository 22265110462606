import React from "react"
import { useActions, useState } from "../lib/overmind"

const InboxFilterDropDown = () => {
  const state = useState().activeInbox
  const actions = useActions().activeInbox
  const setFilterOption = (showUnopened) => {
    actions.updateFilter({ showUnopened })
  }

  return (
    <div className="btn-group ms-auto">
      <button
        type="button"
        title="Select multiple conversations"
        onClick={() => actions.toggleMultiSelectEnabled()}
        className={`btn ${
          state.multiSelectEnabled ? "btn-primary" : "btn-outline-secondary"
        } p-0 m-auto rounded`}
        style={{ width: "25px", height: "25px", fontSize: "14px" }}>
        <i className="bi-ui-checks " />
      </button>
      <button
        style={{ fontSize: "13px" }}
        className="btn btn-link dropdown-toggle pe-0 text-secondary"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false">
        {state.filter.showUnopened ? "Unopened" : "Latest"}
      </button>
      <ul className="dropdown-menu">
        <li>
          <a
            className="dropdown-item"
            role="button"
            onClick={() => setFilterOption(false)}>
            Latest
          </a>
        </li>
        <li>
          <a
            className="dropdown-item"
            role="button"
            onClick={() => setFilterOption(true)}>
            Unopened
          </a>
        </li>
      </ul>
    </div>
  )
}

export default InboxFilterDropDown
