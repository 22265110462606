import React from "react"
import Stars from "../../components/Stars"
import { formatRelativeDate } from "../../lib/date"

const Header = ({ reviewerName, rating, updateTime, redact }) => {
  const relativeDate = formatRelativeDate(updateTime)

  return (
    <>
      <span className="fw-14 fw-bold text-capitalize">{reviewerName}</span>
      <div className="d-flex flex-column gap-1 ">
        <div className="d-flex flex-column">
          <span className="fs-13 text-secondary">
            Left a review {relativeDate.usedRelativeDate ? "" : "on "}
            {relativeDate.dateString}
          </span>
          <span className="fs-13 text-secondary">on Google</span>
        </div>

        <Stars
          rating={rating}
          className={redact ? "blur-9" : ""}
          style={{
            fontSize: "20px",
          }}
        />
      </div>
    </>
  )
}

export default Header
