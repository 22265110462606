import React from "react"

const ReviewsZeroState = ({ hidden }) => {
  if (hidden) return null
  return (
    <div className="text-center text-secondary">
      <span className="h5">No reviews yet, but they will roll in soon.</span>
    </div>
  )
}

export default ReviewsZeroState
