import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import DataTable from "../components/DataTable";
import { useGet } from "../lib/api";
import APIResponseError from "../components/APIResponseError";
import Loader from "../components/Loader";
import FilterBar2 from "../components/DataTable/FilterBar2";
import { useState } from "../lib/overmind";
import ReportUpgrade from "./ReportUpgrade";
import { FeatureTierBadge } from "../components/FeatureTierBadge";
import { todayMinus } from "../lib/date";

const Report = () => {
  const { reportId } = useParams();
  const [get, isLoading, error, resp] = useGet();
  const state = useState().account;
  const [params, setParams] = React.useState({
    start_date: todayMinus(14),
    end_date: todayMinus(0),
  });

  useEffect(() => {
    const computedParams = {
      report_id: reportId,
      shop_id: state.shopExternalId,
      ...params,
    };

    get(`/account/{account_id}/report`, computedParams);
  }, [reportId, params]);

  if (error?.response?.status === 403) {
    return <ReportUpgrade />;
  }
  if (error) return <APIResponseError response={error} />;
  if (isLoading || !resp) return <Loader />;
  if (!resp) return null;

  const onFormChange = (values) => {
    setParams(values);
  };

  return (
    <div>
      <div hidden={!resp?.data?.is_sample} className=" bg-info-subtle p-2">
        <b>Sample data</b>, see below to access this report.
      </div>
      <div className="d-flex align-items-center gap-3  mt-3 mb-3 mx-3">
        <h5 className="mb-0">{resp?.data?.title}</h5>
        <FeatureTierBadge
          premium={resp.data.is_premium_feature}
          hidden={!resp.data.is_premium_feature}
        />
      </div>
      <div className="px-3">
        <ReportUpgrade hidden={!resp?.data?.is_sample} />
        <FilterBar2
          params={params}
          controls={resp?.data?.filters}
          onChange={onFormChange}
          disabled={resp?.data?.is_sample}
        />
      </div>
      <DataTable rows={resp?.data?.rows} headers={resp?.data?.headers} />
    </div>
  );
};

export default Report;
