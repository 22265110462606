import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useActions, useState } from "../../lib/overmind";

const CreateContactRow = () => {
  const navigate = useNavigate();
  const actions = useActions().threadCreateContacts;
  const state = useState().threadCreateContacts;
  const params = useParams();

  const onClick = () => {
    actions.setSelectedContact({ name: state.query });
    navigate(`/inbox/${params.inboxName}/new/create`, { replace: true });
  };

  return (
    <div
      role="button"
      onClick={onClick}
      className="d-flex flex-column justify-content-center fw-bold inbox-row"
      style={{ height: "85px" }}
    >
      <div className="fw-bold">
        <i className="bi-plus-circle me-1" /> New Contact
      </div>
    </div>
  );
};

export default CreateContactRow;
