import React from "react"
import { NavLink } from "react-router-dom"
import InboxFolderIcon from "./InboxFolderIcon"
import { useMixpanelTrackingRef } from "../services/mixpanel"
import { useState } from "../lib/overmind"
import { supportedCountries } from "../Reviews"
import BadgeCount from "../components/BadgeCount"

const SetupBadge = ({ hidden, isActive }) => {
  if (hidden) return null
  const treatment = isActive ? "light" : "dark"
  return (
    <span className={`badge border border-${treatment} text-${treatment}`}>
      Setup
    </span>
  )
}

const InboxReviewListItem = ({ slug, title }) => {
  const state = useState()
  const unreadMessageCount = state.account.data.ui_metadata.unread_review_count
  const isGoogleInstalled =
    state.integrations.integrationsIndex?.google.status === "uninstalled"

  const trackingRef = useMixpanelTrackingRef("click inbox review row", {
    slug,
    title,
    unreadMessageCount,
  })

  if (!supportedCountries.includes(state.account.country)) {
    return null
  }

  return (
    <NavLink
      ref={trackingRef}
      style={{ paddingTop: "7px", paddingBottom: "7px" }}
      className={({ isActive }) => {
        return isActive
          ? "nav-link inbox-list-item-active fs-15 bg-dark"
          : "nav-link text-dark fs-15"
      }}
      to="/inbox/reviews">
      {({ isActive }) => (
        <div className="d-flex text-capitalize">
          <InboxFolderIcon
            hasUnreadMessages={!!unreadMessageCount}
            iconName="bi-star"
          />
          <span className="w-100 ms-2">Reviews</span>
          <div>
            <BadgeCount isActive={isActive}>{unreadMessageCount}</BadgeCount>
            <SetupBadge isActive={isActive} hidden={!isGoogleInstalled} />
          </div>
        </div>
      )}
    </NavLink>
  )
}

export default InboxReviewListItem
