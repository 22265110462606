import React from "react"
import NpsScorePieChart from "./NpsScorePieChart"
import NpsScoreBarChart from "./NpsScoreBarChart"
import NpsDetractorList from "./NpsDetractorList"
import PosNegLabel from "../../components/PosNegLabel"
import DataCard from "../../components/DataCard"

const formatPreviousDataPoint = (dataPoint, previousDataPoint) => {
  return (
    <span>
      Previous {dataPoint}
      <PosNegLabel startValue={dataPoint} endValue={previousDataPoint} />
    </span>
  )
}

const NpsDashboard = ({ data }) => {
  if (!data) return null

  const responseRate = Math.min(
    parseInt(
      (data.current.total_replies / (data.current.totalSent || 1)) * 100
    ),
    100
  )

  const previousResponseRate = Math.min(
    parseInt(
      (data.previous.total_replies / (data.previous.totalSent || 1)) * 100
    ),
    100
  )

  return (
    <>
      <div className="row">
        <DataCard
          title="NPS"
          body={data.current.nps_score}
          subtitle={formatPreviousDataPoint(
            data.previous.nps_score,
            data.current.nps_score
          )}
        />
        <DataCard
          title="YTD Score"
          body={data.ytd.nps_score}
          subtitle="All Time"
        />
        <DataCard
          title="Total Sent"
          body={data.current.totalSent}
          subtitle={formatPreviousDataPoint(
            data.previous.totalSent,
            data.current.totalSent
          )}
        />
        <DataCard
          title="Total Repiles"
          body={data.current.total_replies}
          subtitle={formatPreviousDataPoint(
            data.previous.total_replies,
            data.current.total_replies
          )}
        />
        <DataCard
          title="Response Rate"
          body={`${responseRate.toString()}%`}
          subtitle={formatPreviousDataPoint(`${previousResponseRate}%`)}
        />
      </div>
      <div className="row  align-items-center gap-3 mb-3">
        <div className="col card">
          <div className="card-header bg-transparent text-center">
            Responses by Type
          </div>
          <div className="card-body">
            <NpsScorePieChart
              promoterTotal={data.current.promoter_total}
              detractorTotal={data.current.detractor_total}
              neutralTotal={data.current.neutral_total}
              totalReplies={data.current.total_replies}
            />
          </div>
        </div>
        <div className="col card p-0">
          <div className="card-header  bg-transparent text-center">
            Responses by Score
          </div>
          <div className="card-body">
            <NpsScoreBarChart scoreData={data.current.data} />
          </div>
        </div>
      </div>
      <div className="row mb-5">
        <div className="col">
          <NpsDetractorList detractors={data.current.detractor_records} />
        </div>
      </div>
    </>
  )
}

export default NpsDashboard
