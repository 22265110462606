import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "./Button";
import * as api from "../lib/api";
import { Env, EnvEnum } from "../lib/environment";
import { useState } from "../lib/overmind";

const isProduction = Env === EnvEnum.production;

class Plan {
  constructor(name, prod_plan_id, dev_plan_id) {
    this.prod_plan_id = prod_plan_id;
    this.dev_plan_id = dev_plan_id;
    this.name = name;
  }

  getStripeUrl(baseUrl, subscriptionId) {
    const planId = isProduction ? this.prod_plan_id : this.dev_plan_id;
    return `${baseUrl}/subscriptions/${subscriptionId}/preview/${planId}?quantity=1`;
  }
}

export const PricingPlans = {
  premium: new Plan(
    "premium",
    "price_1OwA8zEbQwueHA5Z5YwKSUlm",
    "price_1J2FfOEbQwueHA5ZUl43FKVu"
  ),
  standard: new Plan(
    "standard",
    "price_1OwA8gEbQwueHA5Zgxv7WCGE",
    "price_1J2FewEbQwueHA5ZZkLYPAPG"
  ),
};

export const UpgradeAccountButtonV2 = ({
  hidden,
  plan,
  featureName,
  redirectPath,
}) => {
  if (hidden) return null;
  const [get, _, error, resp] = api.useGet();
  const [isLoading, setIsLoading] = React.useState(false);
  const state = useState().account;
  const navigate = useNavigate();

  const onClick = () => {
    setIsLoading(true);
    get("/account/{account_id}/billing/portal", {
      redirect_path: redirectPath,
    });

    const data = {
      detail: `Shop wants to upgrade to ${featureName}: ${state.shopName}`,
    };
    api.get("/shop/{exid}/account/upgrade", data);
  };

  useEffect(() => {}, [error]);

  useEffect(() => {
    if (!resp) return;
    const { url, active_plan } = resp.data;

    if (!active_plan) {
      navigate("/settings/billing");
      return;
    }

    window.location =
      plan?.getStripeUrl(url, active_plan?.subscription_id) || url;
  }, [resp]);

  return (
    <Button
      showLoader={isLoading && !error}
      onClick={onClick}
      className="align-self-start "
      type="button"
      style={{
        minWidth: "240px",
        height: "53px",
        border: "none",
        color: "white",
        background: 'url("/blurble-button.svg") no-repeat 0 0 transparent',
        backgroundSize: "cover",
        fontWeight: 600,
        fontSize: "17px",
      }}
    >
      <img
        style={{ height: "23px" }}
        src="/sparkle-left.svg"
        className="me-2"
      />
      Upgrade to <span className="text-capitalize">{plan?.name}</span>
    </Button>
  );
};

export default UpgradeAccountButtonV2;
