import React from "react"
import { formatDate, formatRelativeDate } from "../lib/date"

const MessageCardDate = ({ date }) => {
  return (
    <span className="text-secondary fs-12" title={formatDate(date)}>
      {formatRelativeDate(date).dateString}
    </span>
  )
}

export default MessageCardDate
