import React from "react";
import AppFaq from "./AppFaq";
import AppContact from "./AppContact";
import AppStatus from "./AppStatus";

const Support = () => {
  return (
    <div className="container mt-4">
      <div className="d-flex gap-5">
        <div className="w-100">
          <AppStatus />
        </div>
        <div className="w-100 d-flex flex-column gap-4">
          <AppContact />
          <AppFaq />
        </div>
      </div>
    </div>
  );
};

export default Support;
