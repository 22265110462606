import React from "react"
import ReviewsSettings from "../../Reviews/Settings/ReviewsSettings"
import FeaturePermissionCheck from "../../components/FeaturePermissionCheck"
import ReviewsUpgrade from "../../Reviews/Setup/ReviewsUpgrade"

const LocationReviewsSettings = () => {
  return (
    <FeaturePermissionCheck
      permissionKey="feedback_loop_module"
      upgradeComponent={<ReviewsUpgrade />}>
      <ReviewsSettings />
    </FeaturePermissionCheck>
  )
}

export default LocationReviewsSettings
