import React, { useState } from "react";
import { reportError } from "../../lib/errorHandler";
import { get } from "../../lib/api";
import { openPopup } from "../../lib/popup";
import { AppConfig } from "../../services/config";

const useVendOAuthHook = () => {
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();

  const onClick = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const params = {
        pos: "vend",
        redirect_url: `${AppConfig.CurrentHost}/pos/oauth/complete`,
      };
      const { data } = await get("/shop/{exid}/vend/authorize", params);
      setIsLoading(false);
      openPopup(data.url, "Vend Authorize", 600, 750);
    } catch (e) {
      reportError(e);
      setError(e);
      setIsLoading(false);
    }
  };

  return [isLoading, error, onClick];
};

export default useVendOAuthHook;
