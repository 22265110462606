import React, { useState } from "react"

import Container from "../Container"
import SectionNavBar from "../SectionNavBar"

const FeatureContainer = ({ navItems, children, className, style }) => {
  return (
    <div className={`${className}`} style={style}>
      <SectionNavBar>{navItems}</SectionNavBar>
      <Container>{children}</Container>
    </div>
  )
}

export default FeatureContainer
