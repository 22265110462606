import React, { useEffect } from "react";
import { Route, Routes } from "react-router";
import UserPermissionCheck from "../components/UserPermissionCheck";
import UserDetail from "./UserDetail";
import UserTable from "./UserTable";

const UserManagement = () => {
  return (
    <UserPermissionCheck permissionKey="can_manage_users">
      <Routes>
        <Route path="/*" element={<UserTable />} />
      </Routes>
      <UserDetail />
    </UserPermissionCheck>
  );
};

export default UserManagement;
