import React from "react"
import SimpleForm from "../../components/Form/SimpleForm"
import { SettingsFactory } from "../../components/Settings/SettingsFactory"
import { put } from "../../lib/api"
import { prependHttps } from "../../lib/url"
import ReviewLinkDescription from "../../Reviews/Settings/ReviewLinkDescription"
import SectionHeader from "../../components/SectionHeader"

const fields = [
  {
    label: "Location Name",
    body: "",
    props: {
      type: "text",
      name: "name",
    },
  },
  {
    label: "Email",
    body: "This is used for location specific communication.",
    props: {
      type: "email",
      name: "email",
    },
  },
  {
    label: "Website",
    body: "",
    props: {
      name: "website",
    },
  },
  {
    label: "Timezone",
    body: "The timezone of your location. This affects when automated messages are scheduled.",
    as: "timezone",
    props: {
      type: "text",
      name: "timezone",
    },
  },
  {
    label: "Review Link",
    body: <ReviewLinkDescription body="This is used for Review Requests." />,
    props: {
      type: "text",
      name: "review_link",
    },
  },
]

const LocationDetailForm = ({ location }) => {
  if (!location) {
    return <div>Unable to find location.</div>
  }

  const apiSubmitHandler = async (data) => {
    data.website = prependHttps(data.website)
    data.review_link = prependHttps(data.review_link)
    await put(`/shop/${data.id}/account`, data)
  }

  return (
    <>
      <SectionHeader className="mb-3">Location Details</SectionHeader>
      <SimpleForm
        initialValues={{ ...location }}
        apiSubmitHandler={apiSubmitHandler}>
        <SettingsFactory fields={fields} />
      </SimpleForm>
    </>
  )
}

export default LocationDetailForm
