import { Outlet } from "react-router"
import React, { useEffect } from "react"
import "./App.css"
import SystemMessage from "./components/SystemMessage"
import MainMenuNav from "./MainMenu"
import NotificationPreferenceModal from "./NotificationPreference"
import ChangePasswordModal from "./ChangePassword/ChangePasswordModal"
import "bootstrap/dist/css/bootstrap.min.css"
import { inPortalIframe } from "./lib/environment"
import ToastManager from "./components/Toast/ToastManager"
import { useActions } from "./lib/overmind"
import AccountUpdatesOffCanvas from "./AccountUpdates"
import InboxInvoice from "./Invoice/InboxInvoice"
import ReviewsFeatureUpgradeModal from "./Reviews/Setup/ReviewsFeatureUpgradeModal"
import ReviewSetupModal from "./Reviews/Setup/ReviewsSetupModal"
import BrowserExtensionInstallModal from "./BrowserExtension/BrowserExtensionInstallModal"
import { reachedLastStepKey } from "./Signup/AccountSetupManager"
import AccountEinUpdateModal from "./Account/AccountEinUpdateModal"

const App = () => {
  const actions = useActions().browserExtensionEventBus

  useEffect(() => {
    actions.checkExtensionIsInstalled()
    reachedLastStepKey.delete()
  }, [])

  return (
    <div className="d-flex flex-column vh-100">
      <MainMenuNav hidden={inPortalIframe()} />
      <main className="flex-grow-1">
        <div className="wrapper" style={{ width: "100vw" }}>
          <SystemMessage />
          <Outlet />
        </div>
      </main>
      <NotificationPreferenceModal />
      <ChangePasswordModal />
      <ToastManager />
      <AccountUpdatesOffCanvas />
      <InboxInvoice />
      <ReviewsFeatureUpgradeModal />
      <ReviewSetupModal />
      <BrowserExtensionInstallModal />
      <AccountEinUpdateModal/>
    </div>
  )
}

export default App
