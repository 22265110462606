/* eslint-disable camelcase */
import { getVariant } from "../../../NPS/rating"
import { reportError } from "../../errorHandler"

export const get = async ({ state, actions, effects }) => {
  if (state.nps.isLoading) return

  state.nps.isLoading = true
  state.nps.responseError = null
  if (state.nps.tableData) {
    /*
    TODO: Maybe there needs to be one endpoint that loads the table configuration (e.g. filters) and another to fetch the data.
    Otherwise we have to maintain the last successful response so that the filter can still show.  This allows the user to
    either change the filter, reset the filter or rerun the report
    */
    state.nps.tableData.rows = null
  }

  try {
    const data = await effects.nps.get(state.nps.filterParams)
    state.nps.data = data
    actions.nps.makeDataTable(data)
  } catch (error) {
    console.log(error)
    reportError(error)
    state.nps.errorMessage = "There was an error"
    state.nps.responseError = error.response
  }
  state.nps.isLoading = false
}

export const makeDataTable = ({ state }, invoiceData) => {
  const rows = invoiceData?.map((d) => {
    return {
      id: d.id,
      url: `${d.id}`,
      data_bs_target: "#nps-detail-offcanvas",
      columns: [
        {
          value: d.date_sent,
          type: "date",
        },

        {
          value: d.score,
          type: "badge",
          ui_variant: getVariant(d.score),
        },
        {
          value: d.source,
        },
        {
          value: d.phone_number,
          type: "phonenumber",
        },
        {
          value: d.external_type,
        },
        {
          value: d.customer_original_reply,
        },
        {
          value: d.replies?.length || 0,
        },
        {
          value: d.external_employee_id
            ? `ID: ${d.external_employee_id} ${d.external_employee_name || ""}`
            : "",
          type: "link",
          url: `https://us.merchantos.com/?name=admin.views.employee&form_name=view&id=${d.external_employee_id}&tab=details`,
          className: "text-nowrap text-capitalize",
        },
        {
          value: d.note,
          style: { maxWidth: "55ch" },
        },
      ],
    }
  })

  const headers = [
    "Date Sent",
    "Score",
    "Source",
    "Phone Number",
    "Source",
    "Original Reply",
    "Replies",
    "Employee",
    "Private Note",
  ]

  const tableData = {
    headers,
    rows,
    filter: [
      {
        type: "dropdown",
        title: "Status",
        key: "score_type",
        options: [
          {
            title: "Promoters",
            value: "promoter",
          },
          {
            title: "Neutral",
            value: "neutral",
          },
          {
            title: "Detractors",
            value: "detractor",
          },
        ],
      },
    ],
  }

  state.nps.tableData = tableData
}

export const getDetail = async ({ state, effects }, id) => {
  return effects.nps.getDetail(id)
}

export const updateFilter = ({ state, actions }, params) => {
  state.nps.filterParams = params
  actions.nps.get()
}

export const updateScore = async (
  { state, actions, effects },
  { id, score }
) => {
  await effects.nps.update(id, { score })

  const record = state.nps.data?.find((r) => r.id === id)
  record.score = score

  actions.nps.makeDataTable(state.nps.data)
}

export const updateNote = async (
  { state, actions, effects },
  { id, note, score }
) => {
  await effects.nps.update(id, { note, score })

  const record = state.nps.data?.find((r) => r.id === id)
  record.note = note

  actions.nps.makeDataTable(state.nps.data)
}
