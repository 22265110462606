import React from "react";
import { Route, Routes } from "react-router-dom";

import ReviewsOverviewManager from "../Overview";
import FeaturePermissionCheck from "../../components/FeaturePermissionCheck";
import ReviewsUpgrade from "./ReviewsUpgrade";

const ReviewsRoutes = () => {
  return (
    <Routes>
      <Route
        path="/*"
        index
        element={
          <FeaturePermissionCheck
            permissionKey="google_reviews_dashboard"
            upgradeComponent={<ReviewsUpgrade />}
          >
            <ReviewsOverviewManager />
          </FeaturePermissionCheck>
        }
      />
    </Routes>
  );
};

export default ReviewsRoutes;
