import React, { useRef } from "react";
import { useActions } from "../../lib/overmind";

const InboxAttachmentButton = () => {
  const inputFileRef = useRef();
  const actions = useActions().activeThread;

  const convertImageFileToData = (file) => {
    if (!file) return;
    const fr = new FileReader();
    fr.onload = () => {
      if (
        inputFileRef.current.files.length &&
        inputFileRef.current.files[0].size > 8000000
      ) {
        alert("File is too large. Limit is 8MB");
      } else {
        actions.updateAttachment({
          type: "imageFileData",
          data: fr.result,
          file,
        });
        inputFileRef.current.value = null;
      }
    };
    fr.readAsDataURL(file);
  };

  return (
    <div>
      <button
        type="button"
        className="btn btn-link btn-sm text-black text-decoration-none"
        onClick={() => inputFileRef.current?.click()}
      >
        <i className="bi-plus-circle me-1" />
        Attachments
      </button>
      <input
        ref={inputFileRef}
        type="file"
        accept="image/png,image/jpeg,image/gif,application/pdf"
        onChange={() => {
          convertImageFileToData(inputFileRef.current.files[0]);
        }}
        className="d-none"
      />
    </div>
  );
};

export default InboxAttachmentButton;
