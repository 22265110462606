import React, { useEffect } from "react"
import SurveyQuestionList from "./SurveyQuestionList"
import NpsScoreHeader from "./NpsScoreHeader"
import NpsManageNote from "./NpsManageNote"
import NpsCustomerDetail from "./NpsCustomerDetail"
import OffcanvasSection from "../../components/OffCanvas/OffCanvasSection"
import NpsReplies from "./NpsReplies"

const NpsDetail = ({ hidden, data }) => {
  const [npsData, setNpsData] = React.useState(data)
  useEffect(() => {
    setNpsData(data)
  }, [data])

  if (hidden || !npsData) return null

  return (
    <div>
      <OffcanvasSection>
        <NpsScoreHeader
          id={npsData.id}
          score={npsData.score}
          onChange={(newScore) => {
            npsData.score = newScore
            setNpsData({ ...npsData })
          }}
        />
      </OffcanvasSection>
      <OffcanvasSection>
        <NpsManageNote
          id={npsData.id}
          note={npsData.note}
          score={npsData.score}
          onChange={(newNote) => {
            npsData.note = newNote
            setNpsData({ ...npsData })
          }}
        />
      </OffcanvasSection>
      <OffcanvasSection>
        <NpsCustomerDetail
          customerName={npsData.customer_name}
          customerPhone={npsData.customer_phone}
        />
      </OffcanvasSection>
      <OffcanvasSection hidden={npsData.source === "survey"}>
        <NpsReplies messages={npsData.replies} />
      </OffcanvasSection>
      <div className="px-3" hidden={npsData.source !== "survey"}>
        <SurveyQuestionList questions={npsData.survey_session} />
      </div>
    </div>
  )
}

export default NpsDetail
