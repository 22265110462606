// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ls-button {
  padding: 0 12px;
  display: inline-block;
  border: 1px solid #999;
  border-radius: 3px;
  color: #fff;
  font-size: 0.9375rem;
  font-weight: normal;
  line-height: 32px;
  white-space: nowrap;
  transition:
    border 0.3s,
    background 0.3s,
    color 0.3s;
  background: #888;
  border-color: #888;
}

.ls-button-error {
  position: absolute;
  bottom: 0;
  color: white;
  width: 100%;
  height: 100%;
  font-size: 16px;
  border-radius: 0px 4px 4px 0px;
}

.ls-button-success {
  position: absolute;
  bottom: 0;
  color: white;
  width: 100%;
  height: 100%;
  font-size: 16px;
  border-radius: 0px 4px 4px 0px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Lightspeed/style.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,qBAAqB;EACrB,sBAAsB;EACtB,kBAAkB;EAClB,WAAW;EACX,oBAAoB;EACpB,mBAAmB;EACnB,iBAAiB;EACjB,mBAAmB;EACnB;;;cAGY;EACZ,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,YAAY;EACZ,WAAW;EACX,YAAY;EACZ,eAAe;EACf,8BAA8B;AAChC;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,YAAY;EACZ,WAAW;EACX,YAAY;EACZ,eAAe;EACf,8BAA8B;AAChC","sourcesContent":[".ls-button {\n  padding: 0 12px;\n  display: inline-block;\n  border: 1px solid #999;\n  border-radius: 3px;\n  color: #fff;\n  font-size: 0.9375rem;\n  font-weight: normal;\n  line-height: 32px;\n  white-space: nowrap;\n  transition:\n    border 0.3s,\n    background 0.3s,\n    color 0.3s;\n  background: #888;\n  border-color: #888;\n}\n\n.ls-button-error {\n  position: absolute;\n  bottom: 0;\n  color: white;\n  width: 100%;\n  height: 100%;\n  font-size: 16px;\n  border-radius: 0px 4px 4px 0px;\n}\n\n.ls-button-success {\n  position: absolute;\n  bottom: 0;\n  color: white;\n  width: 100%;\n  height: 100%;\n  font-size: 16px;\n  border-radius: 0px 4px 4px 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
