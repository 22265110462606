import React from "react"
import { useModalDisplayManager } from "../../components/ModalHook"
import Modal, { ModalVariant } from "../../components/Modal"
import CreatePaymentRequestForm from "./CreatePaymentRequestForm"
import CreatePaymentRequestSuccess from "./CreatePaymentRequestSuccess"
import { useConfigureInvoicePrompt } from "../../Invoice/ConfigureInvoicePrompt"

const OffCanvasId = "create-payment-offcanvas"

const CreatePaymentRequestModal = () => {
  const [paymentRequestData, setPaymentRequestData] = React.useState()
  useModalDisplayManager(OffCanvasId, ["/payments/create"])

  let children = useConfigureInvoicePrompt()
  const modalVariant = children ? ModalVariant.lg : ModalVariant.md

  if (!children) {
    children = (
      <div>
        <CreatePaymentRequestForm
          hidden={paymentRequestData}
          onSuccess={(data) => setPaymentRequestData(data)}
        />
        <CreatePaymentRequestSuccess
          hidden={!paymentRequestData}
          data={paymentRequestData}
        />
      </div>
    )
  }

  return (
    <Modal
      title="New Payment Request"
      variant={modalVariant}
      id={OffCanvasId}
      onHide={() => {
        setPaymentRequestData(null)
      }}
      hideOnLocationChange={false}>
      {children}
    </Modal>
  )
}

export default CreatePaymentRequestModal
