import React from "react";
import { useActions, useState } from "../../lib/overmind";
import InboxAttachmentPreview from "./InboxAttachmentPreview";
import InboxInvoiceAttachment from "./InboxInvoiceAttachment";
import InboxReviewRequestAttachmentPreview from "./InboxReviewRequestAttachmentPreview";

const InboxAttachmentPreviewList = () => {
  const actions = useActions().activeThread;
  const state = useState().activeThread;

  return (
    <div className="d-flex mb-2 gap-2">
      <InboxAttachmentPreview
        imageFileData={state.attachment?.data}
        onRemove={() => actions.updateAttachment(null)}
      />
      <InboxInvoiceAttachment
        hidden={!state.invoiceAttachment}
        total={state.invoiceAttachmentTotal}
        onDelete={() => actions.updateInvoiceAttachment()}
      />
      <InboxReviewRequestAttachmentPreview
        hidden={!state.reviewRequestAttachment}
        onDelete={() => actions.updateReviewRequestAttachment()}
      />
    </div>
  );
};

export default InboxAttachmentPreviewList;
