import React from "react"

const DepositStatusBadge = ({ children }) => {
  if (children === "pending") {
    return <span className="badge text-bg-light">Pending</span>
  }
  if (children === "success" || children === "complete") {
    return <span className="badge bg-success">Added as deposit</span>
  }
  if (children === "error") {
    return <span className="badge bg-danger">Error</span>
  }
  if (children === "void") {
    return <span className="badge text-bg-light">Void</span>
  }
  return <span className="badge bg-success">Unknown status: {children}</span>
}

export default DepositStatusBadge
