/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
export const toggleSelectThread = ({ state }, threadId) => {
  const { selectedThreads } = state.activeInbox

  if (selectedThreads.has(threadId)) {
    selectedThreads.delete(threadId)
  } else {
    selectedThreads.add(threadId)
  }
  state.activeInbox.selectedThreads = new Set(selectedThreads.values())
}

export const toggleMultiSelectEnabled = ({ state }) => {
  if (state.activeInbox.multiSelectEnabled) {
    // We're going to hide the multiSelect UI so reset state
    state.activeInbox.selectedThreads = new Set()
  }
  state.activeInbox.multiSelectEnabled = !state.activeInbox.multiSelectEnabled
}

export const archiveSelectedThreads = ({ state, actions, effects }) => {
  const threadIds = Array.from(state.activeInbox.selectedThreads)
  if (!threadIds?.length) return

  for (const threadId of threadIds) {
    delete state.activeInbox.indexedThreads[threadId]
  }
  actions.activeInbox.refreshThreads()
  actions.activeInbox.toggleMultiSelectEnabled()
  effects.activeInbox.bulkUpdate({
    threadIds,
    archived: true,
  })
}

export const unarchiveSelectedThreads = ({ state, actions, effects }) => {
  const threadIds = Array.from(state.activeInbox.selectedThreads)
  if (!threadIds?.length) return

  for (const threadId of threadIds) {
    delete state.activeInbox.indexedThreads[threadId]
  }
  actions.activeInbox.refreshThreads()

  effects.activeInbox.bulkUpdate({
    threadIds,
    archived: false,
  })
}

export const updateThreads = (
  { state, actions, effects },
  hasUnreadMessages
) => {
  const threads = Array.from(state.activeInbox.selectedThreads)
  if (!threads?.length) return

  for (const threadId of threads) {
    const thread = state.activeInbox.indexedThreads[threadId]
    if (!thread) continue
    thread.has_unread_messages = hasUnreadMessages
  }
  actions.activeInbox.refreshThreads()
  actions.activeInbox.toggleMultiSelectEnabled()

  effects.activeInbox.bulkUpdate({
    threadIds: threads,
    hasUnreadMessages,
  })
}

export const markSelectedThreadsUnread = ({ actions }) => {
  actions.activeInbox.updateThreads(true)
}

export const markSelectedThreadsRead = ({ actions }) => {
  actions.activeInbox.updateThreads(false)
}

export const assignSelectedThreadsToInbox = (
  { state, actions, effects },
  inbox
) => {
  const threadIds = Array.from(state.activeInbox.selectedThreads)
  if (!threadIds?.length) return

  for (const threadId of threadIds) {
    const thread = state.activeInbox.indexedThreads[threadId]
    if (!thread) continue
    thread.inboxes = [inbox]
  }
  actions.activeInbox.toggleMultiSelectEnabled()
  actions.activeInbox.refreshThreads()

  effects.activeInbox.assignSelectedThreadsToInbox({
    threadIds,
    inboxId: inbox.id,
  })
}

export const selectAllThreads = ({ state, actions }) => {
  const { selectedThreads } = state.activeInbox

  if (state.activeInbox.selectAllMode === "remove") {
    state.activeInbox.selectedThreads = new Set()
    state.activeInbox.selectAllMode = "add"
  } else {
    for (const thread of state.activeInbox.threads) {
      selectedThreads.add(thread.id)
    }
    state.activeInbox.selectedThreads = new Set(selectedThreads.values())
    state.activeInbox.selectAllMode = "remove"
  }
}
