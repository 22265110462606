import { findAndDelete } from "../../arrayUtil"

const makeTableData = (users) => {
  const rows = users.map((u) => ({
    data_bs_target: "#user-detail-offcanvas",
    id: u.id,
    url: `/settings/users/${u.id}`,
    columns: [
      {
        value: u.email,
        className: "text-lowercase",
      },
      {
        value: u.first_name,
      },
      {
        value: u.last_name,
      },
      {
        value: u.permission_can_send_messages,
        type: "bool",
      },
      {
        value: u.permission_can_view_settings,
        type: "bool",
      },
      {
        value: u.permission_can_view_app_store,
        type: "bool",
      },
      {
        value: u.permission_can_view_feedback,
        type: "bool",
      },
      {
        value: u.permission_can_edit_nps_score,
        type: "bool",
      },
      {
        value: u.permission_can_edit_reviews,
        type: "bool",
      },
      {
        value: u.permission_can_manage_users,
        type: "bool",
      },
    ],
  }))

  return {
    headers: [
      "Email",
      "First Name",
      "Last Name",
      "Send Messages",
      "View Settings",
      "View Integrations",
      "View Feedback/NPS",
      "Edit NPS Score",
      "Edit Reviews",
      "Manage Users",
    ],
    rows,
  }
}

export const get = async ({ state, effects }) => {
  state.users.isLoading = true
  try {
    const data = await effects.users.get(state.account.accountId)
    state.users.data = data?.data
    state.users.shops = data?.shops

    state.users.tableData = makeTableData(state.users.data.users)
  } catch (error) {
    state.users.responseError = error
  }
  state.users.isLoading = false
}

export const getDetail = ({ state }, id) => {
  const user = state.users.data?.users.find((u) => u.id === id)
  if (!user) {
    throw Error("Unable to find user")
  }
  return user
}

export const update = async ({ state, effects }, { userId, user }) => {
  const data = await effects.users.update(state.account.accountId, userId, user)
  const { users } = state.users.data

  state.users.data.users = users.map((localUser) => {
    if (localUser.id === userId) return data.data
    return localUser
  })

  state.users.tableData = makeTableData(state.users.data.users)
}

export const create = async ({ state, effects }, { user }) => {
  const data = await effects.users.create(state.account.accountId, user)
  state.users.data.users.push(data.data)
  state.users.tableData = makeTableData(state.users.data.users)
}

export const delete_ = async ({ state, effects }, userId) => {
  effects.users.delete_(state.account.accountId, userId)
  findAndDelete(state.users.data.users, ({ id }) => id === userId)
  state.users.tableData = makeTableData(state.users.data.users)
}
