import { findAndDelete } from "../../arrayUtil";
import { history } from "../../history";
import { changeBookmarkIcon } from "../../bookmarkIcon";

export const get = async ({ state, actions, effects }) => {
  const inboxesState = state.inboxes;
  const inboxesEffects = effects.inboxes;
  if (inboxesState.isLoading || inboxesState.inboxes) return;

  inboxesState.isLoading = true;
  inboxesState.apiError = null;
  try {
    const data = await inboxesEffects.get();
    inboxesState.inboxes = data.data;

    actions.inboxes.refreshLocationHasUnreadMessages(
      data.location_has_unread_messages,
    );
  } catch (e) {
    inboxesState.apiError = e;
  }
  inboxesState.isLoading = false;
};

export const create = async ({ state, effects }, { name }) => {
  await effects.inboxes.create(name);
};

export const update = async ({ state, effects }, { id, name }) => {
  const updatedInbox = await effects.inboxes.udpate({ id, name });

  const inbox = state.inboxes.inboxes?.find((i) => i.id === id);

  if (!inbox) return;
  inbox.title = updatedInbox.title;
  inbox.slug = updatedInbox.slug;
  history.push(`/inbox/${inbox.slug}`, { replace: true });
};

export const delete_ = async ({ state, effects }, id) => {
  await effects.inboxes.delete_({ id });
  findAndDelete(state.inboxes.inboxes, (i) => i.id === id);
};

export const updateLocally = ({ state, actions }, updatedInboxes) => {
  state.inboxes.inboxes = updatedInboxes;
};

export const refreshLocationHasUnreadMessages = (
  { state },
  hasUnreadMessages,
) => {
  state.inboxes.locationHasUnreadMessages = hasUnreadMessages;
  changeBookmarkIcon(!!hasUnreadMessages);
};

export const onInitializeOvermind = ({ actions, state }, instance) => {
  instance.reaction(
    ({ account }) => account.data,
    () => {
      state.inboxes.inboxes = null;
      state.inboxes.tableData = null;
      state.templates.apiError = null;
    },
  );
};
