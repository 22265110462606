/* eslint-disable camelcase */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from "react"
import Button from "../components/Button"
import { captureException } from "../services/sentry"
import { checkPhoneNumberValidity } from "../lib/phoneNumber"
import { post } from "../lib/api"
import { useConfigureInvoicePrompt } from "../Invoice/ConfigureInvoicePrompt"

const BrowserExtensionNewInvoiceForm = ({
  onIsLoading,
  onComplete,
  customerName,
  phoneNumber,
  total,
  lineItemDescription,
  email,
  hideSubmitButton,
  primaryId,
  primaryType,
  customerId,
  employeeId,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const [customerNameV, setCustomerName] = useState(customerName)
  const [totalV, setTotal] = useState(total || 0.0)
  const [phoneNumberV, setPhoneNumber] = useState(phoneNumber || "")
  const [emailV, setEmail] = useState(email || "")
  const [lineItemDescriptionV, setLineItemDescription] = useState(
    lineItemDescription || ""
  )
  const [addCustomerDeposit, setAddCustomerDeposit] = useState(!!customerId)

  const configureInvoicePrompt = useConfigureInvoicePrompt()

  if (configureInvoicePrompt) return configureInvoicePrompt

  useEffect(() => {
    onIsLoading && onIsLoading(isLoading)
  })

  useEffect(() => {
    setCustomerName(customerName)
    setLineItemDescription(lineItemDescription)
    setEmail(email)
    setPhoneNumber(phoneNumber)
    setTotal(total)
  }, [customerName, lineItemDescription, email, phoneNumber, total])

  const handleSubmit = async (e) => {
    e.preventDefault()

    setIsLoading(true)
    setErrorMessage(null)

    const sendEmail = false // e.target.elements.sendEmail.checked;
    const sendSMS = e.target.elements.sendSMS.checked
    const customerEmail = null // e.target.elements.customerEmail.value;
    const customerPhone = e.target.elements.customerPhone.value

    if (sendEmail && (!customerEmail || customerEmail === "")) {
      setErrorMessage(
        "To send an email invoice, please provide an email address"
      )
      setIsLoading(false)
      return
    }
    if (sendSMS) {
      const phoneError = checkPhoneNumberValidity(customerPhone)
      if (phoneError) {
        setErrorMessage(
          `To send an SMS invoice, please provide a valid phone number. ${phoneError}`
        )
        setIsLoading(false)
        return
      }
    }

    try {
      const data = {
        line_item_description: e.target.elements.lineItemDescription.value,
        total: Math.round(e.target.elements.total.value * 100),
        customer_name: e.target.elements.customerName.value,
        customer_email: customerEmail || null,
        customer_phone: customerPhone || null,
        send_email: sendEmail,
        send_sms: sendSMS,
        add_customer_deposit_when_paid: addCustomerDeposit,
        pos_primary_id: primaryId,
        pos_primary_type: primaryType,
        pos_customer_id: customerId,
        pos_employee_id: employeeId,
        screen_context: window.location.href,
      }
      const response = await post("/shop/{exid}/invoice", data)

      const { id, hosted_page_url } = response.data

      onComplete &&
        onComplete({
          invoice: {
            id,
            hostedPageUrl: hosted_page_url,
          },
          didSendEmail: sendEmail,
          didSendSMS: sendSMS,
        })
    } catch (error) {
      console.error(error)
      captureException(error)
      setErrorMessage(
        error?.response?.data?.message ||
          "There was an error. Please try again."
      )
    }

    setIsLoading(false)
  }

  return (
    <form id="createInvoiceForm" onSubmit={handleSubmit} autoComplete="off">
      <div className="card" style={{ backgroundColor: "#f8f8f8" }}>
        <div className="card-body py-3">
          <div className="mb-2">
            <label htmlFor="customerNameInput" className="form-label">
              Customer Name
            </label>
            <input
              className="form-control form-control-sm"
              id="customerNameInput"
              name="customerName"
              required
              value={customerNameV}
              onChange={(e) => setCustomerName(e.target.value)}
              maxLength={100}
            />
          </div>
          {/* <div className="mb-2">
            <label htmlFor="emailInput" className="form-label">
              Email (optional)
            </label>
            <input
              type="email"
              className="form-control form-control-sm"
              id="emailInput"
              name="customerEmail"
              value={emailV}
              onChange={(e: any) => setEmail(e.target.value)}
            />
          </div> */}
          <div className="">
            <label htmlFor="phoneInput" className="form-label">
              Phone (optional)
            </label>
            <input
              type="text"
              className="form-control form-control-sm"
              id="phoneInput"
              name="customerPhone"
              value={phoneNumberV}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="card mt-2" style={{ backgroundColor: "#f8f8f8" }}>
        <div className="card-body py-3">
          <div className="mb-2">
            <label htmlFor="amountInput" className="form-label">
              Amount
            </label>
            <div className="input-group input-group-sm">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">
                  <i className="bi-currency-dollar" />
                </span>
              </div>
              <input
                type="number"
                className="form-control form-control-sm"
                id="amountInput"
                required
                style={{ maxWidth: "50%" }}
                name="total"
                min={0.5}
                step={0.01}
                value={totalV}
                onChange={(e) => setTotal(e.target.value)}
              />
            </div>
          </div>

          <div className="">
            <label htmlFor="lineItemDescriptionInput" className="form-label">
              Line Item (e.g. Work order #303)
            </label>
            <input
              className="form-control form-control-sm"
              id="lineItemDescriptionInput"
              required
              maxLength={100}
              name="lineItemDescription"
              value={lineItemDescriptionV}
              onChange={(e) => setLineItemDescription(e.target.value)}
            />
          </div>
          <div className="form-row  mt-2">
            <div className="col">
              <div className="ml-2 form-group mb-0 pb-0 form-check">
                <input
                  disabled={!customerId}
                  type="checkbox"
                  className="form-check-input"
                  id="customerDepositCheckbox"
                  checked={addCustomerDeposit}
                  onChange={() => {
                    setAddCustomerDeposit(!addCustomerDeposit)
                  }}
                />
                <label
                  className="form-check-label"
                  htmlFor="customerDepositCheckbox">
                  Add customer deposit when paid
                </label>
                {!customerId && (
                  <label className="d-inline-block ml-3">
                    <i>(Invoice will not be added as a deposit.)</i>
                  </label>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card mt-2" style={{ backgroundColor: "#f8f8f8" }}>
        <div className="card-body py-2">
          {/* <div className="form-check mt-2 d-inline-block mr-3">
            <input
              id="emailCheckbox"
              className="form-check-input"
              type="checkbox"
              name="sendEmail"
            />
            <label className="form-check-label" htmlFor="emailCheckbox">
              Send Email
            </label>
          </div> */}
          <div className="form-check mt-2 d-inline-block">
            <input
              id="smsCheckbox"
              className="form-check-input"
              type="checkbox"
              name="sendSMS"
            />
            <label className="form-check-label" htmlFor="smsCheckbox">
              Send SMS
            </label>
          </div>
        </div>
      </div>

      {errorMessage && (
        <div className="alert alert-danger mt-2" role="alert">
          {errorMessage}
        </div>
      )}
      <Button
        showLoader={isLoading}
        className="btn btn-primary btn-sm mt-2"
        hidden={hideSubmitButton}>
        Create Invoice
      </Button>
    </form>
  )
}

export default BrowserExtensionNewInvoiceForm
