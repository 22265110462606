import React from "react"
import { useState, useActions } from "../lib/overmind"
import InboxReceiverDetails from "./InboxReceiverDetails"
import InboxChangeDropDown from "./ThreadActions/InboxChangeDropDown"
import ThreadActionDropdown from "./ThreadActions/ThreadActionDropdown"

const InboxMessageListHeader = ({ threadId }) => {
  const state = useState()
  const { contact } = state.activeThread
  const receiverId = state.activeThread.detail?.receiverId

  return (
    <div className="d-flex p-2 ps-1 border-bottom flex-column flex-md-row mx-2">
      <InboxReceiverDetails
        receiverId={receiverId}
        contact={contact}
        receiverIsMuted={state.activeThread.detail?.receiverIsMuted}
        threadType={state.activeThread.detail?.threadType}
        messengerPageName={state.activeThread.detail?.messengerPageName}
      />
      <div
        className={`ms-md-auto d-flex flex-row gap-3 align-items-center me-2 ${
          state.activeThread.isLoading ? "visually-hidden" : ""
        }`}>
        <InboxChangeDropDown
          threadId={threadId}
          inboxId={state.activeInbox.inboxId}
        />
        <ThreadActionDropdown
          threadId={threadId}
          contact={contact}
          receiverId={receiverId}
        />
      </div>
    </div>
  )
}

export default InboxMessageListHeader
