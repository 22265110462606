/* eslint-disable no-useless-escape */

import * as api from "../lib/api"
import { accountIsLoaded } from "../lib/overmind/account/util"

const urlB64ToUint8Array = (base64String) => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/")

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

const publicVapidKey =
  "BPoCxw915mUeK2lsHuz9PrJlEvIVqryw4fYrcLf_bvQ6k3KyEESRuxot6cXabin8_g0dUsmsaSDhcPSc4n9RzfM"

const updateSubscriptionOnServer = async (subscription) => {
  // log.trace('Service Worker: Updating push notification on server')
  if (!accountIsLoaded()) {
    return
  }
  await api.post("/shop/{exid}/webpushsubscription", {
    subscription: JSON.stringify(subscription),
  })
}

const isPushNotificationsSupported = () =>
  "serviceWorker" in navigator && "PushManager" in window

export const registerServiceWorker = async () => {
  try {
    navigator.serviceWorker.register("/sw.js")
  } catch (e) {
    console.log(e)
  }
}

export const isSubscribed = async () => {
  const registration = await navigator.serviceWorker.ready
  const subscription = await registration.pushManager.getSubscription()
  return subscription !== null
}

export const currentPermission = async () => {
  const result = await navigator.permissions.query({ name: "notifications" })
  return result.state
}

export const subscribeUser = async () => {
  const registration = await navigator.serviceWorker.ready
  console.log("tmp: servericeworker is ready")
  const subscription = await registration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: urlB64ToUint8Array(publicVapidKey),
  })

  await updateSubscriptionOnServer(subscription)
}

const unsubscribeUser = async () => {
  const registration = await navigator.serviceWorker.ready

  const subscription = await registration.pushManager.getSubscription()
  if (!subscription) return

  const data = new FormData()
  data.set("subscription", JSON.stringify(subscription))
  api.delete_("/webpushnotification", data)

  await subscription.unsubscribe()
}
