import React from "react";
import FirstLetterBadge from "./FirstLetterBadge";

const ContactNameColumn = ({ children }) => {
  return (
    <div className="d-flex flex-row gap-3">
      <FirstLetterBadge>{children}</FirstLetterBadge>
      {children}
    </div>
  );
};

export default ContactNameColumn;
