/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
import React, { useEffect, useRef, useState } from "react"
import TextareaAutosize from "react-textarea-autosize"
import Button from "../components/Button"
import { useActions, useState as useOvermindState } from "../lib/overmind"
import { TemplatesDropDown } from "../Templates"
import InboxAttachmentButton from "../Inbox/Attachment/InboxAttachmentButton"
import InboxAttachmentPreviewList from "../Inbox/Attachment/InboxAttachmentPreviewList"
import { toggleInvoiceModal } from "../Invoice/InboxInvoice"
import InboxMessageSendError from "./InboxMessageSendError"
import FieldsetReadonlyManager from "../components/FieldsetReadonlyManager"
import EmojiPickerDropDown from "../components/EmojiPickerDropDown"
import {
  calculateSegmentsWithTitle,
  isMessageOverTheCharacterLimit,
  smsMessageCharacterLimit,
} from "../lib/sms"
import PosVisibility from "../components/PosVisibility"
import InboxMessageInputReviewAction from "./InboxMessageInputReviewAction"
import { LocalStorageKey } from "../lib/localStorageKey"

class MessageManager {
  constructor() {
    this.storageKey = new LocalStorageKey("ikUserTypedMessages")
    this.load()
  }

  set(receiverId, body) {
    this.storage.set(receiverId, body)
  }

  get(receiverId) {
    return this.storage.get(receiverId) || ""
  }

  save() {
    let storedArray = Array.from(this.storage.entries()) || []
    if (storedArray.length > 300) {
      storedArray = storedArray.reverse().slice(0, 300).reverse()
    }
    this.storageKey.set(storedArray)
  }

  load() {
    const dateKey = new LocalStorageKey("ikUserTypedMessagesSavedOn")

    const timeSinceLastSave = dateKey.getTimeDifferenceFromNow()
    if (timeSinceLastSave > 60 * 60) {
      dateKey.setValueAsDateNow()
      this.storage = new Map()
    } else {
      this.storage = new Map(this.storageKey.get())
    }
  }
}

const manager = new MessageManager()
setInterval(() => manager.save(), 1000)

const InboxMessageInput = ({ hidden, threadId }) => {
  const actions = useActions().activeThread
  const [body, setBody] = useState("")
  const [isSending, setIsSending] = useState(false)
  const [error, setError] = useState(null)
  const textareaRef = useRef()

  const setBodySafely = (b) => {
    const cleanBody = b?.substr(0, smsMessageCharacterLimit - 1) || ""
    setBody(cleanBody)

    if (cleanBody?.includes("{review link}")) {
      actions.updateReviewRequestAttachment({ type: "review" })
    } else {
      actions.updateReviewRequestAttachment()
    }
    manager.set(threadId, b)
  }

  useEffect(() => {
    setBodySafely(manager.get(threadId))
  }, [threadId])

  useEffect(() => {
    const handler = (message) => {
      if (
        !message.isTrusted ||
        message.data?.name !== "com.ikeono.updateInboxMessageInput"
      )
        return
      if (body?.length) {
        console.log(
          "warn: Attempted to replace the body but user already has a typed message. Will not overwrite. Message",
          message.data?.message
        )
        return
      }
      setBodySafely(message.data?.message)
    }
    window.addEventListener("message", handler)
    return () => window.removeEventListener(handler)
  }, [])

  const sendMessage = async (e) => {
    if (e) {
      e.preventDefault()
    }
    if (isSending) return
    setIsSending(true)
    setError(null)
    try {
      await actions.sendMessage(body)
      setBodySafely("")
      actions.removeAllAttachments()

      setTimeout(() => {
        textareaRef.current.focus()
      }, 1)
    } catch (error) {
      setError(error)
    }

    setIsSending(false)
  }

  return (
    <div
      className={`mx-3 p-3 pt-2 border rounded ${hidden && "visually-hidden"}`}
      onClick={(e) => {
        if (e.target.type) return
        textareaRef.current.focus()
      }}>
      <InboxMessageSendError
        error={error}
        onCloseError={() => setError(null)}
      />
      <form onSubmit={sendMessage}>
        <FieldsetReadonlyManager disabled={isSending}>
          <div className="d-flex flex-row mb-2">
            <TextareaAutosize
              ref={textareaRef}
              className="inbox-input-control glow-underline px-0 mx-2"
              maxLength={smsMessageCharacterLimit}
              rows={1}
              maxRows={4}
              placeholder="Message"
              value={body}
              onChange={(event) => {
                setBodySafely(event.target.value)
              }}
              onKeyDown={(e) => {
                if (!e.shiftKey && e.key === "Enter") {
                  e.preventDefault()
                  sendMessage()
                }
              }}
            />
            <div className="mx-2 d-none d-md-block">
              <EmojiPickerDropDown
                onEmojiSelect={(emoji) => {
                  const textarea = textareaRef.current
                  setBodySafely(
                    body.substr(0, textarea.selectionStart) +
                      emoji +
                      body.substr(textarea.selectionEnd)
                  )
                }}
              />
            </div>
          </div>
          <InboxAttachmentPreviewList />
          <div className="d-flex gap-1 flex-column flex-md-row">
            <div className="d-flex">
              <InboxAttachmentButton />
              <TemplatesDropDown
                onClick={(value) => {
                  const newBody = body?.length ? `${body} ${value}` : value
                  setBodySafely(newBody)
                }}
              />
              <button
                type="button"
                className="btn btn-link btn-sm text-black text-decoration-none"
                onClick={() => toggleInvoiceModal()}>
                <i className="bi-credit-card me-1" />
                Payment
              </button>
              <PosVisibility showStandalone>
                <InboxMessageInputReviewAction
                  onInsert={(body) => {
                    setBodySafely(body)
                  }}
                />
              </PosVisibility>
            </div>
            <div className="ms-md-auto me-2 d-flex align-items-center">
              <div className="mx-2 d-md-none">
                <EmojiPickerDropDown
                  onEmojiSelect={(emoji) => {
                    const textarea = textareaRef.current
                    setBodySafely(
                      body.substr(0, textarea.selectionStart) +
                        emoji +
                        body.substr(textarea.selectionEnd)
                    )
                  }}
                />
              </div>
              <span
                hidden={!isMessageOverTheCharacterLimit(body)}
                className="me-2 text-secondary ms-auto"
                style={{ fontSize: "13px" }}>
                {" "}
                <i className="bi-exclamation-triangle-fill text-warning me-1" />
                You reached the 1,600 character limit.
              </span>
              <span
                className="me-3 text-secondary ms-auto"
                style={{ fontSize: "13px" }}>
                {calculateSegmentsWithTitle(body)}
              </span>
              <Button showLoader={isSending} className="px-3">
                Send
              </Button>
            </div>
          </div>
        </FieldsetReadonlyManager>
      </form>
    </div>
  )
}
export default InboxMessageInput
