import React from "react";
import { Link } from "react-router-dom";
import { useState } from "../lib/overmind";

const TrialExpiringSoon = ({ hidden }) => {
  if (hidden) return null;
  const state = useState().account;

  if (state.daysLeftOnTrial === 0 || state.daysLeftOnTrial > 3) {
    return null;
  }

  const daysLeft = state.daysLeftOnTrial;
  const variant = daysLeft <= 1 ? "danger" : "warning";

  return (
    <div className="card">
      <div className="card-body">
        <h6 className="fw-bold">Your trial is expiring soon!</h6>
        <div className="d-flex flex-column gap-2">
          <span className="d-block">
            We hope you have enjoyed your free trial. Sadly your trial is ending
            soon. To prevent disruption in your service subscribe today!
          </span>
          <span
            className={`d-inline-flex mb-3 px-2 text-${variant}-emphasis bg-${variant}-subtle border border-${variant}-subtle rounded-2 align-self-start fs-14`}
          >
            {" "}
            {daysLeft} day{daysLeft !== 1 && "s"} left on trial
          </span>
          <Link className="btn btn-primary w-100" to="/settings/billing">
            <i className="bi-stars me-1" />
            Subscribe today!
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TrialExpiringSoon;
