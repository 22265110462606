import React, { useRef } from "react";
import OffCanvas from "../OffCanvas";
import LightspeedWorkorderForm from "./LightspeedWorkorderForm";
import OffcanvasFooter from "../OffCanvas/OffcanvasFooter";
import Button, { ButtonVarint } from "../Button";

export const createWorkorderOffcanvasId = "createWorkorderOffcanvas";

const LightspeedCreateWorkorderAutomationOffcanvas = () => {
  const cancelButtonRef = useRef();

  return (
    <OffCanvas
      datatarget={createWorkorderOffcanvasId}
      title="Create new automation"
    >
      <LightspeedWorkorderForm
        onSuccess={() => cancelButtonRef?.current?.click()}
      >
        <OffcanvasFooter>
          <Button
            ref={cancelButtonRef}
            variant={ButtonVarint.secondaryOutline}
            data-bs-dismiss="offcanvas"
          >
            Cancel
          </Button>
        </OffcanvasFooter>
      </LightspeedWorkorderForm>
    </OffCanvas>
  );
};

export default LightspeedCreateWorkorderAutomationOffcanvas;
