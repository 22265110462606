import React, { useState, useEffect, useRef } from "react"
import APIResponseError from "../../components/APIResponseError"
import InfiniteList from "../../components/InfiniteList"
import Loader from "../../components/Loader"
import * as overmind from "../../lib/overmind"
import CloseButton from "./CloseButton"
import ContactRow from "./ContactRow"
import CreateContactRow from "./CreateContactRow"
import "./style.css"
import { usePosConnectedEvent } from "../../lib/pos"

const RowWrapper = ({ style, index, onCreateThread }) => {
  const state = overmind.useState().threadCreateContacts
  const contact = state.contacts[index]

  return (
    <div
      style={style}
      className="d-flex align-items-center inbox-row"
      data-index={index}>
      <ContactRow
        style={style}
        name={contact.name}
        phoneNumber={contact.mobile_phone_number}
        email={contact.email}
        onCreateThread={onCreateThread}
        vendorId={contact.vendor_id}
        vendorSource={contact.vendor_source}
      />
    </div>
  )
}

const ContactList = ({ onCreateThread }) => {
  const actions = overmind.useActions().threadCreateContacts
  const state = overmind.useState().threadCreateContacts
  const inputRef = useRef()
  const [firstLoad, setFirstLoad] = useState(false)

  useEffect(() => {
    if (!firstLoad) {
      setFirstLoad(true)
      return
    }
    const delayDebounceFn = setTimeout(() => {
      actions.get()
    }, 850)

    return () => clearTimeout(delayDebounceFn)
  }, [state.query])

  useEffect(() => {
    inputRef.current.focus()
  }, [state.contacts])

  usePosConnectedEvent(() => {
    actions.get()
  })

  return (
    <>
      <div className="mb-3 pt-2 d-flex flex-column">
        <CloseButton />
        <span
          className="text-uppercase text-secondary mt-2"
          style={{ fontWeight: "800", fontSize: "12px" }}>
          Send to:
        </span>
        <input
          onKeyDown={(e) => {
            if (e.key !== "Enter") return
            actions.get()
          }}
          ref={inputRef}
          disabled={state.isLoading}
          value={state.query}
          onChange={(e) => actions.updateFilter({ query: e.target.value })}
          className="inbox-contact-search-input glow-underline"
          placeholder="Search by phone number or name to get started"
          autoComplete="off"
        />
      </div>
      {state.isLoading && <Loader />}
      {!state.isLoading && state.contacts && <CreateContactRow />}
      <div className="h-100">
        <InfiniteList
          RowClass={(props) => (
            <RowWrapper
              onCreateThread={(contact) => {
                onCreateThread(contact)
                actions.clearSearch()
              }}
              {...props}
            />
          )}
          itemCount={state.contacts?.length || 0}
          itemSize={70}
          loadNextPageCallback={() => actions.get()}
          hasNextPage={state.hasNextPage}
          isNextPageLoading={state.isLoading}
        />
        <APIResponseError response={state.apiError} />
      </div>
    </>
  )
}

export default ContactList
