import React from "react"
import { Link } from "react-router-dom"
import {
  BrowserExtensionAppIcon,
  LightspeedRSeriesAppIcon,
  LightspeedXSeriesAppIcon,
  MessengerAppIcon,
  StripeAppIcon,
  WebchatAppIcon,
} from "../IntegrationAppIcon"

const Item = ({ title, path, children }) => (
  <Link
    className="text-black"
    to={path}
    style={{ width: "50px", textDecoration: "none" }}>
    <div className="text-center  d-grid justify-content-center align-items-center gap-2">
      {children}
      <div className="fs-13">{title}</div>
    </div>
  </Link>
)

const MainMenuIntegrationDropdown = () => {
  return (
    <div className="nav-item dropdown me-2 d-none d-md-block">
      <a
        href="#"
        className="nav-link d-flex align-items-center justify-content-center text-decoration-none text-white dropdown-toggle"
        id="dropdownUser3"
        data-bs-toggle="dropdown"
        aria-expanded="false">
        <i
          className=" bi-grid-3x3-gap-fill  text-white me-1"
          style={{ fontSize: "20px" }}
        />
      </a>
      <div className="dropdown-menu text-small shadow dropdown-menu-end px-3 pt-4 main-menu">
        <div
          className="d-grid  m-2"
          style={{ gridTemplateColumns: "1fr 1fr 1fr", gap: "30px" }}>
          <Item title="Payments" path="/settings/location/payments/connection">
            <StripeAppIcon />
          </Item>
          <Item title="Webchat" path="/settings/location/website-widget">
            <WebchatAppIcon />
          </Item>
          <Item title="Messenger" path="/settings/location/meta/messenger">
            <MessengerAppIcon />
          </Item>
          <Item
            title="Lightspeed R-Series"
            path="/settings/location/lightspeed-r-series/store-assignment">
            <LightspeedRSeriesAppIcon />
          </Item>
          <Item
            title="Lightspeed X-Series"
            path="/settings/location/lightspeed-x-series/store-assignment">
            <LightspeedXSeriesAppIcon />
          </Item>
          <Item
            title="Browser Extension"
            path="/settings/location/browser-extension">
            <BrowserExtensionAppIcon />
          </Item>
        </div>
        <div
          className="mt-3 w-100 text-center start-0 fs-14"
          style={{
            borderBottomLeftRadius: "5px",
            borderBottomRightRadius: "5px",
            padding: "4px 0px",
          }}>
          <Link className="text-black" to="/settings/integrations">
            More Integrations
          </Link>
        </div>
      </div>
    </div>
  )
}

export default MainMenuIntegrationDropdown
