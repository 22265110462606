import * as React from "react";

const OffcanvasFooter = ({ children }) => {
  return (
    <div
      className="position-absolute start-0 bottom-0 border-top d-flex align-items-center p-2 bg-white"
      style={{ height: "60px", width: "100%" }}
    >
      {children}
    </div>
  );
};

export default OffcanvasFooter;
