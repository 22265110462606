import React from "react";
import SettingsCard from "../components/Settings/SettingsCard";
import BrowserExtensionDownload from "./BrowserExtensionDownload";

const BrowserExtensionSettingsCard = () => (
  <SettingsCard title="Enhance your Point of Sale Experience">
    <div className="mb-2 d-flex flex-row">
      <div>
        <p>
          Downloading the Browser Extension enhances your Point of Sale. You
          gain powerful features such as
        </p>
        <ul className="mb-3">
          <li>View and reply to messages without leaving your Point of Sale</li>
          <li>Send messages for special orders</li>
          <li>Text receipts and work order quotes</li>
          <li>
            Plus more. It is easy to install. Simple click the below button.
          </li>
        </ul>
        <BrowserExtensionDownload />
      </div>
      <div className="ms-auto text-center">
        <img
          className="w-50 rounded shadow"
          src="/r-series-integration-example.png"
        />
      </div>
    </div>
  </SettingsCard>
);

export default BrowserExtensionSettingsCard;
