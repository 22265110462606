// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inbox-contact-search-input {
  font-size: 23px;
  font-weight: 600;
  border: none;
  border-bottom: 1px gray solid;
  box-shadow: none;
  width: 100%;
  padding: 12px 0px 15px 0px;
}

.inbox-contact-phone-number {
  font-size: 13.5px;
}
`, "",{"version":3,"sources":["webpack://./src/Inbox/InboxNewThreadContainer/style.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,6BAA6B;EAC7B,gBAAgB;EAChB,WAAW;EACX,0BAA0B;AAC5B;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".inbox-contact-search-input {\n  font-size: 23px;\n  font-weight: 600;\n  border: none;\n  border-bottom: 1px gray solid;\n  box-shadow: none;\n  width: 100%;\n  padding: 12px 0px 15px 0px;\n}\n\n.inbox-contact-phone-number {\n  font-size: 13.5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
