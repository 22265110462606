import React from "react";
import MessageCardInfoLine from "./MessageCardInfoLine";

const MessageCardDetails = ({ message, children }) => {
  if (!message) return null;
  const isFromShop = message.is_from_shop;

  let senderName = `${message.meta?.context?.user_first_name || ""} ${
    message.meta?.context?.last_name || ""
  }`.trim();
  if (!senderName) {
    senderName = message.meta?.context?.user_email;
  }

  return (
    <>
      {children}
      <MessageCardInfoLine
        status={message.status}
        dateSent={message.date_sent}
        isFromShop={isFromShop}
        senderName={senderName}
      />
      <div className="message-error text-danger ms-auto">
        {message.error_message}
      </div>
    </>
  );
};

export default MessageCardDetails;
