// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings-nav li:hover {
  background-color: var(--light-gray);
}

.settings-nav li .active {
  background-color: var(--bs-primary);
  color: white !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/VerticalNav/style.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;AACrC;;AAEA;EACE,mCAAmC;EACnC,uBAAuB;AACzB","sourcesContent":[".settings-nav li:hover {\n  background-color: var(--light-gray);\n}\n\n.settings-nav li .active {\n  background-color: var(--bs-primary);\n  color: white !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
