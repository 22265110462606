import React from "react";

const InstalledBadge = ({ hidden, children = "Installed" }) => {
  if (hidden) return null;
  return (
    <span className="badge bg-success-subtle text-success py-1 px-2">
      {" "}
      <i className="bi-check-circle me-1" />
      {children}
    </span>
  );
};
export default InstalledBadge;

export const ConnectedBadge = ({ hidden }) => {
  if (hidden) return null;
  return (
    <span className="badge bg-success-subtle text-success py-1 px-2">
      {" "}
      <i className="bi-check-circle me-1" />
      Connected
    </span>
  );
};
