import React, { useEffect } from "react";
import {
  audibleNotificationsEnabled,
  setAudibleNotificationsEnabled,
} from "../lib/audio";

const AudibleNotification = () => {
  const [enabled, setEnabled] = React.useState();

  useEffect(() => {
    setEnabled(audibleNotificationsEnabled());
  }, []);

  const onChange = () => {
    setEnabled(!enabled);
    setAudibleNotificationsEnabled(!enabled);
  };

  return (
    <div className="d-flex flex-column gap-2">
      <b>Play audible notifications</b>
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          checked={enabled}
          id="audibleEnabledCheckbox"
          onChange={onChange}
        />
        <label className="form-check-label" htmlFor="audibleEnabledCheckbox">
          Enabled
        </label>
      </div>
    </div>
  );
};

export default AudibleNotification;
