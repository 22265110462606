import React from "react"
import Dropdown from "../../components/Dropdown"
import UnreadActionItem from "./UnreadActionItem"
import MarketingOptOutItem from "./MarketingOptOutItem"
import MuteActionItem from "./MuteActionItem"
import OpenInLightspeedActionItem from "./OpenInLightspeedActionItem"
import ThreadActionItem from "./ThreadActionItem"
import CreateContactActionItem from "./CreateContactActionItem"
import EditThreadActionItem from "./EditThreadActionItem"
import PosVisibility from "../../components/PosVisibility"
import { useActions, useState } from "../../lib/overmind"
import { supportPhoneNumber } from "../../lib/ikeono"

const ThreadActionDropdown = ({ contact, threadId, receiverId }) => {
  const state = useState()
  const actions = useActions().activeInbox
  const isSupport = receiverId === supportPhoneNumber

  return (
    <Dropdown
      dropdownMenuClassName="p-0"
      className="btn btn-link text-black fs-14 dropdown-toggle px-0 "
      title="Actions">
      <ThreadActionItem
        onClick={() => actions.unarchive(threadId)}
        title="Open"
        description="Open this conversation."
        iconName="bi-folder"
        hidden={!state.activeThread.detail?.archived}
        hideBorder
      />
      <UnreadActionItem hideBorder={!state.activeThread.detail?.archived} />
      <ThreadActionItem
        onClick={() => actions.archive(threadId)}
        title="Close"
        description="Close this conversation. You can reopen it later."
        iconName="bi-check-lg"
        hidden={state.activeThread.detail?.archived}
      />
      <CreateContactActionItem hidden={!!contact?.id || isSupport} />
      <PosVisibility showLightspeed>
        <OpenInLightspeedActionItem
          firstName={contact?.first_name}
          lastName={contact?.last_name}
          phoneNumber={contact?.mobile_phone_number}
          vendorUrl={contact?.vendor_url}
        />
      </PosVisibility>
      <MarketingOptOutItem hidden={isSupport} />
      <MuteActionItem hidden={isSupport} />
      <EditThreadActionItem hidden={!contact?.id || isSupport} />

      <ThreadActionItem
        onClick={() => actions.deleteThread(threadId)}
        title="Delete"
        description="Delete this conversation. Messages will still be kept."
        iconName="bi-trash"
        hidden={!state.activeThread.detail?.archived}
      />
    </Dropdown>
  )
}

export default ThreadActionDropdown
