import React from "react"
import { SupportAnchor } from "../lib/ikeono"
import { useState } from "../lib/overmind"
import SimpleHeaderBodyBanner from "./SimpleHeaderBodyBanner"
import DefaultFeatureUpgradePlan from "../FeatureUpgrade/DefaultFeatureUpgrade"

const FeaturePermissionCheck = ({
  children,
  permissionKey,
  upgradeComponent,
  fallbackComponent = null,
  plan = null,
  redirectPath = null,
}) => {
  const permissions = useState().account?.data?.config?.portal

  if (!permissions)
    return (
      <div>
        Unable to validate permission. Contact support. <SupportAnchor />
      </div>
    )
  if (!permissionKey) {
    throw new Error("permission key is required")
  }
  if (!Object.hasOwn(permissions, permissionKey)) {
    throw new Error(`Invalid permissin key: ${permissionKey}`)
  }

  const permissionObj = permissions[permissionKey]

  if (Object.hasOwn(permissionObj, "needs_account_upgrade")) {
    if (permissionObj.needs_account_upgrade) {
      return (
        upgradeComponent || (
          <DefaultFeatureUpgradePlan
            plan={plan}
            featureName={permissionKey}
            redirectPath={redirectPath}
          />
        )
      )
    }
  }

  if (Object.hasOwn(permissionObj, "visible")) {
    if (!permissionObj.visible) return fallbackComponent
  }

  if (Object.hasOwn(permissionObj, "coming_soon")) {
    if (permissionObj.coming_soon) {
      return (
        <SimpleHeaderBodyBanner
          title="Coming soon"
          body="We will be rolling this feature out soon. Stay tuned!"
        />
      )
    }
  }

  return children
}

export default FeaturePermissionCheck
