import { Cookie } from "./cookie"

export const CookieJar = {
  token: new Cookie("ik_api_token"),
  shopId: new Cookie("ik_shop_id"),
  shopExId: new Cookie("ik_external_shop_id"),
}

export const isLoggedIn = () => !!CookieJar.token.get()

export const login = ({ token }) => {
  CookieJar.token.set(token)
}

export const logout = () => {
  CookieJar.token.delete()
  CookieJar.shopId.delete()
  CookieJar.shopExId.delete()

  const userId = new Cookie("ik_user_id") // Only set in old portal, but need to clear for people who logged into the old portal before switching over
  userId.delete()
  window.location.reload()
}

export const getApiToken = () => CookieJar.token.get()

export const getShopExternalId = () => CookieJar.shopExId.get()

export const setShopExternalId = (locationId) => {
  CookieJar.shopExId.set(locationId)
}

export const setShopId = (shopId) => {
  CookieJar.shopId.set(shopId)
}
