import React from "react"
import { Tooltip, useTooltips } from "../Tooltip"
import InfoBubble from "../../lib/InfoBubble"
import Checkbox from "./Checkbox"

const PosSalesWorkorderCheckbox = ({ warningMessage }) => {
  useTooltips()
  return (
    <div className="">
      <Tooltip
        title="In order to prevent sending to many messages to your contact only NPS or Reviews can be active at a time."
        placement="top">
        <InfoBubble className="mb-2">{warningMessage}</InfoBubble>
      </Tooltip>
      <div className="d-flex gap-3">
        <Checkbox id="sales_enabled" label="Sales" name="sales_enabled" />
        <Checkbox
          id="workorder_enabled"
          label="Workorders"
          name="workorder_enabled"
        />
      </div>
    </div>
  )
}

export default PosSalesWorkorderCheckbox
