import React from "react"
import PosNegLabel from "../../components/PosNegLabel"

const HistoricalChangeInfo = ({ initialAverageRating, averageRating }) => {
  if (!initialAverageRating || !averageRating) return null

  const change = (
    ((averageRating - initialAverageRating) / initialAverageRating) *
    100
  ).toFixed(1)
  if (change <= 0) return null

  return (
    <div className="fs-14 text-secondary">
      Your score was {initialAverageRating.toFixed(1)} when you connected
      Ikeono. An increase of{" "}
      <PosNegLabel
        startValue={initialAverageRating}
        endValue={averageRating}
        defaultNullValue=""
        inline
      />
    </div>
  )
}

export default HistoricalChangeInfo
