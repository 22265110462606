import React from "react";

const PhoneErrorWarning = ({ phoneNumber, error }) => {
  return (
    <div className="alert alert-warning mx-2 mt-5">
      <b>Invalid phone number: {phoneNumber || <i>empty</i>}</b>
      <p>{error}</p>
      <p>
        Please change the Mobile or Home fields to a valid phone number and
        reopen the chat window.
      </p>
    </div>
  );
};

export default PhoneErrorWarning;
