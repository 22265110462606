import { LocalStorageKey } from "./localStorageKey";
import sound from "./new-message.mp3";

const audibleNotificationsEnabledKey = new LocalStorageKey(
  "ikAudibleNotificationsEnabled",
);

export const audibleNotificationsEnabled = () => {
  const value = audibleNotificationsEnabledKey.get();
  if (value === null || value === undefined) {
    audibleNotificationsEnabledKey.set(true);
  }
  return audibleNotificationsEnabledKey.get();
};

const audioPlayLocked = new LocalStorageKey("ikAudioPlayLocked");
audioPlayLocked.set(false);

const a = new Audio(sound);
export const playNewMessageSound = () => {
  try {
    if (audioPlayLocked.isEnabled() || !audibleNotificationsEnabled()) return;
    a.addEventListener("play", () => {
      audioPlayLocked.set(true);
    });
    a.addEventListener("ended", () => {
      setTimeout(() => {
        audioPlayLocked.set(false);
      }, 5000);
    });
    a.play();
  } catch (e) {
    audioPlayLocked.set(false);
  }
};

export const setAudibleNotificationsEnabled = (enabled) => {
  audibleNotificationsEnabledKey.set(enabled);
};
