import React from "react";

export const LightspeedImg = (props) => (
  <img src="/lightspeed-logo.png" alt="Lightspeed" {...props} />
);

export const LightspeedAppIconImg = (props) => (
  <img src="/lightspeed-app-icon.png" alt="Lightspeed" {...props} />
);

export const VendImg = (props) => (
  <img src="/vend-logo.png" alt="Vend" {...props} />
);

export const EcwidImg = (props) => (
  <img src="/ecwid-logo.png" alt="Ecwid" {...props} />
);

export const StripeImg = (props) => (
  <img src="/stripe-logo.png" alt="Ecwid" {...props} />
);
