import moment from "moment"

export const formatDate = (
  dateString,
  format = "MMM Do YYYY, h:mm A",
  asUTC = false
) => {
  const date = moment(dateString)
  if (asUTC) {
    return date.utc().format(format)
  }
  return date.format(format)
}

const plural = (value, unit) => {
  if (value === 1) {
    return `${value} ${unit}`
  }
  if (value > 1) {
    return `${value} ${unit}s`
  }
}

export const formatMinutes = (minutes) => {
  if (minutes < 60) return plural(minutes, "Minute")

  const hours = minutes / 60
  if (hours < 24) return plural(hours, "Hour")

  const days = hours / 24
  return plural(days, "Day")
}

export const formatRelativeDate = (date) => {
  if (!date) return ""
  const momentDate = moment(date)
  const hours = moment().diff(momentDate, "hours")
  const days = moment().diff(momentDate, "days")
  let dateString = momentDate.format("MMM Do, h:mm A")
  let usedRelativeDate = false
  if (hours < 6) {
    dateString = momentDate.fromNow()
    usedRelativeDate = true
  } else if (days > 30) {
    dateString = momentDate.format("MMM Do YYYY, h:mm A")
  }
  return {
    dateString,
    usedRelativeDate,
  }
}

export const formatRelativeDateNoTime = (date) => {
  if (!date) return ""
  const momentDate = moment(date)
  const hours = moment().diff(momentDate, "hours")
  let dateString = momentDate.format("MMM Do")
  if (hours < 12) {
    dateString = momentDate.fromNow()
  }
  return dateString
}

export const toISOString = (date) => {
  return moment(date).toISOString()
}

export const dateIsToday = (date) => {
  const now = moment().format("MMM Do YYYY")
  const dateFormatted = moment(date).format("MMM DD YYYY")
  return now === dateFormatted
}


export const formatSeconds = seconds => {
  if (!seconds) return `-`;
  if (seconds < 60) return `${seconds} seconds`;
  const minutes = parseInt(seconds / 60);
  if (seconds < 3600) return `${minutes} minute${minutes > 1 ? 's' : ''}`;
  const hours = parseInt(minutes / 60);
  if (seconds < 86400) return `${hours} hour${hours > 1 ? 's' : ''}`;
  const days = parseInt(hours / 24);
  const months = parseInt(days / 30);
  if (days > 90) return `${months} month${months > 1 ? 's' : ''}`;
  return `${days} day${days > 1 ? 's' : ''}`;
};

export const todayMinus = days => {
  let startdate = moment();
  startdate = startdate.subtract(days, "days");
  startdate = startdate.format("YYYY-MM-DD");
  return startdate
}