import React from "react"
import { useActions, useState } from "../lib/overmind"
import InboxListZeroState from "./InboxListZeroState"
import Dropdown from "../components/Dropdown"
import Loader from "../components/Loader"

const InboxMultiAssignDropdown = () => {
  const state = useState()
  const actions = useActions()
  const hasInboxes = !!state.inboxes.inboxes?.length

  return (
    <Dropdown
      containerClass="flex-grow-1 text-center text-secondary"
      onOpen={() => actions.inboxes.get()}
      className="btn btn-link text-black   p-0 text-secondary text-center flex-grow-1"
      title={
        <i
          role="button"
          className="bi-inbox text-secondary"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          data-bs-title="Assign to inbox"
        />
      }
      dropdownMenuClassName="dropdown-menu-end">
      <InboxListZeroState
        className="fs-14 p-2"
        hidden={hasInboxes || state.inboxes.isLoading}
      />
      <Loader className="my-3" hidden={!state.inboxes.isLoading} />
      <div
        className="overflow-y-scroll"
        style={{ maxHeight: "calc(100vh - 200px)" }}>
        {state.inboxes.inboxes?.map((inbox) => {
          return (
            <li key={inbox.id}>
              <a
                role="button"
                className="dropdown-item  text-capitalize"
                onClick={() => {
                  actions.activeInbox.assignSelectedThreadsToInbox(inbox)
                }}>
                {inbox.title}
              </a>
            </li>
          )
        })}
      </div>
    </Dropdown>
  )
}

export default InboxMultiAssignDropdown
