import React from "react"

const InboxFolderIcon = ({ hasUnreadMessages, iconName = "bi-folder" }) => (
  <i className={`${iconName} position-relative`}>
    <i
      hidden={!hasUnreadMessages}
      className="bi-circle-fill text-danger position-absolute"
      style={{ top: "-1px", right: "-4px", fontSize: "10px" }}
    />
  </i>
)

export default InboxFolderIcon
