import React from "react"
import NpsSettings from "../../NPS/NpsSettings"
import FeaturePermissionCheck from "../../components/FeaturePermissionCheck"
import NpsUpgrade from "../../NPS/NpsUpgrade"

const LocationNpsSettings = () => {
  return (
    <FeaturePermissionCheck
      permissionKey="feedback_loop_module"
      upgradeComponent={<NpsUpgrade />}>
      <NpsSettings />
    </FeaturePermissionCheck>
  )
}

export default LocationNpsSettings
