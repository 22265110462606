import React, { useRef } from "react"
import { Outlet } from "react-router"
import { useNavigate, useParams } from "react-router-dom"
import "./Inbox.css"
import InboxHeader from "./InboxHeader"
import InboxThreadList from "./InboxThreadList"
import InboxThreadOpenCloseTab from "./InboxThreadOpenCloseTab"
import InboxMultiSelectActions from "./InboxMultiSelectActions"
import { useState } from "../lib/overmind"

export const Threads = () => {
  const ref = useRef()
  const params = useParams()
  const navigate = useNavigate()

  const { threadId, inboxName } = params
  const state = useState().activeInbox
  const heightOffset = state.multiSelectEnabled ? "52px" : "9px"

  React.useEffect(() => {
    if (!inboxName) {
      navigate("/inbox/all")
    }
  }, [])

  return (
    <>
      <div
        className="border-end"
        style={{ width: "300px", height: `calc(100% - ${heightOffset})` }}>
        <div ref={ref}>
          <InboxHeader inboxName={inboxName} />
          <InboxThreadOpenCloseTab />
          <InboxMultiSelectActions />
        </div>
        <InboxThreadList
          heightOffset={120}
          includeThreadId={threadId}
          inboxName={inboxName}
        />
      </div>
      <div style={{ flexGrow: 1 }}>
        <Outlet />
      </div>
    </>
  )
}
