/* eslint-disable no-shadow */
import React, { useState } from "react"
import { useActions } from "../lib/overmind"
import ContactButton from "./ContactButton"
import { toggleInvoiceModal } from "../Invoice/InboxInvoice"
import APIResponseError from "../components/APIResponseError"

const ContactActionsRow = ({
  phoneNumber,
  email,
  firstName,
  lastName,
  vendorId,
  vendorSource,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState()

  const actions = useActions()

  const createThread = async ({ toggleInvoice }) => {
    try {
      setError(null)
      setIsLoading(true)
      await actions.activeInbox.createThread({
        receiverId: phoneNumber,
        navigateToThread: true,
        name: `${firstName} ${lastName}`,
        email,
        vendorId,
        vendorSource,
      })
      if (toggleInvoice) {
        toggleInvoiceModal()
      }
    } catch (error) {
      setError(error)
    }
    setIsLoading(false)
  }

  return (
    <div>
      <div className="d-flex flex-row flex-stretch gap-3 ">
        <ContactButton
          icon="bi-chat"
          isLoading={isLoading}
          disabled={!phoneNumber}
          onClick={() => createThread({})}
        />
        <ContactButton
          icon="bi-currency-dollar"
          isLoading={isLoading}
          disabled={!phoneNumber}
          onClick={() => createThread({ toggleInvoice: true })}
        />
        <ContactButton
          icon="bi-send"
          href={`mailto:${email}`}
          disabled={!email}
          onClick={() => setError(null)}
          target="_blank"
        />
      </div>
      <APIResponseError response={error} className="mt-3" />
    </div>
  )
}

export default ContactActionsRow
