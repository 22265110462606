import React, { useRef } from "react";
import { useActions, useState } from "../../lib/overmind";
import OffCanvas from "../OffCanvas";
import LightspeedWorkorderForm from "./LightspeedWorkorderForm";
import OffcanvasFooter from "../OffCanvas/OffcanvasFooter";
import Button, { ButtonVarint } from "../Button";

export const editWorkorderOffcanvasId = "editWorkorderOffcanvas";

const LightspeedEditWorkorderAutomationOffcanvas = () => {
  const cancelButtonRef = useRef();
  const state = useState().lightspeed;
  const actions = useActions().lightspeed;

  return (
    <OffCanvas datatarget={editWorkorderOffcanvasId} title="Edit">
      {!state.selectedEditRecord && (
        <div>Pleae reload the page and try again.</div>
      )}
      {state.selectedEditRecord && (
        <LightspeedWorkorderForm
          onSuccess={() => cancelButtonRef?.current.click()}
          submitButtonPortalNodeId="workorderFormUpdateSubmitButton"
          workorder={state.selectedEditRecord}
        >
          <OffcanvasFooter>
            <Button
              ref={cancelButtonRef}
              variant={ButtonVarint.secondaryOutline}
              data-bs-dismiss="offcanvas"
            >
              Cancel
            </Button>
            <Button
              className="ms-auto"
              variant={ButtonVarint.dangerOutline}
              onClick={() => {
                if (!confirm("Are you sure?")) return;
                actions.deleteSelectedEditRecord();
                cancelButtonRef.current?.click(); // Work around. For some reason toggling the canvas does not work
              }}
            >
              Delete
            </Button>
          </OffcanvasFooter>
        </LightspeedWorkorderForm>
      )}
    </OffCanvas>
  );
};

export default LightspeedEditWorkorderAutomationOffcanvas;
