import React from "react";
import { useNavigate } from "react-router-dom";
import OvermindDataTable from "../components/DataTable/OvermindDataTable";
import { useState } from "../lib/overmind";

const UserTable = () => {
  const navigate = useNavigate();
  const state = useState();

  return (
    <div>
      <div className="mb-3 d-flex">
        <h4 className="fw-bold">
          Users{" "}
          <span className="h6 text-secondary ps-1">
            {state.users.data?.account_name}
          </span>
        </h4>
        <button
          type="button"
          className="btn btn-sm px-2 btn-primary ms-auto"
          data-bs-toggle="offcanvas"
          data-bs-target="#user-detail-offcanvas"
          onClick={() => {
            navigate("/settings/users");
          }}
        >
          Create User
        </button>
      </div>
      <OvermindDataTable overmindPropertyKey="users" />
    </div>
  );
};

export default UserTable;
