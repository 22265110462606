import React from "react"
import LabeledField from "./LabeledField"

const delayOptions = {
  0: "ASAP",
  15: "15 minutes",
  60: "1 hour",
  120: "2 hours",
  240: "4 hours",
  480: "8 hours",
  1440: "1 day",
  4320: "3 days",
  7200: "5 days",
  10080: "7 days",
}

const MinDelaySelect = () => {
  return (
    <LabeledField
      label="Minimum Delay"
      name="min_delay"
      className="form-control form-control-sm w-100"
      as="select">
      {Object.keys(delayOptions).map((k) => (
        <option key={k} value={k}>
          {delayOptions[k]}
        </option>
      ))}
    </LabeledField>
  )
}

export default MinDelaySelect
