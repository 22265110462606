// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.template-dropdown {
  min-width: 75;
}

@media (max-width: 300px) {
  .template-dropdown {
    width: 100vw !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Templates/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE;IACE,uBAAuB;EACzB;AACF","sourcesContent":[".template-dropdown {\n  min-width: 75;\n}\n\n@media (max-width: 300px) {\n  .template-dropdown {\n    width: 100vw !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
