/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import { useLocation, useNavigate } from "react-router-dom"
import PosVisibility from "../components/PosVisibility"
import { formatPhoneNumberWithAccountCountry } from "../lib/overmind/account/util"
import { supportPhoneNumber } from "../lib/ikeono"
import MessengerPageNameBadge from "../Messenger/MessengerPageNameBadge"

const ThreadTypeBadge = ({ children }) => {
  if (children !== "messenger" && children !== "sms") return null
  if (children === "messenger") {
    return (
      <span
        className="badge text-messenger-blue ms-2"
        style={{ border: "1px solid var(--messenger-blue)" }}>
        Messenger
      </span>
    )
  }
  return (
    <span className="badge border border-success text-success ms-2">SMS</span>
  )
}

const InboxReceiverDetails = ({
  receiverIsMuted,
  contact,
  receiverId,
  threadType,
  messengerPageName,
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  if (!receiverId) return null

  let contactName = `${contact?.first_name || ""} ${contact?.last_name || ""}`
  const isSupportContact = receiverId === supportPhoneNumber
  if (isSupportContact) {
    contactName = "Ikeono Support"
  }

  return (
    <span className="mt-1">
      <PosVisibility showStandalone>
        <span
          className="text-primary fw-bold text-capitalize"
          hidden={!isSupportContact}>
          {contactName}
        </span>
        <a
          hidden={contact?.id || isSupportContact}
          role="button"
          className="text-primary fw-bold"
          style={{ textDecoration: "none" }}
          onClick={() => {
            navigate(`${location.pathname}/detail/create`)
          }}>
          {" "}
          <i className="bi-person-fill-add" /> Create contact
        </a>
      </PosVisibility>
      <a
        hidden={!contact?.id || isSupportContact}
        role="button"
        className="text-primary text-capitalize fw-bold"
        style={{ textDecoration: "none" }}
        onClick={() => {
          navigate(`${location.pathname}/detail`)
        }}>
        {" "}
        {contactName}
      </a>
      <span
        hidden={!receiverId || threadType !== "sms"}
        className="fs-6 ms-2 text-secondary">
        {formatPhoneNumberWithAccountCountry(receiverId)}
      </span>
      <ThreadTypeBadge>{threadType}</ThreadTypeBadge>
      <MessengerPageNameBadge hidden={threadType !== "messenger"}>
        {messengerPageName}
      </MessengerPageNameBadge>
      <span
        className="badge border border-secondary text-secondary ms-2"
        hidden={!receiverIsMuted}>
        <i className="bi-bell-slash" /> Muted
      </span>
    </span>
  )
}

export default InboxReceiverDetails
