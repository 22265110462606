import React from "react"

const CopyToClipboard = ({ children }) => {
  const [inputRef] = React.useState(React.createRef())
  const [showCopiedConfirmation, setShowCopiedConfirmation] =
    React.useState(false)

  const onClick = () => {
    const copyText = inputRef.current
    copyText.select()
    copyText.setSelectionRange(0, 99999) /* For mobile devices */
    document.execCommand("copy")
    setShowCopiedConfirmation(true)
  }

  return (
    <div>
      <textarea
        style={{ border: "none" }}
        className="text-primary w-100"
        value={children}
        ref={inputRef}
        rows={3}
      />
      <button
        type="button"
        className="btn btn-sm btn-primary pl-0 ml-0 d-inline-block mr-2"
        onClick={onClick}>
        Copy to clipboard
      </button>
      {showCopiedConfirmation && (
        <span className="ms-2 d-inline-flex px-2 text-success-emphasis bg-success-subtle border border-success-subtle rounded-2 align-self-start fs-13">
          Copied
        </span>
      )}
    </div>
  )
}

export default CopyToClipboard
