import React from "react";
import { useState } from "../lib/overmind";
import { accountUpdatesOffCanvasId } from "../AccountUpdates";

const MainMenuAccountStatus = () => {
  const state = useState();

  const { integrationsIndex } = state.integrations;
  const hasPosWarning =
    integrationsIndex?.lightspeed_r_series?.status === "warning" ||
    integrationsIndex?.lightspeed_x_series?.status === "warning";
  const trialExpiringSoon =
    state.account.daysLeftOnTrial > 0 && state.account.daysLeftOnTrial <= 3;

  const hideEinWarning =
    !state.account.data.ui_metadata.show_tcr_info_modal ||
    state.account.country !== "US";

  if (!hasPosWarning && !trialExpiringSoon && hideEinWarning) {
    return null;
  }

  return (
    <div
      className="badge text-bg-warning me-3 p-2"
      role="button"
      data-bs-toggle="offcanvas"
      data-bs-target={`#${accountUpdatesOffCanvasId}`}
    >
      Action Required <i className="bi-caret-down-fill" />
    </div>
  );
};

export default MainMenuAccountStatus;
