import React from "react"
import $ from "jquery"
import Dropdown from "../../components/Dropdown"
import { delete_ } from "../../lib/api"

const DeleteReply = ({ reviewId, onDelete }) => {
  const onYes = () => {
    const data = {
      review_id: reviewId,
    }
    delete_(`/account/{account_id}/reviews/reply`, data)
    onDelete()
  }

  return (
    <Dropdown
      containerClass="text-center text-secondary"
      className="btn btn-link text-black  p-0 text-secondary text-center flex-grow-1"
      title={
        <div>
          <button
            type="button"
            data-bs-toggle="tooltip"
            data-bs-title="Assign to inbox"
            className="btn btn-link text-secondary fs-15 px-0"
            style={{ textDecoration: "none" }}>
            Delete
          </button>
        </div>
      }>
      <div className="px-3 py-1">
        <div className="mb-2" style={{ maxWidth: "20ch" }}>
          Are you sure you want to delete your reply?
        </div>
        <div className="d-flex gap-3">
          <button
            onClick={onYes}
            type="button"
            className="btn text-secondary p-0 "
            style={{ textDecoration: "underline" }}>
            Yes
          </button>
          <button
            onClick={() => {
              $("body").click()
            }}
            type="button"
            className="btn text-black p-0 "
            style={{ textDecoration: "underline" }}>
            No
          </button>
        </div>
      </div>
    </Dropdown>
  )
}

export default DeleteReply
