import React, { useEffect } from "react"
import { Route, Routes, useParams } from "react-router-dom"
import FeatureContainer from "../components/FeatureContainer"
import ContactDetailOffCanvas from "./ContactDetailOffCanvas"
import ContactPageHeader from "./ContactPageHeader"
import ContactTable from "./ContactTable"

const Contacts = () => {
  // const params = useParams()
  // useEffect(() => {
  //   if (!params['*'] || !window.location.href === '/contacts/create') return
  //   if (ContactDetailOffcanvasManager.isVisible()) return
  //   ContactDetailOffcanvasManager.toggle()
  // }, [params])

  return (
    <FeatureContainer>
      <ContactPageHeader />
      <Routes>
        <Route path="/create" element={<ContactTable />} />
        <Route path="/:id/*" element={<ContactTable />} />
        <Route path="/" element={<ContactTable />} />
      </Routes>
      <ContactDetailOffCanvas />
    </FeatureContainer>
  )
}

export default Contacts
