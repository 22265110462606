// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-menu .dropdown-item {
  padding: 10px 8px;
}

.navbar-nav li {
  font-weight: 500 !important;
}

.nav-link-active {
  position: absolute;
  left: 0px;
  bottom: 0px;
  border-bottom: 3px var(--bs-primary) solid;
  width: 100%;
}

.nav-link {
  position: relative;
  border-bottom: 3px transparent solid;
}

.main-menu li i {
  margin-right: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/MainMenu/style.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,0CAA0C;EAC1C,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,oCAAoC;AACtC;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".main-menu .dropdown-item {\n  padding: 10px 8px;\n}\n\n.navbar-nav li {\n  font-weight: 500 !important;\n}\n\n.nav-link-active {\n  position: absolute;\n  left: 0px;\n  bottom: 0px;\n  border-bottom: 3px var(--bs-primary) solid;\n  width: 100%;\n}\n\n.nav-link {\n  position: relative;\n  border-bottom: 3px transparent solid;\n}\n\n.main-menu li i {\n  margin-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
