/* eslint-disable no-shadow */
import React, { useEffect, useState } from "react"

import { Field, useField, useFormikContext } from "formik"
import moment from "moment"
import TimeControl from "./TimeControl"

const Badge = ({ variant, children, ...props }) => (
  <span
    className={`d-inline-flex px-2 text-${variant}-emphasis bg-${variant}-subtle border border-${variant}-subtle rounded-2 fs-13`}
    {...props}>
    {children}
  </span>
)

const TimeControlRow = ({ label, dow, onHasError }) => {
  const { values, setFieldValue } = useFormikContext()
  const [openTime, setOpenTime] = useState()
  const [closeTime, setCloseTime] = useState()
  const [hasError, setHasError] = useState(null)

  useEffect(() => {
    if (!values) return
    let dowValue = values[dow]
    if (!dowValue) {
      values[dow] = {
        open_time: null,
        close_time: null,
        day_of_week: dow,
      }
      dowValue = values[dow]
    }
    setOpenTime(dowValue.open_time)
    setCloseTime(dowValue.close_time)
  }, [values])

  const onOpenChange = (value) => {
    setOpenTime(value)
    values[dow].open_time = value
  }

  const onCloseChange = (value) => {
    setCloseTime(value)
    values[dow].close_time = value
  }

  useEffect(() => {
    if (!closeTime || !openTime) return
    const t1 = moment(`2014-03-24T${openTime}Z`)
    const t2 = moment(`2014-03-24T${closeTime}Z`)
    const tDiff = t2.diff(t1, "seconds")
    const hasError = tDiff < 0
    setHasError(hasError)
    onHasError(hasError)
  }, [openTime, closeTime])

  const isOpen = !!openTime && !values.closed_for_business

  return (
    <div>
      <div
        className="d-flex column-gap-2 row-gap-0 flex-column d-md-grid"
        style={{ gridTemplateColumns: "170px 300px auto" }}>
        <div className="d-md-flex gap-2 align-items-center">
          <label className="fw-bold" style={{ width: "95px" }}>
            {label}
          </label>
          <Badge
            variant={isOpen ? "success" : "danger"}
            style={{ marginTop: "0px" }}>
            {isOpen ? "Open" : "Closed"}
          </Badge>
        </div>
        <TimeControl
          id={dow}
          label="from"
          value={openTime}
          required={!!closeTime}
          onChange={onOpenChange}
        />
        <div className="d-flex">
          <TimeControl
            id={dow}
            label="to"
            value={closeTime}
            onChange={onCloseChange}
            defaultPeriod="PM"
            required={!!openTime}
          />
          <button
            type="button"
            hidden={!closeTime && !openTime}
            className="btn btn-link text-secondary p-0 my-0 fs-14 ms-2"
            onClick={() => {
              onOpenChange(null)
              onCloseChange(null)
            }}>
            Clear
          </button>
        </div>
        <div className="d-none d-md-block" />
        <span hidden={!hasError} className="text-danger fs-13">
          Open time must come before close time.
        </span>
      </div>
    </div>
  )
}

export default TimeControlRow
