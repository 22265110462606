import React from "react";
import IntegrationCard from "./IntegrationCard";
import { AscendAppIcon } from "../../IntegrationAppIcon";
import { useState } from "../../lib/overmind";
import { PosSystem } from "../../lib/pos";
import useAscendOauthHook from "../../Ascend/AscendOauthHook";
import APIResponseError from "../../components/APIResponseError";
import { ConnectedBadge } from "../../components/InstalledBadge";

const AscendIntegrationCard = ({ hidden }) => {
  if (hidden) return null;
  const state = useState().account;
  const [isLoading, error, onClick] = useAscendOauthHook();

  return (
    <>
      <IntegrationCard
        icon={<AscendAppIcon />}
        title="Ascend"
        onClick={onClick}
      >
        <ConnectedBadge hidden={state.posSystem !== PosSystem.Ascend} />
        <APIResponseError response={error} />
      </IntegrationCard>
      <div
        className="alert alert-success"
        hidden={state.posSystem !== PosSystem.Ascend}
      >
        After you finish onboarding we will contact you on how to finish setting
        up Ascend.
      </div>
    </>
  );
};

export default AscendIntegrationCard;
