import React, { useState } from "react";
import LabeledField from "./LabeledField";
import RegionDropDown from "./RegionDropDown";
import CountryDropDown from "./CountryDropDown";

const AddressControl = () => {
  const [region, setRegion] = useState();
  const [country, setCountry] = useState();

  return (
    <>
      <LabeledField
        label="Address Line 1"
        name="addr_line_1"
        className="form-control form-control-sm"
        required
      />
      <LabeledField
        label="Address Line 2"
        name="addr_line_2"
        className="form-control form-control-sm mb-2"
      />
      <div className="mb-2">
        <CountryDropDown
          country={country}
          onChange={(val) => setCountry(val)}
        />
      </div>
      <div className="row">
        <div className="col">
          <LabeledField
            label="City"
            name="city"
            className="form-control form-control-sm"
            required
          />
        </div>
        <div className="col">
          <RegionDropDown
            country={country}
            region={region}
            onChange={(val) => setRegion(val)}
          />
        </div>
        <div className="col">
          <LabeledField
            label="Postal Code"
            name="postal_code"
            className="form-control form-control-sm"
            required
          />
        </div>
      </div>
    </>
  );
};

export default AddressControl;
