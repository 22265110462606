import React, { useState } from "react";
import { reportError } from "../lib/errorHandler";
import { openPopup } from "../lib/popup";
import { AppConfig } from "../services/config";

const useAscendOauthHook = () => {
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();

  const onClick = async () => {
    setError(null);
    try {
      openPopup("/ascend", "Ascend Authorize", 600, 750);
    } catch (e) {
      reportError(e);
      setError(e);
    }
  };

  return [isLoading, error, onClick];
};

export default useAscendOauthHook;
