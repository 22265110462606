export const Browser = Object.freeze({
  firefox: "firefox",
  chrome: "chrome",
  unknown: "unkown",

  current: () => {
    const isFirefox = typeof InstallTrigger !== "undefined";
    const isChrome = !!window.chrome;

    if (isFirefox) {
      return Browser.firefox;
    }
    if (isChrome) {
      return Browser.chrome;
    }
    return Browser.unknown;
  },
  icon: () => {
    const isFirefox = typeof InstallTrigger !== "undefined";
    const isChrome = !!window.chrome;

    if (isFirefox) {
      return "bi-browser-firefox";
    }
    if (isChrome) {
      return "bi-browser-chrome";
    }
    return "bi-laptop";
  },
  firefoxVersion: () => {
    try {
      return Number(window.navigator.userAgent.match(/Firefox\/([0-9]+)\./)[1]) || 9999
    } catch(error) {
      return 9999
    }
  }
});

export const useCurrentBrowser = () => Browser.current();

export default Browser;
