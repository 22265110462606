import * as API from "../../api"

export const get = async (shopId) => {
  const response = await API.get(`/shop/${shopId}/message/template/`)
  return response.data
}

export const delete_ = async (id) => {
  API.delete_(`/shop/{exid}/message/template/${id}/`)
}
