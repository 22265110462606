/* eslint-disable no-shadow */
import React, { useEffect, useState } from "react"
import * as overmind from "../../lib/overmind"
import ContactList from "./ContactList"
import InboxLoadingStateContainer from "./InboxLoadingStateContainer"
import "./style.css"

const InboxNewThreadContainer = () => {
  const actions = overmind.useActions().activeInbox
  const state = overmind.useState().activeInbox
  const [isCreating, setIsCreating] = useState(false)
  const [error, setError] = useState(null)

  const onCreateThread = async ({
    phoneNumber,
    name,
    vendorId,
    vendorSource,
    email,
  }) => {
    setError(null)
    setIsCreating(true)
    try {
      await actions.createThread({
        receiverId: phoneNumber,
        name,
        navigateToThread: true,
        inboxId: state.inboxId,
        vendorId,
        vendorSource,
        email,
      })
    } catch (error) {
      console.log(error)
      setError(error)
    }
    setIsCreating(false)
  }

  return (
    <InboxLoadingStateContainer isCreating={isCreating} error={error}>
      <ContactList onCreateThread={onCreateThread} />
    </InboxLoadingStateContainer>
  )
}

export default InboxNewThreadContainer
