import * as api from "../../api";

export const getAccount = async (locationId) => {
  const response = await api.get(`/location-v2/${locationId}`);
  return response.data;
};

export const acceptTerms = async () => {
  await api.put("/user/terms", {
    accepted: true,
  });
};

export const getSetupStatus = async (locationId) => {
  const response = await api.get(`/location-v2/${locationId}/setup`);
  return response.data;
};

export const changePhoneNumber = async (locationId, { phoneNumber }) => {
  const response = await api.put(`/location-v2/${locationId}/setup`, {
    phone_number: phoneNumber,
  });
  return response.data;
};
