import React, { useEffect } from "react"
import Modal, { ModalVariant, toggleModal } from "../components/Modal"
import { useActions, useState } from "../lib/overmind"
import { LocalStorageKey } from "../lib/localStorageKey"
import BrowserExtensionDownload from "./BrowserExtensionDownload"
import { BrowserExtensionAppIcon } from "../IntegrationAppIcon"
import { inIframe } from "../lib/environment"

export const BrowserExtensionInstallModalId = "browser-extension-install-modal"
const BrowserExtensionLastPromptedKey = new LocalStorageKey(
  "ikBrowserExtensionLastPromptedKey"
)
const OneMonth = 60 * 60 * 24 * 30

const BrowserExtensionInstallModal = () => {
  const state = useState()
  const actions = useActions().browserExtensionEventBus

  const checkStatus = async () => {
    await actions.checkExtensionIsInstalled()
    if (state.browserExtensionEventBus.isExtensionInstalled) return
    if (
      !!BrowserExtensionLastPromptedKey.exists() &&
      BrowserExtensionLastPromptedKey.getTimeDifferenceFromNow() < OneMonth
    ) {
      return
    }
    if (
      state.account.posSystem !== "lightspeed-retail" &&
      state.account.posSystem !== "vend"
    )
      return
    if (inIframe()) return

    // toggleModal(BrowserExtensionInstallModalId);
    BrowserExtensionLastPromptedKey.setValueAsDateNow()
  }
  useEffect(() => {
    checkStatus()
  }, [])

  return (
    <Modal
      id={BrowserExtensionInstallModalId}
      variant={ModalVariant.lg}
      title="Enhance your Point of Sale">
      <div className="my-2 d-flex gap-3" style={{ maxWidth: "55ch" }}>
        <BrowserExtensionAppIcon />
        <div>
          <p className="mb-2">
            Downloading the Browser Extension enhances your Point of Sale. You
            gain powerful features such as
          </p>
          <ul className="mb-4">
            <li>
              View and reply to messages without leaving your Point of Sale
            </li>
            <li>Send messages for special orders</li>
            <li>Text receipts and work order quotes</li>
            <li>
              Plus more. It is easy to install. Simple click the below button.
            </li>
          </ul>
          <BrowserExtensionDownload />
        </div>
      </div>
    </Modal>
  )
}

export default BrowserExtensionInstallModal
