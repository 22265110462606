/* eslint-disable no-throw-literal */
import { extractNumbers } from "../../number"
import { checkPhoneNumberValidity } from "../../phoneNumber"
import { makeTableData } from "./table"
import { findAndDelete } from "../../arrayUtil"

const getNamespacedObj = (overmind) => {
  const namespace = overmind.execution.namespacePath[0]

  const state = overmind.state[namespace]
  const actions = overmind.actions[namespace]
  const effects = overmind.effects[namespace]

  return { state, effects, actions }
}

export const get = async (overmind, props) => {
  const { state, effects } = getNamespacedObj(overmind)

  if (state.isLoading || !state.isDirty) return
  state.isLoading = true
  state.apiError = null
  state.contacts = null

  try {
    const data = await effects.get({
      query: state.query,
      offset: state.offset,
      includeId: props?.includeId,
    })
    state.canCreate = data?.meta?.can_create
    state.contacts = data?.customers
    state.tableData = makeTableData(state.contacts)
    state.isDirty = false
  } catch (e) {
    state.apiError = e
  }
  state.isLoading = false
}

export const setSelectedContact = (
  overmind,
  { name, vendorId, vendorSource, email }
) => {
  const { state } = getNamespacedObj(overmind)

  const sanitizedContactName = name?.trim()

  const numbersInString = extractNumbers(sanitizedContactName)
  const isAPossiblePhoneNumber = numbersInString > 5

  const contactName = isAPossiblePhoneNumber ? "" : sanitizedContactName
  const phoneNumber = isAPossiblePhoneNumber ? sanitizedContactName : ""

  state.selectedContact = {
    name: contactName,
    phoneNumber,
    vendorId,
    vendorSource,
    email,
  }
}

export const updateFilter = (overmind, { query, executeFetch }) => {
  const { state, actions } = getNamespacedObj(overmind)
  state.query = query
  state.isDirty = true
  if (executeFetch) {
    actions.get()
  }
}

export const clearSearch = (overmind) => {
  const { state } = getNamespacedObj(overmind)
  state.query = null
  state.contacts = null
  state.tableData = null
  state.isDirty = true
  state.hasMoreData = true
  state.apiError = null
}

export const getDetail = (overmind, id) => {
  const { state } = getNamespacedObj(overmind)
  const contact = state.contacts?.find((c) => c.id === id)
  return contact
  // TODO: If not contact, then fetch from API
}

export const update = async (overmind, newContact) => {
  const { state, effects } = getNamespacedObj(overmind)

  await effects.editContact(newContact)
  const { contacts } = state

  // Update locally
  if (!contacts) return null
  state.contacts = contacts.map((local) => {
    if (local.id === newContact.id) return newContact
    return local
  })
  state.tableData = makeTableData(state.contacts)
  return newContact
}

export const create = async (overmind, newContact) => {
  const { state, effects } = getNamespacedObj(overmind)
  const data = await effects.createContact(newContact)

  findAndDelete(state.contacts, ({ id }) => id === data.id)
  if (state.contacts) {
    state.contacts.unshift(data)
  }

  state.tableData = makeTableData(state.contacts)
  return data
}

export const delete_ = async (overmind, contactId) => {
  const { state, effects } = getNamespacedObj(overmind)
  effects.delete_(contactId)

  findAndDelete(state.contacts, ({ id }) => id === contactId)
  state.tableData = makeTableData(state.contacts)
}

export const onInitializeOvermind = (overmind, instance) => {
  instance.reaction(
    ({ account }) => account.data,
    () => {
      const { actions } = getNamespacedObj(overmind)
      if (!overmind.state.account.data) return
      actions.clearSearch()
    }
  )
}
