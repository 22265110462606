import React from "react";
import { Link, useNavigate } from "react-router-dom";

import MainMenuNavItem from "./MainMenuNavItem";
import "./style.css";
import MainMenuUserDropdown from "./MainMenuUserDropdown";
import MainMenuIntegrationDropdown from "./MainMenuIntegrationDropdown";
import MainMenuAccountStatus from "./MainMenuAccountStatus";
import PageDebugDropdown from "../Debug/PageDebugDropdown";
import { useActions, useState } from "../lib/overmind";
import ManagedLocationSelectDropdown from "../LocationSelectDropDown/ManagedLocationSelectDropdown";

const MainMenuNav = ({ hidden }) => {
  const actions = useActions().account;
  const state = useState();
  const navigate = useNavigate();

  if (hidden) return null;
  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container-fluid">
        <button
          type="button"
          className="btn btn-sm btn-outline-light d-block d-lg-none me-3"
          onClick={() => navigate(-1)}
        >
          <i className="bi-arrow-left" /> Back
        </button>
        <Link
          to="/inbox/all"
          className="btn btn-sm btn-outline-light d-block d-lg-none me-auto"
        >
          Home
        </Link>
        <ManagedLocationSelectDropdown
          selectedId={state.account.shopExternalId}
          onSelect={async (externalId) => {
            navigate("/inbox/all");
            await actions.getAccount(externalId);
          }}
        />
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse ms-3" id="navbarNav">
          <ul className="navbar-nav me-auto">
            <MainMenuNavItem
              to="/inbox/all"
              showNotificationBadge={!!state.inboxes.locationHasUnreadMessages}
            >
              Inbox
            </MainMenuNavItem>
            <MainMenuNavItem to="/contacts">Contacts</MainMenuNavItem>
            <MainMenuNavItem to="/payments">Payments</MainMenuNavItem>
            <MainMenuNavItem className="d-none d-md-block" to="/nps">
              NPS
            </MainMenuNavItem>
            <MainMenuNavItem
              hidden={
                state.account.data?.config.portal.campaigns
                  .needs_account_upgrade
              }
              className="d-none d-md-block"
              to="/campaigns"
            >
              Campaigns
            </MainMenuNavItem>
            <MainMenuNavItem to="/reports">Reports</MainMenuNavItem>

            <div className="d-block d-md-none">
              <li>
                <hr className="dropdown-divider" />
              </li>
              <MainMenuNavItem to="/settings">Account Settings</MainMenuNavItem>
              <li
                className="nav-item mx-1 my-2 text-white"
                data-bs-toggle="modal"
                data-bs-target="#change-password-modal"
              >
                Change password
              </li>
              <MainMenuNavItem to="/settings/support">Support</MainMenuNavItem>
              <MainMenuNavItem to="/logout">Sign out</MainMenuNavItem>
            </div>
          </ul>
          <MainMenuAccountStatus />
          <PageDebugDropdown />
          <MainMenuIntegrationDropdown />
          <MainMenuUserDropdown />
        </div>
      </div>
    </nav>
  );
};

export default MainMenuNav;
