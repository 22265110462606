import React from "react";

const VideoCard = ({ url, contentType }) => {
  return (
    <video width="350" controls>
      <source src={url} type={contentType} />
      Your browser does not support HTML video.
    </video>
  );
};

export default VideoCard;
