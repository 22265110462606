import React from "react";
import APIResponseError from "../components/APIResponseError";

function InboxMessageSendError({ error, onCloseError }) {
  if (!error) return null;
  return (
    <div
      className="overflow-scroll position-relative"
      style={{ maxHeight: "150px" }}
    >
      <button
        type="button"
        style={{ zIndex: "9000" }}
        className="btn btn-link position-absolute top-0 end-0"
        onClick={() => {
          onCloseError();
        }}
      >
        <i className="bi bi-x-circle text-black" />
      </button>
      <APIResponseError
        className="p-1 m-0 rounded-0 w-100"
        response={error?.response}
      />
      {!error?.response && (
        <div className="alert alert-warning p-1 px-2">{error.message}</div>
      )}
    </div>
  );
}

export default InboxMessageSendError;
