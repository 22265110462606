import React from "react"
import AccountSetupFeatureList from "../../components/AccountSetupFeatureList"
import { changePhoneNumberModalId } from "./ChangeLocationPhoneNumberModal"
import { useState } from "../../../lib/overmind"
import { formatPhoneNumberWithAccountCountry } from "../../../lib/overmind/account/util"
import PhoneInfoCard from "../PhoneInfoCard"

const PhoneNumberInEligibleInfoCard = ({ hidden }) => {
  const state = useState().account
  const phoneNumber =
    formatPhoneNumberWithAccountCountry(state.setupStatus?.phone_number) || ""

  return (
    <div hidden={hidden}>
      <p className="">
        We are currently evaluating your phone number, {phoneNumber}.
      </p>
      <p>
        Currently we are able to text from all landline phone numbers, and most
        VoIP numbers. Mobile numbers, Google Voice, and Verizon OneTalk are not
        eligible due to carrier restrictions.
      </p>

      <AccountSetupFeatureList className="mb-3">{[]}</AccountSetupFeatureList>
      <PhoneInfoCard />
    </div>
  )
}

export default PhoneNumberInEligibleInfoCard
