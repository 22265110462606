import React from "react";
import LabeledField from "../components/Form/LabeledField";
import SimpleForm from "../components/Form/SimpleForm";
import InfoBubble from "../lib/InfoBubble";
import { getRandomInt } from "../lib/number";
import { useActions } from "../lib/overmind";

const InboxCreateForm = ({ values, onComplete }) => {
  const actions = useActions().inboxes;
  const onSubmit = async (data) => {
    if (values.id) {
      await actions.update(data);
    } else {
      await actions.create(data);
    }
    onComplete();
  };

  const onDelete = async (e) => {
    e.preventDefault();
    const shouldContinue = confirm(
      "Are you sure you want to delete this inbox?",
    );
    if (!shouldContinue) return;
    actions.delete_(values?.id);
    onComplete();
  };

  const containerId = `inboxFormSubmitButtonContainer-${getRandomInt()}`;

  return (
    <div className="py-2">
      <SimpleForm
        submitButtonPortalNodeId={containerId}
        apiSubmitHandler={onSubmit}
        initialValues={values || { name: "" }}
        resetForm
      >
        <LabeledField
          label="Inbox Name"
          name="name"
          className="form-control form-control"
          required
          maxLength={25}
        />
        <InfoBubble>
          <i className="bi-stars text-info" /> Sales, Support, or employee
          specific inboxes are common examples
        </InfoBubble>
        <div className="d-flex pt-3 gap-2 mb-3">
          <button
            type="button"
            className="btn btn-secondary btn-sm"
            onClick={() => onComplete()}
          >
            Cancel
          </button>
          <button
            type="button"
            hidden={!values?.id}
            className="btn btn-outline-danger btn-sm"
            onClick={onDelete}
          >
            Delete
          </button>
          <div id={containerId} className="ms-auto" />
        </div>
      </SimpleForm>
    </div>
  );
};

export default InboxCreateForm;
