/* eslint-disable no-shadow */
import { derived } from "overmind";

export const state = {
  apiData: null,

  /* start DataTable interface */
  tableData: null,
  isLoading: false,
  responseError: null,
  /* end */

  /*  selected table row when editing a work order status */
  selectedEditRecordId: null,
  selectedEditRecord: derived((state) => {
    if (!state.selectedEditRecordId) return null;
    return state.apiData?.campaigns?.find(
      (c) => c.id === state.selectedEditRecordId,
    );
  }),
  /* end */

  // List of the Lightspeed Workorder statuses. E.g. "Finished, #38383"
  workorderStatusData: derived((state) => state.apiData?.workorder_status),

  activeWorkorderStatuses: derived((state) =>
    state.apiData?.campaigns?.map((c) => c.status),
  ),
};
