import React from "react";
import { Route, Routes } from "react-router";
import AccountUpdate from "../Account/AccountUpdate";
import Integrations from "./Integrations";
import UserManagement from "../UserManagement";
import UserPermissionCheck from "../components/UserPermissionCheck";
import { inPortalIframe } from "../lib/environment";
import { privacyPolicyUrl } from "../lib/ikeono";
import Redirect from "../components/Redirect";
import LocationManagement from "../LocationManagement";
import SectionNavBar from "../components/SectionNavBar";
import StripeBillingPortal from "../Billing/StripeBillingPortal";
import Support from "../Support";

const Settings = () => {
  let navItems = [
    { title: "Location", path: "/settings/location/details" },
    { title: "Account", path: "/settings/account" },
    { title: "Billing", path: "/settings/billing" },
    { title: "Integrations", path: "/settings/integrations" },
    { title: "Users", path: "/settings/users" },
    { title: "Support", path: "/settings/support" },
    {
      title: "Privacy Policy",
      path: privacyPolicyUrl,
      type: "external",
      style: { marginLeft: "auto" },
    },
    {
      title: "Terms of Use",
      path: "https://ikeono.com/terms-of-use",
      type: "external",
    },
  ];
  if (inPortalIframe()) {
    navItems = [];
  }

  const locationsSettingsUrl = `/settings/location/details`;

  return (
    <UserPermissionCheck permissionKey="view_settings">
      <div className="container-fluid px-3">
        <SectionNavBar>{navItems}</SectionNavBar>
        <Routes>
          <Route
            path="/"
            index
            element={<Redirect to={locationsSettingsUrl} />}
          />
          <Route path="account" element={<AccountUpdate />} />
          <Route
            path="locations"
            element={<Redirect to={locationsSettingsUrl} />}
          />
          <Route path="location/*" element={<LocationManagement />} />
          <Route path="users/:id" element={<UserManagement />} />
          <Route path="users" element={<UserManagement />} />
          <Route path="integrations" element={<Integrations />} />
          <Route path="billing" element={<StripeBillingPortal />} />
          <Route path="support" element={<Support />} />
        </Routes>
      </div>
    </UserPermissionCheck>
  );
};

export default Settings;
