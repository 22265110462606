import React from "react";

const FormGroup = ({ children, label, formText }) => {
  return (
    <div className="d-flex mb-3">
      <span style={{ width: "300px", fontSize: "14px", fontWeight: "600" }}>
        {label}
      </span>
      <div>
        {children}
        {formText && <div className="form-text">{formText}</div>}
      </div>
    </div>
  );
};

export default FormGroup;
