import React from "react"

const ThreadActionItem = ({
  onClick,
  title,
  description,
  iconName,
  hideBorder,
  hidden,
  buttonProps,
  disabled,
}) => {
  return (
    <li hidden={hidden} className="text-truncate">
      <button
        disabled={disabled}
        type="button"
        className={`dropdown-item py-3 ${hideBorder ? "" : "border-top"}`}
        onClick={onClick}
        {...buttonProps}>
        <div className="d-flex flex-row gap-3 align-items-center">
          <i className={iconName} />
          <div className="d-flex flex-column">
            <h6 className="fw-bold mb-0" style={{ fontSize: "14px" }}>
              {title}
            </h6>
            <span style={{ fontSize: "13px" }}>{description}</span>
          </div>
        </div>
      </button>
    </li>
  )
}

export default ThreadActionItem
