import React from "react";
import IntegrationCard from "./IntegrationCard";
import { LightspeedRSeriesAppIcon } from "../../IntegrationAppIcon";
import useLightspeedOAuthHook from "../../components/Lightspeed/LighspeedOAuthHook";
import Loader from "../../components/Loader";
import { ConnectedBadge } from "../../components/InstalledBadge";
import { useState } from "../../lib/overmind";
import { PosSystem } from "../../lib/pos";
import useIntegrationErrorHook from "./IntegrationErrorHook";

const LightspeedRSeriesIntegrationCard = ({ hidden }) => {
  if (hidden) return null;
  const [isLoading, error, onClick] = useLightspeedOAuthHook();
  const state = useState().account;
  useIntegrationErrorHook(error);

  return (
    <IntegrationCard
      icon={<LightspeedRSeriesAppIcon />}
      title={
        <span>
          Lightspeed R-Series <Loader hidden={!isLoading} inline />
        </span>
      }
      onClick={onClick}
    >
      <ConnectedBadge hidden={state.posSystem !== PosSystem.LightspeedRetail} />
    </IntegrationCard>
  );
};

export default LightspeedRSeriesIntegrationCard;
