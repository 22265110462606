import React from "react"
import AccountSetupFeatureList from "./components/AccountSetupFeatureList"
import { subscribeUser } from "../services/notification"
import { usePageViewHook } from "../services/mixpanel"
import IntegrationCard from "./IntegrationSetup/IntegrationCard"
import { PushNotificationAppIcon } from "../IntegrationAppIcon"

const PushNotificationSetup = ({ onComplete }) => {
  usePageViewHook("push notification setup")

  const onClick = async () => {
    try {
      await subscribeUser()
    } catch (error) {
      console.log(error)
    }
    onComplete({
      action: "enabled notifications",
    })
  }

  return (
    <div>
      <IntegrationCard
        onClick={onClick}
        title="Enable push notifications"
        icon={<PushNotificationAppIcon />}
      />
      <button
        type="button"
        className="btn btn-link text-secondary mt-3 ps-0"
        onClick={() =>
          onComplete({
            action: "continue without setup",
          })
        }>
        Continue without enabling
      </button>
    </div>
  )
}

export default PushNotificationSetup

export const PushNotificationDetail = () => (
  <AccountSetupFeatureList title="Get notified when...">
    {[
      "Invoices and repairs get paid",
      "A customer leaves a Google or Facebook review",
      "A website visitor asks a question",
      "Someone texts your business phone number",
      "A customer gives you feedback",
    ]}
  </AccountSetupFeatureList>
)
