import React from "react";

const BrowserExtensionMissingBadge = () => (
  <div className="alert alert-warning py-1 px-3 d-inline-block ">
    <i className="bi-exclamation-circle me-2" />
    The Ikeono extension is not installed and needs to be. Please click the
    below link.
  </div>
);

export default BrowserExtensionMissingBadge;
