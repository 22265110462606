/* eslint-disable no-shadow */
import React, { useState } from "react"
import Modal from "../components/Modal"
import { useActions, useState as useStateO } from "../lib/overmind"
import InboxCreateForm from "./InboxCreateForm"

export const inboxEditModalId = "inbox-edit-modal"

const InboxEditModal = () => {
  const state = useStateO().activeInbox
  const actions = useActions().activeInbox
  const [inbox, setInbox] = useState()

  return (
    <Modal
      title="Edit Inbox"
      id={inboxEditModalId}
      onShow={() => {
        const inbox = actions.getActiveInboxDetail()
        setInbox(inbox)
      }}>
      <InboxCreateForm
        values={{ id: state.inboxId, name: inbox?.title }}
        onComplete={() =>
          document.getElementById("modal-close-btn-edit-modal").click()
        }
      />
      <button
        type="button"
        className="btn-close d-none"
        data-bs-dismiss="modal"
        id="modal-close-btn-edit-modal"
      />
    </Modal>
  )
}

export default InboxEditModal
