import React from "react";

const ProgressBar = ({ hidden, style, className, percentage = 50 }) => {
  if (hidden) return null;
  return (
    <div
      className={`progress ${className}`}
      role="progressbar"
      aria-valuenow="25"
      aria-valuemin="0"
      aria-valuemax="100"
      style={{ width: "100px", height: "11px", ...style }}
    >
      <div className="progress-bar" style={{ width: `${percentage}%` }} />
    </div>
  );
};

export default ProgressBar;
