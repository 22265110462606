import * as api from "../../api";

export const getWorkorderStatuses = async (params) => {
  const response = await api.get("/shop/{exid}/campaign/workorder/", params); // TODO: Handle Error
  const { data } = response;
  return data;
};

export const delete_ = async (id) => {
  api.delete_(`/shop/{exid}/campaign/workorder/${id}/`);
};
