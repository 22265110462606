import React from "react"
import DataTableRow from "./DataTableRow"
import DataTableZeroState from "./DataTableZeroState"

const DataTable = ({ rows, headers, rowClickHandler, ZeroStateComponent }) => {
  if (!headers) return null
  return (
    <div>
      <table className="table table-hover">
        <thead>
          <tr>
            {headers?.map((title) => {
              return (
                <th scope="col" key={title}>
                  {title}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {rows?.map((row) => (
            <DataTableRow
              onClick={rowClickHandler}
              dataBsTarget={row.data_bs_target}
              key={row.id}>
              {row}
            </DataTableRow>
          ))}
        </tbody>
      </table>
      {!rows?.length && (ZeroStateComponent || <DataTableZeroState />)}
    </div>
  )
}

export default DataTable
