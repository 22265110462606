import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import Checkbox from "../components/Form/Checkbox";

const WebchatLocationPicker = ({ locations }) => {
  const { values } = useFormikContext();

  useEffect(() => {
    const selectedLocations = Object.keys(values).filter((k) =>
      values[k] === true ? k : null
    );
  }, [values]);

  return (
    <div>
      {locations.map(({ shop_id, name }) => {
        return <Checkbox id={`shop__${shop_id}`} label={name} value />;
      })}
    </div>
  );
};

export default WebchatLocationPicker;
