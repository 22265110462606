import React, { useEffect, useRef } from "react"
import APIResponseError from "../components/APIResponseError"
import MessageCardFactory from "../MessageCard/MessageCardFactory"
import AttachmentDetailModal from "./AttachmentDetailModal"
import InboxMessageLoading from "./InboxMessageLoading"
import InboxMessageZeroState from "./InboxMessageZeroState"

const InboxMessageList = ({
  threadId,
  isLoading,
  apiError,
  messages,
  actions,
}) => {
  const bottomRef = useRef()
  const [hasScrolled, setHasScrolled] = React.useState(false)
  const [messageCount, setMessageCount] = React.useState(0)

  useEffect(() => {
    if (!hasScrolled) {
      bottomRef.current?.scrollIntoView()
    }
  })

  useEffect(() => {
    // Scroll if the user has not started scrolling or if a new message comes in
    if (!hasScrolled || messages?.length > messageCount) {
      bottomRef.current?.scrollIntoView()
    }
    setMessageCount(messages?.length)
  }, [messages])

  useEffect(() => {
    setHasScrolled(false)
  }, [threadId])

  if (isLoading) {
    return <InboxMessageLoading />
  }

  if (apiError) {
    if (apiError.response?.status === 404) {
      const shopExid = apiError.response?.data?.shop_exid
      if (shopExid) {
        return (
          <div className="d-flex h-100 align-items-center justify-content-center flex-column  text-secondary text-center">
            <div style={{ maxWidth: "50ch" }}>
              <i className="bi-shop" style={{ fontSize: "38px" }} />
              <h6>
                This conversation is at a different location you are associted
                with. Would you like to that location?
              </h6>
              <span className="fw-bold d-block">
                {apiError.response?.data?.shop_name}
              </span>
              <button
                className="btn btn-link"
                type="button"
                onClick={async () => {
                  await actions.account.getAccount(shopExid)
                }}>
                Login to Location
              </button>
            </div>
          </div>
        )
      }
      return (
        <div className="d-flex h-100 align-items-center justify-content-center flex-column  text-secondary">
          <i className="bi-robot" style={{ fontSize: "38px" }} />
          <h6>Yikes! Unable to find this thread.</h6>
          <button
            className="btn btn-link"
            type="button"
            onClick={() => actions.activeInbox.navigateToFirstThread()}>
            Open inbox
          </button>
        </div>
      )
    }

    return (
      <div className="d-flex h-100 align-items-center justify-content-center flex-column">
        <div style={{ maxWidth: "55ch" }}>
          <APIResponseError response={apiError} />
        </div>
        <button
          type="button"
          className="btn btn-link"
          onClick={() => actions.activeThread.get(threadId)}>
          Refresh
        </button>
      </div>
    )
  }

  if (!messages?.length) {
    return <InboxMessageZeroState />
  }

  return (
    <div
      className="overflow-y-scroll p-3"
      style={{ flex: 1 }}
      onScroll={() => {
        if (hasScrolled) return
        setHasScrolled(true)
      }}>
      {messages?.map((m, idx) => {
        return (
          <MessageCardFactory
            key={m.sid}
            idx={idx}
            message={m}
            bottomRef={bottomRef}
          />
        )
      })}
      <div ref={bottomRef} />
      <AttachmentDetailModal />
    </div>
  )
}

export default InboxMessageList
