import React, { useEffect, useState } from "react"
import SimpleForm from "../../components/Form/SimpleForm"
import { SettingsFactory } from "../../components/Settings/SettingsFactory"
import { get, post } from "../../lib/api"
import Loader from "../../components/Loader"
import APIResponseError from "../../components/APIResponseError"
import SectionHeader from "../../components/SectionHeader"

const fields = [
  {
    label: "Message Body",
    body: "",
    as: "textarea",
    props: {
      mergeFields: [
        {
          name: "Shop",
          value: "{shop}",
        },
      ],
      label: "Message Body",
      name: "message",
      rows: 5,
      maxLength: 1000,
    },
  },
  {
    label: "Enabled",
    as: "checkbox",
    props: {
      id: "enabled",
      name: "enabled",
      label: "Enabled",
    },
  },
]

const LocationAutomatedResponseForm = ({ locationId }) => {
  const [responseData, setResponseData] = useState(null)
  const [apiError, setApiError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const apiSubmitHandler = (data) => {
    return post(`shop/${locationId}/autoresponse/`, data)
  }

  const apiGetHandler = async () => {
    setIsLoading(true)
    setApiError(null)
    try {
      const response = await get(`shop/${locationId}/autoresponse/`)
      setResponseData(response.data)
    } catch (error) {
      setApiError(error)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    apiGetHandler()
  }, [locationId])

  if (isLoading) return <Loader />
  if (apiError) return <APIResponseError response={apiError} />

  return (
    <>
      {" "}
      <SectionHeader className="mb-3">After Hours Response</SectionHeader>
      <SimpleForm
        initialValues={responseData}
        apiSubmitHandler={apiSubmitHandler}>
        <SettingsFactory fields={fields} />
      </SimpleForm>
    </>
  )
}

export default LocationAutomatedResponseForm
