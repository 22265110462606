import { useFormikContext } from "formik";
import React, { useEffect } from "react";

const FormChange = ({ onChange }) => {
  const { values } = useFormikContext();

  useEffect(() => {
    onChange(values);
  }, [values]);

  return null;
};

export default FormChange;
