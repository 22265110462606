import React from "react";

const InfoBubble = ({
  children,
  variant = "info",
  className = "",
  hidden,
  style,
}) => {
  if (hidden) return null;
  return (
    <div
      className={`ps-2 fs-14 ${className}`}
      style={{
        borderRadius: "5px",
        borderLeft: `3px solid var(--bs-${variant})`,
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default InfoBubble;
