import * as api from "../../api";

export const get = async () => {
  const response = await api.get("/shop/{exid}/inbox");
  return response.data;
};

export const create = async (inboxName) => {
  const response = await api.post("/shop/{exid}/inbox}", { name: inboxName });
  return response.data.data;
};

export const udpate = async ({ id, name }) => {
  const response = await api.put(`/shop/{exid}/inbox/${id}`, { name });
  return response.data.data;
};

export const delete_ = async ({ id }) => {
  await api.delete_(`/shop/{exid}/inbox/${id}`);
};
