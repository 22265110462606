/* eslint-disable no-shadow */
/* eslint-disable no-undef */
import React, { useEffect, useState } from "react"
import Loader from "../components/Loader"
import { post } from "../lib/api"
import { AppConfig } from "../services/config"
import { captureException } from "../services/sentry"
import InfoBubble from "../lib/InfoBubble"

const FacebookLogin = ({ onLogin }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showNotConnectedWarning, setShowConnnectedWarning] = useState(false)

  useEffect(() => {
    FB.init({
      appId: AppConfig.FacebookAppId,
      version: "v19.0",
    })
  })

  const onLoginClick = async () => {
    setIsLoading(true)
    setShowConnnectedWarning(false)

    const callback = async (response) => {
      try {
        if (response?.status !== "connected") {
          setShowConnnectedWarning(response?.status || "no responsee")
          setIsLoading(false)
          return
        }
        await post("/shop/{exid}/fb/oauth-exchange", response)
        onLogin()
      } catch (error) {
        console.log(error)
        captureException(error)
        setError(
          "There was an error. Please try again or contact help@ikeono.com"
        )
      }
      setIsLoading(false)
      FB.logout()
    }
    FB.logout()

    FB.login(
      (response, e) => {
        console.log(`tmp: e: `, e)
        callback(response)
      },
      {
        config_id: AppConfig.FacebookConfigId,
        response_type: "code",
        override_default_response_type: true,
      }
    )
  }
  return (
    <div>
      <div>
        <div hidden={!isLoading}>
          <InfoBubble className="my-1">
            <p className="fs-16">
              <Loader inline className="me-1 text-secondary" /> Connecting to
              your Facebook account. Please wait a moment.
            </p>
          </InfoBubble>
        </div>
        <button
          disabled={isLoading}
          type="button"
          className="loginBtn loginBtn--facebook cursor-pointer"
          onClick={onLoginClick}>
          Continue with Facebook
        </button>
        <div
          className="alert alert-warning mt-4"
          hidden={!showNotConnectedWarning}>
          <span>
            There was an issue connecting your Facebook pages. Please be sure to
            click <b>Got It</b> at the end and not close the window yourself.
            <span className="d-block fs-14">
              Status: {showNotConnectedWarning}
            </span>
          </span>
        </div>
      </div>

      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  )
}

export default FacebookLogin
