import React from "react";
import { Field } from "formik";
import StandardFormGroup from "./StandardFormGroup";

const LabeledFieldGroup = ({
  label,
  inputGroupJustify = "end",
  inputGroupText,
  ...props
}) => {
  let computedClassName = props.className
    ? props.className
    : "form-control mb-3";
  if (props?.as === "select" && !props.className) {
    computedClassName = "form-select mb-2";
  }

  return (
    <StandardFormGroup
      label={label}
      fieldId={props.id}
      className={props.groupClassName}
    >
      <div className="input-group">
        <span hidden={inputGroupJustify === "end"} className="input-group-text">
          {inputGroupText}
        </span>
        <Field className={computedClassName} {...props} />
        <span
          hidden={inputGroupJustify === "start"}
          className="input-group-text"
        >
          {inputGroupText}
        </span>
      </div>
    </StandardFormGroup>
  );
};

export default LabeledFieldGroup;
