/* eslint-disable prefer-const */
import React, { useEffect } from "react"
import SimpleForm from "../../components/Form/SimpleForm"
import StoreAssignmentField from "../../components/Form/StoreAssignmentField"
import { put, useGet } from "../../lib/api"
import APIResponseError from "../../components/APIResponseError"
import Loader from "../../components/Loader"
import { useActions, useState } from "../../lib/overmind"
import SectionHeader from "../../components/SectionHeader"
import GoogleNoLocationsWarning from "../GoogleNoLocationsWarning"

const GoogleLocationAssignment = () => {
  const actions = useActions().account
  const state = useState().account

  let [get, isLoading, error, resp] = useGet()
  const [initialValues, setInitialValues] = React.useState({})

  useEffect(() => {
    get("/account/{account_id}/google/locations")
  }, [])

  useEffect(() => {
    if (!resp) return
    const vals = {}

    for (const location of resp.data.ikeono_locations) {
      vals[`ik_store_id__${location.id}`] = location.google_location_id
    }

    setInitialValues(vals)
  }, [resp])

  if (error) return <APIResponseError response={error} />
  if (isLoading) return <Loader />
  if (!initialValues) return null

  const apiSubmitHandler = async (data) => {
    isLoading = true
    await put("/account/{account_id}/google/locations", data)
    isLoading = false
    const googleLocationId = data[`ik_store_id__${state.shopExternalId}`]
    actions.setGoogleLocationId(googleLocationId)
  }

  return (
    <>
      <div className="mb-4">
        <SectionHeader>Google Location Assignment</SectionHeader>
        Assign your Ikeono location to your Google Location to sync business
        information, store hours and reviews.
      </div>
      <GoogleNoLocationsWarning hidden={resp?.data?.google_locations?.length} />
      <SimpleForm
        className="d-inline-block"
        initialValues={initialValues}
        apiSubmitHandler={apiSubmitHandler}>
        <StoreAssignmentField
          ikeonoStores={resp?.data?.ikeono_locations}
          externalStores={resp?.data?.google_locations}
          title="Google Locations"
        />
      </SimpleForm>
    </>
  )
}

export default GoogleLocationAssignment
