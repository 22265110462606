/* eslint-disable no-shadow */
import React, { useState } from "react"
import { get } from "../../lib/api"
import Button from "../../components/Button"
import APIResponseError from "../../components/APIResponseError"

const MessageInvoiceCardExpired = ({ status, invoiceId }) => {
  if (status !== "void") return null
  const [error, setError] = useState()
  const [success, setSUccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const onClick = async () => {
    setError(null)
    setIsLoading(true)
    try {
      await get("/shop/{exid}/invoice/resend", {
        invoice_id: invoiceId,
      })
    } catch (error) {
      setError(error)
    }
    setIsLoading(false)
  }

  return (
    <>
      <hr />
      <div className="d-flex flex-column gap-1">
        <b>Expired</b>
        <span className="card-invoice-expired">
          Invoice is expired as a security measure. If you would like you can
          resend the invoice.
        </span>
        <Button
          className="btn btn-primary btn-sm mt-1"
          showLoader={isLoading}
          onClick={onClick}>
          Resend invoice
        </Button>
        <APIResponseError response={error?.response} />
        {success && (
          <span>
            <i className="bi-circle-check" /> The invoice has been sent to the
            customer.
          </span>
        )}
      </div>
    </>
  )
}

export default MessageInvoiceCardExpired
