import React from "react"
import FacebookLogin from "./FacebookLogin"
import { useActions } from "../lib/overmind"

const MessengerInstall = ({ hidden }) => {
  const actions = useActions().integrations

  if (hidden) return null
  return (
    <div className="container">
      <div className="container col-xxl-10 px-4 ">
        <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
          <div className="col-10 col-sm-8 col-lg-6">
            <img
              className="w-100"
              src="https://images.squarespace-cdn.com/content/v1/63a3448b4bb6213ae43afb6d/788bcced-d9ef-4ce6-8311-ccdfef61a580/Inbox+Backoffice+Details+%281%29.png?format=2500w"
            />
          </div>
          <div className="col-lg-6">
            <h1 className="display-5 fw-bold lh-1 mb-3">Messenger</h1>
            <p style={{ fontSize: "18px" }}>
              Do not miss out on the opportunity to connect with your customers
              where they are; harness the power of Facebook Messenger to build
              stronger relationships and drive meaningful engagement.
            </p>
            <p style={{ fontSize: "18px" }}>
              Setup Facebook now to start receiving messages in your inbox.
            </p>
            <div className="d-grid gap-2 d-md-flex justify-content-md-start mb-3">
              <FacebookLogin
                onLogin={() => actions.get({ forceReload: true })}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MessengerInstall
