import React from "react";
import ContactForm from "./ContactForm";

const CreateContactForm = ({ onSuccess }) => {
  return (
    <div>
      <h5>Create Contact</h5>
      <ContactForm
        contact={{
          first_name: null,
          last_name: null,
          mobile_phone_number: null,
          email: null,
        }}
        onSuccess={(newContact) => onSuccess(newContact)}
      />
    </div>
  );
};

export default CreateContactForm;
