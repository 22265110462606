import React, { useState } from "react";
import { get } from "../../lib/api";
import { openPopup } from "../../lib/popup";
import { AppConfig } from "../../services/config";

const useLightspeedOAuthHook = (props) => {
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();

  const onClick = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const params = {
        pos: "lightspeed",
        redirect_url:
          props?.redirectUrl || `${AppConfig.CurrentHost}/pos/oauth/complete`,
      };
      const { data } = await get("/shop/{exid}/lightspeed/authorize", params);
      openPopup(data.auth_url, "Lightspeed Authorize", 600, 750);
    } catch (e) {
      setError(e);
    }
    setIsLoading(false);
  };

  return [isLoading, error, onClick];
};

export default useLightspeedOAuthHook;
