/* eslint-disable no-shadow */
import React, { useState } from "react"
import Modal, { hideModal } from "../../../components/Modal"
import StandardFormGroup from "../../../components/Form/StandardFormGroup"
import APIResponseError from "../../../components/APIResponseError"
import Button from "../../../components/Button"
import { useActions } from "../../../lib/overmind"
import { checkPhoneNumberValidity } from "../../../lib/phoneNumber"
import { useMixpanel } from "../../../services/mixpanel"

export const changePhoneNumberModalId = "change-phone-number-modal"

const ChangeLocationPhoneNumberModal = () => {
  const actions = useActions().account
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const [unhostable, setUnhostable] = useState()
  const [phoneNumber, setPhoneNumber] = useState("")
  const [isPhoneInvalid, setIsPhoneInvalid] = useState("")
  const mixpanel = useMixpanel()

  const onClick = async () => {
    setError(null)
    setUnhostable(false)
    const isPhoneInValid = checkPhoneNumberValidity(phoneNumber)
    setIsPhoneInvalid(isPhoneInValid)
    if (isPhoneInValid) return
    setIsLoading(true)

    try {
      await actions.changePhoneNumber(phoneNumber)
      hideModal(changePhoneNumberModalId)
      mixpanel.track("user changed phone number", {
        "new phone number": phoneNumber,
      })
    } catch (error) {
      const isUnhostable = error.response?.data?.detail?.includes("hostable")
      if (isUnhostable) {
        setUnhostable(true)
      } else {
        setError(error)
      }
    }
    setIsLoading(false)
  }

  return (
    <Modal
      title="Check another phone number"
      id={changePhoneNumberModalId}
      onHide={() => setError(null)}>
      <div className="mb-3">
        If you have another number you would like to use for your business we
        can check to see if that phone number is eligible.
      </div>
      <div
        hidden={!unhostable}
        className="mb-3 p-3 text-warning-emphasis bg-warning-subtle border border-warning-subtle rounded-3">
        This phone number is not eligible to be hosted. But do not worry! We
        will contact you shortly to assign you a local phone number.
      </div>
      <div style={{ maxWidth: "300px" }}>
        <StandardFormGroup label="Business Phone Number">
          <input
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            className="form-control"
          />
          <div hidden={!isPhoneInvalid} className="text-danger">
            {isPhoneInvalid}
          </div>
        </StandardFormGroup>
        <div className="d-flex mt-3 mb-4">
          <button
            type="button"
            className="btn btn-outline-secondary btn-sm me-auto"
            data-bs-dismiss="modal"
            aria-label="Close">
            {" "}
            Cancel
          </button>
          <Button
            showLoader={isLoading}
            className="btn btn-primary btn-sm"
            onClick={onClick}>
            Update phone number
          </Button>
        </div>
      </div>
      <APIResponseError response={error} />
    </Modal>
  )
}

export default ChangeLocationPhoneNumberModal
