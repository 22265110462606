import React, { useState } from "react";
import { delete_ } from "../lib/api";
import APIResponseError from "../components/APIResponseError";
import Button, { ButtonVarint } from "../components/Button";

const FacebookDisconnect = ({ onDisconnect }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const onClick = async () => {
    const didConfirm = confirm("Are you sure you want to disconnect Facebook?");
    if (!didConfirm) return;
    try {
      setError(null);
      setIsLoading(true);
      await delete_("/shop/{exid}/fb/disconnect");
      setIsLoading(false);
      onDisconnect();
    } catch (e) {
      console.log(e);
      setError(e);
    }
  };

  return (
    <div>
      <Button
        showLoader={isLoading}
        variant={ButtonVarint.dangerOutline}
        onClick={onClick}
      >
        Disconnect
      </Button>
      <APIResponseError response={error} />
    </div>
  );
};

export default FacebookDisconnect;
